import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FundingProject } from '../core/interfaces/funding-project';
import { GuidelineProjectIds } from '../core/interfaces/guideline-project-ids';
import * as FundingProjectStates from '../funding-project/funding-project-details/utils/fundingProjectStates';
import { APP_CONFIG } from '../core/util/app-config.token';
import { AppConfiguration } from '../core/interfaces/app-configuration';
import { merge, tap } from 'rxjs';
import {
  isLeaderFundingProject,
  getDepartmentNameById,
  PageOption,
  stringToDatestring,
  FundingProjectFilter
} from '../core/public-api';

enum ColumnNames {
  TITLE = 'title',
  STATE_FUNDING_PROJECT = 'stateFundingProject',
  LOCATION = 'location',
  REQUESTED_FUNDING_VALUE = 'requestedFundingValue',
  OAMAN_ID = 'oamanId',
  SUBMISSION_DATE = 'submissionDate',
  GUIDELINE = 'guideline',
  AUTHORITY_INFO = 'authorityInfo',
  USER = 'user',
  ASSIGNED_CLERKS = 'assignedClerks'
}

/**
 * Displays the funding projects in table view with frontend (enroll) or backend (manage) pagination.
 */
@Component({
  selector: 'oaman-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements AfterViewInit, OnChanges {
  displayedColumns: string[] = [
    ColumnNames.TITLE,
    ColumnNames.STATE_FUNDING_PROJECT,
    ColumnNames.USER,
    ColumnNames.LOCATION,
    ColumnNames.ASSIGNED_CLERKS,
    ColumnNames.REQUESTED_FUNDING_VALUE,
    ColumnNames.OAMAN_ID,
    ColumnNames.SUBMISSION_DATE,
    ColumnNames.GUIDELINE,
    ColumnNames.AUTHORITY_INFO
  ];

  dataSource = new MatTableDataSource<FundingProject>();
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Output() openProjectDetails = new EventEmitter<GuidelineProjectIds>();
  manageApp = false;
  @Output() loadPage = new EventEmitter<PageOption>();
  @Input() totalElements!: number;
  @Input() projectFilter!: FundingProjectFilter;

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfiguration) {
    this.manageApp = this.appConfig.context === 'manage';
  }

  ngAfterViewInit() {
    // initializes the paginator and sort after the content is rendered
    this.dataSource.sort = this.sort;
    if (!this.manageApp) {
      this.dataSource.paginator = this.paginator;
    } else {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
      });

      // subscribing to sort changes
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadPageInner()))
        .subscribe();
    }
  }

  loadPageInner(): void {
    let pageOption: PageOption = {
      pageNumber: this.paginator.pageIndex + 1,
      pageSize: this.paginator.pageSize,
      clearResults: true
    };
    if (this.sort.active && this.sort.direction) {
      pageOption.sortBy = this.sort.active;
      pageOption.sortOrder = this.sort.direction;
    }
    this.loadPage.emit(pageOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    // initializes the paginator and sort again after the content has changed
    if (!this.manageApp) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;
  }

  initTable(data: FundingProject[]) {
    //Set the right page number to the paginator
    if (this.manageApp && this.paginator) {
      this.paginator.pageIndex = this.projectFilter.page - 1;
    }

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case ColumnNames.TITLE:
          return item.title;
        case ColumnNames.STATE_FUNDING_PROJECT:
          return item.stateFundingProject.title;
        case ColumnNames.LOCATION:
          return item.location.title;
        case ColumnNames.REQUESTED_FUNDING_VALUE:
          return item.requestedFundingValue;
        case ColumnNames.OAMAN_ID:
          return item.oamanId;
        case ColumnNames.SUBMISSION_DATE:
          if (
            item.fundingRequestDTO !== null &&
            item.fundingRequestDTO.submissionDate
          ) {
            return item.fundingRequestDTO.submissionDate;
          } else {
            return '';
          }
        case ColumnNames.GUIDELINE:
          return item.fundingGuideline.title;
        default: {
          console.error('error in sorting data accessor');
          return item.id;
        }
      }
    };
  }

  @Input()
  set fundingProjects(value: FundingProject[]) {
    if (value) {
      this.initTable(value);
    }
  }

  onRowClick(fundingProject: FundingProject) {
    if (!this.isProjectInaccessible(fundingProject)) {
      this.openProjectDetails.emit(<GuidelineProjectIds>{
        fundingGuidelineId: fundingProject.fundingGuideline.id,
        fundingProjectId: fundingProject.id
      });
    }
  }

  isProjectInaccessible(fundingProject: FundingProject): boolean {
    return (
      !this.manageApp &&
      fundingProject.stateFundingProject.resourceKey ===
        FundingProjectStates.WITHDRAWN
    );
  }

  getAuthorityInfo(fundingProject: FundingProject): string {
    let leaderFundingProject = isLeaderFundingProject(fundingProject);
    if (
      (fundingProject.localActionGroup !== null && leaderFundingProject) ||
      !leaderFundingProject
    ) {
      return (
        fundingProject.authority.title +
        '\n' +
        getDepartmentNameById(fundingProject.authorityLocationNumber)
      );
    } else {
      return '';
    }
  }

  stringToDatestring = stringToDatestring;
}
