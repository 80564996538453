<label class="dropdown-label" for="documentFilter">{{ label }}</label>
<br />
<select
  #selectionRef
  name="filter"
  id="documentFilter"
  class="filter"
  (change)="changeFilter(selectionRef.value)"
  [(ngModel)]="activeSelection"
>
  <option *ngFor="let option of options" [value]="option.key">
    {{ option.value }}
  </option>
</select>
