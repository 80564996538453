<oaman-detail-actions></oaman-detail-actions>
<p class="breadcrumb">
  <a
    tabindex="0"
    role="button"
    (click)="navigateToInquiries()"
    (keyup.enter)="navigateToInquiries()"
  >
    <strong>{{ 'fundingProject.details.links.inquiries' | translate }}</strong>
  </a>
  ->
  <span
    >{{ 'fundingProject.details.inquiries.table.topic' | translate }}:
    {{ activeInquiry?.topic }}</span
  >
</p>
<div class="heading-wrapper">
  <h1>
    {{ 'fundingProject.details.inquiries.table.topic' | translate }}:
    {{ activeInquiry?.topic }}
  </h1>
  <mat-icon
    *ngIf="activeInquiry?.questionAnswered"
    class="confirmation-icon"
    matTooltip="{{
      'fundingProject.details.inquiries.table.confirmationTooltip' | translate
    }}"
    attr.aria-label="{{
      'fundingProject.details.inquiries.table.confirmationTooltip' | translate
    }}"
  >
    done
  </mat-icon>
</div>

<div fxLayout="row" fxLayoutAlign="center">
  <div class="message-action-wrapper">
    <div class="messages-wrapper">
      <div class="messages">
        <div *ngFor="let message of messages">
          <oaman-message [data]="message"></oaman-message>
        </div>
      </div>
    </div>

    <div class="actions" *ngIf="showActions()">
      <oaman-button
        *ngIf="activeInquiry?.questionAnswered; else notAnswered"
        (click)="openAnswerDialog()"
      >
        {{
        'fundingProject.details.inquiries.messages.anotherQuestion' | translate
        }}
      </oaman-button>
      <ng-template #notAnswered
      ><oaman-button (click)="setQuestionAnswered()" style="margin-right: 1rem">
        {{
        'fundingProject.details.inquiries.messages.markAnswered' | translate
        }}</oaman-button
      >
        <oaman-button (click)="openAnswerDialog()">{{
          'fundingProject.details.inquiries.messages.answer' | translate
          }}</oaman-button></ng-template
      >
    </div>
  </div>
</div>
