import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-eb-organisations-field-wrapper',
  template: `
    <span [style.font-weight]="'500'">{{ props.description }}</span>
    <div fxLayout="row wrap">
      <div
        [fxFlex]="first ? '60' : '40'"
        [fxFlex.xs]="'100'"
        [fxFlex.sm]="'100'"
        *ngFor="let field of field.fieldGroup; let first = first"
      >
        <div *ngIf="first" class="wrapper">
          <formly-field fxFlex="90" [field]="field"></formly-field>
          <span fxFlex="10" [style.padding-left]="'.5rem'" *ngIf="first"
            >von</span
          >
        </div>
        <div *ngIf="!first" class="wrapper">
          <formly-field [field]="field"></formly-field>
          <span [style.padding-left]="'.5rem'" *ngIf="!first"
            >(Vordruck AS 510.03)</span
          >
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: flex;
        align-items: center;
      }
    `
  ]
})
export class FormEbOrganisationsFieldWrapperComponent extends FieldWrapper {}
