import { FundingProjectRequest } from './../../../../core/interfaces/funding-project-request';
import { FundingProject } from '../../../../core/interfaces/funding-project';
import { AppConfiguration } from '../../../../core/interfaces/app-configuration';
import { APP_CONFIG } from '../../../../core/util/app-config.token';
import { DialogService } from '../../../../dialog/services/dialog.service';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { DetailAction } from '../../interfaces/detail-actions';
import { ProjectActionService } from '../../services/project-action.service';
import { ActivatedRoute } from '@angular/router';
import { FilterObservableService } from './services/filter-observable.service';
import { Subscription } from 'rxjs';
import { FundingProjectDocument } from './public-api';
import { ProjectActionData } from '../../interfaces/project-action';
import {
  DocumentSubType,
  DocumentType,
  DocumentTypeData
} from '../../../../dialog/interfaces/document-dialog-data';
import { get as _get, find as _find } from 'lodash';
import { isProcessedDocument, isAntrag } from './utils/document-utils';
import { UserObjectObservableService } from '../../../../core/services/user-object-observable.service';
import { BaseComponent } from '../../../../core/base.component';
import { isProjectModifiable } from '../../../../core/util/funding-project-utils';
import { FundingProjectDataService } from '../../../public-api';

@Component({
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsComponent extends BaseComponent implements OnDestroy {
  @ViewChild('routeRef') routeRef: any;
  private documentSubTypes!: DocumentSubType[];
  private documentTypes!: DocumentType[];
  private documents!: FundingProjectDocument[];
  private enrollApp = false;
  // displayed actions
  documentActions: DetailAction[] = [];
  defaultActions: DetailAction[] = [
    {
      name: 'submitDoc',
      icon: 'description',
      event: 'createDocumentDialog'
    }
  ];
  fundingGuidelineId!: string;
  requiredDocumentCount = 0;
  currentDate = new Date();
  filterObservableSubscription: Subscription;
  activeRequest!: string;
  allRequests!: FundingProjectRequest[];
  fundingProject!: FundingProject;
  applicationFilterVisible = false; //make the request filter hidden by default

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private projectActionService: ProjectActionService,
    private filterObservableService: FilterObservableService,
    public userObjectObservableService: UserObjectObservableService,
    private fundingProjectData: FundingProjectDataService
  ) {
    super(userObjectObservableService);
    this.enrollApp = this.appConfig.context === 'enroll';
    this.route.data.subscribe((data) => {
      const resolvedDataRequests: FundingProjectRequest[] = _get(
        data,
        'resolvedData.requests'
      );
      const resolvedDocumentTypeData: DocumentTypeData =
        data['resolvedDocumentTypeData'];
      this.documents = _get(data, 'resolvedData.documents');
      if (resolvedDataRequests && resolvedDocumentTypeData) {
        this.allRequests = resolvedDataRequests;
        this.documentTypes = resolvedDocumentTypeData.documentTypes;
        this.documentSubTypes = resolvedDocumentTypeData.documentSubTypes;
      }
    });
    if (this.route.parent) {
      this.route.parent.data.subscribe((data) => {
        const resolvedData: FundingProject = data['resolvedData'];
        if (resolvedData) {
          this.fundingProject = resolvedData;
          this.fundingGuidelineId = resolvedData.fundingGuideline.id;
        }
      });
    }
    // disable the default actions if the "all douments" filter is selected
    this.filterObservableSubscription =
      this.filterObservableService.filterObject$.subscribe((filter) => {
        this.activeRequest = filter.applicationFilter;
        if (
          filter.applicationFilter === '0' ||
          filter.applicationFilter === 'UNASSIGNED_REQUEST'
        ) {
          this.documentActions = [];
        } else {
          this.documentActions = this.defaultActions;
        }
      });
  }

  openDialog(event: string) {
    if (event === 'createDocumentDialog') {
      this.dialogService.openCreateDocumentDialog().subscribe((result) => {
        // if result is not false, it contains the data for the new document
        if (result) {
          result.fundingProjectId = this.fundingProject.id;
          result.requestId = this.activeRequest;
          result.fundingGuidelineId = this.fundingGuidelineId;
          this.projectActionService.setAction('create', result);
        }
      });
      return;
    }

    if (event === 'requestDocumentDialog') {
      this.dialogService.openRequestDocumentDialog().subscribe((result) => {
        // if result is not false, it contains the data for the requested document
        if (result) {
          result.data.fundingProjectId = this.fundingProject.id;
          result.data.requestId = this.activeRequest;
          result.data.fundingGuidelineId = this.fundingGuidelineId;
          this.projectActionService.setAction('request', result.data);
          if (result.repeat) {
            this.openDialog('requestDocumentDialog');
          }
        }
      });
      return;
    }

    if (event === 'provideDocumentDialog') {
      this.dialogService
        .openProvideDocumentDialog(
          this.allRequests,
          this.activeRequest,
          this.documentTypes,
          this.documentSubTypes,
          this.fundingProject.title,
          this.fundingProject.id
        )
        .subscribe((result) => {
          // the result should contains the data for the provided document
          if (result) {
            const documentCreationData: ProjectActionData = {
              fundingGuidelineId: this.fundingGuidelineId,
              documentCreationData: result.data,
              fundingProjectId: this.fundingProject.id
            };
            this.projectActionService.setAction(
              'createDocument',
              documentCreationData
            );
          }
        });
      return;
    }

    if (event === 'uploadFileDialog') {
      this.openUploadDialog();
    }
  }

  openUploadDialog() {
    this.route.firstChild?.params.subscribe((currentDocument) => {
      if (currentDocument.id && this.documents) {
        this.documents.forEach((document: FundingProjectDocument) => {
          if (document.id == currentDocument.id) {
            this.dialogService
              .openUploadDialog(
                document.title,
                this.fundingProject.id,
                document.id
              )
              .subscribe((dialogResult) => {
                if (dialogResult) {
                  // append document id to formdata
                  dialogResult.data.append(
                    'applicantDocumentId',
                    new Blob([JSON.stringify(document.id)], {
                      type: 'application/json'
                    })
                  );

                  const fileUploadData: ProjectActionData = {
                    fundingProjectId: this.fundingProject.id,
                    fundingGuidelineId: this.fundingGuidelineId,
                    documentId: document.id,
                    fileUploadData: dialogResult.data,
                    fileUploadContext: 'fileTable'
                  };
                  this.projectActionService.setAction(
                    'uploadFile',
                    fileUploadData
                  );
                }
              });
          }
        });
      }
    });
  }

  onActivate(componentRef: any) {
    this.defaultActions = [];
    this.applicationFilterVisible = true;
    if (componentRef.tableType === 'documents') {
      if (this.enrollApp) {
        this.defaultActions = [
          {
            name: 'submitDoc',
            icon: 'description',
            event: 'createDocumentDialog'
          }
        ];
      } else if (
        isProjectModifiable(
          this.fundingProject,
          this.currentUser,
          !this.enrollApp
        )
      ) {
        this.defaultActions = [
          {
            name: 'createDoc',
            icon: 'description',
            event: 'provideDocumentDialog'
          },
          {
            name: 'requestDoc',
            icon: 'description',
            event: 'requestDocumentDialog'
          }
        ];
      }
    } else if (componentRef.tableType === 'files') {
      if (this.enrollApp && this.isCurrentDocumentModifiable()) {
        this.defaultActions = [
          { name: 'uploadFile', icon: 'description', event: 'uploadFileDialog' }
        ];
      }
      // the request filter must be hidden for the document files table
      this.applicationFilterVisible = false;
    }
    this.documentActions = this.defaultActions;
  }

  isCurrentDocumentModifiable(): boolean {
    const documentId = this.route.firstChild?.snapshot.paramMap.get('id');
    if (!documentId || !this.documents) {
      return false;
    }

    const document = _find(this.documents, ['id', documentId]);
    // the document is allowed for modifications only if wasn't already submitted or delivered and if it is not a Antrag
    return document
      ? !isProcessedDocument(document) &&
          !isAntrag(document) &&
          this.isDocumentModificationAuthorized(document)
      : false;
  }

  private isDocumentModificationAuthorized(
    document: FundingProjectDocument
  ): boolean {
    const isDocumentModificationAuthorized =
      this.fundingProjectData.hasProjectAuthority('documentChange');

    // Note: the document modification should be allowed if the document was created by the currently logged in User
    return isDocumentModificationAuthorized
      ? true
      : document.author.id === this.currentUser.id;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.filterObservableSubscription?.unsubscribe();
  }
}
