import { AppConfigService } from './../../../../../../../core/config/app-config.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { BackendVersionResponse } from 'oaman-components';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private env: AppConfigService
  ) {}

  fetchBackendVersions(): Observable<string> {
    return this.http
      .get<BackendVersionResponse>(
        this.env.getConfig().backendApiUrl + '/version'
      )
      .pipe(
        startWith({ version: '...' }),
        map((response) => response.version),
        catchError(() => this.translate.get('about.error'))
      );
  }
}
