import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-eb-organisations-base-data-wrapper',
  template: `
    <div *ngFor="let field of field.fieldGroup">
      <formly-field [field]="field"></formly-field>
    </div>
    <div>
      <p>{{ props.text1 }}</p>
      <p>{{ props.text2 }}</p>
      <p>{{ props.text3 }}</p>
    </div>
  `,
  styles: []
})
export class FormEbOrganisationsBaseDataWrapperComponent extends FieldWrapper {}
