import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-eb-organisations-designation-wrapper',
  template: `
    <div *ngIf="props.description">{{ props.description }}</div>
    <div fxLayout="row wrap">
      <div
        [style.padding-right]="'1rem'"
        *ngFor="let field of field.fieldGroup"
      >
        <formly-field [field]="field"></formly-field>
      </div>
    </div>
  `,
  styles: []
})
export class FormEbOrganisationsDesignationWrapperComponent extends FieldWrapper {}
