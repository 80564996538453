<oaman-detail-actions
  [actions]="projectActions"
  [fundingProject]="fundingProject"
  (clickTriggered)="openDialog($event)"
></oaman-detail-actions>
<div fxLayout="row wrap" fxLayout.lt-sm="column nowrap" class="detail-wrapper">
  <div class="detail-list-wrapper" fxFlex="60">
    <mat-list fxLayout="column wrap">
      <mat-list-item *ngFor="let item of items; index as i">
        <span fxFlex="45" class="item-title">{{
          'fundingProject.details.project.items.' + item | translate
        }}</span>
        <span fxFlex="45" class="item-value">{{ values[i] }}</span>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxFlex="40">
    <img
      [src]="defaultImage"
      [title]="'fundingProject.details.project.image' | translate"
      [alt]="'fundingProject.details.project.image' | translate"
    />
  </div>
</div>
