<h2 mat-dialog-title>
  {{ 'fundingProject.details.inquiries.dialog.createInquiry.header' | translate }}
</h2>
<form [formGroup]="createForm" (ngSubmit)="onSubmit()" novalidate>
  <mat-dialog-content class="mat-typography">
    <p>
      {{
        'fundingProject.details.inquiries.dialog.createInquiry.body'
          | translate
      }}
    </p>
    <p>
      <label for="topic">
        <strong>{{
          'fundingProject.details.inquiries.dialog.createInquiry.topicLabel'
            | translate
        }}</strong>
        <span [style.color]="'red'"> *</span>
      </label>
      <input
        id="topic"
        formControlName="topic"
        [style.width]="'100%'"
        [style.height]="'1.5rem'"
        type="text"
      />
    </p>
    <p>
      <label for="question"
        ><strong>{{
          'fundingProject.details.inquiries.dialog.createInquiry.questionLabel'
            | translate
        }}</strong>
        <span [style.color]="'red'"> *</span>
      </label
      >
      <textarea
        id="question"
        formControlName="question"
        [style.width]="'100%'"
        [style.resize]="'none'"
        [style.font]="'inherit'"
        rows="6"
      ></textarea>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <oaman-button
      mat-button
      [mat-dialog-close]="false"
      type="reset"
      [style.margin-right]="'1rem'"
    >
      {{
        'fundingProject.details.inquiries.dialog.createInquiry.cancelBtn'
          | translate
      }}
    </oaman-button>
    <oaman-button
      mat-button
      color="primary"
      [disabled]="!createForm.valid"
      [style.pointer-events]="!createForm.valid ? 'none' : 'auto'"
      type="submit"
    >
      {{
        'fundingProject.details.inquiries.dialog.createInquiry.createBtn'
          | translate
      }}
    </oaman-button>
  </mat-dialog-actions>
</form>
