<h2 mat-dialog-title>
  {{ 'fundingProject.details.project.dialog.edit.dialogTitle' | translate }}
</h2>

<form
  [formGroup]="editForm"
  class="project-edit-form"
  (ngSubmit)="onSubmit()"
  novalidate
>
  <mat-dialog-content class="mat-typography">
    <p>
      {{ 'fundingProject.details.project.dialog.edit.message1' | translate }}
    </p>

    <div class="form-field">
      <div class="label required">
        <label for="project-title">
          {{ 'fundingProject.details.project.dialog.edit.title' | translate }}
          <span [style.color]="'red'"> *</span>
        </label>
      </div>
      <input
        id="project-title"
        class="input-field"
        formControlName="title"
        type="text"
        required
      />
      <ng-container
        *ngTemplateOutlet="validationMessages; context: { $implicit: 'title' }"
      ></ng-container>
    </div>

    <div class="form-field">
      <div class="label required">
        <label for="project-description">
          {{
            'fundingProject.details.project.dialog.edit.description' | translate
          }}
          <span [style.color]="'red'"> *</span>
        </label>
      </div>
      <textarea
        class="input-field"
        [style.resize]="'none'"
        id="project-description"
        formControlName="description"
        rows="10"
        required
      ></textarea>
      <ng-container
        *ngTemplateOutlet="
          validationMessages;
          context: { $implicit: 'description' }
        "
      ></ng-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <oaman-button
      [mat-dialog-close]="false"
      [style.margin-right]="'1rem'"
      type="reset"
    >
      {{ 'fundingProject.details.project.dialog.cancelBtn' | translate }}
    </oaman-button>
    <oaman-button
      color="primary"
      [disabled]="!editForm.valid || this.activeProgressBar"
      type="submit"
    >
      {{ 'fundingProject.details.project.dialog.save' | translate }}
    </oaman-button>
  </mat-dialog-actions>

  <mat-progress-bar
    mode="determinate"
    *ngIf="activeProgressBar"
    value="20"
    class="full-width"
  ></mat-progress-bar>
</form>

<ng-template #validationMessages let-name>
  <div
    class="validation-msg"
    *ngIf="
      editForm.controls[name].invalid &&
      (editForm.get(name)?.dirty || editForm.get(name)?.touched)
    "
  >
    <div *ngIf="editForm.get(name)?.errors?.required">
      {{ 'validationErrorMessages.required' | translate }}
    </div>
    <div *ngIf="editForm.get(name)?.errors?.minlength">
      {{ 'validationErrorMessages.minLength' | translate }}
    </div>
    <div *ngIf="editForm.get(name)?.errors?.maxlength">
      {{ 'validationErrorMessages.maxLength' | translate: {lengthVar: maxLengthFundingProjectTitle} }}
    </div>
    <div *ngIf="editForm.get(name)?.errors?.pattern">
      {{ 'validationErrorMessages.forbiddenCharacters' | translate: { forbiddenChars: forbiddenSpecialChars } }}
    </div>
  </div>
</ng-template>
