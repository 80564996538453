import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppConfiguration } from '../../../../../core/interfaces/app-configuration';
import { DialogService } from '../../../../../dialog/services/dialog.service';
import { APP_CONFIG } from '../../../../../core/util/app-config.token';
import { Inquiry } from '../interfaces/inquiry';
import { FundingProject } from '../../../../../core/interfaces/funding-project';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectActionService } from '../../../services/project-action.service';
import { DetailAction } from '../../../interfaces/detail-actions';
import { UserObjectObservableService } from '../../../../../core/services/user-object-observable.service';
import { BaseComponent } from '../../../../../core/base.component';
import { isProjectModifiable } from '../../../../../core/util/funding-project-utils';
import { findIndex as _findIndex } from 'lodash';

@Component({
  templateUrl: './inquiries-table.component.html',
  styleUrls: ['./inquiries-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InquiriesTableComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  // displayed actions
  inquiryActions: DetailAction[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  private fundingGuidelineId!: string;
  private navigationSubscription!: Subscription;
  private manageApp = false;
  inquiries!: Inquiry[];
  fundingProject!: FundingProject;
  dataSource = new MatTableDataSource<Inquiry>();
  displayedColumns: string[] = [
    'status',
    'topic',
    'createdDate',
    'lastPostBy',
    'lastPostDate'
  ];
  currentDate = new Date();

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    private projectActionService: ProjectActionService,
    public userObjectObservableService: UserObjectObservableService
  ) {
    super(userObjectObservableService);
    this.manageApp = this.appConfig.context === 'manage';
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialize the component
      if (e instanceof NavigationEnd) {
        this.initializeInquiries();
      }
    });
  }

  ngOnInit(): void {
    this.initializeInquiries();
  }

  ngAfterViewInit() {
    this.initTablePaginatorAndSort();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navigationSubscription?.unsubscribe();
  }

  private initializeInquiries() {
    this.route.parent?.data.subscribe((data) => {
      const resolvedData: Inquiry[] = data['resolvedData'];
      if (resolvedData) {
        this.inquiries = resolvedData;
        this.initTable(this.inquiries);
      }
    });

    this.route.parent?.parent?.data.subscribe((data) => {
      const resolvedData: FundingProject = data['resolvedData'];
      if (resolvedData) {
        this.fundingProject = resolvedData;
        this.fundingGuidelineId = resolvedData.fundingGuideline.id;

        if (
          this.isInquiryActionNotInitialized('createTopic') &&
          isProjectModifiable(
            this.fundingProject,
            this.currentUser,
            this.manageApp
          )
        ) {
          this.inquiryActions.push({
            name: 'createTopic',
            icon: 'description'
          });
        }
      }
    });
  }

  private isInquiryActionNotInitialized(actionName: string): boolean {
    // checks if the given action name was already added to the actions column
    return _findIndex(this.inquiryActions, ['name', actionName]) === -1;
  }

  private initTablePaginatorAndSort() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private initTable(data: Inquiry[]) {
    this.dataSource = new MatTableDataSource([...data]);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'topic':
          return item.topic;
        case 'createdDate':
          return item.createdDate;
        case 'lastPostBy':
          return item.lastPostBy.fullName;
        case 'lastPostDate':
          return item.lastPostDate;
        default: {
          console.error('error in sorting data accessor');
          return item.topic;
        }
      }
    };
    // initializes the paginator and sort again after the content has changed
    this.initTablePaginatorAndSort();
  }
  showMessages(id: number) {
    this.router.navigate([id], { relativeTo: this.route }).then();
  }

  openDialog() {
    this.dialogService.openCreateInquiryDialog().subscribe((result) => {
      // if result is not false, it contains the data for the new inquiry
      if (result) {
        result.fundingProjectId = this.fundingProject.id;
        result.fundingGuidelineId = this.fundingGuidelineId;
        this.projectActionService.setAction('createInquiryTopic', result);
      }
    });
  }
}
