import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '../../core/authentication/services/auth.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/state';
import { isAuthorized } from '../../core/authentication/state/auth.selectors';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  authorized$: Observable<boolean> = this.store.select(isAuthorized);

  constructor(
    private authService: AuthService,
    private store: Store<AppState>
  ) {}

  login(): void {
    this.authService.startAuthentication();
  }
}
