import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'oaman-form-year-type',
  template: `
    <input
      type="number"
      [formControl]="formControl"
      [formlyAttributes]="field"
      oamanCleanNumber
    />
  `
})
export class FormYearTypeComponent extends FieldType<FieldTypeConfig> {}
