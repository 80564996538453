import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar.component';
import { SnackBar, SnackBarType } from '../interfaces/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  private horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private matSnackBar: MatSnackBar) {}

  /***
   * This method uses the custom SnackBarComponent
   * which is built upon the Material default layout
   * @param displayMessage
   * @param buttonText
   */
  show(
    displayMessage: string,
    buttonText?: string,
    snackBarType?: SnackBarType
  ) {
    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: <SnackBar>{
        message: displayMessage,
        buttonText: buttonText
      },
      horizontalPosition: this.getHorizontalPosiiton(snackBarType),
      verticalPosition: this.getVerticalPosiiton(snackBarType),
      duration: 5000,
      panelClass: this.getPanelClass(snackBarType)
    });
  }

  private getHorizontalPosiiton(
    snackBarType?: SnackBarType
  ): MatSnackBarHorizontalPosition | undefined {
    return SnackBarType.Error === snackBarType
      ? 'center'
      : this.horizontalPosition;
  }

  private getVerticalPosiiton(
    snackBarType?: SnackBarType
  ): MatSnackBarVerticalPosition | undefined {
    return SnackBarType.Error === snackBarType ? 'top' : this.verticalPosition;
  }

  private getPanelClass(
    snackBarType?: SnackBarType
  ): string | string[] | undefined {
    return snackBarType;
  }
}
