import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  FundingProject,
  User,
  UserObjectObservableService
} from '../../core/public-api';
import { hasProjectAuthority } from '../utils/funding-project-authority';

@Injectable({ providedIn: 'root' })
export class FundingProjectDataService implements OnDestroy {
  private userObject?: User;
  private fundingProject?: FundingProject;
  private userObjectSubscription!: Subscription;
  private fundingProjectSubject: Subject<FundingProject> =
    new ReplaySubject<FundingProject>(1);
  private _displayActiveDeputiesOnly: boolean = false;

  constructor(
    private userObjectObservableService: UserObjectObservableService
  ) {
    this.userObjectSubscription =
      this.userObjectObservableService.userObject$.subscribe(
        (user: User) => (this.userObject = user)
      );
  }

  ngOnDestroy(): void {
    this.userObjectSubscription?.unsubscribe();
  }

  getFundingProject(): Observable<FundingProject> {
    return this.fundingProjectSubject
      .asObservable()
      .pipe(filter((node) => !!node));
  }

  publish(data: FundingProject) {
    this.setFundingProject(data);
    this.fundingProjectSubject.next(data);
  }

  hasProjectAuthority(
    actionKey: string,
    updateProjectData?: boolean,
    fundingProject?: FundingProject
  ): boolean {
    if (updateProjectData) {
      this.setFundingProject(fundingProject);
    }
    if (!this.fundingProject) {
      return false;
    }

    return hasProjectAuthority(
      this.userObject?.id,
      this.fundingProject,
      actionKey
    );
  }

  isCreatedByCurrentUser(authorId?: string): boolean {
    if (!authorId || !this.userObject) {
      return false;
    }
    return authorId === this.userObject?.id;
  }

  private setFundingProject(data?: FundingProject) {
    this.fundingProject = data;
  }

  public get displayActiveDeputiesOnly(): boolean {
    return this._displayActiveDeputiesOnly;
  }
  public set displayActiveDeputiesOnly(value: boolean) {
    this._displayActiveDeputiesOnly = value;
  }
}
