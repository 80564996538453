<h2 mat-dialog-title>
  {{
    'fundingProject.details.authorization.dialog.inactivateDeputy.title'
      | translate
  }}
</h2>
<mat-dialog-content class="mat-typography inactivation-content">
  <span>
    {{
      'fundingProject.details.authorization.dialog.inactivateDeputy.descriptionPart1'
        | translate
    }}
  </span>
  <span class="user-name">
    {{ data.userName }}
  </span>
  <span>
    {{
      'fundingProject.details.authorization.dialog.inactivateDeputy.descriptionPart2'
        | translate
    }}
  </span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button
    mat-button
    type="reset"
    [style.margin-right]="'1rem'"
    [mat-dialog-close]="false"
  >
    {{ 'fundingProject.details.authorization.dialog.cancelBtn' | translate }}
  </oaman-button>
  <oaman-button
    mat-button
    color="primary"
    type="submit"
    [mat-dialog-close]="true"
  >
    {{
      'fundingProject.details.authorization.dialog.inactivateDeputy.submitBtn'
        | translate
    }}
  </oaman-button>
</mat-dialog-actions>
