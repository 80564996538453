import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ErrorDialogActionService {
  private subject = new Subject<void>();
  private loginPath = '/login';

  logout() {
    this.subject.next();
    // as the logout page is not accessible without a valid session we have to redirect to login here
    window.location.href = this.loginPath;
  }

  onAction() {
    return this.subject.asObservable();
  }
}
