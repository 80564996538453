import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export const DEFAULT_MAX_FRACTIONAL_DIGITS: number = 2;
export const DEFAULT_TRUNCATE_FRACTIONAL_DIGITS: boolean = true;
export const DEFAULT_FILL_UP: boolean = false;

/**
 * Subscribes the correction of the decimal value for the valueChanges event of a specific
 * field of a FormGroup to the defined amount of fractional digits.
 *
 * @param formGroup The FormGroup object
 * @param fieldName The Name of the field in the FormGroup object
 * @param maxFractionalDigits The maximal number of fractional digits of an input value (Default=DEFAULT_MAX_FRACTIONAL_DIGITS)
 * @param truncateFractionalDigits true = fractional digits > maxFractionalDigits are cut off and
 * false fractional digits > maxFractionalDigits are rounded (Default=DEFAULT_TRUNCATE_FRACTIONAL_DIGITS)
 * @param fillUp Fills the fraction digits up to the maxFractionalDigits value
 * (e.g. maxFractionalDigits = 2 and value = 1.0 -> result is 1,00) (Default=DEFAULT_FILL_UP)
 * @param kommaSeperator Changes the result from 1.0 to 1,0
 *
 * @returns true if the subscription was successful otherwise false
 */
export function subscribeDecimalFormFieldCorrection(
  formGroup: UntypedFormGroup,
  fieldName: string,
  maxFractionalDigits = DEFAULT_MAX_FRACTIONAL_DIGITS,
  truncateFractionalDigits = DEFAULT_TRUNCATE_FRACTIONAL_DIGITS,
  fillUp = DEFAULT_FILL_UP,
  kommaSeperator = false
): boolean {
  if (formGroup != null && formGroup.get(fieldName) != null) {
    const field = formGroup.get(fieldName) as AbstractControl;
    return subscribeDecimalFieldCorrection(
      field,
      maxFractionalDigits,
      truncateFractionalDigits,
      fillUp,
      kommaSeperator
    );
  } else {
    console.error(
      'FormGroup or field "' +
        fieldName +
        '" not defined for subscribeDecimalFormFieldCorrection(...)!'
    );
  }
  return false;
}

/**
 * Subscribes to the correction of the decimal value for the valueChanges event of a given
 * field to the defined amount of fractional digits.
 *
 * @param field The AbstractControl object
 * @param maxFractionalDigits The maximal number of fractional digits of an input value (Default=DEFAULT_MAX_FRACTIONAL_DIGITS)
 * @param truncateFractionalDigits true = fractional digits > maxFractionalDigits are cut off and
 * false fractional digits > maxFractionalDigits are rounded (Default=DEFAULT_TRUNCATE_FRACTIONAL_DIGITS)
 * @param fillUp Fills the fraction digits up to the maxFractionalDigits value
 * (e.g. maxFractionalDigits = 2 and value = 1.0 -> result is 1,00) (Default=DEFAULT_FILL_UP)
 * @param kommaSeperator Changes the result from 1.0 to 1,0
 *
 * @returns true if the subscription was successful otherwise false
 */
export function subscribeDecimalFieldCorrection(
  field: AbstractControl,
  maxFractionalDigits = DEFAULT_MAX_FRACTIONAL_DIGITS,
  truncateFractionalDigits = DEFAULT_TRUNCATE_FRACTIONAL_DIGITS,
  fillUp = DEFAULT_FILL_UP,
  kommaSeperator = false
): boolean {
  if (field != null) {
    field?.valueChanges.subscribe(() =>
      correctDecimalFieldValue(
        field,
        maxFractionalDigits,
        truncateFractionalDigits,
        fillUp,
        kommaSeperator
      )
    );
    return true;
  } else {
    console.error(
      'AbstractControl not defined for subscribeDecimalFieldCorrection(...)!'
    );
  }
  return false;
}

/**
 * Removes cost relevant characters like € signs form the string to get a clean number.
 *
 * Attention: Works only for currency 'EUR' right now!
 *
 * @param value The cost number
 * @returns The cleaned cost number
 */
function cleanNumberString(value: any) {
  if (
    value &&
    typeof value === 'string' &&
    (value.includes(',') ||
      value.includes(' €') ||
      value.includes(' €') ||
      (value.match(/./g) || []).length > 1)
  ) {
    // Check if the value contains chars not allowed in a number
    return value
      .replace(/,/g, '.')
      .replace(/[.](?=.*[.])/g, '')
      .replace(' €', '')
      .replace(' €', '')
      .trim();
  }
  return value;
}

/**
 * Correction of the decimal value of a given field to the defined amount of fractional digits.
 *
 * @param field The AbstractControl object
 * @param maxFractionalDigits The maximal number of fractional digits of an input value (Default=DEFAULT_MAX_FRACTIONAL_DIGITS)
 * @param truncateFractionalDigits true = fractional digits > maxFractionalDigits are cut off and
 * false fractional digits > maxFractionalDigits are rounded (Default=DEFAULT_TRUNCATE_FRACTIONAL_DIGITS)
 * @param fillUp Fills the fraction digits up to the maxFractionalDigits value
 * (e.g. maxFractionalDigits = 2 and value = 1.0 -> result is 1.00) (Default=DEFAULT_FILL_UP)
 * @param kommaSeperator Changes the result from 1.0 to 1,0
 */
export function correctDecimalFieldValue(
  field: AbstractControl,
  maxFractionalDigits = DEFAULT_MAX_FRACTIONAL_DIGITS,
  truncateFractionalDigits = DEFAULT_TRUNCATE_FRACTIONAL_DIGITS,
  fillUp = DEFAULT_FILL_UP,
  kommaSeperator = false
): void {
  if (field != null) {
    const curFractionalDigits = countFractionalDigits(field?.value);
    if (curFractionalDigits > maxFractionalDigits) {
      field?.setValue(
        correctDecimalNumberString(
          field.value,
          maxFractionalDigits,
          truncateFractionalDigits,
          fillUp,
          kommaSeperator
        )
      );
    }
  } else {
    console.error(
      'AbstractControl not defined for correctDecimalFieldValue(...)!'
    );
  }
}

/**
 * Adjustment of a given number to the defined amount of fractional digits.
 *
 * @param val The number to correct
 * @param maxFractionalDigits The maximal number of fractional digits of an input value (Default=DEFAULT_MAX_FRACTIONAL_DIGITS)
 * @param truncateFractionalDigits true = fractional digits > maxFractionalDigits are cut off and
 * false fractional digits > maxFractionalDigits are rounded (Default=DEFAULT_TRUNCATE_FRACTIONAL_DIGITS)
 *
 * @returns The corrected number
 */
export function correctDecimalNumber(
  val: number | string,
  maxFractionalDigits = DEFAULT_MAX_FRACTIONAL_DIGITS,
  truncateFractionalDigits = DEFAULT_TRUNCATE_FRACTIONAL_DIGITS
): number {
  if (val) {
    const intNum =
      typeof val === 'string' ? cleanNumberString(val) : val.toString();
    return parseFloat(
      correctDecimalNumberString(
        intNum,
        maxFractionalDigits,
        truncateFractionalDigits,
        false
      )
    );
  } else {
    return typeof val === 'string' ? NaN : val;
  }
}

/**
 * Adjustment of a given number as string to the defined amount of fractional digits.
 *
 * @param val The number to correct
 * @param maxFractionalDigits The maximal number of fractional digits of an input value (Default=DEFAULT_MAX_FRACTIONAL_DIGITS)
 * @param truncateFractionalDigits true = fractional digits > maxFractionalDigits are cut off and
 * false fractional digits > maxFractionalDigits are rounded (Default=DEFAULT_TRUNCATE_FRACTIONAL_DIGITS)
 * @param fillUp Fills the fraction digits up to the maxFractionalDigits value
 * (e.g. maxFractionalDigits = 2 and value = 1.0 -> result is 1.00) (Default=DEFAULT_FILL_UP)
 * @param kommaSeperator Changes the result from 1.0 to 1,0
 *
 * @returns The corrected number as string
 */
export function correctDecimalNumberString(
  val: string,
  maxFractionalDigits = DEFAULT_MAX_FRACTIONAL_DIGITS,
  truncateFractionalDigits = DEFAULT_TRUNCATE_FRACTIONAL_DIGITS,
  fillUp = DEFAULT_FILL_UP,
  kommaSeperator = false
): string {
  if (val) {
    let calcVal = parseFloat(cleanNumberString(val));

    if (!isNaN(calcVal)) {
      if (maxFractionalDigits == null || maxFractionalDigits < 0) {
        maxFractionalDigits = 0;
      }

      let currentFractionalDigits = countFractionalDigits(calcVal);
      if (currentFractionalDigits >= maxFractionalDigits) {
        if (truncateFractionalDigits) {
          val = toFixed(calcVal, maxFractionalDigits);
        } else {
          let fractionPotency = Math.pow(10, maxFractionalDigits);
          val = (
            Math.round((calcVal + Number.EPSILON) * fractionPotency) /
            fractionPotency
          ).toFixed(maxFractionalDigits);
        }
      } else if (fillUp) {
        val = calcVal.toFixed(maxFractionalDigits);
      }

      val = kommaSeperator ? val.replace(/\./g, ',') : val;
    }
  }
  return val;
}

/**
 * Returns a string representing a number in fixed-point notation. The number will be changed from e.g. 1.0 to 1.00.
 * If the parameter val is not a number a '0' is returned. If the parameter val has more fractions then is defined in
 * parmeter fractionalDigits, the fractions are cutted without rounding. If rounding is needed use Number.toFixed or
 * Math.round!
 *
 * @param val The number to correct
 * @param fractionalDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 *
 * @returns The corrected number as string
 */
export function toFixed(num: number, fractionalDigits: number): string {
  if (num != null && !isNaN(num)) {
    if (
      fractionalDigits != null &&
      !isNaN(fractionalDigits) &&
      fractionalDigits > 0
    ) {
      const matcher = new RegExp(
        '^-?\\d+(?:.\\d{0,' + (fractionalDigits || -1) + '})?'
      );

      const result = num.toString().match(matcher);
      if (result) {
        return parseFloat(result[0]).toFixed(fractionalDigits);
      }
    }

    return num.toFixed(fractionalDigits);
  }
  return parseFloat('0').toFixed(fractionalDigits);
}

/**
 * Determines the fractional digits of a given number.
 *
 * @param num The number
 * @returns The amount of fractional digits of a given number or 0 if the number passed is not defined or is not a number.
 */
export function countFractionalDigits(num: number | string): number {
  if (!num) return 0;
  const intNum =
    typeof num === 'string' ? Number.parseFloat(cleanNumberString(num)) : num;
  if (isNaN(intNum) || Math.floor(intNum.valueOf()) === intNum.valueOf())
    return 0;

  const numArray: Array<string> = intNum.toString().split('.');
  if (numArray.length > 1) return numArray[1].length;
  return 0;
}
