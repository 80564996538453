import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './core/state';
import {
  getAuthentication,
  notLoggedIn
} from './core/authentication/state/auth.actions';
import { ErrorDialogActionService } from 'oaman-components';
import { AuthService } from './core/authentication/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'oaman-enroll';
  newFooterRoutes = ['/login', '/contact'];

  constructor(
    private translateService: TranslateService,
    private store: Store<AppState>,
    private errorDialogActionService: ErrorDialogActionService,
    private authService: AuthService,
    private cookie: CookieService,
    public router: Router
  ) {
    this.translateService.setDefaultLang('de');
    this.translateService.use('de');
  }

  ngOnInit(): void {
    this.errorDialogActionService
      .onAction()
      .subscribe(() => this.authService.logout());

    const authStatus = this.cookie.get('AUTH_STATUS');
    if (authStatus === 'LOGGED_IN') {
      // if the user is already logged in we must try to fetch the User information
      this.store.dispatch(getAuthentication());
    } else {
      // otherwise we have to redirect to login page
      this.store.dispatch(notLoggedIn());
    }
  }
}
