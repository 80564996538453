import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-year-wrapper',
  template: `
    <div [style.display]="'flex'">
      <label [attr.for]="id" *ngIf="props.label">
        <ng-container>
          {{ props.label }}
        </ng-container>
      </label>
      <div class="field">
        <ng-template #fieldComponent></ng-template>
        <div *ngIf="showError" class="error-msg">
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .field {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
      }
      .error-msg {
        margin-top: 0.2rem;
        color: #ff002d;
      }
    `
  ]
})
export class FormYearWrapperComponent
  extends FieldWrapper
  implements AfterViewInit
{
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }
  ngAfterViewInit() {
    if (!this.formControl.valid && this.formControl.value) {
      this.formControl.markAllAsTouched();
      this.formControl.markAsDirty();
      this.changeDetectorRef.detectChanges();
    }
  }
}
