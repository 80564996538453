import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserObjectObservableService } from '../core/services/user-object-observable.service';
import { User } from '../core/interfaces/user';

/**
 * Can only be used as base component, having the advantage of accessing the currently logged in user in currentUser field.
 */
@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
  // Don't let the outside world trigger this _userDestroy signal.
  // It's only meant to be trigger by the component when destroyed!
  private _userDestroy = new Subject<void>();
  public currentUser!: User;

  constructor(public userObjectObservableService: UserObjectObservableService) {
    this.userObjectObservableService.userObject$
      .pipe(takeUntil(this._userDestroy))
      .subscribe((user: User) => {
        this.currentUser = user;
      });
  }

  /** Lifecycle hook called by parent components. */
  ngOnDestroy(): void {
    this._userDestroy?.next();
    this._userDestroy?.complete();
  }
}
