import { Component } from '@angular/core';

@Component({
  selector: 'app-info-bundidkonto',
  templateUrl: './info-bundidkonto.component.html',
  styleUrls: ['./info-bundidkonto.component.scss']
})
export class InfoBundIdKontoComponent {
  panelOpenState: Array<boolean> = [];
  panelHeaderHeight = '100%';
}
