import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssignClerkDialogData } from '../interfaces/assign-clerk-dialog-data';
import {
  Department,
  getDepartmentNameById,
  getDepartmentsBySlaAuthorityId,
  User
} from '../../core/public-api';
import { SearchComponent } from '../../funding-project/search/search.component';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Component({
  selector: 'oaman-assign-clerk-dialog',
  templateUrl: './assign-clerk-dialog.component.html',
  styleUrls: ['./assign-clerk-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignClerkDialogComponent implements OnInit {
  @ViewChild('searchField') searchField!: SearchComponent;
  constructor(
    private dialogRef: MatDialogRef<AssignClerkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssignClerkDialogData,
    private translate: TranslateService
  ) {
    data.allUsers = [
      data.currentUser,
      ...data.allUsers.filter(
        (user) =>
          user.authority.slaAuthorityId ===
            data.projectAuthority.slaAuthorityId &&
          user.id !== data.currentUser.id
      )
    ];
    this.displayedUsers = data.allUsers;
  }

  departments: Department[] = [];
  displayedUsers: User[];
  selected: string = '';
  getDepartmentNameById = getDepartmentNameById;
  searchFieldStyle = 'width: 100%';

  ngOnInit(): void {
    const arlDepartments = getDepartmentsBySlaAuthorityId(
      this.data.projectAuthority.slaAuthorityId
    );
    this.departments = _.sortBy(arlDepartments, [
      (department) => department.name
    ]);
    this.departments.unshift({
      id: '',
      name: `${this.translate.instant(
        'fundingProject.search.state.allDepartments'
      )} (${this.data.projectAuthority.title})`
    });
    this.selected = this.departments[0].id;
  }

  onSelectUser(selectedClerkId: string) {
    this.dialogRef.close(selectedClerkId);
  }

  onSelectDepartment(departmentId: string, clearInput: boolean = true) {
    this.displayedUsers = !departmentId
      ? this.data.allUsers
      : this.data.allUsers.filter(
          (user) => user.authorityLocationNumber === departmentId
        );
    if (clearInput) {
      this.searchField.clearSearchField();
    }
  }

  compareNames(userName: string, searchName: string) {
    return userName.toLowerCase().search(searchName.toLowerCase()) >= 0;
  }
  onSearch(searchName: string) {
    this.onSelectDepartment(this.selected, false);
    this.displayedUsers = this.displayedUsers.filter((user) =>
      this.compareNames(user.fullName, searchName)
    );
  }
}
