import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';

@Component({
  selector: 'oaman-menu',
  templateUrl: './menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  @Input()
  authorized$!: Observable<boolean>;
  @Input()
  initials$ = new Observable<string>();
  @Output()
  logoutEvent = new EventEmitter<MouseEvent>();

  @ViewChild('matMenuTrigger')
  matMenuTrigger!: MatMenuTrigger;

  onKeyupEnter() {
    this.matMenuTrigger.openMenu();
  }

  logout($event: MouseEvent) {
    this.logoutEvent.emit($event);
  }
}
