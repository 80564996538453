import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProfileInformation } from '../../core/interfaces/profile-information';

@Component({
  selector: 'oaman-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent {
  @Input()
  dataSource$: Observable<ProfileInformation[]> = of([]);
  @Input()
  displayedColumns: string[] = ['key', 'value'];
  @Input()
  userState$!: Observable<any>;
}
