import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'oaman-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @Input()
  context: 'default' | 'missingContext' | 'noContent' = 'default';
}
