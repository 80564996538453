export const FUNDING_MEASURE_FLURBEREINIGUNG = 'Flurbereinigung';
export const FUNDING_MEASURE_DORFENTWICKLUNG = 'Dorfentwicklung';
export const FUNDING_MEASURE_DORFENTWICKLUNGSPLAN = 'Dorfentwicklungsplan';
export const FUNDING_MEASURE_BASISDIENSTLEISTUNGEN = 'Basisdienstleistungen';
export const FUNDING_MEASURE_KLEINSTUNTERNEHMEN =
  'Kleinstunternehmen der Grundversorgung';

export const LEGAL_FORM_SONSTIGE_GEBIETKORPERSCHAFT =
  'Sonstige Gebietskörperschaft';
export const LEGAL_FORM_KORPERSCHAFT_OFFENTLICHES_RECHT =
  'Körperschaften des öffentlichen Rechts';

export const LEGAL_FORM_EHELEUTE = 'Eheleute (soweit keine GbR)';
export const LEGAL_FORM_SONSTIGE_PERSONENGESELLSCHAFTEN =
  'Sonstige Personengesellschaften (z. B. Erbengemeinschaften)';
export const LEGAL_FORM_GESELLSCHAFT_DES_BUERGERLICHEN_RECHTS =
  'Gesellschaft des bürgerlichen Rechts (GbR)';

export const FORM_TEMPLATE_ZILE = 'ZILE - Investive Maßnahmen';
export const FORM_TEMPLATE_VEREINE = 'Einlageblatt Verein';
export const FORM_TEMPLATE_TEILNEHMER = 'Einlageblatt Flurbereinigung';
export const FORM_TEMPLATE_LEADER = 'Erstantrag LEADER';
