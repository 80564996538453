<mat-form-field id="funding-search-form" appearance="outline" [style]="style">
  <mat-label>{{ searchLabel }}</mat-label>
  <input
    id="project-search"
    matInput
    #searchInputRef
    aria-hidden="true"
    [title]="'fundingProject.search.title' | translate"
    [value]="searchSelect$ | async"
    [(ngModel)]="searchInput"
    (keyup)="onSearchInput(searchInputRef.value)"
    name="search"
  />
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>
