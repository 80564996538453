import { FundingProjectFile } from '../interfaces/funding-project-file';
import { TypeApplicantDocumentTypes } from '../../../../../dialog/interfaces/document-dialog-data';

/**
 * Checks if the given file is from TypeAntragsDocument Antragsformular.
 * @param file
 * @returns
 */
export function isAntragsformular(file: FundingProjectFile): boolean {
  return (
    file?.typeApplicantDocument.title ===
    TypeApplicantDocumentTypes.ANTRAGSFORMULAR
  );
}
