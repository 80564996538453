import { DropdownModule } from './../../../../dropdown/dropdown.module';
import { DetailActionsModule } from './../../detail-actions/detail-actions.module';
import { ButtonModule } from './../../../../button/button.module';
import { MaterialModule } from './../../../../material/material.module';
import { DialogModule } from './../../../../dialog/dialog.module';
import { ActionSheetModule } from './../../../../action-sheet/action-sheet.module';
import { HelpTextModule } from './../../../../help-text/help-text.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTemplateComponent } from './form-template/form-template.component';
import { FormInputComponent } from './form-components/types/form-input.component';
import { FormSelectComponent } from './form-components/types/form-select.component';
import { FormTextareaComponent } from './form-components/types/form-textarea.component';
import { FormFieldWrapperComponent } from './form-components/wrappers/form-field-wrapper.component';
import { FormSubGroupComponent } from './form-components/types/form-sub-group.component';
import { FormGroupComponent } from './form-components/types/form-group.component';
import { FormCheckboxComponent } from './form-components/types/form-checkbox.component';
import { FormRadioComponent } from './form-components/types/form-radio.component';
import { FormRepeatComponent } from './form-components/types/form-repeat.component';
import { FormYearTypeComponent } from './form-components/types/form-year-type.component';
import { FormYearWrapperComponent } from './form-components/wrappers/form-year-wrapper.component';
import { FormCostCalculationTypeComponent } from './form-components/types/form-cost-calculation-type.component';
import { FormCostCalculationFinancialPlanTypeComponent } from './form-components/types/form-cost-calculation-financial-plan-type.component';
import { FormExplanationAttachmentGroupComponent } from './form-components/types/form-explanation-attachment-group.component';
import { FormNavigationComponent } from './form-components/types/form-navigation.component';
import { FormPageComponent } from './form-components/types/form-page.component';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormsComponent } from './forms.component';
import {
  fromBeforeToDateValidationMessage,
  maxlengthValidationMessage,
  maxValidationMessage,
  minlengthValidationMessage,
  minValidationMessage,
  zipcodeValidationMessage
} from './utils/validation-messages';
import {
  DateValidator,
  BirthDateValidator,
  IBANValidator,
  IntegerValidator,
  EmailValidator,
  PLZValidator,
  BICValidator,
  RegisterNrValidator,
  MultiCheckBoxValidator,
  MeasureNr1Validator,
  MeasureNr2Validator,
  CostValidator,
  Max4FloatingNumbersValidator,
  WholeNumberValidator,
  YearValidator,
  FromBeforeToDateValidator,
  PhoneNumberValidator,
  TaxNumberValidator,
  FoundingDateValidator,
  FutureDateValidator
} from './utils/custom-validators';
import { FormCostCalculationTableComponent } from './form-components/types/form-cost-calculation-table.component';
import { FormEbOrganisationsDesignationWrapperComponent } from './form-components/wrappers/form-eb-organisations-designation-wrapper.component';
import { FormEbOrganisationsFieldWrapperComponent } from './form-components/wrappers/form-eb-organisations-field-wrapper.component';
import { FormEbOrganisationsBaseDataWrapperComponent } from './form-components/wrappers/form-eb-organisations-base-data-wrapper.component';
import { FormIntextFieldWrapperComponent } from './form-components/wrappers/form-intext-field-wrapper.component';
import { CleanNumberDirective } from './directives/clean-number.directive';
import { FormCostFieldWrapperComponent } from './form-components/wrappers/form-cost-field-wrapper.component';
import { FormEbMeasureNumberWrapperComponent } from './form-components/wrappers/form-eb-measure-number-wrapper.component';
import { FormDateTypeComponent } from './form-components/types/form-date-type.component';
import { FormDateDirective } from './directives/form-date.directive';
import { MatNativeDateModule } from '@angular/material/core';
import { FormDateFieldWrapperComponent } from './form-components/wrappers/form-date-field-wrapper.component';
import { FormMatDateTypeComponent } from './form-components/types/form-mat-date-type.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormMultiSelectComponent } from './form-components/types/form-multi-select.component';
import { FormCostInputComponent } from './form-components/types/form-cost-input.component';
import { FormCostInputFinancialPlanComponent } from './form-components/types/form-cost-input-financial-plan.component';
import { NgxCurrencyDirective } from 'ngx-currency';
@NgModule({
  declarations: [
    FormsComponent,
    FormTemplateComponent,
    FormInputComponent,
    FormCostInputComponent,
    FormCostInputFinancialPlanComponent,
    FormSelectComponent,
    FormTextareaComponent,
    FormFieldWrapperComponent,
    FormSubGroupComponent,
    FormGroupComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    FormRepeatComponent,
    FormYearTypeComponent,
    FormYearWrapperComponent,
    FormCostCalculationTypeComponent,
    FormCostCalculationFinancialPlanTypeComponent,
    FormExplanationAttachmentGroupComponent,
    FormNavigationComponent,
    FormPageComponent,
    FormCostCalculationTableComponent,
    FormEbOrganisationsDesignationWrapperComponent,
    FormEbOrganisationsFieldWrapperComponent,
    FormEbOrganisationsBaseDataWrapperComponent,
    FormIntextFieldWrapperComponent,
    CleanNumberDirective,
    FormCostFieldWrapperComponent,
    FormEbMeasureNumberWrapperComponent,
    FormDateTypeComponent,
    FormDateDirective,
    FormDateFieldWrapperComponent,
    FormMatDateTypeComponent,
    FormMultiSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    ButtonModule,
    RouterModule,
    HelpTextModule,
    ActionSheetModule,
    DropdownModule,
    DialogModule,
    DetailActionsModule,
    FormlyBootstrapModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxCurrencyDirective,
    FormlyModule.forRoot({
      extras: {
        resetFieldOnHide: true
      },
      validators: [
        { name: 'integer', validation: IntegerValidator },
        { name: 'iban', validation: IBANValidator },
        { name: 'plz', validation: PLZValidator },
        { name: 'phone', validation: PhoneNumberValidator },
        { name: 'taxNumber', validation: TaxNumberValidator },
        { name: 'date', validation: DateValidator },
        { name: 'birthdateValidator', validation: BirthDateValidator },
        { name: 'dateCompare', validation: FromBeforeToDateValidator },
        { name: 'email', validation: EmailValidator },
        { name: 'bic', validation: BICValidator },
        { name: 'regnr', validation: RegisterNrValidator },
        { name: 'measureNr1', validation: MeasureNr1Validator },
        { name: 'measureNr2', validation: MeasureNr2Validator },
        { name: 'multiCheckBoxRequired', validation: MultiCheckBoxValidator },
        { name: 'requiredTrue', validation: Validators.requiredTrue },
        { name: 'costValidator', validation: CostValidator },
        { name: 'yearValidator', validation: YearValidator },
        { name: 'wholeNumberValidator', validation: WholeNumberValidator },
        { name: 'foundingDate', validation: FoundingDateValidator },
        { name: 'futureDate', validation: FutureDateValidator },
        {
          name: 'max4FloatingNumbersValidator',
          validation: Max4FloatingNumbersValidator
        }
      ],
      validationMessages: [
        { name: 'required', message: 'Dieses Feld ist notwendig!' },
        { name: 'integer', message: 'Die Zahl ist nicht gültig!' },
        { name: 'iban', message: 'IBAN ist nicht gültig!' },
        {
          name: 'plz',
          message: zipcodeValidationMessage
        },
        { name: 'invalidTaxNumber', message: 'Steuernummer ist nicht gültig!' },
        {
          name: 'invalidPhone',
          message: 'Die Telefonnummer ist nicht gültig!'
        },
        { name: 'date', message: 'Datum ist nicht gültig!' },
        {
          name: 'invalidFoundingDate',
          message: 'Das Gründungsdatum muss in der Vergangenheit liegen!'
        },
        {
          name: 'invalidFutureDate',
          message: 'Das Datum muss in der Zukunft liegen!'
        },
        {
          name: 'invalidBirthdate',
          message: 'Das Alter muss mind. 18 Jahre betragen.'
        },
        { name: 'dateCompare', message: fromBeforeToDateValidationMessage },
        { name: 'email', message: 'E-Mail ist nicht gültig!' },
        { name: 'bic', message: 'BIC ist nicht gültig!' },
        {
          name: 'regnr',
          message:
            'Ungültige Registriernummer. Eine gültige besteht aus 15 Ziffern'
        },
        { name: 'invalidDecimal2', message: 'Maximal 2 Nachkommastellen' },
        { name: 'invalidDecimal4', message: 'Maximal 4 Nachkommastellen' },
        { name: 'notWholeNumber', message: 'Die Zahl ist nicht gültig!' },
        { name: 'invalidYear', message: 'Ungültiges Jahr (1900 - 2099)' },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: 'minLength', message: minlengthValidationMessage },
        { name: 'maxLength', message: maxlengthValidationMessage }
      ],
      types: [
        {
          name: 'form-input',
          component: FormInputComponent
        },
        {
          name: 'form-cost-input',
          component: FormCostInputComponent
        },
        {
          name: 'form-cost-input-financial-plan',
          component: FormCostInputFinancialPlanComponent
        },
        {
          name: 'form-select',
          component: FormSelectComponent
        },
        {
          name: 'form-textarea',
          component: FormTextareaComponent
        },
        {
          name: 'form-checkbox',
          component: FormCheckboxComponent
        },
        {
          name: 'form-radio',
          component: FormRadioComponent
        },
        {
          name: 'page-group',
          component: FormGroupComponent
        },
        {
          name: 'sub-group',
          component: FormSubGroupComponent
        },
        {
          name: 'explanation-attachment-group',
          component: FormExplanationAttachmentGroupComponent
        },
        {
          name: 'cost-calculation-table',
          component: FormCostCalculationTableComponent
        },
        {
          name: 'repeat',
          component: FormRepeatComponent
        },
        {
          name: 'year',
          component: FormYearTypeComponent
        },
        {
          name: 'cost-calculation',
          component: FormCostCalculationTypeComponent
        },
        {
          name: 'cost-calculation-financial-plan',
          component: FormCostCalculationFinancialPlanTypeComponent
        },
        {
          name: 'navigation',
          component: FormNavigationComponent
        },
        {
          name: 'page',
          component: FormPageComponent
        },
        {
          name: 'date-input',
          component: FormDateTypeComponent
        },
        {
          name: 'date-picker',
          component: FormMatDateTypeComponent
        },
        {
          name: 'form-multi-select',
          component: FormMultiSelectComponent
        }
      ],
      wrappers: [
        { name: 'field-wrapper', component: FormFieldWrapperComponent },
        {
          name: 'cost-field-wrapper',
          component: FormCostFieldWrapperComponent
        },
        { name: 'year-wrapper', component: FormYearWrapperComponent },
        {
          name: 'eb-organisations-field-wrapper',
          component: FormEbOrganisationsFieldWrapperComponent
        },
        {
          name: 'eb-organisations-base-data-wrapper',
          component: FormEbOrganisationsBaseDataWrapperComponent
        },
        {
          name: 'eb-organisations-designation-wrapper',
          component: FormEbOrganisationsDesignationWrapperComponent
        },
        {
          name: 'intext-field-wrapper',
          component: FormIntextFieldWrapperComponent
        },
        {
          name: 'eb-measure-number-wrapper',
          component: FormEbMeasureNumberWrapperComponent
        },
        {
          name: 'date-wrapper',
          component: FormDateFieldWrapperComponent
        }
      ]
    })
  ],
  exports: [FormsComponent, FormTemplateComponent]
})
export class OamanFormsModule {}
