import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './app-config';
import { firstValueFrom, Observable } from 'rxjs';
import { AppPropertiesDTO } from '../../../../../oaman-components/src/lib/core/config/base-app-config.dt';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig!: AppConfig;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  loadAppConfig() {
    return firstValueFrom(
      this.http.get<AppConfig>('/assets/environments/env.json')
    )
      .then((data) => {
        this.appConfig = data;
        if (this.appConfig.backendApiPrefix) {
          this.appConfig.backendApiUrl += this.appConfig.backendApiPrefix;
        }
      })
      .then(() => {
        this.fetchAppProperties().subscribe((data) => {
          this.localStorageService.store(
            'maxFileSizeMb',
            data.maxFileSizeMb.split('MB', 1)[0]
          );
        });
      });
  }
  fetchAppProperties(): Observable<AppPropertiesDTO> {
    return this.http.get<AppPropertiesDTO>(
      `${this.appConfig.backendApiUrl}/app-properties`
    );
  }

  getConfig() {
    return this.appConfig;
  }
}
