import { HostListener, Directive } from '@angular/core';

/**
 * This directive is for the custom formly type "FormDateTypeComponent",
 * to prevent manual entries into date fields of all OAman forms
 */
@Directive({
  selector: '[oamanFormDate]'
})
export class FormDateDirective {
  @HostListener('keypress', ['$event']) public onKeypress(
    event: KeyboardEvent
  ): void {
    event.preventDefault();
  }
}
