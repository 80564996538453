import { FundingProjectDocument } from '../interfaces/funding-project-document';
import { DocumentTypes } from '../../../../../dialog/interfaces/document-dialog-data';

/**
 * Checks if the given document is empty. (has no files inside)
 * @param document
 * @returns
 */
export function isEmptyDocument(document: FundingProjectDocument): boolean {
  // the total value is missing or it's equal with zero
  return !document.total || document.total === 0;
}

/**
 * Checks if the given document is processed. The document is considered processed when it was submitted or delivered
 * @param document
 * @returns
 */
export function isProcessedDocument(document: FundingProjectDocument): boolean {
  return !!document?.checkInDate || !!document?.deliveryDate;
}

/**
 * Checks if the given document has a document type for which delivery is enabled.
 * @param document
 * @returns
 */
export function isDeliveryEnabled(document: FundingProjectDocument): boolean {
  return document?.documentType.submissionEnabled;
}

/**
 * Checks if the given document is from DocumentType Antrag.
 * @param document
 * @returns
 */
export function isAntrag(document: FundingProjectDocument): boolean {
  return document?.documentType.title === DocumentTypes.ANTRAG;
}

/**
 * Checks if the given document has Antragsformulare as title.
 * @param document
 * @returns
 */
export function isAntragsformulare(
  document: FundingProjectDocument,
  connectorType: string
): boolean {
  const title =
    connectorType === 'OAMAN' ? document?.title.split('_')[0] : document?.title;
  return title === 'Antragsformulare';
}

/**
 * Checks if the given document was created by an applicant
 * @param document
 * @returns
 */
export function isDocumentCreatedByApplicant(
  document: FundingProjectDocument
): boolean {
  return !document.author?.authority;
}

/**
 * Checks if the given document is linked to a Request which was not already submitted (the request type is 'request.state.created').
 * @param document
 * @returns
 */
export function isForUnsubmittedRequest(
  document: FundingProjectDocument
): boolean {
  return document.requestAndType.stateResourceKey === 'request.state.created';
}
