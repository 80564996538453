<div>
  <h1 id="about-header" class="header-versions">
    {{ 'about.header' | translate }}
  </h1>
  <div *ngIf="dataSource$ | async as dataSource" class="component-table">
    <table
      id="about-table"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
    >
      <caption>
        {{
          'about.caption' | translate
        }}
      </caption>
      <ng-container matColumnDef="name">
        <th id="component-name" mat-header-cell *matHeaderCellDef>
          {{ 'about.component' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th id="component-version" mat-header-cell *matHeaderCellDef>
          {{ 'about.version' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.version }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
