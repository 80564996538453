import { FieldWrapper } from '@ngx-formly/core';
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'oaman-form-date-wrapper',
  template: `
    <div class="field-wrapper">
      <div *ngIf="props['focused'] && props['helpText']" class="help-text">
        <oaman-help-text [helpText]="props['helpText']"></oaman-help-text>
      </div>
      <div>
        <label [attr.for]="id" *ngIf="props.label">
          {{ props.label }}
          <ng-container
            *ngIf="props.required && props.hideRequiredMarker !== true"
          >
            <span class="asterisk">*</span>
          </ng-container>
        </label>
      </div>
      <ng-template #fieldComponent></ng-template>
      <div>
        <label class="additional-label">{{ props.additionalLabel }}</label>
      </div>
    </div>
  `,
  styles: [
    `
      .field-wrapper {
        margin: 1rem 0;
        position: relative;
      }
      .help-text {
        position: absolute;
        display: block;
        z-index: 1;
        width: 42%;
        top: 10px;
        left: 100%;
        margin-top: 1px;
        margin-left: 4rem;
      }
      .additional-label {
        margin-top: 0.2rem;
        font-size: 0.875rem;
        color: #666;
      }
    `
  ]
})
export class FormDateFieldWrapperComponent
  extends FieldWrapper
  implements AfterViewInit
{
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }
  ngAfterViewInit() {
    if (!this.formControl.valid && this.formControl.value) {
      this.formControl.markAllAsTouched();
      this.formControl.markAsDirty();
      this.changeDetectorRef.detectChanges();
    }
  }
}
