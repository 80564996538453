import { DialogService } from '../../../../../dialog/services/dialog.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Inquiry, MessageData } from '../public-api';
import { ProjectActionService } from '../../../public-api';
import { Subscription } from 'rxjs';
import { FundingProject } from '../../../../../core/interfaces/funding-project';
import { BaseComponent } from '../../../../../core/base.component';
import { UserObjectObservableService } from '../../../../../core/services/user-object-observable.service';
import { APP_CONFIG } from '../../../../../core/util/app-config.token';
import { AppConfiguration } from '../../../../../core/interfaces/app-configuration';
import { isProjectModifiable } from '../../../../../core/util/funding-project-utils';

@Component({
  selector: 'oaman-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageViewComponent
  extends BaseComponent
  implements OnDestroy, OnInit
{
  messages!: MessageData[];
  activeInquiry!: Inquiry;
  fundingProjectId!: string;
  fundingProject!: FundingProject;
  private manageApp = false;
  private navigationSubscription!: Subscription;
  private fundingGuidelineId!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: DialogService,
    private projectActionService: ProjectActionService,
    private changeDetectorRef: ChangeDetectorRef,
    public userObjectObservableService: UserObjectObservableService,
    @Inject(APP_CONFIG) public appConfig: AppConfiguration
  ) {
    super(userObjectObservableService);
    this.manageApp = this.appConfig.context === 'manage';
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialize the component
      if (e instanceof NavigationEnd) {
        this.initializeMessages();
      }
    });
  }

  ngOnInit(): void {
    this.initializeMessages();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navigationSubscription?.unsubscribe();
  }

  initializeMessages() {
    this.route.data.subscribe((data) => {
      const resolvedData = data['resolvedData'] as MessageData[];
      if (resolvedData) {
        this.messages = resolvedData;
      }
    });
    this.route.parent?.data.subscribe((data) => {
      const resolvedData = data['resolvedData'] as Inquiry[];
      const inquiryId = this.route.snapshot.paramMap.get('id');
      const fundingProjectId =
        this.route.parent?.parent?.snapshot.paramMap.get('id');
      const fundingGuidelineId =
        this.route.snapshot.parent?.parent?.parent?.parent?.params.id;
      if (resolvedData && inquiryId && fundingProjectId && fundingGuidelineId) {
        this.fundingProjectId = fundingProjectId;
        this.fundingGuidelineId = fundingGuidelineId;
        resolvedData.forEach((inquiry) => {
          if (inquiry.id === inquiryId) {
            this.activeInquiry = inquiry;
          }
        });
      }
    });
    this.changeDetectorRef.markForCheck();
    this.route.parent?.parent?.data.subscribe((data) => {
      this.fundingProject = data['resolvedData'];
    });
  }
  navigateToInquiries() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  openAnswerDialog() {
    this.dialog.openAnswerDialog().subscribe((result) => {
      if (result) {
        this.projectActionService.setAction('postMessage', {
          fundingProjectId: this.fundingProjectId,
          fundingGuidelineId: this.fundingGuidelineId,
          inquiryId: this.activeInquiry.id,
          ...result
        });
      }
    });
  }
  setQuestionAnswered() {
    this.projectActionService.setAction('checkAnswered', {
      fundingProjectId: this.fundingProjectId,
      fundingGuidelineId: this.fundingGuidelineId,
      inquiryId: this.activeInquiry.id,
      questionAnswered: true
    });
  }

  showActions(): boolean {
    return isProjectModifiable(
      this.fundingProject,
      this.currentUser,
      this.manageApp
    );
  }
}
