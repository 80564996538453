<div id="funding-project-wrapper">
  <div fxLayout="row wrap" fxLayoutAlign.gt-md="space-between">
    <div
      fxFlex="16"
      fxFlex.md="20"
      fxFlex.lt-sm="100"
      fxLayoutAlign.lt-sm="start"
    ></div>
    <div
      class="headline-wrapper"
      fxFlex="80"
      fxFlex.lt-md="100"
      fxFlex.md="100"
    >
      <div class="headline">
        <h1>{{ 'fundingProject.headline' | translate }}</h1>
        <oaman-button
          *ngIf="context === 'enroll'"
          class="action-button"
          color="primary"
          icon="add"
          tabindex="-1"
          routerLink="create"
        >
          {{ 'fundingProject.button.addProject' | translate }}
        </oaman-button>
      </div>
      <div class="headline">
        <oaman-button
          class="action-button"
          (click)="toggleViewChange($event)"
          [icon]="(isListView$ | async) ? 'grid_view' : 'list'"
          [tooltip]="
            (isListView$ | async)
              ? ('fundingProject.component.tooltip.changeToCardView'
                | translate)
              : ('fundingProject.component.tooltip.changeToListView'
                | translate)
          "
        >
          {{
            (isListView$ | async)
              ? ('fundingProject.button.cardView' | translate)
              : ('fundingProject.button.listView' | translate)
          }}
        </oaman-button>
        <form class="search-form">
          <oaman-sort
            *ngIf="(isListView$ | async) === false"
            [isAscending$]="isAscending$"
            [sortValue$]="sortValue$"
            (sortCriterion)="onSortCriterion($event)"
            (sortDirection)="onToggleSortDirection($event)"
          ></oaman-sort>
          <oaman-search
            [searchSelect$]="searchSelect$"
            [searchLabel]="'fundingProject.search.label' | translate"
            (searchQuery)="onSearch($event)"
          ></oaman-search>
        </form>
      </div>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign.gt-md="space-between">
    <div
      fxFlex="16"
      fxFlex.md="20"
      fxFlex.lt-sm="100"
      fxLayoutAlign.lt-sm="start"
    >
      <oaman-filters
        fxLayout="column"
        [roleSelect$]="roleSelect$"
        [states$]="states$"
        [stateSelect$]="stateSelect$"
        [fundingGuidelines]="fundingGuidelines"
        [fundingGuidelineSelect$]="fundingGuidelineSelect$"
        (changeState)="onChangeState($event)"
        (changeRole)="onChangeRole($event)"
        (changeFundingGuideline)="onChangeFundingGuideline($event)"
      >
      </oaman-filters>
    </div>
    <div fxFlex="80" fxFlex.lt-md="100" fxFlex.md="100">
      <ng-container *ngIf="fundingProjects$.length === 0; else showProjects">
        <ng-template #noContent>
          <oaman-alert [context]="'noContent'">
            <div>{{ 'fundingProject.search.noResults' | translate }}</div>
          </oaman-alert>
        </ng-template>
        <ng-container
          *ngIf="
            fundingProjects$.length === 0 && !changedFilter;
            else noContent
          "
        >
          <div>
            <div [style.padding-bottom]="'1.5rem'">
              <oaman-alert [context]="'noContent'">
                <span>{{ 'home.noProjects' | translate }}</span>
              </oaman-alert>
            </div>
            <div fxLayout="row wrap">
              <oaman-action-sheet
                *ngIf="context === 'enroll'"
                icon="add_circle"
                text="{{ 'home.createProject' | translate }}"
                routerLink="/funding-projects/create"
              ></oaman-action-sheet>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #showProjects>
    <oaman-table
      *ngIf="isListView$ | async"
      [fundingProjects]="fundingProjects$"
      [totalElements]="totalElements"
      [projectFilter]="projectFilter"
      (openProjectDetails)="passRouteIds($event)"
      (loadPage)="loadPage($event)"
    ></oaman-table>
    <oaman-card-funding-grid
      *ngIf="(isListView$ | async) === false"
      [fundingProjects]="fundingProjects$"
      [totalPages]="totalPages"
      [projectFilter]="projectFilter"
      [reinitialiseInfiniteScroll$]="reinitialiseInfiniteScroll$"
      (openProjectDetails)="passRouteIds($event)"
      (setClerkAction)="passChangeClerk($event)"
      (loadPage)="loadPage($event)"
    ></oaman-card-funding-grid>
  </ng-template>
</div>
