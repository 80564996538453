import { Inject, Injectable } from '@angular/core';
import { BaseAppConfig } from './base-app-config.dt';
import { LocalStorageService } from 'ngx-webstorage';
import { APP_CONFIG } from '../util/app-config.token';
import { AppConfiguration } from '../interfaces/app-configuration';

@Injectable({
  providedIn: 'root'
})
export class BaseAppConfigService {
  context!: string;
  defaultPropertyValues!: Map<string, number>;
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private localStorageService: LocalStorageService
  ) {
    this.context = this.appConfig.context;
    this.defaultPropertyValues = new Map([
      [this.context + '-maxFileSizeMb', this.context === 'enroll' ? 5 : 50],
      [this.context + '-maxRequestSizeMb', 500],
      [this.context + '-maxDeliveryFileSizeMb', 3],
      [this.context + '-maxFileNumber', 10]
    ]);
  }

  loadAppConfig(): BaseAppConfig {
    return {
      maxFileSizeMb: this.retrieveStoredVarAsNumber('maxFileSizeMb'),
      maxRequestSizeMb: this.retrieveStoredVarAsNumber('maxRequestSizeMb'),
      maxDeliveryFileSizeMb: this.retrieveStoredVarAsNumber(
        'maxDeliveryFileSizeMb'
      ),
      maxFileNumber: this.retrieveStoredVarAsNumber('maxFileNumber')
    };
  }
  retrieveStoredVarAsNumber(varName: string): number {
    let varValue = this.localStorageService.retrieve(varName) as string;
    if (varValue) {
      return Number(varValue);
    } else {
      return this.defaultPropertyValues.get(
        `${this.context}-${varName}`
      ) as number;
    }
  }
}
