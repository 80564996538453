import { AssignedClerksPipe } from '../../table/pipes/assigned-clerks.pipe';
import { FundingProject } from '../interfaces/funding-project';
import { User } from '../public-api';
import { findIndex as _findIndex } from 'lodash';
import * as FundingProjectStates from '../../funding-project/funding-project-details/utils/fundingProjectStates';

export function getSearchValuesFromFundingProject(project: FundingProject) {
  const searchArray: string[] = [];
  searchArray.push(project.title);
  if (project.description) searchArray.push(project.description);
  searchArray.push(project.location.title);
  searchArray.push(project.user.fullName);
  if (project.grantedFundingValue)
    searchArray.push(project.grantedFundingValue.toString());
  searchArray.push(project.requestedFundingValue.toString());
  const pipe = new AssignedClerksPipe();
  searchArray.push(pipe.transform(project.assignedClerks));
  searchArray.push(pipe.transform(project.deputies));
  searchArray.push(project.oamanId);
  return searchArray;
}

export function findInProject(
  fundingProject: FundingProject,
  searchQuery: RegExp
) {
  if (fundingProject != null) {
    const searchValues = getSearchValuesFromFundingProject(fundingProject);
    for (const value in searchValues) {
      if (searchQuery.test(searchValues[value])) return fundingProject;
    }
  }
  return null;
}

function returnSortDirectonString(
  string1: string,
  string2: string,
  isAscending: boolean
) {
  return isAscending
    ? string1.localeCompare(string2, 'de')
    : string2.localeCompare(string1, 'de');
}

function returnSortDirectonNumber(
  number1: number,
  number2: number,
  isAscending: boolean
) {
  return isAscending ? number1 - number2 : number2 - number1;
}

export function replaceSpecialCharsRegExp(searchQuery: string): string {
  // this array contains RegExp special characters that we want to include in the searchQuery if user inputs in the search mask.
  // We use a double backslash (\\) to make RegExp see them as normal characters included in the query string lateral
  const obstructions = [
    '\\',
    '$',
    '-',
    '*',
    '^',
    '{',
    '}',
    '|',
    '/',
    '+',
    '?',
    '[',
    ']',
    '(',
    '.',
    ')'
  ];
  obstructions.forEach((o) => {
    searchQuery = searchQuery.replace(new RegExp(`\\${o}`, 'g'), `\\${o}`);
  });
  return searchQuery;
}

export function sortFundingProjectsBy(
  project1: FundingProject,
  project2: FundingProject,
  sortBy: string,
  isAscending: boolean
) {
  switch (sortBy) {
    case 'requested':
      return returnSortDirectonNumber(
        project1.requestedFundingValue,
        project2.requestedFundingValue,
        isAscending
      );
    case 'granted':
      return returnSortDirectonNumber(
        project1.grantedFundingValue,
        project2.grantedFundingValue,
        isAscending
      );
    case 'title':
      return returnSortDirectonString(
        project1.title,
        project2.title,
        isAscending
      );
    case 'place':
      return returnSortDirectonString(
        project1.location.title,
        project2.location.title,
        isAscending
      );
    default:
      return 0;
  }
}

export function isUserAssignedClerk(
  project: FundingProject,
  user: User
): boolean {
  return _findIndex(project.assignedClerks, ['user.id', user.id]) !== -1;
}

export function isProjectModifiable(
  project: FundingProject,
  user: User,
  manageApp: boolean
): boolean {
  // the manage user must be an assigned clerk
  let isVisibleForUser = manageApp ? isUserAssignedClerk(project, user) : true;

  let isWithdrawnProject =
    project.stateFundingProject.resourceKey === FundingProjectStates.WITHDRAWN;

  // the project cannot be in withdrawn state
  return !isWithdrawnProject && isVisibleForUser;
}

const departments: Map<string, string> = new Map([
  ['40102', 'ZS Braunschweig'],
  ['40104', 'GS Göttingen'],
  ['40205', 'ZS Hildesheim'],
  ['40211', 'GS Sulingen'],
  ['40306', 'ZS Lüneburg'],
  ['40310', 'GS Verden'],
  ['40303', 'GS Bremerhaven'],
  ['40408', 'ZS Oldenburg'],
  ['40401', 'GS Aurich'],
  ['40407', 'GS Meppen'],
  ['40409', 'GS Osnabrück']
]);

export interface Department {
  name: string;
  id: string;
}
export function getDepartmentNameById(id: string): string {
  return departments.get(id) ?? '';
}
export function getDepartmentsBySlaAuthorityId(
  slaAuthorityId: string
): Department[] {
  return [...departments.keys()]
    .filter((key) => key.startsWith(slaAuthorityId))
    .map(
      (key) => ({ id: key, name: getDepartmentNameById(key) }) as Department
    );
}

export function stringToDatestring(str: string): string {
  if (str !== null) {
    return new Date(str).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  } else {
    return '';
  }
}

export function isLeaderFundingProject(
  fundingProject: FundingProject
): boolean {
  return fundingProject.oamanId.startsWith('02');
}
