import { AvatarModule } from './../avatar/avatar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MenuComponent } from './menu.component';
import { AboutComponent } from './about/about.component';
import { ProfileComponent } from './profile/profile.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MenuComponent, AboutComponent, ProfileComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexModule,
    TranslateModule,
    RouterModule,
    AvatarModule
  ],
  exports: [MenuComponent, AboutComponent, ProfileComponent]
})
export class MenuModule {}
