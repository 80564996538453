import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ProjectWithdrawalDialogData } from '../interfaces/project-withdrawal-dialog-data';
import * as FundingProjectStates from '../../funding-project/funding-project-details/utils/fundingProjectStates';

@Component({
  selector: 'oaman-project-withdrawal-dialog',
  templateUrl: './project-withdrawal-dialog.component.html',
  styleUrls: ['./project-withdrawal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectWithdrawalDialogComponent {
  isProjectStateDraft!: boolean;
  constructor(
    public dialogRef: MatDialogRef<ProjectWithdrawalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectWithdrawalDialogData
  ) {
    this.isProjectStateDraft =
      this.data.projectState === FundingProjectStates.DRAFT;
  }

  onAction(context: string) {
    this.dialogRef.close(context);
  }
}
