import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { ActionSheetComponent } from './action-sheet.component';

@NgModule({
  declarations: [ActionSheetComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ActionSheetComponent]
})
export class ActionSheetModule {}
