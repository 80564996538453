import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  AttornyPowerType,
  DeputyRole,
  StateFundingProject,
  getAttorneyPowerAvailabilities,
  getAttorneyPowerTypes
} from '../../../core/public-api';
import { minDateValidator } from '../../../core/util/min-date-validator';
import { DatePipe } from '@angular/common';
import { datePeriodValidator } from '../../../core/util/date-period-validator';
import * as FundingProjectStates from '../../../funding-project/funding-project-details/utils/fundingProjectStates';

@Component({
  selector: 'oaman-add-deputy-dialog',
  templateUrl: './add-deputy-dialog.component.html',
  styleUrls: ['./add-deputy-dialog.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeputyDialogComponent {
  deputyForm!: UntypedFormGroup;
  availableRoles = this.initParticipantRoles();
  attorneyPowerTypes = getAttorneyPowerTypes();
  attorneyPowerAvailabilities = getAttorneyPowerAvailabilities();
  selectedRoleId = this.availableRoles[0].id;
  selectedAttorneyPowerType = this.attorneyPowerTypes[0].id;
  selectedAttorneyPowerAvailability?: number;
  activeProgressBar = false; // used to display the progress bar during the backend call

  constructor(
    private dialogRef: MatDialogRef<AddDeputyDialogComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: StateFundingProject
  ) {
    // initialize validFrom date to first second of the current day
    const minValidFrom = new Date();
    minValidFrom.setUTCHours(0, 0, 0, 0);

    this.deputyForm = new UntypedFormGroup({
      participantRole: new UntypedFormControl(this.availableRoles[0].id, [
        Validators.required
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(255),
        Validators.email
      ]),
      nameFirst: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      nameLast: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      validFrom: new UntypedFormControl(
        this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        [
          Validators.required,
          Validators.maxLength(10),
          minDateValidator(minValidFrom),
          datePeriodValidator('validFrom', 'validTo')
        ]
      ),
      validTo: new UntypedFormControl(null, [
        Validators.maxLength(10),
        datePeriodValidator('validFrom', 'validTo')
      ]),
      attorneyPowerType: new UntypedFormControl(
        this.attorneyPowerTypes[0].id,
        []
      ),
      attorneyPowerStartDate: new UntypedFormControl(null, [
        Validators.maxLength(10),
        datePeriodValidator('attorneyPowerStartDate', 'attorneyPowerEndDate')
      ]),
      attorneyPowerEndDate: new UntypedFormControl(null, [
        Validators.maxLength(10),
        datePeriodValidator('attorneyPowerStartDate', 'attorneyPowerEndDate')
      ]),
      attorneyPowerAvailability: new UntypedFormControl('', [])
    });

    this.onParticipantRoleChange();
  }

  onSubmit(): void {
    let deputyDto = this.deputyForm.value;
    if (!this.isRepresentativeDeputy()) {
      deputyDto.attorneyPowerType = null;
      deputyDto.attorneyPowerStartDate = null;
      deputyDto.attorneyPowerEndDate = null;
      deputyDto.attorneyPowerAvailability = null;
    }
    if (!this.isAttorneyPowerEndVisible()) {
      deputyDto.attorneyPowerEndDate = null;
    }

    this.dialogRef.close({
      data: deputyDto
    });
  }

  isRepresentativeDeputy(): boolean {
    return this.selectedRoleId === DeputyRole.Representative;
  }

  getDatePeriodErrorMsg(name: string): string {
    let errorMsg =
      'fundingProject.details.authorization.dialog.validationErrorMessages.';
    if (name === 'validTo') {
      errorMsg += 'invalidDatePeriodTo';
    } else if (name === 'validFrom') {
      errorMsg += 'invalidDatePeriodFrom';
    } else if (name === 'attorneyPowerStartDate') {
      errorMsg += 'invalidAttorneyPowerStart';
    } else {
      errorMsg += 'invalidAttorneyPowerEnd';
    }

    return errorMsg;
  }

  isAttorneyPowerEndVisible(): boolean {
    return !this.isAttorneyPowerOfType(AttornyPowerType.Unlimited);
  }

  isAttorneyPowerEndRequired(): boolean {
    return this.isAttorneyPowerOfType(AttornyPowerType.Limited);
  }

  onParticipantRoleChange(): void {
    const attorneyPowerStartControl = this.deputyForm.get(
      'attorneyPowerStartDate'
    );
    const attorneyPowerEndControl = this.deputyForm.get('attorneyPowerEndDate');
    const attorneyPowerTypeControl = this.deputyForm.get('attorneyPowerType');
    const attorneyPowerAvailabilityControl = this.deputyForm.get(
      'attorneyPowerAvailability'
    );
    if (this.isRepresentativeDeputy()) {
      attorneyPowerStartControl?.addValidators(Validators.required);
      attorneyPowerTypeControl?.addValidators(Validators.required);
      attorneyPowerAvailabilityControl?.addValidators(Validators.required);
      if (this.isAttorneyPowerEndRequired()) {
        attorneyPowerEndControl?.addValidators(Validators.required);
      }
    } else {
      attorneyPowerStartControl?.removeValidators(Validators.required);
      attorneyPowerTypeControl?.removeValidators(Validators.required);
      attorneyPowerAvailabilityControl?.removeValidators(Validators.required);
      attorneyPowerEndControl?.removeValidators(Validators.required);
    }
    attorneyPowerStartControl?.updateValueAndValidity();
    attorneyPowerTypeControl?.updateValueAndValidity();
    attorneyPowerAvailabilityControl?.updateValueAndValidity();
    attorneyPowerEndControl?.updateValueAndValidity();
  }

  onAttorneyPowerTypeChange(): void {
    const attorneyPowerEndControl = this.deputyForm.get('attorneyPowerEndDate');
    if (this.isAttorneyPowerEndRequired()) {
      attorneyPowerEndControl?.addValidators(Validators.required);
    } else {
      attorneyPowerEndControl?.removeValidators(Validators.required);
    }
    attorneyPowerEndControl?.updateValueAndValidity();
  }

  private isAttorneyPowerOfType(expectedType: number): boolean {
    return this.selectedAttorneyPowerType === expectedType;
  }

  /**
   * Used to initialize the available participant roles.
   * @returns
   */
  private initParticipantRoles() {
    let availableRoles = [
      { id: DeputyRole.Representative, title: DeputyRole.Representative }
    ];
    if (this.data.resourceKey !== FundingProjectStates.DRAFT) {
      availableRoles.push({
        id: DeputyRole.Assistant,
        title: DeputyRole.Assistant
      });
    }

    return availableRoles;
  }

  public isMaxLengthError(fieldName: string): boolean {
    if (fieldName !== null) {
      return this.deputyForm.get(fieldName)?.errors?.maxlength;
    }
    return false;
  }
}
