import { AppState } from '../../state';
import { createSelector } from '@ngrx/store';
import { isUserWithHighTrustLevel } from 'oaman-components';

export const authState = (state: AppState) => state.authentication;

export const isAuthorized = createSelector(
  authState,
  (state) => state.authorized
);

export const isHighLevelAuth = createSelector(authState, (state) =>
  state.user ? isUserWithHighTrustLevel(state.user) : false
);

export const isLoggedOut = createSelector(
  authState,
  (state) => state.loggedOut
);

export const getCurrentUser = createSelector(authState, (state) => state.user);

export const hasLoaded = createSelector(authState, (state) => state.hasLoaded);

export const hasAuthError = createSelector(authState, (state) => !!state.error);

export const selectUserState = createSelector(authState, (state) => state.user);
