import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-eb-measure-number-wrapper',
  template: `
    <div>
      <ng-container *ngIf="props.parent; else child">
        <div *ngIf="props.label">
          <span>{{ props.label }}</span>
          <ng-container
            *ngIf="props.required && props.hideRequiredMarker !== true"
          >
            <span class="asterisk">*</span>
          </ng-container>
        </div>
        <div fxLayout="row wrap">
          <div
            [style.padding-right]="'1rem'"
            *ngFor="let field of field.fieldGroup"
          >
            <formly-field [field]="field"></formly-field>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showError">
        <span class="error-msg" *ngIf="props.parent">
          {{ 'fundingProject.details.forms.errors.ebMeasureNr' | translate }}
        </span>
      </ng-container>
      <ng-template #child>
        <div fxLayout="row wrap" class="field">
          <div fxFlex="100" fxFlex.xs="100" [style.flex-direction]="'column'">
            <ng-template #fieldComponent></ng-template>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .error-msg {
        margin-top: 0.2rem;
        color: #ff002d;
      }
      .field {
        position: relative;
      }
    `
  ]
})
export class FormEbMeasureNumberWrapperComponent extends FieldWrapper {}
