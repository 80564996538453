import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexModule } from '@angular/flex-layout';
import { TableComponent } from './table.component';
import { AssignedClerksPipe } from './pipes/assigned-clerks.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlImpl } from './utils/MatPaginatorIntlImpl';

@NgModule({
  declarations: [TableComponent, AssignedClerksPipe],
  imports: [CommonModule, MaterialModule, FlexModule, TranslateModule],
  exports: [TableComponent],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlImpl }]
})
export class TableModule {}
