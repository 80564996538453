import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorIntlImpl extends MatPaginatorIntl {
  constructor(private translationService: TranslateService) {
    super();
  }

  itemsPerPageLabel = this.translationService.instant(
    'fundingProject.table.paginator.itemsPerPageLabel'
  );
  nextPageLabel = this.translationService.instant(
    'fundingProject.table.paginator.nextPageLabel'
  );
  previousPageLabel = this.translationService.instant(
    'fundingProject.table.paginator.previousPageLabel'
  );

  firstPageLabel = this.translationService.instant(
    'fundingProject.table.paginator.firstPageLabel'
  );

  lastPageLabel = this.translationService.instant(
    'fundingProject.table.paginator.lastPageLabel'
  );

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    let ofTranslated = this.translationService.instant(
      'fundingProject.table.paginator.rangeLabelOf'
    );
    if (length == 0 || pageSize == 0) {
      return `0 ${ofTranslated} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${ofTranslated} ${length}`;
  };
}
