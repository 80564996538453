import { Pipe, PipeTransform } from '@angular/core';
import { FundingProjectDocument } from '../interfaces/funding-project-document';
import { FilterObject } from '../public-api';

@Pipe({
  name: 'documentFilter'
})
export class DocumentFilterPipe implements PipeTransform {
  transform(
    value: FundingProjectDocument[],
    args?: FilterObject
  ): FundingProjectDocument[] {
    if (!value) return [];
    if (args) {
      return value.filter((data) => {
        if (!data.requestAndType || data.isTempDocument) {
          return data;
        }
        // Note: the application filter for All request types is 0,
        // and we don't need to filter the documents by request for that one
        if (
          data.requestAndType.id != args.applicationFilter &&
          args.applicationFilter !== '0'
        ) {
          return null;
        }
        return data.title
          .toLowerCase()
          .includes(args.searchQuery.toLowerCase());
      });
    }
    return value;
  }
}
