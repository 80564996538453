<div fxFlex fxLayout="column wrap" class="filter-wrapper">
  <oaman-search
    [searchLabel]="'fundingProject.details.documents.searchLabel' | translate"
    (searchQuery)="triggerSearch($event)"
  ></oaman-search>
  <oaman-dropdown
    *ngIf="!manageApp && applicationFilterVisible"
    [activeSelection]="filterObject.applicationFilter"
    [label]="'fundingProject.details.documents.filters.filter1' | translate"
    [title]="'fundingProject.details.documents.filters.title1' | translate"
    [options]="applicationFilters"
    (filterChanged)="changeApplicationFilters($event)"
  ></oaman-dropdown>

  <div *ngIf="manageApp && applicationFilterVisible">
    <mat-accordion class="form-navigation" fxFlex="75" fxFlex.lt-md="100">
      <mat-expansion-panel
        class="mat-elevation-z0 selection-group"
        [expanded]="filterObject.applicationFilter"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> {{ projectTitle }} </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item
            *ngFor="let listItem of applicationFilters; let pageIndex = index"
            role="button"
            tabindex="0"
            class="selection-item"
            [ngClass]="
              filterObject.applicationFilter === listItem.key
                ? 'active-selection'
                : ''
            "
            (click)="changeApplicationFilters(listItem.key)"
            (keyup.enter)="changeApplicationFilters(listItem.key)"
          >
            <span class="selection-item-text">{{ listItem.value }}</span>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div
    *ngIf="!manageApp && requiredDocumentCount > 0"
    (click)="setAllRequiredFilter()"
    fxFlex
    fxLayoutAlign="start center"
    class="hint-warpper"
  >
    <mat-icon [matBadge]="requiredDocumentCount" matBadgeColor="accent"
      >article</mat-icon
    >
    <u class="hint-text">
      {{ 'fundingProject.details.documents.filters.filterAction' | translate }}
    </u>
  </div>
</div>
