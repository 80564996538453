import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { DetailAction } from '../interfaces/detail-actions';
import { FundingProject } from '../../../core/interfaces/funding-project';
import { WITHDRAWN } from '../utils/fundingProjectStates';

@Component({
  selector: 'oaman-detail-actions',
  templateUrl: './detail-actions.component.html',
  styleUrls: ['./detail-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailActionsComponent {
  @Input() actions: DetailAction[] = [];
  @Input() fundingProject!: FundingProject;
  @Output() clickTriggered = new EventEmitter<string>();

  triggerAction(event: string) {
    this.clickTriggered.emit(event);
  }

  showDetailActions(action: DetailAction): boolean {
    if (action.skipDefaultVisibilityCheck) {
      // nothing to verify. This action must be visible
      return true;
    }

    return this.fundingProject
      ? this.fundingProject.stateFundingProject.resourceKey !== WITHDRAWN
      : true;
  }
}
