import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-select-type',
  template: `
    <select
      [formControl]="formControl"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      autocomplete="off"
    >
      <option [ngValue]="null" [selected]="true">- Bitte auswählen -</option>
      <ng-container *ngIf="props.valueProp; else defaultOptions">
        <option
          [ngValue]="option[props.valueProp]"
          *ngFor="let option of props.options"
        >
          {{ option[props.labelProp] }}
        </option>
      </ng-container>
      <ng-template #defaultOptions>
        <option [ngValue]="option.value" *ngFor="let option of props.options">
          {{ option.label }}
        </option>
      </ng-template>
    </select>
  `,
  styles: [
    `
      select {
        width: 100%;
        height: 2rem;
      }
    `
  ]
})
export class FormSelectComponent extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type || 'text';
  }
}
