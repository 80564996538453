import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/state';
import { isAuthorized } from '../../../../core/authentication/state/auth.selectors';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements OnInit {
  authorized$!: Observable<boolean>;
  links: Array<{ name: string; link: string } | undefined> = [
    { name: 'Home', link: '/home' },
    { name: 'Anträge', link: '/funding-projects' }
  ];
  activeLink: { name: string; link: string } | undefined = undefined;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.activeLink = this.links.find((link) =>
          event.urlAfterRedirects.includes(link?.link)
        );
        this.changeDetector.markForCheck();
      });
  }

  ngOnInit(): void {
    this.authorized$ = this.store.pipe(
      select(isAuthorized),
      distinctUntilChanged()
    );
  }

  displayCurrentComponent(): boolean {
    if (window.location.pathname === '/login') {
      return false;
    } else return true;
  }
}
