<div class="entry" [ngClass]="getBubblePosition()">
  <div class="bubble">
    <div class="header">
      <span class="name">{{ data.author.fullName }}</span>
      <span *ngIf="data.author.authority" class="name authority">{{
        data.author.authority.title
      }}</span>
    </div>
    <span class="message">{{ data.message }}</span>
    <div class="footer">
      <span class="date">{{ data.date | date: 'dd.MM.YYYY' }}</span>
    </div>
  </div>
</div>
