import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit
} from '@angular/core';
import { ProjectStateChangeDialogData } from '../interfaces/project-state-dialog-data';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { StateFundingProject } from '../../core/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oaman-project-state-change-dialog',
  templateUrl: './project-state-change-dialog.component.html',
  styleUrls: ['./project-state-change-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectStateChangeDialogComponent implements OnInit {
  stateChangeForm!: UntypedFormGroup;
  selectedState!: StateFundingProject;
  activeProgressBar = false; // used to display the progress bar during the backend call

  constructor(
    public dialogRef: MatDialogRef<ProjectStateChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectStateChangeDialogData,
    private translateService: TranslateService
  ) {
    this.stateChangeForm = new UntypedFormGroup({
      state: new UntypedFormControl(undefined, [Validators.required])
    });
  }
  ngOnInit(): void {
    this.data.availableStates.forEach((state) => {
      state.sortKey = this.translateService.instant(state.resourceKey);
    });
  }

  onSubmit(): void {
    this.dialogRef.close(this.stateChangeForm.value.state);
  }
}
