import { MaterialModule } from '../../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OamanFormsModule } from './tabs/forms/forms.module';
import { ApplicationDocumentFilterPipe } from './tabs/documents/pipes/application-document-filter.pipe';
import { DocumentsTableComponent } from './tabs/documents/documents-table/documents-table.component';
import { StatusDocumentFilterPipe } from './tabs/documents/pipes/status-document-filter.pipe';
import { ClassDocumentFilterPipe } from './tabs/documents/pipes/class-document-filter.pipe';
import { DocumentSearchPipe } from './tabs/documents/pipes/document-search.pipe';
import { FilterElementComponent } from './tabs/documents/filter-element/filter-element.component';
import { HistoryComponent } from './tabs/history/history.component';
import { DataProtectionComponent } from './tabs/data-protection/data-protection.component';
import { AuthorizationComponent } from './tabs/authorization/authorization.component';
import { DocumentsComponent } from './tabs/documents/documents.component';
import { ProjectComponent } from './tabs/project/project.component';
import { TimelineModule } from '../../timeline/timeline.module';
import { FundingProjectDetailsComponent } from './funding-project-details.component';
import { RouterModule } from '@angular/router';
import { DialogModule } from '../../dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { ActionSheetModule } from '../../action-sheet/action-sheet.module';
import { ButtonModule } from '../../button/button.module';
import { AlertModule } from '../../alert/alert.module';
import { SearchModule } from '../search/search.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DetailActionsModule } from './detail-actions/detail-actions.module';
import { RequestsComponent } from './tabs/requests/requests.component';
import { RequestsTableComponent } from './tabs/requests/requests-table/requests-table.component';
import { FilesTableComponent } from './tabs/documents/files-table/files-table.component';
import { FileSearchPipe } from './tabs/documents/pipes/file-search.pipe';
import { DocumentFilterPipe } from './tabs/documents/pipes/document-filter.pipe';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from '../../dropdown/public-api';
import { RequestReviewComponent } from './tabs/requests/request-review/request-review.component';
import { InquiriesComponent } from './tabs/inquiries/inquiries.component';
import { MessageViewComponent } from './tabs/inquiries/message-view/message-view.component';
import { MessageComponent } from './tabs/inquiries/message-view/message/message.component';
import { InquiriesTableComponent } from './tabs/inquiries/inquiries-table/inquiries-table.component';
import {
  DocumentAndFileTitlePipe,
  HasProjectAuthorityDirective
} from '../public-api';

@NgModule({
  declarations: [
    FundingProjectDetailsComponent,
    ProjectComponent,
    DocumentsComponent,
    AuthorizationComponent,
    HistoryComponent,
    FilterElementComponent,
    DocumentSearchPipe,
    DocumentFilterPipe,
    FileSearchPipe,
    ApplicationDocumentFilterPipe,
    ClassDocumentFilterPipe,
    StatusDocumentFilterPipe,
    DocumentsTableComponent,
    RequestReviewComponent,
    RequestsComponent,
    RequestsTableComponent,
    FilesTableComponent,
    InquiriesComponent,
    MessageViewComponent,
    MessageComponent,
    InquiriesTableComponent,
    DocumentAndFileTitlePipe,
    DataProtectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    OamanFormsModule,
    DropdownModule,
    FormsModule,
    DetailActionsModule,
    TimelineModule,
    TranslateModule,
    MaterialModule,
    ActionSheetModule,
    ButtonModule,
    SearchModule,
    AlertModule,
    DialogModule
  ],
  exports: [
    FundingProjectDetailsComponent,
    ProjectComponent,
    DocumentsComponent,
    AuthorizationComponent,
    HistoryComponent,
    RequestReviewComponent,
    DocumentsTableComponent,
    FilesTableComponent,
    InquiriesComponent,
    InquiriesTableComponent,
    MessageViewComponent,
    HasProjectAuthorityDirective,
    DocumentAndFileTitlePipe,
    DataProtectionComponent
  ]
})
export class FundingProjectDetailsModule {}
