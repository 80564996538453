<div class="action-wrapper">
  <div
    *ngFor="let action of actions"
    class="action-item"
    role="button"
    tabindex="0"
    (click)="triggerAction(action.event)"
    (keyup.enter)="triggerAction(action.event)"
  >
    <ng-container *ngIf="showDetailActions(action)">
      <ng-container *ngIf="action.requiresProjectAuthority; else defaultState">
        <div *oamanHasProjectAuthority="action.name">
          <mat-icon aria-hidden="true" class="action-icon">{{
            action.icon
          }}</mat-icon>
          <a>{{
            'fundingProject.details.actions.' + action.name | translate
          }}</a>
        </div>
      </ng-container>
      <ng-template #defaultState>
        <mat-icon aria-hidden="true" class="action-icon">{{
          action.icon
        }}</mat-icon>
        <a>{{ 'fundingProject.details.actions.' + action.name | translate }}</a>
      </ng-template>
    </ng-container>
  </div>
</div>
