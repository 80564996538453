<mat-card appearance="raised" class="oaman-card-funding">
  <mat-chip-listbox aria-label="Status"
    ><mat-chip-option [selectable]="false"
      *ngIf="context === 'manage' && fundingProject.assignedClerks.length === 0"
      class="card-chip"
      >{{ 'fundingProject.card.chip' | translate }}</mat-chip-option
    ></mat-chip-listbox
  >
  <mat-card-content class="title-wrapper">
    <mat-card-title
      *ngIf="
        context === 'manage' && fundingProject.assignedClerks.length === 0;
        else cardWithNoNewTag
      "
      class="mat-mdc-card-title-new"
    >
      {{ fundingProject.title }}
    </mat-card-title>
    <ng-template #cardWithNoNewTag>
      <mat-card-title>{{ fundingProject.title }}</mat-card-title>
    </ng-template>
    <oaman-button
      color="primary"
      [disabled]="isProjectInaccessible()"
      (click)="onCardButtonClick()"
      >{{ 'fundingProject.card.buttonOpen' | translate }}</oaman-button
    >
  </mat-card-content>
  <mat-divider class="card-divider"></mat-divider>
  <mat-card-content>
    <mat-list dense>
      <mat-list-item *ngFor="let item of items; index as i">
        <span class="item-title">{{ item }}</span>
        <span
          class="item-value"
          *ngIf="item !== 'Sachbearbeiter:in'; else assignClerk"
          >{{ values[i] }}</span
        >
        <ng-template #assignClerk
          ><span
            *ngIf="values[i] === 'canAssignClerk'; else changeClerk"
            class="item-value item-assign-clerk"
            (click)="openDialog()"
            >SB zuordnen</span
          ></ng-template
        >
        <ng-template #changeClerk>
          <span
            class="item-value"
            [ngClass]="context === 'manage' ? 'item-change-clerk' : ''"
            (click)="openDialog()"
            ><mat-icon
              *ngIf="context === 'manage'"
              aria-hidden="true"
              class="item-change-clerk-icon"
              >edit</mat-icon
            >{{ values[i] }}
          </span>
        </ng-template>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
