<div class="spinner-wrapper">
  <div class="loader-fading-circle">
    <div class="loader-circle1 loader-circle"></div>
    <div class="loader-circle2 loader-circle"></div>
    <div class="loader-circle3 loader-circle"></div>
    <div class="loader-circle4 loader-circle"></div>
    <div class="loader-circle5 loader-circle"></div>
    <div class="loader-circle6 loader-circle"></div>
    <div class="loader-circle7 loader-circle"></div>
    <div class="loader-circle8 loader-circle"></div>
  </div>
</div>
