import { DetailActionsComponent } from './detail-actions.component';
import { MaterialModule } from './../../../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HasProjectAuthorityDirective } from '../../public-api';

@NgModule({
  declarations: [DetailActionsComponent, HasProjectAuthorityDirective],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [DetailActionsComponent, HasProjectAuthorityDirective]
})
export class DetailActionsModule {}
