<div #alertRef>
  <ng-container *ngIf="context === 'default'">
    <div class="alert alert-default">
      <mat-icon aria-hidden="true" class="infoicon">info</mat-icon>
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <mat-icon
        aria-label="close"
        class="closebtn"
        (click)="alertRef.style.display = 'none'"
        >highlight_off
      </mat-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="context === 'noContent'">
    <div class="alert alert-noContent">
      <mat-icon aria-hidden="true" fxFlex="4" class="infoicon">info</mat-icon>
      <div fxFlex="96">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="context === 'missingContext'">
    <div class="alert-missingContext">
      <mat-icon aria-hidden="true" fxFlex="4" class="error-icon">error</mat-icon>
      <div fxFlex="96">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #content><ng-content></ng-content></ng-template>
