<ng-container *ngIf="isProjectStateDraft; else withdraw">
  <h2 mat-dialog-title>
    {{ 'fundingProject.details.documents.dialog.deleteProject.dialogTitle' | translate }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <p>
      {{ 'fundingProject.details.documents.dialog.deleteProject.deletionText' | translate: { projectTitle: data.projectTitle } }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <oaman-button
      mat-button
      type="reset"
      [style.margin-right]="'1rem'"
      [mat-dialog-close]="false">
      {{
      'fundingProject.details.documents.dialog.buttons.cancelBtn' | translate
      }}
    </oaman-button>
    <oaman-button
      mat-button
      color="primary"
      type="submit"
      (click)="onAction('delete')"
      [mat-dialog-close]="true"
    >
      {{
      'fundingProject.details.documents.dialog.deleteProject.deleteBtn' | translate
      }}
    </oaman-button>
  </mat-dialog-actions>
</ng-container>

<ng-template #withdraw>
  <h2 mat-dialog-title>
    {{ 'fundingProject.details.documents.dialog.withdrawProject.dialogTitle' | translate }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <p>
      {{ 'fundingProject.details.documents.dialog.withdrawProject.withdrawalText' | translate: { projectTitle: data.projectTitle } }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <oaman-button
      mat-button
      type="reset"
      [style.margin-right]="'1rem'"
      [mat-dialog-close]="false">
      {{
      'fundingProject.details.documents.dialog.buttons.cancelBtn' | translate
      }}
    </oaman-button>
    <oaman-button
      mat-button
      color="primary"
      type="submit"
      (click)="onAction('withdraw')"
      [mat-dialog-close]="true"
    >
      {{
      'fundingProject.details.documents.dialog.withdrawProject.withdrawBtn' | translate
      }}
    </oaman-button>
  </mat-dialog-actions>
</ng-template>

