import { FieldWrapper } from '@ngx-formly/core';
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'oaman-form-cost-field-wrapper',
  template: `
    <div class="field-wrapper">
      <div
        *ngIf="props['focused'] && props['helpText']"
        class="help-text"
        [style]="getStyle()"
      >
        <oaman-help-text [helpText]="props['helpText']"></oaman-help-text>
      </div>

      <div fxLayout="row wrap" class="field">
        <div fxFlex="100" fxFlex.xs="100" [style.flex-direction]="'column'">
          <ng-template #fieldComponent></ng-template>
          <div *ngIf="showError" class="error-msg">
            <formly-validation-message [field]="field">
            </formly-validation-message>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .field-wrapper {
        position: relative;
      }
      .error-msg {
        color: #ff002d;
      }
      .field {
        position: relative;
      }
      .help-text {
        position: absolute;
        display: block;
        z-index: 1;
        top: -18px;
        margin-left: 4rem;
      }
    `
  ]
})
export class FormCostFieldWrapperComponent
  extends FieldWrapper
  implements AfterViewInit
{
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }
  ngAfterViewInit() {
    if (!this.formControl.valid && this.formControl.value) {
      this.formControl.markAllAsTouched();
      this.formControl.markAsDirty();
      this.changeDetectorRef.detectChanges();
    }
  }

  getStyle() {
    const numberOfSiblings = this.field.parent?.fieldGroup?.length;
    return numberOfSiblings
      ? {
          width: `${numberOfSiblings * 60.33}%`,
          left: `${numberOfSiblings * 100}%`
        }
      : '';
  }
}
