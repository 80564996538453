import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-cost-calculation-financial-plan-type',
  template: `
    <div class="wrapper">
      <div class="label-container">
        <span [innerHTML]="props.label"></span>
        <ng-container
          *ngIf="props.required && props.hideRequiredMarker !== true"
        >
          <span class="asterisk">*</span>
        </ng-container>
      </div>
      <div class="fields-container">
        <div class="field-item" *ngFor="let field of field.fieldGroup">
          <formly-field [field]="field"></formly-field>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        margin-bottom: 2rem;
        word-break: break-word;
        width: 100%; /* Ensure wrapper spans full width */
      }
      .label-container {
        margin-bottom: 0.5rem; /* Add space between label and input fields */
        width: 100%;
      }
      .fields-container {
        width: 100%;
      }
      .field-item {
        width: 100%; /* Ensure each field item spans full width */
      }
      .field-item formly-field {
        width: 100%; /* Ensure each formly field spans full width */
      }
    `
  ]
})
export class FormCostCalculationFinancialPlanTypeComponent extends FieldType<FieldTypeConfig> {}
