import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FundingProjectRequest } from '../../../core/public-api';

@Injectable({
  providedIn: 'root'
})
export class RequestUtilService {
  constructor(private translate: TranslateService) {}

  // constructs an object to utilize with dropdowns which filter requests
  getRequestDropdownObject(request: FundingProjectRequest) {
    return {
      key: request.id.toString(),
      value: `${this.translate.instant(
        'fundingProject.' + request.type
      )} ${this.translate.instant('fundingProject.request.from')} ${new Date(
        request.createdDate
      ).toLocaleDateString()}`
    };
  }
  sortRequests(requests: FundingProjectRequest[]) {
    return requests.sort(
      (a, b) =>
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
    );
  }
}
