import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../spinner/services/spinner.service';

@Component({
  selector: 'oaman-not-found',
  templateUrl: './not-found-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundErrorComponent implements OnInit {
  errorMessage!: { statusCode: string; message: string };

  constructor(
    private route: ActivatedRoute,
    private spinner: SpinnerService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((err) => {
      if (err)
        this.errorMessage = { statusCode: err.status, message: err.message };
    });
    this.spinner.hide();
  }
}
