import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NgxCurrencyInputMode } from 'ngx-currency';

@Component({
  selector: 'oaman-form-cost-input-type',
  template: `
    <!-- Use for input field with decimal value presentation (e.g. 1.458,95) -->
    <input
      *ngIf="type === 'cost'"
      [type]="'tel'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      currencyMask
      [options]="{
        prefix: '',
        thousands: '.',
        decimal: ',',
        nullable: 'false',
        allowNegative: 'false',
        inputMode: inputMode
      }"
    />
    <!-- Use for input field with decimal value presentation with currency sign (e.g. 1.458,95 €) -->
    <input
      *ngIf="type === 'cost-cur'"
      [type]="'tel'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      currencyMask
      [options]="{
        prefix: '',
        suffix: ' €',
        thousands: '.',
        decimal: ',',
        nullable: 'false',
        allowNegative: 'false',
        inputMode: inputMode
      }"
    />
    <input
      *ngIf="type !== 'cost' && type !== 'cost-cur'"
      [type]="type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
    />
  `
})
export class FormCostInputComponent extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type || 'number';
  }

  get inputMode() {
    return NgxCurrencyInputMode.Natural;
  }
}
