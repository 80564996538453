import { DialogService } from './../dialog/services/dialog.service';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit
} from '@angular/core';
import { FundingProjectHistory, TimelineEntry } from '../core/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oaman-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent implements OnInit {
  @Input() groupedHistoryEvents!: Map<string, Array<FundingProjectHistory>>;
  timelineEntries: TimelineEntry[] = [];
  generatedToday = false;
  constructor(
    public dialog: DialogService,
    private translate: TranslateService
  ) {}

  // generates a TimelineEntry object
  private generateTimelineEvent(
    entry: FundingProjectHistory,
    isGrouped = false
  ) {
    // isGrouped attribute suggests multiple events can be grouped on the same entry
    const displayText: string = isGrouped
      ? this.translate.instant('timeline.grouped')
      : this.getHistoryEventMessage(entry);
    return {
      displayText,
      date: entry.date,
      additionalInfo: isGrouped
        ? {
            count: 0,
            eventList: [] as string[]
          }
        : undefined
    };
  }

  private getHistoryEventMessage(entry: FundingProjectHistory): string {
    return this.translate.instant('timeline.' + entry.type, entry.params);
  }

  ngOnInit() {
    // iterate over the grouped events to generate timeline elements
    for (let groupedEvent of this.groupedHistoryEvents) {
      // "today" event is inserted once, on the first position after the newest entry in the timeline
      if (
        !this.generatedToday &&
        Date.parse(groupedEvent[0]) < Date.parse(new Date().toISOString())
      ) {
        this.timelineEntries.push({
          displayText: this.translate.instant('timeline.today'),
          date: new Date().toISOString().slice(0, 10)
        });
        this.generatedToday = true;
      }
      const allEntries = groupedEvent[1];
      // check if entries have to be grouped together
      if (allEntries.length === 1) {
        this.timelineEntries.push(this.generateTimelineEvent(allEntries[0]));
      } else {
        this.handleGroupEntries(allEntries);
      }
    }
  }

  private handleGroupEntries(allEntries: FundingProjectHistory[]): void {
    // now there are multiple events in a day and they are going to be grouped together
    // unless their type is 'applicantdocument.deadline'
    const groupedEntry = this.generateTimelineEvent(allEntries[0], true);
    allEntries.forEach((entry) => {
      if (entry.type === 'applicantdocument.deadline') {
        return this.timelineEntries.push(this.generateTimelineEvent(entry));
      }
      // undef check
      if (groupedEntry.additionalInfo) {
        // add event to grouped list and raise the event count
        groupedEntry.additionalInfo.eventList.push(
          this.getHistoryEventMessage(entry)
        );
        return groupedEntry.additionalInfo.count++;
      }
      return null;
    });
    // another undef check
    if (groupedEntry.additionalInfo) {
      // add the groupedEntry to the timeline if multiple events exist
      if (groupedEntry.additionalInfo.count > 1) {
        this.timelineEntries.push(groupedEntry);
      } else if (groupedEntry.additionalInfo.count === 1) {
        // if only a single event is left, add it to the timeline normally
        this.timelineEntries.push({
          displayText: groupedEntry.additionalInfo.eventList[0],
          date: groupedEntry.date
        });
      }
    }
  }

  getBadgeCount(entry: TimelineEntry) {
    if (entry.additionalInfo) {
      return entry.additionalInfo.count;
    }
    return null;
  }

  openDetails(entry: TimelineEntry): void {
    // render either a list of events or a single event if its not a groupedEntry
    const dialogText = entry.additionalInfo
      ? `<ul>${entry.additionalInfo.eventList
          .map((event) => {
            return '<li>' + event + '</li>';
          })
          .join('')}</ul>`
      : `<p>${entry.displayText}</p>`;
    this.dialog.openDialog(
      this.translate.instant('timeline.details'),
      dialogText
    );
  }
}
