import { FundingProjectRequest } from '../../../../core/interfaces/funding-project-request';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../dialog/services/dialog.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormTemplate } from './interfaces/form-template';
import { RequestUtilService } from '../../services/request-util.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProjectActionData, ProjectActionService } from '../../public-api';
import { FundingProject } from '../../../../core/interfaces/funding-project';
import { APP_CONFIG } from '../../../../core/util/app-config.token';
import { AppConfiguration } from '../../../../core/interfaces/app-configuration';
import { FormOutputAction } from '../../../../core/util/pdf-generation/oaman-form-to-pdf-generator';
import { FundingProjectDataService } from '../../../public-api';
import { orderBy as _orderBy } from 'lodash';

@Component({
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private requestUtil: RequestUtilService,
    private projectActionService: ProjectActionService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private fundingProjectData: FundingProjectDataService
  ) {}

  forms: FormTemplate[] = [];
  matMenuTrigger!: MatMenuTrigger;

  activeRequestFilter = '';

  requestFilters: Array<{ key: string; value: string }> = [];
  fundingProject!: FundingProject;

  get filteredForms(): FormTemplate[] {
    return this.forms.filter(
      (form) => form.requestAndType.id.toString() === this.activeRequestFilter
    );
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.forms = data['resolvedData'];
      const resolvedRequestData = data['resolvedRequestData'];
      if (resolvedRequestData.requests) {
        this.initializeApplicationFilters(resolvedRequestData.requests);
      }
    });
    this.route.parent?.parent?.data.subscribe((data) => {
      const resolvedData: FundingProject = data['resolvedData'];
      if (resolvedData) {
        this.fundingProject = resolvedData;
      }
    });
  }

  initializeApplicationFilters(requests: FundingProjectRequest[]) {
    const sortedRequests = this.requestUtil.sortRequests(requests);
    sortedRequests.forEach((request: FundingProjectRequest, index) => {
      const nextEntry = this.requestUtil.getRequestDropdownObject(request);
      // check if request does exist already
      if (!this.requestFilters.find((object) => nextEntry.key === object.key)) {
        this.requestFilters.push(nextEntry);
      }

      // set the last request as active
      if (index <= this.forms.length - 1) {
        this.activeRequestFilter = request.id.toString();
      }
    });
  }
  changeRequestFilter(requestId: string) {
    this.activeRequestFilter = requestId;
  }

  toFormTemplate(form: FormTemplate) {
    this.router.navigate(
      ['../forms', form.id, 'requests', form.requestAndType.id],
      { relativeTo: this.route }
    );
  }
  onKeyupEnter($event: any) {
    $event.stopPropagation();
    this.matMenuTrigger.openMenu();
  }

  openInfoDialog(description: string) {
    const info = this.translate.instant(
      'fundingProject.details.forms.items.info'
    );
    this.dialogService.openDialog(info, description);
  }
  openDeleteConfirm(form: FormTemplate) {
    if (!this.isDeletable(form)) {
      return;
    }
    const title = this.translate.instant(
      'fundingProject.details.forms.dropdown.delete'
    );
    const description = this.translate.instant(
      'fundingProject.details.forms.items.delete',
      { title: form.title }
    );
    this.dialogService
      .openDialog(
        title,
        description,
        this.translate.instant(
          'fundingProject.details.documents.dialog.buttons.deleteBtn'
        )
      )
      .subscribe((result) => (result ? this.confirmDelete(form.id) : null));
  }

  isDeletable(form: FormTemplate): boolean {
    if (!this.isEnroll()) {
      // the deletion is allowed only for Enroll
      return false;
    }

    return (
      !form.required &&
      this.fundingProjectData.hasProjectAuthority('deleteForm') &&
      this.isFormOfCreatedRequest(form)
    );
  }

  isEnroll(): boolean {
    return this.appConfig.context === 'enroll';
  }

  isEditableForm(form: FormTemplate): boolean {
    if (!this.isEnroll()) {
      // the edition is allowed only for Enroll
      return false;
    }

    return (
      this.fundingProjectData.hasProjectAuthority('editForm') &&
      this.isFormOfCreatedRequest(form)
    );
  }

  getDeletionTooltip(form: FormTemplate): string {
    if (!this.fundingProjectData.hasProjectAuthority('deleteForm')) {
      return 'fundingProject.details.missingProjectAuthority';
    }
    if (form.requestAndType.stateResourceKey === 'request.state.withdrawn') {
      return 'fundingProject.details.forms.dropdown.nonDeletableWithdrawnState';
    } else if (!this.isFormOfCreatedRequest(form)) {
      return 'fundingProject.details.forms.dropdown.nonDeletableProcessedState';
    }

    return form.required
      ? 'fundingProject.details.forms.dropdown.nonDeletable'
      : '';
  }

  private isFormOfCreatedRequest(form: FormTemplate): boolean {
    return form.requestAndType.stateResourceKey === 'request.state.created';
  }

  private confirmDelete(id: string) {
    const fundingGuidelineId =
      this.route.snapshot.parent?.parent?.parent?.params.id;
    const fundingProjectId = this.route.parent?.snapshot.paramMap.get('id');
    if (fundingProjectId) {
      const deleteFormData: ProjectActionData = {
        fundingProjectId: fundingProjectId,
        fundingGuidelineId: fundingGuidelineId,
        requestId: this.activeRequestFilter,
        formId: id
      };
      this.projectActionService.setAction('deleteForm', deleteFormData);
    }
  }

  openPDF() {
    this.pdfAction(FormOutputAction.OPEN);
  }

  printPDF() {
    this.pdfAction(FormOutputAction.PRINT);
  }

  downloadPDF() {
    this.pdfAction(FormOutputAction.DOWNLOAD);
  }

  private pdfAction(formOutputAction: FormOutputAction) {
    const fundingGuidelineId =
      this.route.snapshot.parent?.parent?.parent?.params.id;
    const fundingProjectId = this.route.parent?.snapshot.paramMap.get('id');
    if (fundingProjectId && this.forms.length > 0) {
      const formId = this.forms[0].id;
      const projectActionData: ProjectActionData = {
        fundingGuidelineId: fundingGuidelineId,
        fundingProjectId: fundingProjectId,
        requestId: this.activeRequestFilter,
        formId: formId,
        formOutputAction: formOutputAction
      };
      this.projectActionService.setAction(
        'executeActionOnFormData',
        projectActionData
      );
    }
  }

  get sortedForms(): FormTemplate[] {
    return _orderBy(
      this.filteredForms,
      ['required', 'hasData'],
      ['desc', 'desc']
    );
  }
}
