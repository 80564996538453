import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FundingProject } from '../../core/interfaces/funding-project';
import { APP_CONFIG, AppConfiguration } from '../../core/public-api';
import { ApplicationContext } from '../../core/constants/application-context.constants';

@Component({
  selector: 'oaman-funding-project-details',
  templateUrl: './funding-project-details.component.html',
  styleUrls: ['./funding-project-details.component.scss']
})
export class FundingProjectDetailsComponent implements OnInit {
  @Input() fundingProject!: FundingProject;
  constructor(
    private translate: TranslateService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration
  ) {}

  links: Array<{ name: string; link: string; title: string }> = [
    {
      name: 'project',
      link: './details',
      title: this.translate.instant('fundingProject.details.links.a11y.project')
    },
    {
      name: 'requests',
      link: 'requests',
      title: this.translate.instant(
        'fundingProject.details.links.a11y.requests'
      )
    },
    {
      name: 'forms',
      link: 'forms',
      title: this.translate.instant('fundingProject.details.links.a11y.forms')
    },
    {
      name: 'documents',
      link: 'documents',
      title: this.translate.instant(
        'fundingProject.details.links.a11y.documents'
      )
    },
    {
      name: 'authorized',
      link: 'authorized',
      title: this.translate.instant(
        'fundingProject.details.links.a11y.authorized'
      )
    },
    {
      name: 'history',
      link: 'history',
      title: this.translate.instant('fundingProject.details.links.a11y.history')
    }
  ];
  activeLink = this.links[0];

  ngOnInit(): void {
    // Show 'Rückfragen' only if a clerk is assigned to the fundingproject.
    if (this.fundingProject.assignedClerks.length > 0) {
      this.links.splice(-1, 0, {
        name: 'inquiries',
        link: 'inquiries',
        title: this.translate.instant(
          'fundingProject.details.links.a11y.inquiries'
        )
      });
    }

    if (this.appConfig.context === ApplicationContext.ENROLL) {
      const insertAtIndex = 1;
      this.links.splice(insertAtIndex, 0, {
        name: 'data-protection',
        link: 'data-protection',
        title: this.translate.instant(
          'fundingProject.details.links.a11y.data-protection'
        )
      });
    }
  }
}
