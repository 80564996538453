import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { Observable } from 'rxjs';

@Component({
  selector: 'oaman-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SortComponent {
  @Input()
  sortValue$ = new Observable<string>();
  @Input()
  isAscending$ = new Observable<boolean>();
  @Output()
  sortCriterion = new EventEmitter<string>();
  @Output()
  sortDirection = new EventEmitter<MouseEvent>();

  changeSort(sortCriterion: string) {
    this.sortCriterion.emit(sortCriterion);
  }
  changeDirection() {
    this.sortDirection.emit();
  }
}
