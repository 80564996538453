import { FundingProjectDocument } from '../../funding-project/public-api';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { minDateValidator } from '../../core/util/min-date-validator';
import { formatDate } from '@angular/common';
import {
  forbiddenSpecialChars,
  regExForSpecialCharsExclusion
} from '../../core/util/forbidden-special-characters-regex';

@Component({
  selector: 'oaman-document-request-dialog',
  templateUrl: './document-request-dialog.component.html',
  styleUrls: ['./document-request-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentRequestDialogComponent {
  requestForm: UntypedFormGroup;
  repeat = false;
  isEditable = false;
  private maxTitleLength = 50;
  private maxDescriptionLength = 4096;
  protected readonly forbiddenSpecialChars = forbiddenSpecialChars;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FundingProjectDocument,
    public dialogRef: MatDialogRef<DocumentRequestDialogComponent>
  ) {
    // initialize validFrom date to first second of the current day
    const minValidFrom = new Date();
    minValidFrom.setUTCHours(0, 0, 0, 0);

    this.isEditable = data ? true : false;
    this.requestForm = new UntypedFormGroup({
      title: new UntypedFormControl(data ? data.title : '', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(this.maxTitleLength),
        Validators.pattern(regExForSpecialCharsExclusion)
      ]),
      requestDeadline: new UntypedFormControl(
        this.data?.requestedDeadline
          ? formatDate(this.data.requestedDeadline, 'yyyy-MM-dd', 'en')
          : null,
        minDateValidator(minValidFrom)
      ),
      description: new UntypedFormControl(data ? data.description : '', [
        Validators.maxLength(this.maxDescriptionLength)
      ])
    });
  }

  onSubmit() {
    this.dialogRef.close({ data: this.requestForm.value, repeat: this.repeat });
  }

  getMaxLengthParam(controlName: string): number {
    return controlName === 'title'
      ? this.maxTitleLength
      : this.maxDescriptionLength;
  }
}
