import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-intext-field-wrapper',
  template: `
    <div fxLayout="row wrap" class="wrapper">
      <span>{{ props.text1 }} </span>
      <div [style.padding]="'0 .5rem'">
        <ng-template #fieldComponent></ng-template>
      </div>
      <span> {{ props.text2 }}</span>
    </div>
  `,
  styles: [
    `
      .wrapper {
        align-items: center;
        margin: 1rem 0;
      }
    `
  ]
})
export class FormIntextFieldWrapperComponent extends FieldWrapper {}
