import { createReducer, on } from '@ngrx/store';
import { User } from 'oaman-components';
import {
  getAuthenticationFailure,
  getAuthenticationSuccess,
  notLoggedIn,
  removeAuthObject
} from './auth.actions';

export interface AuthenticationState {
  user: User | null;
  hasLoaded: boolean;
  authorized: boolean;
  loggedOut: boolean;
  error: string;
}

export const initialState: AuthenticationState = {
  user: null,
  hasLoaded: false,
  authorized: false,
  loggedOut: false,
  error: ''
};

export const authReducer = createReducer(
  initialState,
  on(getAuthenticationSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
      authorized: true,
      hasLoaded: true,
      loggedOut: false,
      error: ''
    };
  }),
  on(getAuthenticationFailure, (state) => {
    return {
      ...state,
      user: null,
      authorized: false,
      hasLoaded: true,
      loggedOut: false,
      error: 'authentication failure'
    };
  }),
  on(removeAuthObject, (state) => {
    return {
      ...state,
      user: null,
      authorized: false,
      hasLoaded: true,
      loggedOut: true,
      error: ''
    };
  }),
  on(notLoggedIn, (state) => {
    return {
      ...state,
      user: null,
      authorized: false,
      hasLoaded: true,
      loggedOut: false,
      error: ''
    };
  })
);
