import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../core/interfaces/user';

@Pipe({
  name: 'assignedClerks'
})
export class AssignedClerksPipe implements PipeTransform {
  transform(clerks: Array<{ user: User }>): string {
    return clerks?.map((clerk) => clerk.user.fullName).join(', ');
  }
}
