import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { SaveForm, SaveModel } from '../interfaces/save-model';
import { ActivatedRoute, Router } from '@angular/router';
import { SaveFormObserverService } from '../services/save-form-observer.service';
import { AppConfiguration } from '../../../../../core/interfaces/app-configuration';
import { APP_CONFIG } from '../../../../../core/util/app-config.token';
import { FundingMeasure } from '../../../../../core/interfaces/funding-measure';
import { FormsHelper } from '../services/forms-helper';
import {
  FORM_TEMPLATE_LEADER,
  FORM_TEMPLATE_ZILE
} from '../utils/forms-constants';

import { FormTemplate } from '../interfaces/form-template';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../../../core/interfaces/user';
import { UserObjectObservableService } from '../../../../../core/services/user-object-observable.service';

import { FundingProject } from '../../../../../core/interfaces/funding-project';
import { find as _find } from 'lodash';
import { LocalActionGroup } from '../../../../../core/interfaces/local-action-group';
import { ProjectActionService } from '../../../services/project-action.service';
import {
  FormOutputAction,
  OAManFormToPdfGenerator
} from '../../../../../core/util/pdf-generation/oaman-form-to-pdf-generator';
import { ProjectActionData } from '../../../interfaces/project-action';
import { FundingProjectDataService } from '../../../../public-api';
import { DeputyRole } from '../../../../../core/interfaces/deputy-info';
import { ApplicationContext } from '../../../../../core/constants/application-context.constants';
import { DialogService } from '../../../../../dialog/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';

import { Nation } from '../../../../../core/interfaces/nation';

@Component({
  templateUrl: './form-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
  private actionEvent!: string;
  private visibilityChangedBound!: OmitThisParameter<() => void>;
  private formTitle!: string;
  private currentUser!: User;
  private currentForm?: FormTemplate;
  private userObjectSubscription!: Subscription;
  private projectActionSubscription!: Subscription;
  private fundingProject!: FundingProject;
  context: string;
  actionsEnroll = [{ name: 'formClose', icon: 'close', event: 'close' }];
  actionsManage = [{ name: 'formClose', icon: 'close', event: 'close' }];
  form = new UntypedFormGroup({});
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
      disabled: false,
      fundingMeasure: null,
      hasRepresentatives: false
    }
  };
  model: any;
  fields!: FormlyFieldConfig[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private saveFormObserverService: SaveFormObserverService,
    private formsHelper: FormsHelper,
    private userObjectObservableService: UserObjectObservableService,
    private projectActionService: ProjectActionService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private fundingProjectData: FundingProjectDataService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private zone: NgZone
  ) {
    this.context = this.appConfig.context;
    this.options.formState['formContextManage'] =
      this.context === ApplicationContext.MANAGE;

    this.userObjectSubscription =
      this.userObjectObservableService.userObject$.subscribe(
        (user) => (this.currentUser = user)
      );
  }

  ngAfterViewInit(): void {
    if (
      FORM_TEMPLATE_ZILE === this.formTitle ||
      FORM_TEMPLATE_LEADER === this.formTitle
    ) {
      this.formsHelper.renderCommonConditions(this.fields);
    }
    if (FORM_TEMPLATE_ZILE === this.formTitle) {
      this.formsHelper.renderZileConditions(this.fields, this.options);
    }

    if (
      this.context === ApplicationContext.ENROLL &&
      this.form.valid &&
      !this.currentForm?.hasPdf
    ) {
      this.dialogService.openDialog(
        this.currentForm?.title!,
        this.translate.instant(
          'fundingProject.details.forms.warnings.pdfMissing'
        )
      );
    }
  }

  ngOnInit(): void {
    this.route.parent?.data.subscribe((data) => {
      this.fundingProject = data['resolvedData'];
    });
    this.route.data.subscribe((data) => {
      let resolvedFundingMeasures: FundingMeasure[] =
        data['resolvedFundingMeasures'];
      let resolvedLocalActionGroups: LocalActionGroup[] =
        data['resolvedLocalActionGroups'];
      let resolvedNations: Nation[] = data['resolvedNations'];

      // Since Manage receives no data for FundingMeasures and LocalActionGroups(leader FG) from the BE, the selected
      // FundingMeasure, LocalActionGroup and ParticipatingLAGs(leader FG) for the project will be used here
      // to have possible <option>s in the <select> element
      if (
        this.context === ApplicationContext.MANAGE &&
        this.fundingProject.fundingMeasureDTO
      ) {
        resolvedFundingMeasures = [this.fundingProject.fundingMeasureDTO];
        // TODO: Why do we need to reset the received local action group list from backend ?
        resolvedLocalActionGroups = [];
        if (this.fundingProject.localActionGroup) {
          resolvedLocalActionGroups.push(this.fundingProject.localActionGroup);
        }
        if (this.fundingProject.participatingLAGs?.length > 0) {
          resolvedLocalActionGroups = resolvedLocalActionGroups.concat(
            this.fundingProject.participatingLAGs.map((o) => o.localActionGroup)
          );
        }
      }

      const isFormEditionAuthorized =
        this.fundingProjectData.hasProjectAuthority('editForm');
      const forms: FormTemplate[] = data['resolvedForms'];
      const initialRequestForm = forms.find(
        (form) =>
          form.title === FORM_TEMPLATE_ZILE ||
          form.title === FORM_TEMPLATE_LEADER
      );
      this.formTitle = data['resolvedData']['title'];
      this.currentForm = this.findCurrentForm(forms);
      const isEditableForm = this.isCurrentFormEditable(forms);

      // try to parse the passed schema and model, route back to the forms component on failure
      try {
        // set project's funding measure in form state as default
        this.options.formState.fundingMeasure =
          this.fundingProject.fundingMeasureDTO?.title;

        const loadedFields = JSON.parse(
          data['resolvedData']['jsonFormTemplate']
        );

        if (isEditableForm && isFormEditionAuthorized) {
          this.actionsEnroll.push({
            name: 'formSave',
            icon: 'save',
            event: 'save'
          });
        }

        this.prefillAndRenderFormData(
          initialRequestForm,
          loadedFields,
          resolvedFundingMeasures,
          resolvedLocalActionGroups,
          resolvedNations,
          isEditableForm
        );

        // disable the whole form in manage (for Clerk), and also disable forms that are not in DRAFT state
        this.fields[0]['hooks'] = {
          onInit: () => {
            if (
              this.context === ApplicationContext.MANAGE ||
              !isFormEditionAuthorized ||
              !isEditableForm
            ) {
              this.form.disable();
              this.options.formState.disabled = true;
            }
          }
        };
      } catch (error: any) {
        this.router.navigate(['../'], { relativeTo: this.route });
        throw new Error(error);
      }

      try {
        this.model = JSON.parse(data['resolvedData']['jsonData']);
        this.handleAttorneyPowerRendering(isEditableForm);
      } catch (error: any) {
        this.router.navigate(['../'], { relativeTo: this.route });
        throw new Error(error);
      }

      // save form inputs when user changes/closes browser tab, closes or minimizes the browser
      this.visibilityChangedBound = this.saveFormOnDocumentHidden.bind(
        this,
        this.isCurrentFormEditable(forms) && isFormEditionAuthorized
      );
      document.addEventListener(
        'visibilitychange',
        this.visibilityChangedBound
      );
      return data;
    });
    this.subscribeToProjectActionObserver();
  }

  private isCurrentFormEditable(forms: FormTemplate[]): boolean {
    const currentForm = this.findCurrentForm(forms);
    return (
      currentForm?.requestAndType?.stateResourceKey === 'request.state.created'
    );
  }

  private findCurrentForm(forms: FormTemplate[]): FormTemplate | undefined {
    return _find(forms, (form) => {
      // Check if the form's id matches the route's formId and the form's request id matches the route's requestId
      if (
        form.id === this.route.snapshot.params.formId &&
        form.requestAndType.id === this.route.snapshot.params.requestId
      ) {
        return true;
      }
      // Return false if the conditions don't match
      return false;
    });
  }

  ngOnDestroy(): void {
    // save form inputs when user navigates away from this page
    if (this.actionEvent === 'save') this.onAction('save', 'page');
    // deactivate eventListener from saving form when component is destroyed
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangedBound
    );

    this.userObjectSubscription.unsubscribe();
    this.projectActionSubscription.unsubscribe();
  }

  resetModel() {
    if (this.options.resetModel) {
      this.options.resetModel();
    }
  }

  onAction($event: string, context: string = 'button') {
    this.actionEvent = $event;
    if ($event === 'save') {
      const saveForm: SaveForm = {
        context: context,
        saveModel: <SaveModel>{
          fundingGuidelineId:
            this.route.snapshot.parent?.parent?.parent?.params.id,
          fundingProjectId: this.route.snapshot.parent?.params.id,
          templateId: this.route.snapshot.params.formId,
          model: JSON.stringify(this.model),
          isValid: this.form.valid,
          requestId: this.route.snapshot.params.requestId
        }
      };
      // mark the form as dirty for the manually triggered save action
      // to save it on backend and genertae the PDF too
      this.form.markAsDirty();
      if (saveForm.saveModel.isValid && context === 'button') {
        this.zone.run(() => {
          this.dialogService
            .openFormValidDialog('SaveAndClose')
            .subscribe(() => {
              this.saveFormObserverService.publish(saveForm);
            });
        });
      } else {
        this.saveFormObserverService.publish(saveForm);
      }
    } else {
      this.router
        .navigate(['../../../../forms'], {
          relativeTo: this.route
        })
        .then();
    }
  }

  private prefillAndRenderFormData(
    initialRequestForm: FormTemplate | undefined,
    loadedFields: FormlyFieldConfig[],
    fundingMeasures: FundingMeasure[],
    localActionGroups: LocalActionGroup[],
    nations: Nation[],
    isEditableForm: boolean
  ) {
    let defaultNation = nations.find(
      (nation) => nation.title === 'Deutschland'
    );
    // prefill form with user info, funding measure and attorney power info (if available) on initial creation
    if (
      FORM_TEMPLATE_ZILE === this.formTitle ||
      FORM_TEMPLATE_LEADER === this.formTitle
    ) {
      this.prefillFormAttorneyPower(loadedFields, isEditableForm);
      this.prefillFormBaseData(initialRequestForm, loadedFields, defaultNation);
      this.formsHelper.renderNationList(this.fields, nations);
    } else {
      this.fields = loadedFields;
    }

    // dynamically render list of funding measures into ZILE-form OR list of local action groups (LAGs) into LEADER form
    if (FORM_TEMPLATE_ZILE === this.formTitle) {
      this.formsHelper.renderFundingMeasureList(this.fields, fundingMeasures);
    } else if (FORM_TEMPLATE_LEADER === this.formTitle) {
      this.formsHelper.renderLagList(this.fields, localActionGroups);
    } else {
      this.fields = loadedFields;
    }
  }

  private saveFormOnDocumentHidden(isEditableForm: boolean) {
    if (document.hidden && isEditableForm) {
      this.onAction('save', 'page');
    }
  }

  private subscribeToProjectActionObserver() {
    // If form changes were successfully persisted and the form's state is still "dirty", set form state to "pristine"
    this.projectActionSubscription = this.projectActionService
      .onAction()
      .subscribe((result) => {
        if (result?.action === 'markFormAsPristine' && this.form.dirty) {
          this.form.markAsPristine();
          this.pdfAction(FormOutputAction.BLOB);
        }
      });
  }

  openPDF() {
    this.pdfAction(FormOutputAction.OPEN);
  }

  printPDF() {
    this.pdfAction(FormOutputAction.PRINT);
  }

  downloadPDF() {
    this.pdfAction(FormOutputAction.DOWNLOAD);
  }

  saveBase64() {
    this.pdfAction(FormOutputAction.BASE64);
  }

  private pdfAction(formOutputAction: FormOutputAction): any {
    const projectActionData: ProjectActionData = {
      fundingGuidelineId: this.route.snapshot.parent?.parent?.parent?.params.id,
      fundingProjectId: this.route.snapshot.parent?.params.id,
      requestId: this.route.snapshot.params.requestId,
      formId: this.route.snapshot.params.formId,
      formOutputAction: formOutputAction,
      title: this.formTitle
    };

    const modelToUse = OAManFormToPdfGenerator.escapeForbiddenPDFCharacters(
      this.model
    );
    return OAManFormToPdfGenerator.execute(
      this.fields,
      modelToUse,
      this.currentUser,
      projectActionData,
      this.projectActionService,
      OAManFormToPdfGenerator.saveFormDocument
    );
  }

  private prefillFormAttorneyPower(
    loadedFields: FormlyFieldConfig[],
    isEditableForm: boolean
  ) {
    const projectRepresentatives = this.fundingProject.deputies.filter(
      (o) => o.participantRole === DeputyRole.Representative
    );
    if (projectRepresentatives.length > 0) {
      this.options.formState.hasRepresentatives = true;
      if (isEditableForm) {
        this.fields = this.formsHelper.prefillFormAttorneyPower(
          loadedFields,
          projectRepresentatives
        );
      }
    } else {
      this.fields = loadedFields;
    }
  }

  private prefillFormBaseData(
    initialRequestForm: FormTemplate | undefined,
    loadedFields: FormlyFieldConfig[],
    nation: Nation | undefined
  ) {
    if (!initialRequestForm?.hasData) {
      this.fields = this.formsHelper.prefillFormBaseData(
        loadedFields,
        this.currentUser,
        this.fundingProject.fundingMeasureDTO as FundingMeasure,
        nation,
        initialRequestForm?.title
      );
    } else {
      this.fields = loadedFields;
    }
  }

  /**
   * Remove property "attorneyPower" from model if form has not been submitted and only if form is the initial ZILE or LEADER form
   * @param isEditableForm if form is in editable state
   * @private
   */
  private handleAttorneyPowerRendering(isEditableForm: boolean) {
    if (
      isEditableForm &&
      (FORM_TEMPLATE_ZILE === this.formTitle ||
        FORM_TEMPLATE_LEADER === this.formTitle)
    ) {
      delete this.model['attorneyPower'];
    }
  }
}
