import { APP_CONFIG } from '../../../../../core/util/app-config.token';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  Inject
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  AppConfiguration,
  FundingProject,
  FundingProjectRequest
} from '../../../../../core/public-api';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogService } from '../../../../../dialog/services/dialog.service';
import { DeleteDialogData } from '../../../../../dialog/interfaces/delete-dialog-date';
import { RequestResolverData } from '../interfaces/request-resolver-data';
import { DetailAction } from '../../../interfaces/detail-actions';
import { CreateRequestDialogData } from '../../../../../dialog/interfaces/create-request-dialog-data';
import { RequestType } from '../interfaces/request-type';
import { Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProjectActionData } from '../../../interfaces/public-api';
import { ProjectActionService } from '../../../services/public-api';
import { FundingProjectDataService } from '../../../../public-api';
import * as FundingProjectStates from '../../../utils/fundingProjectStates';
import { ApplicationContext } from '../../../../../core/constants/application-context.constants';

@Component({
  selector: 'oaman-requests-table',
  templateUrl: './requests-table.component.html',
  styleUrls: ['./requests-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestsTableComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('matMenuTrigger')
  matMenuTrigger!: MatMenuTrigger;
  context = '';
  private requests!: FundingProjectRequest[];
  private fundingProjectState!: string;
  private fundingGuidelineId!: string;
  private requestTypes!: RequestType[];
  private navigationSubscription: Subscription;
  dataSource = new MatTableDataSource<FundingProjectRequest>();
  displayedColumns: string[] = [
    'type',
    'submissionDate',
    'submissionBy',
    'status',
    'action'
  ];
  fundingProject!: FundingProject;
  requestsActions: DetailAction[] = [];
  currentDate = new Date();
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private projectActionService: ProjectActionService,
    private fundingProjectData: FundingProjectDataService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialize the component
      if (e.routerEvent instanceof NavigationEnd) {
        this.initializeRequests();
      }
    });
    this.context = this.appConfig.context;
    if (this.context === ApplicationContext.ENROLL) {
      this.requestsActions = [
        {
          name: 'createRequest',
          icon: 'description',
          event: 'createRequest',
          requiresProjectAuthority: true
        }
      ];
    }
  }

  ngAfterViewInit() {
    // initializes the paginator and sort after view initialization
    this.initTablePaginatorAndSort();
  }

  initializeRequests() {
    this.route.data.subscribe((data) => {
      const resolvedData: RequestResolverData = data['resolvedData'];
      if (resolvedData) {
        this.requests = resolvedData.requests;
        this.requestTypes = resolvedData.requestTypes;
        this.initTable(this.requests);
      }
    });

    this.route.parent?.parent?.data.subscribe((data) => {
      const resolvedData: FundingProject = data['resolvedData'];
      if (resolvedData) {
        this.fundingProjectState = resolvedData.stateFundingProject.resourceKey;
        this.fundingProject = resolvedData;
        this.fundingGuidelineId = resolvedData.fundingGuideline.id;
      }
    });
  }

  initTable(data: FundingProjectRequest[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'type':
          return this.translate.instant('fundingProject.' + item.type);
        case 'status':
          return this.translate.instant('fundingProject.' + item.status);
        case 'submissionDate':
          return item.submissionDate ? item.submissionDate : '';
        case 'submissionBy':
          return item.submissionBy;
        default: {
          console.error('error in sorting data accessor');
          return item.id;
        }
      }
    };

    // initializes the paginator and sort again after table content has changed
    this.initTablePaginatorAndSort();
  }

  getDropdownTooltip(action: string, element: FundingProjectRequest): string {
    if (!this.fundingProjectData.hasProjectAuthority(action)) {
      return 'fundingProject.details.missingProjectAuthority';
    }

    if (action === 'request') {
      return element.status !== 'request.state.created'
        ? 'fundingProject.details.requests.table.dropdown.requestSubmittedTooltip'
        : 'fundingProject.details.requests.table.dropdown.projectSubmittedTooltip';
    } else {
      return 'fundingProject.details.requests.table.dropdown.deleteTooltip';
    }
  }

  isButtonDisabled(action: string, element: FundingProjectRequest): boolean {
    if (!this.fundingProjectData.hasProjectAuthority(action)) {
      return true;
    }

    if (action === 'request') {
      return (
        element.status !== 'request.state.created' ||
        this.fundingProjectState === FundingProjectStates.SUBMITTED
      );
    } else {
      return !(
        element.status === 'request.state.created' ||
        element.status === 'request.state.submitted'
      );
    }
  }

  triggerDropdownAction(action: string, request: FundingProjectRequest): void {
    switch (action) {
      case 'request':
        this.router
          .navigate([request.id], {
            relativeTo: this.route.parent
          })
          .then();
        break;
      case 'delete':
        this.openDialog(action, request);
        break;
      case 'forms':
        this.router.navigate(['../forms'], {
          relativeTo: this.route.parent
        });
        break;
      case 'documents':
        this.router.navigate(['../documents'], {
          relativeTo: this.route.parent
        });
        break;
      default:
        break;
    }
  }

  openDialog($event: string, request?: FundingProjectRequest) {
    const dialogData: CreateRequestDialogData = {
      requestTypes: this.requestTypes,
      fundingProjectState: this.fundingProjectState,
      createdStateDisbursementRequestAlreadyExists:
        this.checkIfCreateStateTypeDisbursementRequestAlreadyExists()
    };

    if ($event === 'createRequest') {
      this.dialogService
        .openCreateRequestDialog(dialogData)
        .subscribe((res) => {
          if (res) {
            const createRequestData: ProjectActionData = {
              fundingProjectId: this.fundingProject.id,
              fundingGuidelineId: this.fundingGuidelineId,
              requestType: res
            };
            this.projectActionService.setAction(
              'createRequest',
              createRequestData
            );
          }
        });
    }

    if ($event === 'delete' && request) {
      const deleteRequestData: ProjectActionData = {
        requestId: request.id,
        fundingGuidelineId: this.fundingGuidelineId
      };
      const deleteDialogData: DeleteDialogData = {
        title: this.translate.instant(`fundingProject.${request.type}`),
        deleteTitle:
          'fundingProject.details.requests.dialog.deleteDialog.deleteTitle',
        deleteTxt1:
          'fundingProject.details.requests.dialog.deleteDialog.deleteTxt1',
        deleteTxt2:
          'fundingProject.details.requests.dialog.deleteDialog.deleteTxt2'
      };
      this.dialogService.openDeleteDialog(deleteDialogData).subscribe((res) => {
        if (res) {
          this.projectActionService.setAction(
            'deleteRequest',
            deleteRequestData
          );
        }
      });
    }
  }

  checkIfCreateStateTypeDisbursementRequestAlreadyExists(): boolean {
    let request = this.requests?.find(
      (value) =>
        value.type === 'request.type.disbursment' &&
        value.status === 'request.state.created'
    );
    return request !== undefined && request !== null;
  }

  onKeyupEnter() {
    this.matMenuTrigger.openMenu();
  }

  ngOnDestroy(): void {
    this.navigationSubscription?.unsubscribe();
  }

  private initTablePaginatorAndSort() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
