import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from './interfaces/dialog-data';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'oaman-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
