import { Pipe, PipeTransform } from '@angular/core';
import { DocAndFileTitlePipeParams } from '../interfaces/document-and-file-title.pipe-data';

@Pipe({
  name: 'documentAndFileTitlePipe'
})
export class DocumentAndFileTitlePipe implements PipeTransform {
  test!: string;
  transform(title: string, params: DocAndFileTitlePipeParams): string {
    if (
      (params.appContext === 'manage' && params.connectorType === 'OAMAN') ||
      params.appContext === 'enroll'
    ) {
      let tempTitle = title.split('_');
      //removing the last elem, when it comes from Leader
      if (tempTitle.length > 1) {
        let index = tempTitle.length - 1;

        if (this.checkForUuidFormat(tempTitle[index])) {
          tempTitle.splice(index, 1);
        }
      }
      //rebuilding the title from array.
      return tempTitle.join('_');
    }
    return title;
  }
  checkForUuidFormat(elem: string) {
    //check if elem is an uuid.
    let regex = /^[a-z,0-9,-]{36,36}$/;
    return regex.test(elem);
  }
}
