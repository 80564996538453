import { SearchModule } from './search/search.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FundingProjectCreateComponent } from './funding-project-create/funding-project-create.component';
import { AlertModule } from '../alert/alert.module';
import { ButtonModule } from '../button/button.module';
import { SortComponent } from './sort/sort.component';
import { FiltersComponent } from './filters/filters.component';
import { TimelineModule } from '../timeline/timeline.module';
import { FundingProjectComponent } from './funding-project.component';
import { CardFundingGridModule } from '../card-funding-grid/card-funding-grid.module';
import { TableModule } from '../table/table.module';
import { DialogModule } from '../dialog/dialog.module';
import { ActionSheetModule } from '../action-sheet/action-sheet.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxCurrencyDirective } from 'ngx-currency';

@NgModule({
  declarations: [
    FundingProjectComponent,
    FundingProjectCreateComponent,
    SortComponent,
    FiltersComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    FormsModule,
    DialogModule,
    SearchModule,
    RouterModule,
    ReactiveFormsModule,
    AlertModule,
    ButtonModule,
    TimelineModule,
    CardFundingGridModule,
    ActionSheetModule,
    TableModule,
    InfiniteScrollModule,
    NgxCurrencyDirective
  ],
  exports: [FundingProjectComponent, FundingProjectCreateComponent]
})
export class FundingProjectModule {}
