import {
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from './alert/alert.module';
import { ButtonModule } from './button/button.module';
import { CardFundingGridModule } from './card-funding-grid/card-funding-grid.module';
import { ErrorModule } from './error/error.module';
import { FundingProjectModule } from './funding-project/funding-project.module';
import { MenuModule } from './menu/menu.module';
import { SnackBarModule } from './snack-bar/snack-bar.module';
import { TableModule } from './table/table.module';
import { TimelineModule } from './timeline/timeline.module';
import { ActionSheetModule } from './action-sheet/action-sheet.module';
import { SpinnerModule } from './spinner/spinner.module';
import { FundingProjectDetailsModule } from './funding-project/funding-project-details/funding-project-details.module';
import { DialogModule } from './dialog/dialog.module';
import { OamanFormsModule } from './funding-project/funding-project-details/tabs/forms/forms.module';
import { AppConfiguration } from '../public-api';
import { APP_CONFIG } from './core/util/app-config.token';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ActionSheetModule,
    AlertModule,
    ButtonModule,
    CardFundingGridModule,
    ErrorModule,
    FundingProjectModule,
    MenuModule,
    SnackBarModule,
    SpinnerModule,
    TableModule,
    FundingProjectDetailsModule,
    OamanFormsModule,
    DialogModule
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: LOCALE_ID, useValue: 'de-DE' }
  ],
  exports: [
    MaterialModule,
    CommonModule,
    ActionSheetModule,
    AlertModule,
    ButtonModule,
    ErrorModule,
    FundingProjectModule,
    FundingProjectDetailsModule,
    DialogModule,
    OamanFormsModule,
    MenuModule,
    SnackBarModule,
    TimelineModule,
    SpinnerModule
  ]
})
export class OamanComponentsModule {
  static forRoot(
    configuration: AppConfiguration
  ): ModuleWithProviders<OamanComponentsModule> {
    return {
      ngModule: OamanComponentsModule,
      providers: [{ provide: APP_CONFIG, useValue: configuration }]
    };
  }
}
