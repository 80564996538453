<nav
  id="nav-tabs"
  mat-tab-nav-bar
  [disablePagination]="true"
  [tabPanel]="tabPanel"
>
  <a
    *ngIf="authorized$ | async"
    id="nav-tab-home"
    mat-tab-link
    routerLink="/home"
    [title]="'navbar.homeTitle' | translate"
    routerLinkActive
    [routerLinkActiveOptions]="{ exact: false }"
    [active]="activeLink !== undefined && activeLink.name === 'Home'"
    class="nav-tab"
  >
    <mat-icon aria-label="home">home</mat-icon>
  </a>
  <a
    *ngIf="authorized$ | async"
    id="nav-tab-projects"
    mat-tab-link
    [title]="'navbar.fundingProjectsTitle' | translate"
    routerLinkActive
    [routerLinkActiveOptions]="{ exact: false }"
    routerLink="/funding-projects"
    [active]="activeLink !== undefined && activeLink.name === 'Anträge'"
    class="nav-tab"
  >
    {{ 'navbar.fundingProjects' | translate }}
  </a>
  <div *ngIf="(authorized$ | async) === false">
    <a
      *ngIf="displayCurrentComponent()"
      id="home-tab-nav"
      mat-tab-link
      routerLink="/login"
      routerLinkActive
      color="primary"
      aria-label="Home Button"
      class="nav-tab"
    >
      <mat-icon aria-label="home">home</mat-icon>
    </a>
  </div>
</nav>
<mat-tab-nav-panel #tabPanel> </mat-tab-nav-panel>
