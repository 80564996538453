import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { FundingProject, StateFundingProject } from '../../core/public-api';
import {
  forbiddenSpecialChars,
  regExForSpecialCharsExclusion
} from '../../core/util/forbidden-special-characters-regex';

@Component({
  selector: 'oaman-project-update-dialog',
  templateUrl: './project-update-dialog.component.html',
  styleUrls: ['./project-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectUpdateDialogComponent {
  editForm!: UntypedFormGroup;
  selectedState!: StateFundingProject;
  activeProgressBar = false; // used to display the progress bar during the backend call
  maxLengthFundingProjectTitle = 50;
  protected readonly forbiddenSpecialChars = forbiddenSpecialChars;

  constructor(
    public dialogRef: MatDialogRef<ProjectUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FundingProject
  ) {
    this.editForm = new UntypedFormGroup({
      title: new UntypedFormControl(data.title, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(this.maxLengthFundingProjectTitle),
        Validators.pattern(regExForSpecialCharsExclusion)
      ]),
      description: new UntypedFormControl(data.description, [
        Validators.required,
        Validators.minLength(1)
      ])
    });
  }

  onSubmit(): void {
    this.dialogRef.close(this.editForm.value);
  }
}
