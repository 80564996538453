<h2 mat-dialog-title>
  {{
    isEditable
      ? ('fundingProject.details.documents.dialog.documentRequest.editTitle'
        | translate)
      : ('fundingProject.details.documents.dialog.documentRequest.title'
        | translate)
  }}
</h2>
<form
  [formGroup]="requestForm"
  (ngSubmit)="onSubmit()"
  novalidate
  class="request-form"
>
  <mat-dialog-content class="mat-typography">
    <p>
      <label for="document-request-title">
        <strong>{{
          'fundingProject.details.documents.dialog.documentRequest.titleLabel'
            | translate
        }}</strong>
        <span [style.color]="'red'"> *</span>
      </label>
      <input
        id="document-request-title"
        class="input-field"
        formControlName="title"
        placeholder="{{
          'fundingProject.details.documents.dialog.documentRequest.titlePlaceholder'
            | translate
        }}"
        [style.width]="'100%'"
        [style.height]="'1.5rem'"
        type="text"
      />
      <ng-container
        *ngTemplateOutlet="validationMessages; context: { $implicit: 'title' }"
      ></ng-container>
    </p>
    <p>
      <label for="document-request-requestDeadline">
        <strong>{{
          'fundingProject.details.documents.dialog.documentRequest.requestDeadlineLabel'
            | translate
        }}</strong>
      </label>
      <input
        id="document-request-requestDeadline"
        class="input-field"
        formControlName="requestDeadline"
        placeholder="{{
          'fundingProject.details.documents.dialog.documentRequest.requestDeadlinePlaceholder'
            | translate
        }}"
        [style.width]="'100%'"
        [style.height]="'1.5rem'"
        type="date"
      />
      <ng-container
        *ngTemplateOutlet="
          validationMessages;
          context: { $implicit: 'requestDeadline' }
        "
      ></ng-container>
    </p>
    <p>
      <label for="document-request-description"
        ><strong>{{
          'fundingProject.details.documents.dialog.documentRequest.descriptionLabel'
            | translate
        }}</strong></label
      >
      <textarea
        id="document-request-description"
        formControlName="description"
        placeholder="{{
          'fundingProject.details.documents.dialog.documentRequest.descriptionPlaceholder'
            | translate
        }}"
        [style.width]="'100%'"
        [style.resize]="'none'"
        rows="6"
      ></textarea>
      <ng-container
        *ngTemplateOutlet="
          validationMessages;
          context: { $implicit: 'description' }
        "
      ></ng-container>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <oaman-button
      mat-button
      type="reset"
      [mat-dialog-close]="false"
      [style.margin-right]="'1rem'"
    >
      {{
        'fundingProject.details.documents.dialog.documentRequest.cancelBtn'
          | translate
      }}
    </oaman-button>
    <oaman-button
      *ngIf="!isEditable"
      [disabled]="!requestForm.valid"
      [style.pointer-events]="!requestForm.valid ? 'none' : 'auto'"
      type="submit"
      (click)="repeat = true"
      [mat-dialog-close]="false"
      [style.margin-right]="'1rem'"
    >
      {{
        'fundingProject.details.documents.dialog.documentRequest.saveAndContinue'
          | translate
      }}
    </oaman-button>
    <oaman-button
      [disabled]="!requestForm.valid"
      [style.pointer-events]="!requestForm.valid ? 'none' : 'auto'"
      type="submit"
      color="primary"
      [mat-dialog-close]="true"
    >
      {{
        isEditable
          ? ('fundingProject.details.documents.dialog.documentRequest.saveBtnEdit'
            | translate)
          : ('fundingProject.details.documents.dialog.documentRequest.saveBtn'
            | translate)
      }}
    </oaman-button>
  </mat-dialog-actions>
</form>

<ng-template #validationMessages let-name>
  <div
    class="validation-msg"
    *ngIf="
      requestForm.controls[name].invalid &&
      (requestForm.get(name)?.dirty || requestForm.get(name)?.touched)
    "
  >
    <div *ngIf="requestForm.get(name)?.errors?.required">
      {{ 'validationErrorMessages.required' | translate }}
    </div>
    <div *ngIf="requestForm.get(name)?.errors?.invalidDate">
      {{ 'validationErrorMessages.invalidDate' | translate }}
    </div>
    <div *ngIf="requestForm.get(name)?.errors?.minlength">
      {{ 'validationErrorMessages.minLength' | translate }}
    </div>
    <div *ngIf="requestForm.get(name)?.errors?.maxlength">
      {{
        'validationErrorMessages.maxLength'
          | translate: { lengthVar: getMaxLengthParam(name) }
      }}
    </div>
    <div *ngIf="requestForm.get(name)?.errors?.pattern">
      {{ 'validationErrorMessages.forbiddenCharacters' | translate: { forbiddenChars: forbiddenSpecialChars } }}
    </div>
  </div>
</ng-template>
