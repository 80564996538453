<h1>Datenschutzvereinbarung</h1>

<form
  [formGroup]="dataProtectionForm"
  class="data-protection-form"
  (ngSubmit)="onSubmit()"
  novalidate
>
  <mat-dialog-content class="mat-typography">
    <div class="wrapper">
      <p>
        Zur Nutzung unseres Dienstes zur Förderantragstellung
        („Online-Antragsmanagement“) erfolgt die Verarbeitung unter anderem von
        folgenden personenbezogener Daten:
      </p>
      <ul>
        <li>Vorname, Name</li>
        <li>E-Mail-Adresse</li>
        <li>
          Herkunft der Anmeldung (BundID-Konto oder elster-basiertes
          Unternehmenskonto)
        </li>
        <li>Vertrauensniveau der Anmeldung</li>
      </ul>
      <p class="text-align-justify">
        Diese werden nicht durch uns erhoben, sondern aus Ihrer Anmeldung beim
        BundID-Konto, bzw. dem elster-basierten Unternehmenskonto übernommen.
        Diese Daten werden zum Zweck des Nachweises Ihrer Identität bei der
        Antragseinreichung und -verfolgung verwendet. Sie können nur von
        berechtigten Personen in der Landesverwaltung Niedersachsen eingesehen
        werden. Die Daten werden entsprechend der niedersächsischen Aktenordnung
        gelöscht.
      </p>
      <p class="text-align-justify">
        Sie haben das Recht diese Einwilligung jederzeit ohne Angabe einer
        Begründung zu widerrufen. Weitere Details dazu finden Sie in der
        <a routerLink="/privacy" target="_blank">Datenschutzerklärung</a>. Sie
        haben auch das Recht, dieser Einwilligungserklärung nicht zuzustimmen.
        Da unser Dienst jedoch auf die Verarbeitung der o.g. Daten angewiesen
        ist, würde eine Nicht-Zustimmung eine Inanspruchnahme des Dienstes
        ausschließen. Die Zustimmung zur Nutzung von Daten Dritter im Rahmen der
        Antragstellung muss von Ihnen selbstständig sichergestellt werden.
      </p>
      <p class="text-align-justify">
        Ich bin damit einverstanden, dass mir der Bescheid in diesem Verfahren
        durch Bereitstellung zum Abruf im elektronischen Postfach meines
        Nutzerkontos bekanntgegeben wird. Den Bescheid rufe ich über das
        elektronische Postfach meines Nutzerkontos selbst ab, um ihn zur
        Kenntnis zu nehmen. Über die Bereitstellung zum Abruf werde ich durch
        den Betreiber meines Nutzerkontos über die in meinem Nutzerkonto
        hinterlegte E-Mail-Adresse benachrichtigt werden. Der Bescheid gilt am
        dritten Tag nach der Bereitstellung zum Abruf als bekannt gegeben.
        Etwaige Rechtsbehelfsfristen werden an diesem Tag in Gang gesetzt. Das
        gilt unabhängig davon ab, ob ich den Bescheid tatsächlich abrufe und zur
        Kenntnis nehme (§ 9 Abs. 1 OZG, § 3a Nds. VwVfG).
      </p>
    </div>

    <mat-checkbox id="agreement" formControlName="agreement" required
      >Ich erteile die Zustimmung zur Erhebung und Verarbeitung der genannten
      personenbezogenen Daten.</mat-checkbox
    >
    <ng-container
      *ngTemplateOutlet="
        validationMessages;
        context: { $implicit: 'agreement' }
      "
    ></ng-container>

    <mat-checkbox
      id="deliveryAgreement"
      formControlName="deliveryAgreement"
      required
      >Hiermit stimme ich der elektronischen Zustellung von Bescheiden
      zu.</mat-checkbox
    >
    <ng-container
      *ngTemplateOutlet="
        validationMessages;
        context: { $implicit: 'deliveryAgreement' }
      "
    ></ng-container>

    <mat-dialog-actions align="end">
      <oaman-button
        [mat-dialog-close]="false"
        [style.margin-right]="'1rem'"
        type="reset"
      >
        Abbrechen
      </oaman-button>
      <oaman-button
        color="primary"
        [disabled]="!dataProtectionForm.valid || this.activeProgressBar"
        type="submit"
      >
        Zustimmen
      </oaman-button>
    </mat-dialog-actions>
  </mat-dialog-content>
</form>

<ng-template #validationMessages let-name>
  <div
    class="validation-msg"
    *ngIf="
      dataProtectionForm.controls[name].invalid &&
      (dataProtectionForm.get(name)?.dirty ||
        dataProtectionForm.get(name)?.touched)
    "
  >
    <div *ngIf="dataProtectionForm.get(name)?.errors?.required">
      {{ 'validationErrorMessages.required' | translate }}
    </div>
  </div>
</ng-template>
