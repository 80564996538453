import { Inject } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'oaman-data-protection-consent-dialog',
  templateUrl: './data-protection-consent-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataProtectionConsentDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<DataProtectionConsentDialogComponent>
  ) {}
  onClick(result: boolean) {
    this.dialogRef.close(result);
  }
}
