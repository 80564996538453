export enum AttornyPowerType {
  Unlimited = 1,
  Limited = 2,
  Legal = 3
}

export enum AttornyPowerTypeAvailability {
  Available = 1,
  AtHand = 2
}

export enum FormsAttorneyPowerTypeAvailability {
  Available = 'liegt bereits vor',
  AtHand = 'liegt bei'
}

/**
 * Used to initialize the available attorney power types.
 * @returns
 */
export function getAttorneyPowerTypes() {
  const titlePrefix =
    'fundingProject.details.authorization.attorneyPower.types.';
  return [
    { id: AttornyPowerType.Unlimited, title: titlePrefix + 'unlimited' },
    { id: AttornyPowerType.Limited, title: titlePrefix + 'limited' },
    { id: AttornyPowerType.Legal, title: titlePrefix + 'legal' }
  ];
}

/**
 * Used to initialize the available attorney power availabilities.
 * @returns
 */
export function getAttorneyPowerAvailabilities() {
  const titlePrefix =
    'fundingProject.details.authorization.attorneyPower.availabilities.';
  return [
    {
      id: AttornyPowerTypeAvailability.Available,
      title: titlePrefix + 'available'
    },
    { id: AttornyPowerTypeAvailability.AtHand, title: titlePrefix + 'atHand' }
  ];
}
