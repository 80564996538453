import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Component, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'oaman-form-mat-date-type',
  template: `
    <mat-form-field>
      <input
        matInput
        [formControl]="formControl"
        [matDatepicker]="picker"
        [(ngModel)]="dateInput"
        [required]="props.required"
        [readonly]="true"
        [formlyAttributes]="field"
        (focus)="props['focused'] = true"
        (blur)="props['focused'] = false"
      />

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker-toggle
        matSuffix
        [disabled]="isFormDisabled()"
        (click)="clearDate()"
      >
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>

      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="formControl.invalid && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `
})
export class FormMatDateTypeComponent extends FieldType<FieldTypeConfig> {
  @ViewChild(MatInput) formFieldControl: MatInput | undefined;
  private _dateInput: any;

  constructor() {
    super();
  }

  clearDate() {
    if (!this.isFormDisabled()) {
      this.dateInput = null;
      if (this.field.formControl) this.field.formControl.setValue(null);
    }
  }

  get dateInput(): any {
    if (this.field.formControl) this._dateInput = this.field.formControl.value;
    return this._dateInput;
  }

  set dateInput(value: any) {
    this._dateInput = value;
  }

  isFormDisabled(): boolean {
    return this.form.disabled;
  }

  protected readonly Boolean = Boolean;
}
