import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Used to validate the date period for [ValidFrom-ValidTo]:
 * - the end date must be greater than the start one
 * @param startDateKey
 * @param endDateKey
 * @param formgroup
 * @returns
 */
export function datePeriodValidator(
  startDateKey: string,
  endDateKey: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !control.parent) {
      return null;
    }

    let startDate = control.parent.get(startDateKey)?.value;
    let endDate = control.parent.get(endDateKey)?.value;
    if (!startDate || !endDate) {
      return null;
    }

    const invalid = startDate >= endDate;
    return invalid ? { invalidDatePeriod: { value: control.value } } : null;
  };
}
