<h2 mat-dialog-title>
  {{ data.deleteTitle  | translate }}
</h2>
<mat-dialog-content class="mat-typography">
  <p>
    {{ data.deleteTxt1| translate }}
    <strong>{{ data.title }}</strong>
    {{ data.deleteTxt2 | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button
    mat-button
    type="reset"
    [style.margin-right]="'1rem'"
    [mat-dialog-close]="false">
    {{
      'fundingProject.details.documents.dialog.buttons.cancelBtn' | translate
    }}
  </oaman-button>
  <oaman-button
    mat-button
    color="primary"
    type="submit"
    [mat-dialog-close]="true"
  >
    {{
      'fundingProject.details.documents.dialog.buttons.deleteBtn' | translate
    }}
  </oaman-button>
</mat-dialog-actions>
