<oaman-detail-actions
  [actions]="inquiryActions"
  [fundingProject]="fundingProject"
  (clickTriggered)="openDialog()"
></oaman-detail-actions>
<div class="table-wrapper">
  <mat-table
    id="inquiries-table"
    [dataSource]="dataSource"
    matSort
    matSortActive="lastPostDate"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="status">
      <mat-header-cell class="first-column" *matHeaderCellDef>
      </mat-header-cell>
      <!-- Show icon if the document is missing -->
      <mat-cell class="first-column" *matCellDef="let element">
        <mat-icon
          *ngIf="element.questionAnswered"
          class="confirmation-icon"
          matTooltip="{{
            'fundingProject.details.inquiries.table.confirmationTooltip'
              | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.inquiries.table.confirmationTooltip'
              | translate
          }}"
        >
          done
        </mat-icon>
        <span
          *ngIf="!element.questionAnswered && element.numberOfNewMessages > 0"
          class="msg-feed"
          role="button"
          tabindex="0"
          >{{ element.numberOfNewMessages }}</span
        >
      </mat-cell>
    </ng-container>
    <!-- title Column -->
    <ng-container matColumnDef="topic">
      <mat-header-cell
        [title]="
          'fundingProject.details.inquiries.table.a11y.topic' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.inquiries.table.topic' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.topic }}
      </mat-cell>
    </ng-container>

    <!-- createdDate Column -->
    <ng-container matColumnDef="createdDate">
      <mat-header-cell
        [title]="
          'fundingProject.details.inquiries.table.a11y.createdDate' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.inquiries.table.createdDate' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.createdDate | date }}
      </mat-cell>
    </ng-container>

    <!-- lastPostBy Column -->
    <ng-container matColumnDef="lastPostBy">
      <mat-header-cell
        [title]="
          'fundingProject.details.inquiries.table.a11y.lastPostBy' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.inquiries.table.lastPostBy' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.lastPostBy.fullName }}
      </mat-cell>
    </ng-container>

    <!-- lastPostDate Column -->
    <ng-container matColumnDef="lastPostDate">
      <mat-header-cell
        [title]="
          'fundingProject.details.inquiries.table.a11y.lastPostDate' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.inquiries.table.lastPostDate' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.lastPostDate | date }}
      </mat-cell>
    </ng-container>

    <mat-header-row
      id="header-row"
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="showMessages(row.id)"
      (keyup.enter)="showMessages(row.id)"
      role="button"
      tabindex="0"
    ></mat-row>
  </mat-table>
  <mat-paginator
    id="paginator"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
  <p class="tz-hint">
    {{ 'fundingProject.details.documents.table.timezone' | translate }}
    {{ currentDate | date: 'zzzz' }}
  </p>
</div>
