import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormToPdfGenerator } from '../form-to-pdf-generator';
import { FieldGroupFormatter } from './field-group-formatter';

/**
 * Custom field group formatter for fields with a 'Massnahme Nummer' as value.
 */
export class MassnahmeNummerFieldGroupFormatter extends FieldGroupFormatter {
  generateFormatedFieldGroupTag(
    pdfGenerator: FormToPdfGenerator,
    parentField: FormlyFieldConfig | undefined,
    fieldGroup: FormlyFieldConfig[] | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ): string {
    if (pdfGenerator && fieldGroup) {
      let headline = parentField
        ? pdfGenerator.getTemplateValueTags(parentField)
        : '';
      let content = '';
      let isFirst = true;
      fieldGroup.forEach((field) => {
        if (!headline) {
          headline = pdfGenerator.getTemplateValueTags(field);
        }
        if (!isFirst) {
          content += '-';
        }
        content += pdfGenerator.getFieldValue(field, breadcrumbKey, '_');
        isFirst = false;
      });
      pdfGenerator.writeInputField(undefined, headline, content, undefined);
    }
    // In this case we don't return content cause we write it directly with the writeInputField method of the pdfGenerator!
    return '';
  }
}
