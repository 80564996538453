<oaman-detail-actions [fundingProject]="fundingProject"></oaman-detail-actions>

<oaman-dropdown
  [activeSelection]="activeRequestFilter"
  [label]="'fundingProject.details.documents.filters.filter1' | translate"
  [options]="requestFilters"
  (filterChanged)="changeRequestFilter($event)"
></oaman-dropdown>
<mat-list [style.padding-top]="0">
  <div *ngFor="let form of sortedForms">
    <mat-list-item
      [ngClass]="form.hasData ? 'clickable' : ''"
      (click)="form.hasData ? toFormTemplate(form) : ''"
      [attr.role]="form.hasData ? 'button' : ''"
      [attr.tabindex]="form.hasData ? '0' : ''"
    >
      <div class="title-wrapper">
        <mat-icon
          *ngIf="!form.hasData"
          matTooltip="{{
            'fundingProject.details.forms.icons.empty' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.forms.icons.empty' | translate
          }}"
          >check_box_outline_blank
        </mat-icon>
        <mat-icon
          *ngIf="form.hasData && !form.valid"
          matTooltip="{{
            'fundingProject.details.forms.icons.invalid' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.forms.icons.invalid' | translate
          }}"
          >disabled_by_default
        </mat-icon>
        <mat-icon
          *ngIf="form.valid"
          matTooltip="{{
            'fundingProject.details.forms.icons.valid' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.forms.icons.valid' | translate
          }}"
          >check_box
        </mat-icon>
        <span class="form-title">{{ form.title }}</span>
      </div>
      <div class="action-wrapper">
        <span class="required-message" *ngIf="form.required">
          {{ 'fundingProject.details.forms.items.requiredMessage' | translate }}
        </span>
        <mat-icon
          (click)="$event.stopPropagation()"
          #matMenuTrigger="matMenuTrigger"
          (keyup.enter)="onKeyupEnter($event)"
          [matMenuTriggerFor]="menu"
          role="button"
          tabindex="0"
          matTooltip="{{
            'fundingProject.details.documents.table.more' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.documents.table.more' | translate
          }}"
          class="action-icon"
          >more_horiz
        </mat-icon>
        <mat-menu #menu="matMenu" xPosition="before">
          <button
            *ngIf="!form.hasData"
            (click)="toFormTemplate(form)"
            mat-menu-item
            class="dropdown-item"
          >
            {{
              (isEditableForm(form)
                ? 'fundingProject.details.forms.dropdown.create'
                : 'fundingProject.details.forms.dropdown.view'
              ) | translate
            }}
          </button>
          <button
            *ngIf="form.hasData"
            (click)="toFormTemplate(form)"
            mat-menu-item
            class="dropdown-item"
          >
            {{
              (isEditableForm(form)
                ? 'fundingProject.details.forms.dropdown.edit'
                : 'fundingProject.details.forms.dropdown.view'
              ) | translate
            }}
          </button>
          <button
            (click)="openInfoDialog(form.description)"
            mat-menu-item
            class="dropdown-item"
          >
            {{ 'fundingProject.details.forms.dropdown.info' | translate }}
          </button>
          <div
            *ngIf="isEnroll()"
            matTooltip="
          {{ getDeletionTooltip(form) | translate }}
      "
            [matTooltipDisabled]="isDeletable(form)"
          >
            <button
              [disabled]="!isDeletable(form)"
              (click)="openDeleteConfirm(form)"
              mat-menu-item
              class="dropdown-item"
            >
              {{ 'fundingProject.details.forms.dropdown.delete' | translate }}
            </button>
          </div>
        </mat-menu>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
  </div>
</mat-list>
