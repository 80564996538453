import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { UserObjectObservableService } from 'oaman-components';
import { of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Cookies } from '../constants/cookie-constants';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userObjectObservableService: UserObjectObservableService,
    private cookie: CookieService
  ) {}

  loadAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getAuthentication),
      exhaustMap(() => {
        // check if the cookie has been set
        // by fetching the userinfo the token is validated
        return this.userService
          .fetchCurrentUser(this.cookie.get(Cookies.INVITATION_KEY_COOKIE))
          .pipe(
            map((user) => {
              // clear the invitation key cookie after a successful login
              this.cookie.delete(Cookies.INVITATION_KEY_COOKIE);
              // propagate the User to common components-module
              this.userObjectObservableService.publish(user);
              return AuthActions.getAuthenticationSuccess({
                user
              });
            }),
            catchError(() => {
              return of(AuthActions.getAuthenticationFailure());
            })
          );
      })
    );
  });
}
