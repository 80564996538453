import { FormlyFieldConfig } from '@ngx-formly/core';

export function minlengthValidationMessage(err: any, field: FormlyFieldConfig) {
  return `Das Feld muss mindestens ${field?.templateOptions?.minLength} Zeichen lang sein.`;
}

export function maxlengthValidationMessage(err: any, field: FormlyFieldConfig) {
  return `Das Feld darf ${field?.templateOptions?.maxLength} Zeichen nicht übersteigen.`;
}

export function minValidationMessage(err: any, field: FormlyFieldConfig) {
  // since the value of min is used in the validation message, if the field type is "year", we have to consider 1 year
  // less for a meaningful validation message. Example:
  // if min = 2020 and input value = 2020, then validation message should be "Die Zahl muss größer als ${2020 - 1} sein."
  const lowerBound =
    field.type === 'year'
      ? field!.templateOptions!.min! - 1
      : field?.templateOptions?.min;
  return `Die Zahl muss größer als ${lowerBound} sein.`;
}

export function maxValidationMessage(err: any, field: FormlyFieldConfig) {
  return `Die Zahl darf maximal ${field?.templateOptions?.max} betragen.`;
}

export function fromBeforeToDateValidationMessage(
  err: any,
  field: FormlyFieldConfig
) {
  return `${field?.templateOptions?.attributes?.endDateLabel} muss größer als ${field?.templateOptions?.attributes?.startDateLabel} sein.`;
}

export function zipcodeValidationMessage(err: any, field: FormlyFieldConfig) {
  return `Die Postleitzahl muss genau aus ${field?.templateOptions?.maxLength} Ziffern bestehen!`;
}
