import { createAction, props } from '@ngrx/store';
import { User } from 'oaman-components';

export const getAuthentication = createAction(
  '[Auth] Get authentication object'
);

export const getAuthenticationSuccess = createAction(
  '[Auth] Get authentication object success',
  props<{ user: User }>()
);

export const getAuthenticationFailure = createAction(
  '[Auth] Get authentication object failure'
);

export const notLoggedIn = createAction('[Auth] Not logged in');

export const removeAuthObject = createAction('[Auth] Logout');
