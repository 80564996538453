import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../core/authentication/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/state';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  isAuthorized,
  selectUserState
} from '../../../../core/authentication/state/auth.selectors';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  authorized$!: Observable<boolean>;
  initials$ = new Observable<string>();

  constructor(
    private authService: AuthService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.authorized$ = this.store.pipe(
      select(isAuthorized),
      distinctUntilChanged()
    );
    this.initials$ = this.store.select(selectUserState).pipe(
      map((user) => {
        if (user != undefined) {
          return user.nameFirst.charAt(0).toUpperCase();
        } else return '';
      })
    );
  }
  logout(): void {
    this.authService.logout();
  }
}
