  <oaman-avatar
    id="avatar"
    #matMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    *ngIf="(authorized$ | async) === true"
    (keyup.enter)="onKeyupEnter()"
    role="button"
    tabindex="0"
    [title]="'dropdown.title' | translate"
    >{{ initials$ | async }}</oaman-avatar
  >
  <mat-menu fxShow="true" #menu="matMenu">
    <button id="button-profile" mat-menu-item [routerLink]="'/profile'">
      {{ 'dropdown.profile' | translate }}
    </button>
    <button id="button-logout" mat-menu-item (click)="logout($event)">
      {{ 'dropdown.logout' | translate }}
    </button>
    <mat-divider></mat-divider>
    <button id="button-about" mat-menu-item [routerLink]="'/about'">
      {{ 'dropdown.about' | translate }}
    </button>
  </mat-menu>
