import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { AppConfigService } from '../../config/app-config.service';
import { removeAuthObject } from '../state/auth.actions';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authIssuer = '';
  private client_id = '';
  private redirect_uri = '';
  private response_type = '';
  private scope = '';
  private logoutUrl = '';
  private performSLO = false;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private env: AppConfigService,
    private cookie: CookieService,
    private http: HttpClient
  ) {
    this.authIssuer = this.env.getConfig().authIssuer;
    this.client_id = this.env.getConfig().authClientId;
    this.redirect_uri = this.env.getConfig().authRedirectUrl;
    this.response_type = this.env.getConfig().authResponseType;
    this.scope = this.env.getConfig().authScope;
    this.logoutUrl = this.env.getConfig().logoutUrl;
    this.performSLO = this.env.getConfig().performSLO;
  }

  startAuthentication(loginType?: string) {
    // initiates the login flow with the oauth provider
    let authUrl = `${this.authIssuer}?client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&scope=${this.scope}&response_type=${this.response_type}`;
    const oauthLoginType = this.obtainOauthLoginType(loginType);
    if (oauthLoginType) {
      authUrl += `&loginType=${oauthLoginType}`;
      if (this.isBundIDAccountLogin(loginType)) {
        authUrl += '&authnContextClassRef=STORK_QAA_LEVEL_4';
      }
    }
    window.location.href = authUrl;
  }

  logout(disableRedirect?: boolean) {
    if (!disableRedirect) {
      const logoutMethod = this.performSLO
        ? '/oaman/userlogout'
        : '/oaman/logout';
      let logoutUrl =
        this.env
          .getConfig()
          .backendApiUrl.replace(this.env.getConfig().backendApiPrefix, '') +
        logoutMethod;

      this.http.get<any>(logoutUrl).subscribe({
        next: (tokenResult: any) => {
          if (this.performSLO) {
            this.triggerOauthLogout(tokenResult);
          } else {
            this.logoutCallback();
          }
        }
      });
    }
  }

  private triggerOauthLogout(tokenResult: any) {
    let options = {
      // hint for the interceptor to not add json headers
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${tokenResult.access_token}`)
        .append('Content-Type', 'application/x-www-form-urlencoded')
    };
    this.http.post<any>(this.logoutUrl, {}, options).subscribe({
      next: () => {
        this.logoutCallback();
      },
      error: (error: any) => {
        console.log('OAUTH logout failed: ' + JSON.stringify(error));
        this.logoutCallback();
      }
    });
  }

  private logoutCallback(): void {
    this.cookie.deleteAll();
    this.store.dispatch(removeAuthObject());
    this.router.navigate(['login']);
  }

  private obtainOauthLoginType(loginType?: string): string | undefined {
    if (this.isBundIDAccountLogin(loginType)) {
      return 'bundid';
    }
    if (loginType === 'CorporateAccount') {
      return 'unternehmenskonto';
    }

    return undefined;
  }

  private isBundIDAccountLogin(loginType?: string): boolean {
    return loginType === 'BundID';
  }
}
