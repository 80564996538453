export interface FundingProjectRequest {
  id: string;
  type: string;
  title: string;
  submissionDate: string;
  submissionBy: string;
  status: string;
  createdDate: string;
}

export enum FundingProjectRequestState {
  DECIDED = 'request.state.decided'
}
