<h2 mat-dialog-title>
  {{ 'fundingProject.details.documents.dialog.submitTitle' | translate }}
</h2>
<mat-dialog-content class="mat-typography">
  <p>
    {{ 'fundingProject.details.documents.dialog.submitTxt1' | translate }}
  </p>
  <p>
    {{ 'fundingProject.details.documents.dialog.submitTxt2' | translate }}
  </p>
  <p>
    {{ 'fundingProject.details.documents.dialog.submitTxt3' | translate }}
    <strong>{{ data.title }}</strong>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button
    mat-button
    type="reset"
    [style.margin-right]="'1rem'"
    [mat-dialog-close]="false">
    {{
      'fundingProject.details.documents.dialog.buttons.cancelBtn' | translate
    }}
  </oaman-button>
  <oaman-button
    mat-button
    color="primary"
    type="submit"
    [mat-dialog-close]="true"
  >
    {{
      'fundingProject.details.documents.dialog.buttons.submitBtn' | translate
    }}
  </oaman-button>
</mat-dialog-actions>
