import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FundingProject } from '../../core/public-api';
import { FundingProjectDataService } from '../public-api';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has
 * authority of the given funding project:
 * The user must be the applicant of the project or a valid deputy.
 *
 * Note: the 'actionKey' parameter could be used for custom authority check.
 *
 * @howToUse
 * ```
 *     <some-element *oamanHasProjectAuthority="'actionKey'">...</some-element>
 * ```
 */
@Directive({
  selector: '[oamanHasProjectAuthority]'
})
export class HasProjectAuthorityDirective implements OnDestroy {
  private actionKey!: string; //it can be used to custom authority check by action key

  private readonly destroy$ = new Subject<void>();

  constructor(
    private fundingProjectData: FundingProjectDataService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input()
  set oamanHasProjectAuthority(actionKeyParam: string) {
    this.actionKey = actionKeyParam;
    this.updateView();
    // Get notified each time the funding project is changed.
    this.fundingProjectData
      .getFundingProject()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: FundingProject) => {
        if (value) {
          this.updateView(value);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$?.next();
    this.destroy$?.complete();
  }

  private updateView(value?: FundingProject): void {
    const hasAuthority = this.fundingProjectData.hasProjectAuthority(
      this.actionKey,
      true /*updateProjectData*/,
      value
    );
    this.viewContainerRef.clear();
    if (hasAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
