import { Pipe, PipeTransform } from '@angular/core';
import { Many, orderBy as _orderBy } from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(
    array: any,
    sortBy: string,
    order?: Many<boolean | 'asc' | 'desc'>
  ): any[] {
    const sortOrder = order ? order : 'asc'; // setting default ascending order

    return _orderBy(array, [sortBy], sortOrder);
  }
}
