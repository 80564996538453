import { findIndex as _findIndex } from 'lodash';
import { DeputyRole, FundingProject } from '../../core/public-api';
import { isValidDeputy } from '../funding-project-details/tabs/authorization/utils/deputy-utils';

/**
 * Used to check if the currently logged in User have authority to the given FundingProject.
 * The User must be the creator of the funding project or a valid deputy for this project.
 * @param fundingProject
 * @returns
 */
export function hasProjectAuthority(
  currentUserId?: string,
  fundingProject?: FundingProject,
  actionKey?: string
) {
  if (!currentUserId || !fundingProject) {
    return false;
  }

  if (currentUserId === fundingProject.user?.id) {
    // the currently logged in user is the applicant on the given funding project
    return true;
  }

  if (!isDeputyUser(currentUserId, fundingProject)) {
    return true;
  }

  const deputyRole = getCurrentUserDeputyRole(currentUserId, fundingProject);
  if (!deputyRole) {
    // the currently logged in user is not a valid deputy or the mandatory role is missing
    return false;
  }

  if (DeputyRole.Representative === deputyRole) {
    return true;
  }

  return false;
}

function isDeputyUser(
  currentUserId: string,
  fundingProject: FundingProject
): boolean {
  const deputies = fundingProject.deputies;
  const firstDeputyId = _findIndex(deputies, function (deputy) {
    return deputy.user?.id === currentUserId;
  });

  return firstDeputyId !== -1;
}

function getCurrentUserDeputyRole(
  currentUserId: string,
  fundingProject: FundingProject
): DeputyRole | null {
  const deputies = fundingProject.deputies;
  const firstValidDeputyId = _findIndex(deputies, function (deputy) {
    return deputy.user?.id === currentUserId && isValidDeputy(deputy);
  });

  return firstValidDeputyId !== -1
    ? deputies[firstValidDeputyId].participantRole
    : null;
}
