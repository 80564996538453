import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-page-type',
  template: `
    <div class="page-wrapper">
      <h1>{{ props?.title }} {{ field.props?.label }}</h1>
      <ng-container *ngFor="let field of field.fieldGroup; let i = index">
        <formly-field [field]="field"></formly-field>
      </ng-container>
    </div>
  `,
  styles: [
    `
      h3 {
        margin-left: -0.5rem;
      }
    `
  ]
})
export class FormPageComponent extends FieldType<FieldTypeConfig> {}
