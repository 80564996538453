<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <p>{{ message1 }}</p>
  <p *ngIf="message2" [innerHTML]="message2"></p>
</mat-dialog-content>
<mat-dialog-actions [style.justify-content]="'flex-end'">
  <oaman-button [mat-dialog-close]="true">{{
    buttonKey | translate
  }}</oaman-button>
</mat-dialog-actions>
