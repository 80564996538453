import { HttpResponse } from '@angular/common/http';

export function downloadFileFromBlob(data: HttpResponse<Blob>): void {
  if (!data.body) {
    return;
  }

  var fileName = data.headers
    .get('Content-Disposition')
    ?.replace('attachment; filename=', '');
  if (!fileName) {
    return;
  }

  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(data.body);
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
}

export function openFileFromBlob(data: HttpResponse<Blob>): void {
  if (!data.body) {
    return;
  }

  var fileURL = URL.createObjectURL(data.body);
  window.open(fileURL);
}
