import { FieldFormatter } from './field-formatter';

/**
 * Custom field formatter for field values with a gender value.
 */
export class GenderFieldFormatter extends FieldFormatter {
  generateFormatedFieldTag(
    fieldValue: string,
    _defval: string | undefined
  ): string {
    const valWork = fieldValue ? fieldValue : '';
    switch (valWork) {
      case 'm':
        return 'männlich';
      case 'w':
        return 'weiblich';
      case 'n':
        return 'divers';
      default:
        return !_defval ? '' : _defval;
    }
  }
}
