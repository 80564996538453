<oaman-detail-actions
  [actions]="context === 'enroll' ? actionsEnroll : actionsManage"
  (clickTriggered)="onAction($event)"
></oaman-detail-actions>
<form [formGroup]="form">
  <formly-form
    [model]="model"
    [fields]="fields"
    [options]="options"
    [form]="form"
  ></formly-form>
</form>
