import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-page-group-type',
  template: `
    <div>
      <ng-container *ngFor="let field of field.fieldGroup; let i = index">
        <formly-field [field]="field"></formly-field>
      </ng-container>
    </div>
  `,
  styles: []
})
export class FormGroupComponent extends FieldType<FieldTypeConfig> {}
