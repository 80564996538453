import {
  FundingProject,
  FundingProjectRequest
} from '../../../../../core/public-api';

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RequestUtilService } from '../../../services/request-util.service';
import { FilterObject } from '../public-api';
import { FilterObservableService } from '../services/filter-observable.service';
import { APP_CONFIG } from '../../../../../core/util/app-config.token';
import { AppConfiguration } from '../../../../../core/interfaces/app-configuration';
import { get as _get } from 'lodash';
import { FundingGuidelineConstants } from '../../../../../core/constants/funding-guideline.constants';

@Component({
  selector: 'oaman-filter-element',
  templateUrl: './filter-element.component.html',
  styleUrls: ['./filter-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterElementComponent implements OnInit {
  @Input() applicationFilterVisible = false;
  applicationFilters: Array<{ key: string; value: string }> = [];
  classFilters = [
    { key: '0', value: '- Alle Klassen anzeigen -' },
    { key: 'sonstiges', value: 'Sonstige Anlage' },
    { key: 'kosten', value: 'Kostenschätzung' },
    { key: 'zeichnung', value: 'Zeichnung / Foto' },
    { key: 'bauskizze', value: 'Bauskizze / Lageplan' },
    { key: 'flaechen', value: 'Flächenkarte' },
    { key: 'stellung', value: 'Stellungnahme Naturschutzbehörde' },
    { key: 'touris', value: 'Touristische Konzepte' },
    { key: 'genehmigung', value: 'Genehmigung Denkmalschutz' },
    { key: 'markt', value: 'Markt-/Standortanalyse' },
    { key: 'bedarf', value: 'Bedarfsanalyse' },
    { key: 'nkag', value: 'NKAG Gemeindesatzung' },
    { key: 'foerder', value: 'Sonstige Förderbescheide' },
    { key: 'nachweis', value: 'Nachweis berufliche Qualifikation' },
    { key: 'nachweis-2', value: 'Nachweis Gesamtfinanzierung' }
  ];
  statusFilters = [
    { key: '0', value: '- Alle anzeigen -' },
    { key: '1', value: 'Hochgeladen' },
    { key: '2', value: 'Nachgefordert / Benötigt' }
  ];
  @Input() requiredDocumentCount = 0;
  @Input() projectTitle = '';

  filterObject: FilterObject = {
    searchQuery: '',
    applicationFilter: '0',
    classFilter: '',
    statusFilter: ''
  };
  manageApp = false;
  private fundingProject!: FundingProject;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private filterObservableService: FilterObservableService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private requestUtil: RequestUtilService
  ) {}

  ngOnInit() {
    this.manageApp = this.appConfig.context === 'manage';
    this.route.parent?.data.subscribe((data) => {
      this.fundingProject = data.resolvedData;
    });
    this.route.data.subscribe((data) => {
      if (this.applicationFilters.length === 0) {
        const resolvedRequests = _get(data, 'resolvedData.requests');
        if (resolvedRequests) {
          this.initializeApplicationFilters(resolvedRequests);
        }
      }
    });
  }

  initializeApplicationFilters(requests: FundingProjectRequest[]) {
    const sortedRequests = this.requestUtil.sortRequests(requests);
    sortedRequests.forEach((request, index) => {
      this.applicationFilters.push(
        this.requestUtil.getRequestDropdownObject(request)
      );
      // set active filter if it's not already set
      if (
        !this.filterObject.applicationFilter ||
        this.filterObject.applicationFilter === '0'
      ) {
        this.filterObject.applicationFilter = request.id.toString();
      }
    });
    this.filterObservableService.addFilter(this.filterObject);
    if (!this.manageApp) {
      this.applicationFilters.push({
        key: '0',
        value: this.translate.instant('fundingProject.request.showAll')
      });
    }

    this.applicationFilters.push({
      key: 'UNASSIGNED_REQUEST',
      value: this.translate.instant('fundingProject.request.unassigned')
    });
  }

  triggerSearch(searchQuery: string) {
    this.filterObject.searchQuery = searchQuery;
    this.filterObservableService.addFilter(this.filterObject);
  }

  setAllRequiredFilter() {
    // set the status dropdown and emit the event to trigger the table filter
    this.filterObject.applicationFilter = '0';
    // add the not submitted flag here later
    this.filterObservableService.addFilter(this.filterObject);
  }

  changeApplicationFilters(requestId: string) {
    if (this.filterObject.applicationFilter !== requestId) {
      this.filterObject.applicationFilter = requestId;
      this.filterObservableService.addFilter(this.filterObject);
    }
  }
}
