import { Inject, Component, ChangeDetectionStrategy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FundingProjectDocument } from '../../funding-project/public-api';

@Component({
  selector: 'oaman-inquiry-create-dialog',
  templateUrl: './inquiry-create-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InquiryCreateDialogComponent {
  createForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FundingProjectDocument,
    public dialogRef: MatDialogRef<InquiryCreateDialogComponent>
  ) {
    this.createForm = new UntypedFormGroup({
      topic: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(255)
      ]),
      question: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(4096)
      ])
    });
  }
  onSubmit() {
    this.dialogRef.close(this.createForm.value);
  }
}
