import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NgxCurrencyInputMode } from 'ngx-currency';

@Component({
  selector: 'oaman-form-cost-input-financial-plan-type',
  template: `
    <!-- Use for input field with decimal value presentation (e.g. 1.458,95) -->
    <input
      *ngIf="type === 'cost'"
      [type]="'tel'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      currencyMask
      [options]="{
        prefix: '',
        thousands: '.',
        decimal: ',',
        nullable: 'false',
        allowNegative: 'false',
        inputMode: inputMode
      }"
      [value]="formattedValue"
    />
    <!-- Use for input field with decimal value presentation with currency sign (e.g. 1.458,95 €) -->
    <input
      *ngIf="type === 'cost-cur'"
      [type]="'tel'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      currencyMask
      [options]="{
        prefix: '',
        suffix: ' €',
        thousands: '.',
        decimal: ',',
        nullable: 'false',
        allowNegative: 'false',
        inputMode: inputMode
      }"
      [value]="formattedValue"
    />
    <input
      *ngIf="type !== 'cost' && type !== 'cost-cur'"
      [type]="type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
    />
  `
})
export class FormCostInputFinancialPlanComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  get type() {
    return this.props.type || 'number';
  }

  get inputMode() {
    return NgxCurrencyInputMode.Natural;
  }

  formattedValue: string | undefined;

  ngOnInit() {
    // Ensure the value is a number, defaulting to 0 if it's not set or not a number
    const initialValue = this.toNumber(this.formControl.value, 0);
    this.formControl.setValue(initialValue);
    this.formattedValue = this.formatValue(initialValue);
  }

  private toNumber(value: any, defaultValue: number): number {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }

  private formatValue(value: number): string {
    // Format the value as needed, here for example as '0 €'
    return `${value.toFixed(2).replace('.', ',')} €`;
  }
}
