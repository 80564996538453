import { DeleteDialogData } from '../interfaces/delete-dialog-date';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

@Component({
  selector: 'oaman-document-submit-dialog',
  templateUrl: './document-submit-dialog.component.html',
  styleUrls: ['./document-submit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentSubmitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteDialogData) {}
}
