import { AppConfiguration } from './../../../../../../core/interfaces/app-configuration';
import { APP_CONFIG } from './../../../../../../core/util/app-config.token';
import { Component, Inject, Input } from '@angular/core';
import { MessageData } from '../../interfaces/message';

@Component({
  selector: 'oaman-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() data!: MessageData;

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfiguration) {}

  getBubblePosition() {
    // returns right for the current user
    if (
      (this.appConfig.context === 'manage' && this.data.author.authority) ||
      (this.appConfig.context === 'enroll' && !this.data.author.authority)
    ) {
      return 'right';
    }
    return '';
  }
}
