import { FieldFormatter } from './field-formatter';

/**
 * Custom field formatter for field values with a 'Registration Number'.
 */
export class RegistrationNumberFieldFormatter extends FieldFormatter {
  generateFormatedFieldTag(
    fieldValue: string,
    _defval: string | undefined
  ): string {
    const valWork = fieldValue ? fieldValue : '';
    // 15 chars reg number
    let html = '<table><thead>';
    html += '<th colspan="3" style="text-align: center;">Nation</th>';
    html += '<th colspan="2" style="text-align: center;">BL</th>';
    html += '<th colspan="3" style="text-align: center;">LK</th>';
    html += '<th colspan="3" style="text-align: center;">Gemeinde</th>';
    html += '<th colspan="4" style="text-align: center;">Betrieb</th>';
    html += '</thead>';
    html += '<tbody><tr>';
    for (let i = 0; i < 15; i++) {
      html +=
        '<td style="width: 17px; text-align: center;">' +
        valWork.charAt(i) +
        '</td>';
    }
    html += '</tr></tbody></table>';
    return html;
  }
}
