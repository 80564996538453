import { formatCurrency } from '@angular/common';
import {
  Component,
  DEFAULT_CURRENCY_CODE,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FundingProject } from '../../../../core/interfaces/funding-project';
import { DomSanitizer } from '@angular/platform-browser';
import { DetailAction } from '../../interfaces/detail-actions';
import { DialogService } from '../../../../dialog/services/dialog.service';
import { ProjectActionService } from '../../services/project-action.service';
import {
  ProjectActionData,
  StateChangeProjectActionData
} from '../../interfaces/project-action';
import { APP_CONFIG } from '../../../../core/util/app-config.token';
import { AppConfiguration } from '../../../../core/interfaces/app-configuration';
import * as FundingProjectStates from '../../utils/fundingProjectStates';
import { UserObjectObservableService } from '../../../../core/services/user-object-observable.service';
import { isUserWithHighTrustLevel } from '../../../../core/util/user-utils';
import { BaseComponent } from '../../../../core/base.component';
import {
  getDepartmentNameById,
  isLeaderFundingProject,
  isUserAssignedClerk
} from '../../../../core/util/funding-project-utils';

@Component({
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  errorMessage: string | null = null;
  fundingProject!: FundingProject;
  items = [
    'status',
    'user',
    'place',
    'clerk',
    'requested',
    'oamanId',
    'officeInfo',
    'description'
  ];
  values: string[] = [];
  defaultImage;
  projectActions: DetailAction[] = [];
  detailActionWithdraw: DetailAction = {
    name: 'withdrawProject',
    icon: 'layers_clear',
    event: 'withdrawProject',
    requiresProjectAuthority: true
  };
  detailActionDelete: DetailAction = {
    name: 'deleteProject',
    icon: 'delete',
    event: 'deleteProject',
    requiresProjectAuthority: true
  };
  private detailActionChangeState: DetailAction = {
    name: 'changeProjectState',
    icon: 'edit',
    event: 'changeProjectState',
    requiresProjectAuthority:
      false /*disabled because the currently logged in user must be the assigned clerk*/,
    skipDefaultVisibilityCheck: true /*always display this action*/
  };
  private detailActionEditProject: DetailAction = {
    name: 'updateProject',
    icon: 'edit',
    event: 'updateProject',
    requiresProjectAuthority: true
  };
  private manageApp = false;

  constructor(
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private dialogService: DialogService,
    private projectActionService: ProjectActionService,
    public userObjectObservableService: UserObjectObservableService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) public currency: string,
    @Inject(APP_CONFIG) public appConfig: AppConfiguration
  ) {
    super(userObjectObservableService);
    this.manageApp = this.appConfig.context === 'manage';

    this.defaultImage = this.domSanitizer.bypassSecurityTrustUrl(
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj' +
        '8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3Jh' +
        'cGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9Ij' +
        'AgMCA4MDAwIDQ1MDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4' +
        'bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Im' +
        'h0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9r' +
        'ZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjU7Ij48cGF0aCBkPS' +
        'JNMzE0Ljg5NiwyMDguMDc4bDEyLjIxMiw0MDY1bDczNjguNzQsLTAuMzc1bDMzLjM0MywtNDA1Ni4yNGwtNzQxNC4zLC04' +
        'LjM4MVoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNlMmUyZTI7c3Ryb2tlLXdpZHRoOjkyLjc1cHg7Ii8+PGcgaWQ9Ik' +
        'ViZW5lMSI+PHBhdGggZD0iTTIwMjUuNjQsMzEyOC41MWwxMzkxLjc0LC0xMjg1LjlsNzUxLjgzOCwxMjQwLjcxbC0zNDYu' +
        'NDI2LC01NzIuOTMzbDk5MS44MzQsLTc4My42OTdsMTE1Ni41MSwxMzUwLjEyIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZT' +
        'ojZTJlMmUyO3N0cm9rZS13aWR0aDo5Mi43NXB4OyIvPjwvZz48ZyBpZD0iRWJlbmUyIj48cGF0aCBkPSJNMzkzOS4yMywx' +
        'MzA3LjMzYy05MS4zMjcsMi40NiAtMTYzLjU0NSw3OC4xNzMgLTE2MS42OSwxNjkuNTE0YzAuMDc5LDMuODk1IDAuMTU4LD' +
        'cuNzgyIDAuMjM3LDExLjY1MWMxLjUxNCw3NC41NDUgNjIuMzQ4LDEzNC4yMDIgMTM2LjkwOSwxMzQuMjU5YzEwLjAzLDAu' +
        'MDA4IDIwLjIxLDAuMDE1IDMwLjM3OSwwLjAyM2M4OS45MjcsMC4wNjkgMTY0LjAxNiwtNzAuNTggMTY4LjIyLC0xNjAuND' +
        'A4YzAsLTAuMDA1IDAsLTAuMDA5IDAuMDAxLC0wLjAxM2MxLjkzMiwtNDEuMjk3IC0xMy40MTQsLTgxLjUzNCAtNDIuMzU2' +
        'LC0xMTEuMDU2Yy0yOC45NDIsLTI5LjUyMiAtNjguODY4LC00NS42NjMgLTExMC4xOTUsLTQ0LjU1Yy03LjE1NCwwLjE5My' +
        'AtMTQuMzM5LDAuMzg3IC0yMS41MDUsMC41OFoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNlMmUyZTI7c3Ryb2tlLXdp' +
        'ZHRoOjkyLjc1cHg7Ii8+PC9nPjwvc3ZnPg=='
    );
  }

  ngOnInit() {
    if (this.route.parent) {
      this.route.parent.data.subscribe((data) => {
        const resolvedData: FundingProject = data['resolvedData'];
        if (resolvedData) {
          this.fundingProject = resolvedData;
          this.values = this.initializeCardValues(this.fundingProject);
        }
      });
    }
    // Enroll actions
    if (this.appConfig.context === 'enroll') {
      if (this.showDetailsActions()) {
        if (
          this.fundingProject.stateFundingProject.resourceKey ===
          FundingProjectStates.DRAFT
        ) {
          this.projectActions.push(this.detailActionEditProject);
          this.projectActions.push(this.detailActionDelete);
        } else {
          this.projectActions.push(this.detailActionWithdraw);
        }
      }
    } // Manage actions
    else if (isUserAssignedClerk(this.fundingProject, this.currentUser)) {
      this.projectActions.push(this.detailActionChangeState);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  initializeCardValues(fundingProject: FundingProject): string[] {
    const valueList: string[] = [];
    if (fundingProject) {
      valueList.push(fundingProject.stateFundingProject?.title);
      valueList.push(
        fundingProject.user?.nameFirst + ' ' + fundingProject.user?.nameLast
      );
      valueList.push(fundingProject.location?.title);
      let clerkList = '';
      fundingProject.assignedClerks.forEach((clerk) => {
        if (clerkList.length > 0) clerkList += ' , ';
        clerkList += clerk.user.fullName;
      });
      valueList.push(clerkList);
      valueList.push(
        formatCurrency(
          fundingProject.requestedFundingValue,
          this.locale,
          this.currency
        )
      );
      valueList.push(fundingProject.oamanId);

      let leaderFundingProject = isLeaderFundingProject(fundingProject);
      if (
        (fundingProject.localActionGroup !== null && leaderFundingProject) ||
        !leaderFundingProject
      ) {
        valueList.push(
          fundingProject.authority.title +
            ' \n' +
            getDepartmentNameById(fundingProject.authorityLocationNumber)
        );
      } else {
        valueList.push('');
      }

      valueList.push(fundingProject.description);
    }
    return valueList;
  }

  openDialog($event: string) {
    if ($event === 'changeProjectState') {
      const projectStateChangeData: StateChangeProjectActionData = {
        fundingGuidelineId: this.fundingProject.fundingGuideline.id,
        fundingProjectId: this.fundingProject.id,
        stateFundingProject: this.fundingProject.stateFundingProject
      };

      this.projectActionService.setAction(
        'changeProjectState',
        projectStateChangeData
      );
      return;
    }

    if ($event === 'updateProject') {
      this.dialogService
        .openProjectUpdateDialog(this.fundingProject)
        .subscribe((result: FundingProject) => {
          if (result) {
            const projectUpdateData: ProjectActionData = {
              fundingGuidelineId: this.fundingProject.fundingGuideline.id,
              fundingProjectId: this.fundingProject.id,
              title: result.title,
              description: result.description
            };

            this.projectActionService.setAction(
              'updateProject',
              projectUpdateData
            );
          }
        });
      return;
    }

    if (isUserWithHighTrustLevel(this.currentUser)) {
      this.dialogService
        .openProjectWithdrawalDialog(
          this.fundingProject.title,
          this.fundingProject.stateFundingProject.resourceKey
        )
        .subscribe((result) => {
          if (result) {
            const projectWithdrawalData: ProjectActionData = {
              fundingGuidelineId: this.fundingProject.fundingGuideline.id,
              fundingProjectId: this.fundingProject.id
            };

            if (result === 'withdraw') {
              this.projectActionService.setAction(
                'withdrawProject',
                projectWithdrawalData
              );
            } else {
              this.projectActionService.setAction(
                'deleteProject',
                projectWithdrawalData
              );
            }
          }
        });
    } else {
      this.dialogService.openLowTrustLevelDialog().subscribe((result) => {
        if (result) {
          this.projectActionService.setAction('goToLogin', {});
        }
      });
    }
  }

  private showDetailsActions(): boolean {
    // Project delete / withdraw action should only be visible for projects with one of the following states
    return (
      this.fundingProject.stateFundingProject.resourceKey ===
        FundingProjectStates.DRAFT ||
      this.fundingProject.stateFundingProject.resourceKey ===
        FundingProjectStates.SUBMITTED ||
      this.fundingProject.stateFundingProject.resourceKey ===
        FundingProjectStates.APPROVED ||
      this.fundingProject.stateFundingProject.resourceKey ===
        FundingProjectStates.DISBURSEMENT_REQUEST_RECEIVED
    );
  }
}
