import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { AuthService } from '../core/authentication/services/auth.service';
import { AppState } from '../core/state';
import { Store } from '@ngrx/store';

import { ActivatedRoute, Router } from '@angular/router';
import {
  isAuthorized,
  isLoggedOut,
  hasAuthError
} from '../core/authentication/state/auth.selectors';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { getAuthentication } from '../core/authentication/state/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import { DataProtectionDialogComponent } from './data-protection-dialog/data-protection-dialog.component';
import { AppConfigService } from '../core/config/app-config.service';
import {
  Cookies,
  isCookieWithValue
} from '../core/authentication/constants/cookie-constants';
import { UserService } from '../core/authentication/services/user.service';
import { DialogService } from 'oaman-components';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  private AUTH_STATE_COOKIE = 'AUTH_STATUS';
  private IS_BUNDID_GUEST_COOKIE = 'IS_BUNDID_GUEST';
  isLoginFailed = false;
  isBasicAuthEnabled = false;
  private authFailureCode = '';
  private invitationKey: string | undefined = undefined;

  constructor(
    private authService: AuthService,
    private cookie: CookieService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private matDialogService: MatDialog,
    private env: AppConfigService,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    // initialize the login state (for error messages) for the component
    this.initLoginState();

    if (this.isBundIdGuest()) {
      this.dialogService.openErrorDialog(
        'Die Nutzung des Onlineantragsmanagements ist nur mit einem registrierten "BundID-Konto" oder "Mein Unternehmenskonto" möglich.',
        'Anmeldung mit BundID-Gastzugang nicht möglich'
      );
      this.cookie.delete(this.IS_BUNDID_GUEST_COOKIE);
    }

    this.isBasicAuthEnabled = this.env.getConfig().enableBasicAuth;

    // initialize the component with invitation key
    this.initLoginComponent();
  }

  private initLoginComponent() {
    this.route.queryParams.subscribe((params) => {
      // adds the missing inivitaiton key query parameters into the login url
      // Note: the invitation key is only removed after a successfull login
      this.invitationKey = params['key'];
      if (
        !this.invitationKey &&
        isCookieWithValue(this.cookie.get(Cookies.INVITATION_KEY_COOKIE))
      ) {
        // trigger a navigation to the current state to add the query parameter into url
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            key: this.cookie.get(Cookies.INVITATION_KEY_COOKIE)
          }
        });
        return;
      }

      if (this.invitationKey) {
        // store the invitation key value in the cookies
        // this will be used later when we receive the auth success redirect from OAMan backend
        this.cookie.set(
          Cookies.INVITATION_KEY_COOKIE,
          this.invitationKey,
          undefined,
          '/'
        );
      }

      if (this.isLoginFailed) {
        this.cookie.delete(Cookies.INVITATION_KEY_COOKIE);
        this.router.navigate(['/login']);
        return;
      }

      this.proceedToLogin();
    });
  }

  private proceedToLogin() {
    if (this.cookie.get(Cookies.DATA_PROTECTION_COOKIE)) {
      if (this.invitationKey) {
        // if there is an invitation key we first must check its validity
        this.userService.validateInvitationKey(this.invitationKey).subscribe({
          next: () => {
            this.openDataProtectionDialog();
          },
          error: () => {
            this.isLoginFailed = true;
            this.authFailureCode = 'FAILED_E02';
            this.ref.markForCheck();
            this.authService.logout(true /*disableRedirect*/);
          }
        });
      } else {
        // otherwise we can display the data protection dialog
        this.openDataProtectionDialog();
      }
    } else {
      this.fetchUserAndGoToHome();
    }
  }

  /**
   * Used to initialize the login failure state for the following cases:
   * - when something went wrong on backend (having the AUTH_STATE_COOKIE cookie in the redirect)
   * - when something went wrong on backend for a direct call from UI (having the getAuthenticationFailure action)
   */
  private initLoginState() {
    // clear the possible old failure code
    this.authFailureCode = '';
    this.isLoginFailed = this.isAuthFailed();
    this.store.select(hasAuthError).subscribe((hasError) => {
      this.isLoginFailed = this.isLoginFailed || hasError;
      // init the failure reason when we receive it from backend
      this.initFailureReason();
    });

    this.initFailureReason();
  }

  private initFailureReason() {
    if (this.isLoginFailed && this.cookie.check(this.AUTH_STATE_COOKIE)) {
      this.authFailureCode = this.cookie.get(this.AUTH_STATE_COOKIE);
      this.cookie.delete(Cookies.AUTH_STATE_COOKIE);
      this.ref.markForCheck();
    }
  }

  login(loginType?: string) {
    this.authService.startAuthentication(loginType);
  }

  getFailureMessageKey(): string {
    return 'login.' + (this.authFailureCode ? this.authFailureCode : 'FAILED');
  }

  onTabSelect(): void {
    this.isLoginFailed = this.cookie
      .get(this.AUTH_STATE_COOKIE)
      ?.startsWith('FAILED');
  }

  private fetchUserAndGoToHome(): void {
    this.store
      .select(isAuthorized)
      .pipe(
        filter((authorized) => {
          if (authorized) {
            return true;
          }
          // check if user was logged out. If true, don't dispatch getAuthentication
          this.store.select(isLoggedOut).subscribe((userLoggedOut) => {
            const authStatus = this.cookie.get(Cookies.AUTH_STATE_COOKIE);
            if (
              !userLoggedOut &&
              (authStatus === 'LOGGED_IN' || authStatus === 'IN_PROGRESS')
            ) {
              // we must try to fetch the User information only if the User is already logged in or if the data protection agreement was accepted
              this.store.dispatch(getAuthentication());
            }
          });
          return false;
        })
      )
      .subscribe(() => {
        this.router.navigate(['/home']).then();
      });
  }

  private openDataProtectionDialog(): boolean {
    const dialogRef = this.matDialogService.open(
      DataProtectionDialogComponent,
      {
        width: '46rem',
        data: {},
        hasBackdrop: true,
        disableClose: true
      }
    );

    dialogRef.afterClosed().subscribe((res) => {
      // this.cookie.delete(Cookies.DATA_PROTECTION_COOKIE);
      if (res) {
        this.fetchUserAndGoToHome();
      } else {
        this.authService.logout();
      }
    });

    return false;
  }

  isAuthFailed(): boolean {
    return (
      this.isLoginFailed ||
      this.cookie.get(this.AUTH_STATE_COOKIE)?.startsWith('FAILED')
    );
  }

  isBundIdGuest(): boolean {
    // if the account is a BundId guest account the cookie value is 'true'
    // otherwise it is ''
    return Boolean(this.cookie.get(this.IS_BUNDID_GUEST_COOKIE));
  }
}
