import { HttpStatusCode } from './../../core/services/http-status';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogData } from '../interfaces/error-dialog-data';

@Component({
  selector: 'oaman-error-dialog',
  templateUrl: './http-error-dialog.component.html'
})
export class HttpErrorDialogComponent implements OnInit {
  title!: string;
  message1!: string;
  message2!: string;
  buttonKey!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.buttonKey = 'dialog.HttpErrorDialog.cancel';
    if (this.data.status === 0) {
      this.message1 = this.translateService.instant(
        'dialog.HttpErrorDialog.notReachable'
      );
    } else if (this.data.status === HttpStatusCode.Forbidden) {
      this.title = this.translateService.instant(
        'dialog.HttpErrorDialog.403.title'
      );
      this.message1 = this.translateService.instant(
        'dialog.HttpErrorDialog.403.body1'
      );
      this.message2 = this.translateService.instant(
        'dialog.HttpErrorDialog.403.body2'
      );
    } else if (this.data.status === HttpStatusCode.NotFound) {
      this.message1 = this.translateService.instant(
        'dialog.HttpErrorDialog.404.body1'
      );
    } else if (this.data.status === HttpStatusCode.Unauthorized) {
      this.buttonKey = 'dialog.HttpErrorDialog.toLoginButton';
      this.title = this.translateService.instant(
        'dialog.HttpErrorDialog.401.title'
      );
      this.message1 = this.data.message
        ? this.data.message
        : this.translateService.instant('dialog.HttpErrorDialog.401.body1');
    } else if (this.data.status === HttpStatusCode.InternalServerError) {
      this.message1 = this.translateService.instant(
        'dialog.HttpErrorDialog.500.body1'
      );
    } else {
      this.message1 = this.data.message;
    }

    if (!this.title) {
      this.title = this.data.title
        ? this.data.title
        : this.translateService.instant('dialog.HttpErrorDialog.defaultTitle');
    }
    if (!this.message1) {
      this.message1 = this.translateService.instant(
        'dialog.HttpErrorDialog.defaultMessage'
      );
    }
  }
}
