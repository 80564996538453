import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { default as packageJsonInfo } from '../../../../../../../../../../package.json';
import { Observable, of } from 'rxjs';
import { AboutService } from './services/about.service';
import { ComponentVersion } from 'oaman-components';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
  dataSource$: Observable<ComponentVersion[]> = of([]);
  displayedColumns: string[] = ['name', 'version'];

  constructor(
    private aboutService: AboutService,
    private change: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.aboutService
      .fetchBackendVersions()
      .subscribe((versionBackend: string) => {
        this.dataSource$ = of([
          { name: 'OAMan ÄrL-Enroll', version: packageJsonInfo.version },
          { name: 'OAMan ÄrL-Core', version: versionBackend }
        ]);
        this.change.markForCheck();
      });
  }
}
