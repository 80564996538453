<div class="table-wrapper">
  <mat-table id="documents-table" [dataSource]="dataSource" matSort>
    <!-- Hint Column -->
    <ng-container matColumnDef="description">
      <mat-header-cell class="first-column" *matHeaderCellDef>
      </mat-header-cell>
      <!-- Show icon if the document is missing -->
      <mat-cell class="first-column" *matCellDef="let element">
        <mat-icon
          (click)="openInfoDialog($event, element)"
          (keyup.enter)="openInfoDialog($event, element)"
          matTooltip="{{
            'fundingProject.details.documents.table.infoTooltip' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.documents.table.infoTooltip' | translate
          }}"
          class="info-icon"
          role="button"
          tabindex="0"
          >info
        </mat-icon>
        <mat-icon
          matTooltip="{{ getWarningIconTooltip(element) | translate }}"
          attr.aria-label="{{ getWarningIconTooltip(element) | translate }}"
          class="highlighted"
          *ngIf="showWarningIcon(element)"
          >report_problem
        </mat-icon>
      </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.table.a11y.title' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.table.title' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          class="text-fit-ellipsis"
          matTooltip="{{ element.title | documentAndFileTitlePipe: docAndFileTitlePipeParams }}"
          matTooltipClass="long-title-tooltip"
          >{{ element.title | documentAndFileTitlePipe: docAndFileTitlePipeParams }}</span
        >
      </mat-cell>
    </ng-container>

    <!-- submitted Column -->
    <ng-container matColumnDef="submitted">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.table.a11y.submitted' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.table.submitted' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{
          (element.deliveryDate ? element.deliveryDate : element.checkInDate)
            | date
        }}
      </mat-cell>
    </ng-container>

    <!-- deadline Column -->
    <ng-container matColumnDef="requestDeadline">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.table.a11y.requestDeadline'
            | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{
          'fundingProject.details.documents.table.requestDeadline' | translate
        }}
      </mat-header-cell>
      <!-- Highlighted if the document has been requested and has a deadline -->
      <mat-cell
        *matCellDef="let element"
        [ngClass]="!element.checkInDate ? 'highlighted' : ''"
      >
        {{ element.requestedDeadline | date }}
      </mat-cell>
    </ng-container>

    <!-- files Column -->
    <ng-container matColumnDef="files">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.table.a11y.files' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.table.files' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ getFile(element) }} </mat-cell>
    </ng-container>

    <!-- Action Column -->

    <ng-container matColumnDef="action" *ngIf="!withdrawnProject">
      <mat-header-cell class="last-column" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="last-column" *matCellDef="let element">
        <mat-icon
          (click)="$event.stopPropagation()"
          #matMenuTrigger="matMenuTrigger"
          (keyup.enter)="onKeyupEnter($event)"
          [matMenuTriggerFor]="menu"
          role="button"
          tabindex="0"
          matTooltip="{{
            'fundingProject.details.documents.table.more' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.documents.table.more' | translate
          }}"
          class="action-icon"
          *ngIf="element"
          >more_horiz
        </mat-icon>
        <mat-menu #menu="matMenu" xPosition="before">
          <button
            (click)="showFiles(element)"
            mat-menu-item
            class="dropdown-item"
          >
            {{
              'fundingProject.details.documents.table.dropdown.showFiles'
                | translate
            }}
          </button>
          <div
            *ngIf="enrollApp"
            matTooltip="{{
              getDropdownTooltip(element, 'uploadFile') | translate
            }}"
            [matTooltipDisabled]="!isButtonDisabled(element, 'uploadFile')"
            [matTooltipPosition]="toolTipPosition"
          >
            <button
              *ngIf="enrollApp"
              [disabled]="isButtonDisabled(element, 'uploadFile')"
              (click)="openDialog(element, 'uploadFile')"
              mat-menu-item
              class="dropdown-item"
            >
              {{
                'fundingProject.details.documents.table.dropdown.uploadFile'
                  | translate
              }}
            </button>
          </div>
          <mat-divider></mat-divider>
          <div
            *ngIf="manageApp && isAssignDocumentVisible(element)"
            matTooltip="{{
              getDropdownTooltip(element, 'assignDocument') | translate
            }}"
            [matTooltipPosition]="toolTipPosition"
          >
            <button
              mat-menu-item
              class="dropdown-item"
              [disabled]="isButtonDisabled(element, 'assignDocument')"
              (click)="openDialog(element, 'assignDocument')"
            >
              {{
                'fundingProject.details.documents.table.dropdown.assignDocument'
                  | translate
              }}
            </button>
          </div>
          <div
            *ngIf="enrollApp"
            matTooltip="{{ getDropdownTooltip(element, 'submit') | translate }}"
            [matTooltipDisabled]="!isButtonDisabled(element, 'submit')"
            [matTooltipPosition]="toolTipPosition"
          >
            <button
              mat-menu-item
              class="dropdown-item"
              [disabled]="isButtonDisabled(element, 'submit')"
              (click)="openDialog(element, 'submitDocument')"
            >
              {{
                'fundingProject.details.documents.table.dropdown.submitDocument'
                  | translate
              }}
            </button>
          </div>
          <div
            *ngIf="manageApp"
            matTooltip="{{
              getDropdownTooltip(element, 'deliver') | translate
            }}"
            [matTooltipDisabled]="!isButtonDisabled(element, 'deliver')"
            [matTooltipPosition]="toolTipPosition"
          >
            <button
              mat-menu-item
              class="dropdown-item"
              [disabled]="isButtonDisabled(element, 'deliver')"
              (click)="openDialog(element, 'deliverDocument')"
            >
              {{
                'fundingProject.details.documents.table.dropdown.deliverDocument'
                  | translate
              }}
            </button>
          </div>
          <div
            *ngIf="enrollApp"
            matTooltip="{{ getDropdownTooltip(element, 'edit') | translate }}"
            [matTooltipDisabled]="!isButtonDisabled(element, 'edit')"
            [matTooltipPosition]="toolTipPosition"
          >
            <button
              mat-menu-item
              class="dropdown-item"
              [disabled]="isButtonDisabled(element, 'edit')"
              (click)="openDialog(element, 'editDocument')"
            >
              {{
                'fundingProject.details.documents.table.dropdown.editDocument'
                  | translate
              }}
            </button>
          </div>
          <div
            *ngIf="enrollApp"
            matTooltip="{{ getDropdownTooltip(element, 'delete') | translate }}"
            [matTooltipDisabled]="!isButtonDisabled(element, 'delete')"
            [matTooltipPosition]="toolTipPosition"
          >
            <button
              mat-menu-item
              class="dropdown-item"
              [disabled]="isButtonDisabled(element, 'delete')"
              (click)="openDialog(element, 'deleteDocument')"
            >
              {{
                'fundingProject.details.documents.table.dropdown.deleteDocument'
                  | translate
              }}
            </button>
          </div>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row
      id="header-row"
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="showFiles(row)"
      (keyup.enter)="showFiles(row)"
      role="button"
      tabindex="0"
    ></mat-row>
  </mat-table>
  <mat-paginator
    id="paginator"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</div>
