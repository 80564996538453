import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterObject } from '../public-api';

@Injectable({ providedIn: 'root' })
export class FilterObservableService {
  private defaultFilterObject!: FilterObject;

  private filter: Subject<FilterObject> = new BehaviorSubject<FilterObject>(
    {} as FilterObject
  );

  get filterObject$() {
    return this.filter.asObservable().pipe(filter((node) => !!node));
  }

  addFilter(data: FilterObject) {
    this.filter.next(data);
  }

  reset() {
    this.filter.next(this.defaultFilterObject);
  }
}
