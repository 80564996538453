import {
  AttornyPowerType,
  AttornyPowerTypeAvailability
} from './attorny-power-info';
import { User } from './user';

export enum DeputyRole {
  Representative = 'REPRESENTATIVE',
  Assistant = 'ASSISTANT'
}
export interface DeputyInfo {
  id: string;
  user: User;
  participantRole: DeputyRole;
  validFrom: string;
  validTo: string | null;
  attorneyPowerStartDate: string;
  attorneyPowerEndDate: string;
  attorneyPowerType: AttornyPowerType;
  attorneyPowerAvailability: AttornyPowerTypeAvailability;
}

export interface DeputyCreateDTO {
  nameFirst: string;
  nameLast: string;
  email: string;
  participantRole: DeputyRole;
  validFrom: string;
  validTo: string | null;
  attorneyPowerStartDate: string;
  attorneyPowerEndDate: string;
  attorneyPowerType: AttornyPowerType;
  attorneyPowerAvailability: AttornyPowerTypeAvailability;
}
