import { FundingProjectFile } from '../interfaces/funding-project-file';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  Inject,
  OnInit
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogService } from '../../../../../dialog/services/dialog.service';
import { ProjectActionService } from '../../../services/project-action.service';
import { FundingProjectDocument } from '../interfaces/funding-project-document';
import { DeleteDialogData } from '../../../../../dialog/interfaces/delete-dialog-date';
import { FilterObservableService } from '../services/filter-observable.service';
import { FileSearchPipe } from '../pipes/file-search.pipe';
import { ProjectActionData } from '../../../interfaces/project-action';
import { MatMenuTrigger } from '@angular/material/menu';
import { FundingProject } from '../../../../../core/interfaces/funding-project';
import { get as _get } from 'lodash';
import { FundingProjectDataService } from '../../../../public-api';
import * as FundingProjectStates from '../../../utils/fundingProjectStates';
import { APP_CONFIG } from '../../../../../core/util/app-config.token';
import { AppConfiguration } from '../../../../../core/interfaces/app-configuration';
import { BaseComponent } from '../../../../../core/base.component';
import { UserObjectObservableService } from '../../../../../core/services/user-object-observable.service';
import { isProjectModifiable } from '../../../../../core/util/funding-project-utils';
import { isAntragsformular } from '../utils/file-utils';
import { AppPropertiesDTO } from '../../../../../core/config/base-app-config.dt';
import { DocAndFileTitlePipeParams } from '../interfaces/document-and-file-title.pipe-data';
@Component({
  selector: 'oaman-files-table',
  templateUrl: './files-table.component.html',
  styleUrls: ['./files-table.component.scss'],
  providers: [FileSearchPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesTableComponent
  extends BaseComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  dataSource = new MatTableDataSource<FundingProjectFile>();
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('matMenuTrigger')
  matMenuTrigger!: MatMenuTrigger;
  navigationSubscription: Subscription;
  documentTitle = '';
  documentId!: string;
  documentSubmissionEnabled!: boolean;
  fundingProjectId!: string;
  fundingGuidelineId!: string;
  files!: FundingProjectFile[];
  filterObservableSubscription!: Subscription;
  tableType = 'files';
  withdrawnProject = false;
  displayedColumns: Set<string> = new Set([
    'description',
    'title',
    'author',
    'submitted',
    'uploaded',
    'fileType'
  ]);
  private manageApp = false;
  private documentEAkteId!: string;
  docAndFileTitlePipeParams!: DocAndFileTitlePipeParams;
  appProperties!: AppPropertiesDTO;

  constructor(
    public dialogService: DialogService,
    public projectActionService: ProjectActionService,
    public fileSearchPipe: FileSearchPipe,
    public filterObservableService: FilterObservableService,
    public router: Router,
    public route: ActivatedRoute,
    public changeDetector: ChangeDetectorRef,
    public fundingProjectData: FundingProjectDataService,
    public userObjectObservableService: UserObjectObservableService,
    @Inject(APP_CONFIG) public appConfig: AppConfiguration
  ) {
    super(userObjectObservableService);
    this.manageApp = this.appConfig.context === 'manage';
    this.route.parent?.data.subscribe((data) => {
      this.appProperties = _get(data, 'resolvedData.appProperties');
    });
    this.docAndFileTitlePipeParams = {
      appContext: this.appConfig?.context,
      connectorType: this.appProperties?.connectorType
    };
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialize the component
      if (e instanceof NavigationEnd) {
        this.initializeFiles();
      }
    });
  }

  ngOnInit(): void {
    this.initializeFiles();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  initializeFiles() {
    // Note: for temp documents the Id will be the unique EAkteId
    const documentId = this.route.snapshot.paramMap.get('id');
    this.initFilesAndDocuments(documentId);

    this.route.parent?.parent?.data.subscribe((data) => {
      const resolvedData: FundingProject = data['resolvedData'];
      this.fundingProjectId = resolvedData.id;
      this.withdrawnProject =
        resolvedData.stateFundingProject.resourceKey ===
        FundingProjectStates.WITHDRAWN;
      this.fundingGuidelineId = resolvedData.fundingGuideline.id;

      // add "action" column conditionally
      if (isProjectModifiable(resolvedData, this.currentUser, this.manageApp)) {
        this.displayedColumns.add('action');
      }
    });

    // subscribe to filter observable and use pipe to filter file list when user searches
    this.filterObservableSubscription =
      this.filterObservableService.filterObject$.subscribe((data) => {
        this.initTable(
          this.fileSearchPipe.transform(this.files, data.searchQuery)
        );
      });
  }

  initFilesAndDocuments(documentId: string | null): void {
    this.route.data.subscribe((data) => {
      this.files = data['resolvedData'];
    });

    // extract document id and title from parent (documents) resolvedData
    this.route.parent?.data.subscribe((data) => {
      const applicantDocs: FundingProjectDocument[] = _get(
        data,
        'resolvedData.documents'
      );

      applicantDocs.forEach((doc) => {
        if (documentId) {
          this.documentId = documentId;
          if (
            (doc.isTempDocument && doc.eAkteId === this.documentId) ||
            doc.id === documentId
          ) {
            this.documentTitle = doc.title;
            this.documentEAkteId = doc.eAkteId;
            this.documentSubmissionEnabled =
              doc.documentType?.submissionEnabled;
          }
        }
      });
    });
  }

  initTable(data: FundingProjectFile[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'title':
          return item.title;
        case 'author':
          return item.author;
        case 'submitted':
          return item.submitted
            ? item.submitted.toLocaleString()
            : item.deliveryDate
              ? item.deliveryDate.toLocaleString()
              : '';
        case 'uploaded':
          return item.uploaded ? item.uploaded.toLocaleString() : '';
        case 'fileType':
          return item.fileType;
        default: {
          console.error('error in sorting data accessor');
          return item.id;
        }
      }
    };
    // initializes the paginator and sort again after the content has changed
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.changeDetector.detectChanges();
  }

  navigateToDocuments() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  openDialog(file: FundingProjectFile, context: string) {
    const deleteFileData: ProjectActionData = {
      fileId: file.id,
      documentId: this.documentId,
      fundingProjectId: this.fundingProjectId,
      fundingGuidelineId: this.fundingGuidelineId
    };

    const deleteDialogData: DeleteDialogData = {
      title: file.title,
      deleteTitle:
        'fundingProject.details.documents.fileTable.dialog.deleteTitle',
      deleteTxt1:
        'fundingProject.details.documents.fileTable.dialog.deleteTxt1',
      deleteTxt2: 'fundingProject.details.documents.fileTable.dialog.deleteTxt2'
    };

    if (context === 'deleteFile') {
      this.dialogService
        .openDeleteDialog(deleteDialogData)
        .subscribe((result) => {
          // send the delete request if the user confirms
          if (result) {
            this.projectActionService.setAction('deleteFile', deleteFileData);
          }
          return result;
        });
    }
  }

  openFile(file: FundingProjectFile): void {
    const fileData: ProjectActionData = {
      fileId: file.id,
      title: file.title,
      fileEAkteId: file.eAkteId,
      documentEAkteId: this.documentEAkteId,
      fundingGuidelineId: this.fundingGuidelineId
    };

    this.projectActionService.setAction('openFile', fileData);
  }

  isButtonVisible(element: FundingProjectFile, context: string): boolean {
    if (context === 'deleteFile') {
      return this.manageApp || isAntragsformular(element)
        ? false
        : this.fundingProjectData.hasProjectAuthority('deleteFile');
    }

    return false;
  }

  isButtonDisabled(element: FundingProjectFile, context: string): boolean {
    if (context === 'deleteFile') {
      return !!element.submitted || !!element.deliveryDate;
    }

    return false;
  }

  onKeyupEnter() {
    this.matMenuTrigger.openMenu();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navigationSubscription?.unsubscribe();
    this.filterObservableSubscription?.unsubscribe();
  }

  getDropdownTooltip(element: FundingProjectFile, context: string): string {
    if (context === 'deleteFile') {
      if (element.submitted || element.deliveryDate) {
        return 'fundingProject.details.documents.fileTable.dropdown.tooltip.deletionDisabled';
      }
    }

    return '';
  }
}
