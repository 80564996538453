<div class="login-box">
  <mat-accordion class="login-accordion">
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[0] = true"
      (closed)="panelOpenState[0] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">1</mat-panel-title>
        <mat-panel-title class="headline">
          Welche Funktionen bietet Mein Unternehmenskonto bereits heute?
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[0]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[0]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Mein Unternehmenskonto bietet eine Login-Funktion mit
          ELSTER-Zertifikaten, die Verwaltung des Unternehmenskontos sowie ein
          Postfach inkl. der Ablage von Mitteilungen und Bescheiden.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[2] = true"
      (closed)="panelOpenState[2] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">2</mat-panel-title>
        <mat-panel-title class="headline">
          Ich bin Geschäftsführer eines Unternehmens und möchte gerne
          Mitarbeiter dazu berechtigen, bestimmte Verwaltungsvorgänge für mein
          Unternehmen durchzuführen. Kann ich das auf Mein Unternehmenskonto
          einstellen?</mat-panel-title
        >
        <mat-icon class="icon-open" *ngIf="!panelOpenState[2]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[2]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Pro Organisation können bis zu 200 Zertifikate ausgegeben werden.
          Jeder Mitarbeiter eines Unternehmens, der Zugriff auf das
          Unternehmenskonto haben soll, erhält ein persönliches Zertifikat.
          Dadurch ist es unternehmensintern möglich, entsprechend der
          Unternehmenshierarchie bestimmte Berechtigungen zu vergeben und
          unterschiedlichen Zertifikats-Inhabern den erlaubten Handlungsumfang
          vorzugeben. Mit der an das jeweilige Zertifikat gekoppelten Account-ID
          ist so eine Zuordnung von Handlungen und Anträgen zu einem
          Unternehmensmitarbeiter möglich. Ein zentrales Tool zur Steuerung
          dieser Berechtigungen ist bislang nicht in Mein Unternehmenskonto
          integriert.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[3] = true"
      (closed)="panelOpenState[3] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">3</mat-panel-title>
        <mat-panel-title class="headline">
          Welche persönlichen Daten werden auf Mein Unternehmenskonto
          gespeichert?</mat-panel-title
        >
        <mat-icon class="icon-open" *ngIf="!panelOpenState[3]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[3]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Auf Mein Unternehmenskonto selbst werden keine persönlichen Daten
          gespeichert. Die Unternehmensstammdaten werden über das
          ELSTER-Zertifikat aus den Steuerkonten abgerufen. Informationen zu der
          Frage, wie die Datenverarbeitung in Mein Unternehmenskonto abläuft,
          finden Sie unter dem Bereich Datenschutz.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[4] = true"
      (closed)="panelOpenState[4] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">4</mat-panel-title>
        <mat-panel-title class="headline">
          Wie erfolgt eine Zurücksetzung von Passwörtern oder
          PIN?</mat-panel-title
        >
        <mat-icon class="icon-open" *ngIf="!panelOpenState[4]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[4]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Das Passwort des ELSTER-Zertifikats kann unter Mein Benutzerkonto
          geändert werden.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[5] = true"
      (closed)="panelOpenState[5] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">5</mat-panel-title>
        <mat-panel-title class="headline">
          Kann das Konto auch für Organisationen genutzt
          werden?</mat-panel-title
        >
        <mat-icon class="icon-open" *ngIf="!panelOpenState[5]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[5]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Ja, mit dem Konto werden nicht nur Unternehmen, sondern auch
          Organisationen angesprochen, z. B. Vereine und Behörden. Voraussetzung
          für die Beantragung eines Kontos ist lediglich, dass die Organisation
          eine Steuernummer besitzt.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[6] = true"
      (closed)="panelOpenState[6] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">6</mat-panel-title>
        <mat-panel-title class="headline">
          Ich bin Einzelunternehmer und möchte digital Verwaltungsleistungen
          beantragen. Kann ich für die Anmeldung das Unternehmenskonto
          nutzen?</mat-panel-title
        >
        <mat-icon class="icon-open" *ngIf="!panelOpenState[6]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[6]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Ja, auch Einzelunternehmer können ein Unternehmenskonto nutzen. Dafür
          benötigen Sie lediglich ein ELSTER-Zertifikat. Grundsätzlich können
          Sie ihr persönliches Zertifikat nutzen, das Sie bereits für
          steuerliche Zwecke verwenden (insb. wenn Sie sich derzeit im
          Gründungsprozess befinden). Um alle Funktionalitäten des
          Unternehmenskontos nutzen zu können, wird empfohlen ein
          Organisationszertifikat zu beantragen.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[7] = true"
      (closed)="panelOpenState[7] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">7</mat-panel-title>
        <mat-panel-title class="headline">
          Ich bin in einem Unternehmen angestellt und möchte für meinen
          Arbeitgeber digital Verwaltungsleistungen in Anspruch nehmen. Wie kann
          ich das mit dem Unternehmenskonto bewerkstelligen?</mat-panel-title
        >
        <mat-icon class="icon-open" *ngIf="!panelOpenState[7]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[7]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Im persönlichen Bereich von Mein Unternehmenskonto finden Sie keine
          Verwaltungsleistungen. Diese können Sie in den jeweiligen Fachportalen
          abrufen. Dazu melden Sie sich dort mit Ihrem
          ELSTER-Organisationszertifikat an. Dieses Zertifikat ist dem
          Unternehmenskonto Ihres Unternehmens zugeordnet. Sobald Sie über ein
          Organisationszertifikat verfügen, können alle Funktionalitäten des
          Unternehmenskontos genutzt werden.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[8] = true"
      (closed)="panelOpenState[8] = false"
      hideToggle
    >
      <mat-expansion-panel-header [collapsedHeight]="panelHeaderHeight" [expandedHeight]="panelHeaderHeight">
        <mat-panel-title class="number-box">8</mat-panel-title>
        <mat-panel-title class="headline">
          Wie kann ich ein Unternehmenskonto erstellen?</mat-panel-title
        >
        <mat-icon class="icon-open" *ngIf="!panelOpenState[8]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[8]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Das Unternehmenskonto besteht automatisch, sobald ein Unternehmen über
          ELSTER-Organisationszertifikate verfügt. Sofern sich das Unternehmen
          nicht im Gründungsprozess befindet, kann man davon ausgehen, dass es
          über eine aktive Steuernummer verfügt. Mit dieser Steuernummer können
          Angestellte eines Unternehmens einfach und schnell
          ELSTER-Organisationszertifikate beantragen. Das Unternehmenskonto wird
          erstellt, sobald ELSTER-Organisationszertifikate für das Unternehmen
          beantragt werden. Sofern diese bereits in der Vergangenheit beantragt
          wurden, besteht das Unternehmenskonto bereits und Sie müssen nichts
          weiter tun. Weitere Informationen zur Beantragung von Zertifikaten
          finden Sie unter der Rubrik "ELSTER-Zertifikate".
        </p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
