import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-explanation-attachment-group-type',
  template: `
    <div class="sub-group-wrapper">
      <ng-container *ngIf="props.description === 'explanations'">
        <h3 *ngIf="props.label">{{ props.label }}</h3>
        <h4>Der Antragsteller/Die Antragstellerin erklärt:</h4>
        <div class="text-wrapper">
          <span class="point">1.</span>
          <div>
            Mit dem Vorhaben ist noch nicht begonnen worden und wird auch vor
            Bekanntgabe des Zuwendungsbescheides nicht begonnen (Als
            Vorhabenbeginn ist grundsätzlich der Abschluss eines der Ausführung
            zuzurechnenden Lieferungs- oder Leistungsvertrages zu werten.)
          </div>
        </div>
        <div class="text-wrapper">
          <span class="point">2.</span>
          <div>
            Sofern mit der Vorhabendurchführung die Schaffung von Arbeitsplätzen
            verbunden ist, wird eine geschlechterneutrale Verteilung
            sichergestellt. Sollte dies nicht möglich sein, so erfolgt hierzu
            die Vorlage einer begründenden Unterlage.
          </div>
        </div>
        <div class="text-wrapper">
          <span class="point">3.</span>
          <div>
            Bei der Vorhabendurchführung werden die Belange der Barrierefreiheit
            berücksichtigt. Ausnahmen sind gesondert zu begründen.
          </div>
        </div>
        <ng-container *ngFor="let field of field.fieldGroup">
          <div
            class="text-wrapper"
            *ngIf="
              (field.props['point'] === '4.' &&
                field.props['hidden'] === false) ||
              field.props['point'] === '5.'
            "
          >
            <span class="point">{{ field.props['point'] }}</span>
            <formly-field [field]="field"></formly-field>
          </div>
        </ng-container>
        <div class="text-wrapper">
          <span class="point">6.</span>
          <div>
            Die Vorhabendurchführung erfolgt nicht zur Umsetzung einer
            gesetzlichen Verpflichtung.
          </div>
        </div>
        <div class="text-wrapper">
          <span class="point">7.</span>
          <div>
            Ich/Wir bin/sind Eigentümer der zur Förderung beantragten Anlage/n.
            Soweit ich/wir nicht Eigentümer bin/sind, habe/n ich/wir diesem
            Antrag eine Einverständniserklärung des Eigentümers über die
            Durchführung des Vorhabens und die Duldung einer Zweckbindungsfrist
            beigefügt.
          </div>
        </div>
        <div class="text-wrapper">
          <span class="point">8.</span>
          <div>
            Das als Anlage beigefügte Informationsblatt nach der
            Datenschutz-Grundverordnung habe ich/wir zur Kenntnis genommen.
          </div>
        </div>
        <div class="text-wrapper">
          <span class="point">9.</span>
          <div>
            Das Vorhaben ist mit den Planungen für die Ver- und Entsorgung
            abgestimmt.
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="props.description === 'attachments'">
        <div class="text-wrapper">
          <h3 *ngIf="props.label">{{ props.label }}</h3>
        </div>
        <ng-container *ngFor="let field of field.fieldGroup">
          <div class="text-wrapper">
            <formly-field [field]="field"></formly-field>
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
  styles: [
    `
      h3 {
        margin-left: -0.5rem;
      }

      h4,
      .point {
        font-weight: 500;
      }

      .text-wrapper {
        margin-bottom: 1rem;
      }

      .sub-group-wrapper {
        border: 1px solid #e2e2e2;
        padding: 1.5rem;
        margin-bottom: 3rem;
        border-radius: 5px;
      }
    `
  ]
})
export class FormExplanationAttachmentGroupComponent extends FieldType<FieldTypeConfig> {}
