import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormTemplate } from '../../forms/interfaces/form-template';
import { FundingProjectDocumentWithFiles } from '../../documents/interfaces/funding-project-document';
import { DetailAction } from '../../../interfaces/detail-actions';
import { RequestReviewContext } from '../public-api';
import {
  FundingProjectDataService,
  FundingProjectFile,
  ProjectActionData,
  ProjectActionService
} from '../../../../public-api';
import { find as _find } from 'lodash';
import { BaseComponent } from '../../../../../core/base.component';
import {
  getLoginTypeLabel,
  isUserWithHighTrustLevel
} from '../../../../../core/public-api';
import { UserObjectObservableService } from '../../../../../core/services/user-object-observable.service';

@Component({
  selector: 'oaman-request-review',
  templateUrl: './request-review.component.html',
  styleUrls: ['./request-review.component.scss']
})
export class RequestReviewComponent extends BaseComponent implements OnInit {
  @Input() forms: FormTemplate[] = [];
  @Input() documents: FundingProjectDocumentWithFiles[] = [];
  @Input() requestType = '';
  @Input() requestDate = '';
  @Output() submitEvent = new EventEmitter<RequestReviewContext>();
  private requestId!: string;
  isFormValid = true;
  isDocumentEmpty = false;
  submittedForms: FormTemplate[] = [];
  actions: DetailAction[] = [
    {
      name: 'navigateToRequests',
      icon: 'fast_rewind',
      event: 'navigateToRequests'
    }
  ];
  displayedColumns: string[] = [
    'title',
    'author',
    'uploaded',
    'type',
    'action'
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fundingProjectData: FundingProjectDataService,
    private projectActionService: ProjectActionService,
    public userObjectObservableService: UserObjectObservableService
  ) {
    super(userObjectObservableService);
  }

  openFile(file: FundingProjectFile): void {
    const fileData: ProjectActionData = {
      fileId: file.id,
      title: file.title,
      fileEAkteId: file.eAkteId,
      fundingGuidelineId:
        this.route.parent?.parent?.parent?.parent?.snapshot.params['id']
    };

    this.projectActionService.setAction('openFile', fileData);
  }

  handleSubmit() {
    if (this.isButtonDisabled('submit')) {
      // nothing to do if the submit action is disabled
      return;
    }
    this.submitEvent.emit({
      action: 'submitRequest',
      requestId: this.requestId
    });
  }

  get filteredDocuments() {
    return this.documents.filter(
      (d) =>
        d.requestAndType.id === this.requestId &&
        d.documentType.title !== 'Antrag'
    );
  }

  get filteredForms() {
    return this.forms.filter((d) => d.requestAndType.id === this.requestId);
  }

  getFormDocument(
    form: FormTemplate
  ): FundingProjectDocumentWithFiles | undefined {
    return _find(
      this.documents,
      (doc) =>
        doc.documentType.title === 'Antrag' && doc.title.includes(form.title)
    );
  }

  ngOnInit() {
    this.requestId = this.route.snapshot.params['id'];
    for (let form of this.filteredForms) {
      if (form.hasData || form.required) {
        this.submittedForms.push(form);
      }
      if (
        (form.valid === false && form.required) ||
        (form.valid === false && form.hasData)
      ) {
        this.isFormValid = false;
      }
    }
    for (let document of this.filteredDocuments) {
      if (
        !this.isDocumentEmpty &&
        (!document.applicantDocumentsFiles ||
          document.applicantDocumentsFiles.length === 0)
      ) {
        this.isDocumentEmpty = true;
        break;
      }
    }
  }

  getDocumentValidity(document: FundingProjectDocumentWithFiles): boolean {
    return document.applicantDocumentsFiles?.length > 0;
  }

  navigateToRequestTable() {
    this.router
      .navigate(['./'], {
        relativeTo: this.route.parent
      })
      .then();
  }

  isButtonDisabled(context: string): boolean {
    if (context === 'submit') {
      // we have to disable the submit button if the trust level is not high for the current User
      return (
        !isUserWithHighTrustLevel(this.currentUser) ||
        !this.fundingProjectData.hasProjectAuthority('submitRequest')
      );
    }

    return false;
  }

  isSubmitVisible(): boolean {
    return this.isFormValid && !this.isDocumentEmpty;
  }

  goToLogin(): void {
    this.submitEvent.emit({
      action: 'goToLogin'
    });
  }

  getLoginTypeParam(): string {
    return getLoginTypeLabel(this.currentUser);
  }
}
