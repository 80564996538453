import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FundingProject } from '../../../../core/interfaces/funding-project';
import { DialogService } from '../../../../dialog/services/dialog.service';
import { APPROVED, DRAFT, SUBMITTED } from '../../utils/fundingProjectStates';
import { ProjectActionService } from '../../services/project-action.service';
import { ProjectActionData } from '../../interfaces/project-action';

@Component({
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss']
})
export class DataProtectionComponent implements OnInit {
  fundingProject!: FundingProject;

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private projectActionService: ProjectActionService
  ) {}

  ngOnInit(): void {
    this.route.parent?.parent?.data.subscribe((data) => {
      const resolvedData: FundingProject = data['resolvedData'];
      if (resolvedData) {
        this.fundingProject = resolvedData;
      }
    });
  }

  openDialog() {
    //Check is needed to block the opening of the dialog, when the button is disabled
    if (this.isBtnDisabled()) {
      return;
    }
    this.dialogService
      .openDataProtectionConsentDialog(this.getDialogBody())
      .subscribe((result) => {
        if (result) {
          const projectData: ProjectActionData = {
            fundingGuidelineId: this.fundingProject.fundingGuideline.id,
            fundingProjectId: this.fundingProject.id
          };
          this.triggerAction(projectData);
        }
      });
  }

  isBtnDisabled(): boolean {
    switch (this.fundingProject.stateFundingProject.resourceKey) {
      case DRAFT:
      case SUBMITTED:
      case APPROVED:
        return false;
      default:
        return true;
    }
  }

  getDialogBody(): string {
    switch (this.fundingProject.stateFundingProject.resourceKey) {
      case DRAFT:
      case SUBMITTED:
        return 'bodyDraftSubmitted';
      case APPROVED:
        return 'bodyApproved';
      default:
        return '';
    }
  }

  triggerAction(projectData: ProjectActionData) {
    switch (this.fundingProject.stateFundingProject.resourceKey) {
      case DRAFT:
        this.projectActionService.setAction('deleteProject', projectData);
        break;
      case SUBMITTED:
      case APPROVED:
        this.projectActionService.setAction('withdrawProject', projectData);
        break;
    }
  }
}
