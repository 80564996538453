import { Inject } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FundingProjectDocument } from '../../funding-project/public-api';
import {
  forbiddenSpecialChars,
  regExForSpecialCharsExclusion
} from '../../core/util/forbidden-special-characters-regex';

@Component({
  selector: 'oaman-document-create-dialog',
  templateUrl: './document-create-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentCreateDialogComponent {
  createForm: UntypedFormGroup;
  isEditable = false;
  maxTitleLength = 50;
  protected readonly forbiddenSpecialChars = forbiddenSpecialChars;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FundingProjectDocument,
    public dialogRef: MatDialogRef<DocumentCreateDialogComponent>
  ) {
    this.isEditable = data ? true : false;
    this.createForm = new UntypedFormGroup({
      title: new UntypedFormControl(data ? data.title : '', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(this.maxTitleLength),
        Validators.pattern(regExForSpecialCharsExclusion)
      ]),
      description: new UntypedFormControl(data ? data.description : '', [
        Validators.maxLength(4096)
      ])
    });
  }
  onSubmit() {
    this.dialogRef.close(this.createForm.value);
  }
}
