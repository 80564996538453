<h2 mat-dialog-title>
  {{ 'fundingProject.details.documents.dialog.uploadFile.uploadTitle' | translate }}
</h2>
<form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" novalidate>
<mat-dialog-content class="mat-typography">
  <div style="margin-bottom: 12px">
    <label for="document-title">
      {{ 'fundingProject.details.documents.dialog.documentCreate.titleLabel' | translate }}
      <span [style.color]="'red'"> *</span>
    </label>
    <input
      id="document-title"
      formControlName="title"
      [style.width]="'100%'"
      [style.height]="'2rem'"
      [style.box-sizing]="'border-box'"
      type="text"
    />

    <ng-container *ngIf="uploadForm.controls['title'].invalid &&
    (uploadForm.get('title')?.dirty ||
      uploadForm.get('title')?.touched)">
      <div class="validation-msg">
        <div *ngIf="uploadForm.get('title')?.errors?.required">
          {{ 'validationErrorMessages.required' | translate }}
        </div>
        <div *ngIf="uploadForm.get('title')?.errors?.minlength">
          {{ 'validationErrorMessages.minLength' | translate }}
        </div>
        <div *ngIf="uploadForm.get('title')?.errors?.maxlength">
          {{ 'validationErrorMessages.maxLength' | translate: { lengthVar: maxFileNameAndTitleLength } }}
        </div>
        <div *ngIf="uploadForm.get('title')?.errors?.pattern">
          {{ 'validationErrorMessages.forbiddenCharacters' | translate: { forbiddenChars: forbiddenSpecialChars } }}
        </div>
      </div>
    </ng-container>
  </div>

  <p>{{ 'fundingProject.details.documents.dialog.uploadFile.message1' | translate }}</p>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{
      'fundingProject.details.documents.dialog.uploadFile.labelTxt' | translate
    }}</mat-label>
    <mat-select [(value)]="selectedDocumentType" [disabled]="typeIsSet">
      <mat-option *ngFor="let document of documents" [value]="document">
        {{ document.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <p>
    {{ 'fundingProject.details.documents.dialog.uploadFile.acceptFileFormat' | translate }}
  </p>
  <p>
    {{ 'fundingProject.details.documents.dialog.uploadFile.limitedFileSize' | translate:{ maxLimit: maxFileSizeMb } }}
  </p>
  <button
    mat-raised-button
    color="primary"
    class="mb-3"
    type="button"
    (click)="fileInput.click()"
  >
    {{
      'fundingProject.details.documents.dialog.buttons.uploadBtn' | translate
    }}
    <input
      #fileInput
      type="file"
      class="file-upload"
      [accept]="requiredFileType"
      (change)="onFileSelected($event)"
    />
  </button>
  <p [ngClass]="{'file-size-exceeded': fileLimited}">
    {{
      fileName || 'fundingProject.details.documents.dialog.uploadFile.message3' | translate
    }}
  </p>
  <p *ngIf="data.title">
    {{ 'fundingProject.details.documents.dialog.uploadFile.uploadTxt1' | translate }}
    <strong>{{ data.title }}</strong>
    {{ 'fundingProject.details.documents.dialog.uploadFile.uploadTxt2' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button
    [mat-dialog-close]="false"
    [style.margin-right]="'1rem'"
    type="reset">
    {{
      'fundingProject.details.documents.dialog.buttons.cancelBtn' | translate
    }}
  </oaman-button>
  <oaman-button
    color="primary"
    [disabled]="!isSaveActive() || !uploadForm.valid"
    type="submit"
  >
    {{ 'fundingProject.details.documents.dialog.buttons.saveBtn' | translate }}
  </oaman-button>
</mat-dialog-actions>

<mat-progress-bar
  mode="determinate"
  *ngIf="activeProgressBar"
  value="20"
  class="full-width"
></mat-progress-bar>
</form>
