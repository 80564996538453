import { formatDate } from '@angular/common';
import { DeputyInfo } from '../../../../../core/interfaces/deputy-info';

export function isValidDeputy(deputy: DeputyInfo): boolean {
  if (deputy) {
    const currentDate = new Date();
    const currentDateStr = formatDate(
      currentDate,
      'yyyy-MM-ddTHH:mm:ss',
      'en-US'
    );
    return (
      deputy.validFrom <= currentDateStr &&
      (!deputy.validTo || currentDateStr <= deputy.validTo)
    );
  }

  return false;
}
