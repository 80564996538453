import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OamanComponentsModule } from 'oaman-components';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend } from '@angular/common/http';
import { HttpLoaderFactory } from '../core/translation/loader';

@NgModule({
  imports: [
    TranslateModule.forChild({
      defaultLanguage: 'de',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      },
      extend: true
    })
  ],
  exports: [
    OamanComponentsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [],
  providers: []
})
export class SharedModule {}
