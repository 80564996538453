<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <h3 *ngIf="data.status">Status Code: {{ data.status }}</h3>
  <p class="error-msg">
    {{ data.message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button [mat-dialog-close]="true">Schließen</oaman-button>
</mat-dialog-actions>
