import { IFieldFormatter } from './field-formatter-interface';

/**
 * Abstract class for a field formatter
 */
export abstract class FieldFormatter implements IFieldFormatter {
  breadcrumpKeys: string[];
  /**
   * Constructor
   * @param breadcrumpKeys the breadcrump keys chain as array fields where the formatter should be executed
   */
  constructor(breadcrumpKeys: string[]) {
    this.breadcrumpKeys = breadcrumpKeys;
  }
  abstract generateFormatedFieldTag(
    fieldValue: string,
    _defval: string | undefined
  ): string;
}
