import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'oaman-button',
  template: `
    <button
      mat-flat-button
      [style.border]="'1px solid currentColor !important'"
      [style.border-radius]="'0px !important'"
      [color]="color"
      [disabled]="disabled"
      [matTooltip]="tooltip"
      [type]="type"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <!-- <button
      *ngIf="context === 'raised'"
      mat-raised-button
      [style.border-radius]="'0px !important'"
      [color]="color"
      [disabled]="disabled"
      [matTooltip]="tooltip"
      [type]="type"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button> -->

    <ng-template #content>
      <mat-icon aria-hidden="true" *ngIf="icon">{{ icon }}</mat-icon>
      <span class="btn-text">
        <ng-content></ng-content>
      </span>
    </ng-template>
  `,
  styles: [
    `
      ::ng-deep .mdc-button__label {
        display: flex;
      }

      .btn-text {
        display: flex;
        align-items: center;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  // colors are defined in styles/colors.scss
  @Input() color = 'primary';
  @Input() disabled = false;
  @Input() tooltip = '';
  // does not submit by default
  @Input() type = 'button';
  @Input() context = 'default';
  @Input() icon = '';
}
