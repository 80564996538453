import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ComponentVersion } from '../../core/interfaces/component-version';

@Component({
  selector: 'oaman-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutComponent {
  @Input()
  dataSource$: Observable<ComponentVersion[]> = of([]);
  @Input()
  displayedColumns: string[] = ['name', 'version'];
}
