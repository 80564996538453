<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="data.event === 'SaveAndClose'; else FormChangedToValid">
    <p>{{message2_1}} <mat-icon>more_horiz</mat-icon> {{message2_2}}</p>
  </div>
  <ng-template #FormChangedToValid>
    <p>{{message1}}</p>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <oaman-button [mat-dialog-close]="true">{{
      buttonKey | translate
    }}</oaman-button>
</mat-dialog-actions>
