import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-textarea-type',
  template: `
    <input
      [type]="type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
    />
  `,
  styles: [
    `
      textarea {
        width: 100%;
      }
    `
  ]
})
export class FormTextareaComponent extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type || 'text';
  }
}
