import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AppConfiguration,
  APP_CONFIG,
  FundingGuideline,
  StateFundingProject
} from '../../core/public-api';

@Component({
  selector: 'oaman-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
  @Input()
  states$ = new Observable<StateFundingProject[]>();
  @Input()
  fundingGuidelines: FundingGuideline[] = [];
  @Input()
  stateSelect$ = new Observable<string>();
  @Input()
  roleSelect$ = new Observable<string>();
  @Input()
  fundingGuidelineSelect$ = new Observable<string>();
  @Output()
  changeState = new EventEmitter<string>();
  @Output()
  changeRole = new EventEmitter<string>();
  @Output()
  changeFundingGuideline = new EventEmitter<string>();

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfiguration) {}

  onChangeState(stateId: string): void {
    this.changeState.emit(stateId);
  }

  onChangeRole(role: string) {
    this.changeRole.emit(role);
  }

  onChangeFundingGuideline(fundingGuidelineId: string): void {
    this.changeFundingGuideline.emit(fundingGuidelineId);
  }

  hasRoleFilter(): boolean {
    return this.appConfig.context === 'enroll';
  }

  hasFundingGuidelineFilter(): boolean {
    return (
      this.appConfig.context === 'manage' && this.fundingGuidelines.length > 1
    );
  }
}
