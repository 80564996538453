<oaman-detail-actions [fundingProject]="fundingProject"></oaman-detail-actions>
<mat-list [style.padding-top]="0">
  <mat-list-item>
    <div class="title-wrapper">
      <mat-icon> check_box </mat-icon>
      <span class="consent-title">{{
        'fundingProject.details.consents.dataProtectionTitle' | translate
      }}</span>
    </div>
    <oaman-button
      [disabled]="isBtnDisabled()"
      (click)="openDialog()"
      class="withdraw-consent-button"
      >{{
        'fundingProject.details.consents.buttonWithdrawAllowance' | translate
      }}</oaman-button
    >
  </mat-list-item>
</mat-list>
<mat-divider></mat-divider>
