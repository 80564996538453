import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { FundingProjectCreateBody } from '../../core/interfaces/funding-project-create-body';
import { Location } from '../../core/interfaces/location';
import { FundingMeasure } from '../../core/interfaces/funding-measure';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { FormCustomValidators } from './utils/form-custom-validators';
import { FundingGuideline } from '../../core/interfaces/funding-guideline';
import { GuidelineMeasureIds } from '../../core/interfaces/guideline-measure-ids';
import { NgxCurrencyInputMode } from 'ngx-currency';
import {
  forbiddenSpecialChars,
  regExForSpecialCharsExclusion
} from '../../core/util/forbidden-special-characters-regex';
import {
  VillageRegLandCons,
  VillageRegLandConsDropDownData
} from '../../core/interfaces/village-reg-land-cons-drop-down-data';

const MAX_FUNDING_VALUE = 99999999.99;
@Component({
  selector: 'oaman-funding-project-create',
  templateUrl: './funding-project-create.component.html',
  styleUrls: ['./funding-project-create.component.scss'],
  providers: [DecimalPipe]
})
export class FundingProjectCreateComponent implements OnInit {
  @Input()
  locations!: Location[];
  @Input()
  villageRegLandConsDropDownData!: VillageRegLandConsDropDownData;
  @Input()
  fundingMeasures!: FundingMeasure[];
  @Input()
  fundingGuidelines!: FundingGuideline[];
  @Output()
  fundingProject = new EventEmitter<FundingProjectCreateBody>();
  @Output()
  fundingGuideline = new EventEmitter<string>();
  @Output()
  fundingMeasure = new EventEmitter<GuidelineMeasureIds>();
  fundingProjectForm!: UntypedFormGroup;
  filteredLocations$: Observable<Location[]> | undefined;
  filteredVillageRegLandCons$: Observable<VillageRegLandCons[]> | undefined;
  filteredFundingMeasures$: Observable<FundingMeasure[]> | undefined;
  filteredFundingGuidelines$: Observable<FundingGuideline[]> | undefined;
  maxFundingValueLimit = MAX_FUNDING_VALUE;
  maxLengthFundingProjectTitle = 50;
  selectedFGTitleIsZILE!: boolean;
  whiteList = [
    'd3059960-8e36-0901-e053-020012acb34a',
    'd3059960-8e3f-0901-e053-020012acb34a',
    'd3059960-8e37-0901-e053-020012acb34a'
  ];
  private selectedFundingGuideline!: string;
  protected readonly forbiddenSpecialChars = forbiddenSpecialChars;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.fundingProjectForm = this.fb.group({
      title: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(this.maxLengthFundingProjectTitle),
          Validators.pattern(regExForSpecialCharsExclusion)
        ]
      ],
      description: ['', [Validators.required, Validators.minLength(1)]],
      fundingGuidelineId: [
        null,
        [
          Validators.required,
          Validators.min(1),
          FormCustomValidators.autocompleteInputValidator
        ]
      ],
      fundingMeasureId: [
        null,
        [
          Validators.required,
          Validators.min(1),
          FormCustomValidators.autocompleteInputValidator
        ]
      ],
      locationId: [
        null,
        [
          Validators.required,
          Validators.min(1),
          FormCustomValidators.autocompleteInputValidator
        ]
      ],
      villageRegLandConId: [
        null,
        [
          Validators.required,
          Validators.min(1),
          FormCustomValidators.autocompleteInputValidator
        ]
      ],
      requestedFundingValue: 0
    });
    this.filteredFundingGuidelines$ = this.fundingProjectForm
      .get('fundingGuidelineId')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterFundingGuidelines(value))
      );

    this.filteredFundingMeasures$ = this.fundingProjectForm
      .get('fundingMeasureId')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterFundingMeasures(value))
      );

    this.filteredLocations$ = this.fundingProjectForm
      .get('locationId')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterLocations(value))
      );

    this.filteredVillageRegLandCons$ = this.fundingProjectForm
      .get('villageRegLandConId')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterVillaRegLandCons(value))
      );

    //
    this.villageRegLandConIdControl.valueChanges.subscribe((value) => {
      if (value === null) {
        //This is just any dummy-Data as DefaultValue.It´ll be not
        this.villageRegLandConIdControl.setValue(
          'd3059960-8e36-0901-e053-020012acb34a'
        );
      }
    });
  }

  get fundingMeasureControl() {
    return this.fundingProjectForm.get('fundingMeasureId') as FormControl;
  }

  get villageRegLandConIdControl() {
    return this.fundingProjectForm.get('villageRegLandConId') as FormControl;
  }

  createNewFundingProject() {
    if (this.fundingProjectForm.valid && this.fundingProjectForm.dirty) {
      const villageRegLandConId = this.fundingProjectForm.get(
        'villageRegLandConId'
      )?.value;
      const fundingProjectCreateBody: FundingProjectCreateBody =
        this.fundingProjectForm.value;
      if (this.villageRegLandConsDropDownData?.type === 'Dorfregion') {
        fundingProjectCreateBody.villageRegionId = villageRegLandConId;
      }
      if (this.villageRegLandConsDropDownData?.type === 'Flurbereinigung') {
        fundingProjectCreateBody.landConsolidationProcessId =
          villageRegLandConId;
      }
      this.fundingProject.emit(fundingProjectCreateBody);
    }
  }

  getFundingGuidelineTitle(id: string): string {
    if (id !== null) {
      this.fundingProjectForm.get('locationId')?.reset();
      this.fundingProjectForm.get('fundingMeasureId')?.reset();
      this.selectedFundingGuideline = id;
      this.fundingGuideline.emit(id);
    }
    let guideline = this.fundingGuidelines?.find((value) => value.id === id);
    this.selectedFGTitleIsZILE = guideline?.title
      ? guideline.title === 'ZILE'
      : false;
    return guideline?.title ? guideline.title : '';
  }

  getLocationTitle(id: string): string {
    let location = this.locations?.find((value) => value.id === id);
    return location?.title ? location.title : '';
  }

  getVillageRegLandConTitle(id: string): string {
    let villageReg =
      this.villageRegLandConsDropDownData?.villageRegLandCons?.find(
        (value) => value.id === id
      );
    return villageReg?.title ? villageReg.title : '';
  }

  getFundingMeasureTitle(id: string): string {
    if (id !== null) {
      this.fundingProjectForm.get('locationId')?.reset();
      this.fundingProjectForm.get('villageRegLandConId')?.reset();
      this.fundingMeasure.emit({
        fundingGuidelineId: this.selectedFundingGuideline,
        fundingMeasureId: id
      } as GuidelineMeasureIds);
    }
    let measure = this.fundingMeasures?.find((value) => value.id === id);
    return measure?.title ? measure.title : '';
  }

  getInputMode() {
    return NgxCurrencyInputMode.Natural;
  }

  private filterFundingGuidelines(value: string): FundingGuideline[] {
    const filterValue = this._normalizeValue(value);
    return this.fundingGuidelines?.filter((fundingGuideline) =>
      this._normalizeValue(fundingGuideline.title).includes(filterValue)
    );
  }

  private filterLocations(value: string): Location[] {
    const filterValue = this._normalizeValue(value);
    return this.locations?.filter((location) =>
      this._normalizeValue(location.title).includes(filterValue)
    );
  }

  private filterVillaRegLandCons(value: string): VillageRegLandCons[] {
    const filterValue = this._normalizeValue(value);
    return this.villageRegLandConsDropDownData?.villageRegLandCons?.filter(
      (villaReg) => this._normalizeValue(villaReg.title).includes(filterValue)
    );
  }

  private filterFundingMeasures(value: string): FundingMeasure[] {
    const filterValue = this._normalizeValue(value);
    return this.fundingMeasures?.filter((measure) =>
      this._normalizeValue(measure.title).includes(filterValue)
    );
  }

  private _normalizeValue(value: string | number): string {
    return !value || typeof value === 'number'
      ? ''
      : value.toLowerCase().replace(/\s/g, '');
  }
}
