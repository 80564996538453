import { FieldArrayType, FieldArrayTypeConfig } from '@ngx-formly/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';

@Component({
  selector: 'oaman-form-repeat-section-type',
  template: `
    <div
      [ngClass]="{
        'bordered-repeat-wrapper': !props.subGroupChild,
        'unbordered-repeat-wrapper': props.subGroupChild
      }"
    >
      <h3 *ngIf="props.label">{{ props.label }}</h3>
      <div *ngFor="let field of field.fieldGroup; let i = index">
        <div [hidden]="field?.props['disabled']">
          <div
            [ngClass]="{
              spacer: props.readonly && i !== field.parent.model.length - 1
            }"
          >
            <formly-field class="col" [field]="field"></formly-field>
          </div>
          <div
            class="repeat-btn"
            *ngIf="
              field.fieldGroup.length > 1 && !isFormDisabled && !props.readonly
            "
          >
            <button mat-stroked-button type="button" (click)="remove(i)">
              {{ props.removeMemberText }}
            </button>
          </div>
        </div>
      </div>
      <div class="repeat-btn" *ngIf="!isFormDisabled && !props.readonly">
        <button
          mat-stroked-button
          type="button"
          (click)="add()"
          [disabled]="field?.props['disabled']"
        >
          {{ props.addMemberText }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      h3 {
        margin-left: -0.5rem;
      }
      .bordered-repeat-wrapper {
        border: 1px solid #e2e2e2;
        padding: 1.5rem;
        margin-bottom: 3rem;
        border-radius: 5px;
      }
      .unbordered-repeat-wrapper {
        padding: 1.5rem;
      }
      .repeat-btn {
        display: flex;
        justify-content: flex-end;
      }
      .spacer {
        margin-bottom: 4rem;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormRepeatComponent
  extends FieldArrayType<FieldArrayTypeConfig>
  implements AfterViewInit
{
  constructor(private ref: ChangeDetectorRef) {
    super();
  }
  get isFormDisabled() {
    return this.formState.disabled;
  }

  ngAfterViewInit(): void {
    if (
      !this.props['subGroupChild'] &&
      this.field.fieldGroup?.length === 0 &&
      this.field.key !== 'corporateGroupOperationRepeat'
    ) {
      this.add();
      this.ref.detectChanges();
    }
  }
}
