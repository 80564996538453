import { UploadDialogDocument } from '../interfaces/upload-dialog-document';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Input
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadDialogData } from '../interfaces/upload-dialog-data';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseAppConfigService } from '../../core/config/base-app-config.service';
import { BaseAppConfig } from '../../core/config/base-app-config.dt';
import {
  regExForSpecialCharsExclusion,
  forbiddenSpecialChars
} from '../../core/util/forbidden-special-characters-regex';
import { textContainsForbiddenChars } from '../utils/dialog-utils';

@Component({
  selector: 'oaman-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDialogComponent {
  // erlaubt sind Dateiformate .pdf, .jpg, .png, .gif
  @Input()
  requiredFileType = `
    application/pdf,
    image/jpeg,
    image/png,
    image/gif`;

  // Später sollen diese Daten aus dem Backend aufgerufen werden
  documents: UploadDialogDocument[] = [
    {
      id: 'd31859fb-42c1-0266-e053-020012ac901c',
      value: 'sonstiges',
      viewValue: 'Sonstige Anlage'
    },
    {
      id: 'd31859fb-42c2-0266-e053-020012ac901c',
      value: 'kosten',
      viewValue: 'Kostenschätzung'
    },
    {
      id: 'd31859fb-42c3-0266-e053-020012ac901c',
      value: 'zeichnung',
      viewValue: 'Zeichnung / Foto'
    },
    {
      id: 'd31859fb-42c4-0266-e053-020012ac901c',
      value: 'bauskizze',
      viewValue: 'Bauskizze / Lageplan'
    },
    {
      id: 'd31859fb-42c5-0266-e053-020012ac901c',
      value: 'flaechen',
      viewValue: 'Flächenkarte'
    },
    {
      id: 'd31859fb-42c6-0266-e053-020012ac901c',
      value: 'stellung',
      viewValue: 'Stellungnahme Naturschutzbehörde'
    },
    {
      id: 'd31859fb-42c7-0266-e053-020012ac901c',
      value: 'wege',
      viewValue: 'Wegenutzungskonzept'
    },
    {
      id: 'd31859fb-42c8-0266-e053-020012ac901c',
      value: 'touris',
      viewValue: 'Touristische Konzepte'
    },
    {
      id: 'd31859fb-42c9-0266-e053-020012ac901c',
      value: 'genehmigung',
      viewValue: 'Genehmigung Denkmalschutz'
    },
    {
      id: 'd31859fb-42ca-0266-e053-020012ac901c',
      value: 'markt',
      viewValue: 'Markt-/Standortanalyse'
    },
    {
      id: 'd31859fb-42cb-0266-e053-020012ac901c',
      value: 'bedarf',
      viewValue: 'Bedarfsanalyse'
    },
    {
      id: 'd31859fb-42cc-0266-e053-020012ac901c',
      value: 'nkag',
      viewValue: 'NKAG Gemeindesatzung'
    },
    {
      id: 'd31859fb-42cd-0266-e053-020012ac901c',
      value: 'foerder',
      viewValue: 'Sonstige Förderbescheide'
    },
    {
      id: 'd31859fb-42ce-0266-e053-020012ac901c',
      value: 'nachweis',
      viewValue: 'Nachweis berufliche Qualifikation'
    },
    {
      id: 'd31859fb-42cf-0266-e053-020012ac901c',
      value: 'nachweis-2',
      viewValue: 'Nachweis Gesamtfinanzierung'
    }
  ];

  //Getting Infos from Env.
  appProperties!: BaseAppConfig;
  maxFileSizeMb!: number;
  maxFileSize!: number;

  uploadForm: UntypedFormGroup;
  selectedDocumentType = this.documents[0];
  typeIsSet = false;
  fileName = '';
  fileLimited = false; // Wenn die Dateigröße größer als 25MB ist, wird das Boolean auf "true" gesetzt
  fileDone = false; // Nachdem man eine Datei ausgewählt hat, wird dieses Boolean auf "true" gesetzt, damit der Speicher Button anklickbar ist
  activeProgressBar = false; // Nachdem auf Speichern geklickt wird, soll das ProgressBar angezeigt werden
  fileBlob: Blob | null = null;
  maxFileNameAndTitleLength = 50;
  protected readonly forbiddenSpecialChars = forbiddenSpecialChars;

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
    private translateService: TranslateService,
    private baseAppConfigService: BaseAppConfigService
  ) {
    this.appProperties = this.baseAppConfigService.loadAppConfig();
    this.maxFileSizeMb = this.appProperties.maxFileSizeMb;
    this.maxFileSize = this.maxFileSizeMb * 1024 * 1024;

    this.uploadForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(this.maxFileNameAndTitleLength),
        Validators.pattern(regExForSpecialCharsExclusion)
      ])
    });
  }

  onFileSelected(event: any) {
    const fileSizeExceededTxt = this.translateService.instant(
      'fundingProject.details.documents.dialog.uploadFile.fileSizeExceeded',
      { maxLimit: this.maxFileSizeMb }
    );
    const fileNameMaxLengthExceededTxt = this.translateService.instant(
      'fundingProject.details.documents.dialog.uploadFile.maxFileNameLength',
      { lengthVar: this.maxFileNameAndTitleLength }
    );
    let invalidFileNameTxt = this.translateService.instant(
      'fundingProject.details.documents.dialog.uploadFile.forbiddenCharacters',
      { forbiddenChars: this.forbiddenSpecialChars }
    );

    const file: File = event.target.files[0];
    const invalidFileName = textContainsForbiddenChars(file.name);
    if (file) {
      if (
        file.size >= this.maxFileSize ||
        file.name.length > this.maxFileNameAndTitleLength ||
        invalidFileName
      ) {
        this.fileName =
          file.size >= this.maxFileSize
            ? fileSizeExceededTxt
            : invalidFileName
              ? invalidFileNameTxt
              : fileNameMaxLengthExceededTxt;
        this.fileLimited = true;
        this.fileDone = false;
      } else {
        this.fileName = file.name;
        this.fileLimited = false;
        this.fileDone = true;
        this.fileBlob = file;
      }
    }
  }

  isSaveActive() {
    return this.fileDone;
  }

  onSubmit() {
    this.activeProgressBar = true;
    if (this.fileBlob) {
      const formData = new FormData();
      formData.append('file', this.fileBlob);
      const additionalInformation = {
        title: this.uploadForm.value.title,
        typeApplicantDocumentId: this.selectedDocumentType.id
      };
      const additionalInformationFormData = new Blob(
        [JSON.stringify(additionalInformation)],
        {
          type: 'application/json'
        }
      );
      formData.append('fileInformation', additionalInformationFormData);

      this.dialogRef.close({
        data: formData
      });
    }
  }
}
