<h2 class="title" mat-dialog-title>
  {{ 'dialog.assignClerk.title' | translate }}
</h2>
<mat-dialog-content class="mat-typography dialog-content">
  <mat-form-field appearance="outline" class="filter">
    <mat-label> Dienststelle </mat-label>
    <mat-select
      (selectionChange)="onSelectDepartment($event.value)"
      [(ngModel)]="selected"
    >
      <mat-option
        *ngFor="let department of departments"
        [value]="department.id"
      >
        {{ department.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <oaman-search
    [style]="searchFieldStyle"
    [searchLabel]="'fundingProject.search.label' | translate"
    #searchField
    (searchQuery)="onSearch($event)"
  ></oaman-search>
  <mat-list>
    <div *ngFor="let clerk of displayedUsers">
      <mat-list-item
        *ngIf="
          !(clerk.id === data.currentUser.id && data.assignedToCurrentUser)
        "
        (click)="onSelectUser(clerk.id)"
        class="list-item"
      >
        <oaman-avatar
          >{{ clerk.nameLast[0] }}{{ clerk.nameFirst[0] }}</oaman-avatar
        >
        <!-- is current user-->
        <div class="clerk-item">
          <span *ngIf="clerk.id === data.currentUser.id" class="clerk-name">
            {{ 'dialog.assignClerk.assignMyself' | translate }}
          </span>
          <!-- is clerk -->
          <span *ngIf="!(clerk.id === data.currentUser.id)" class="clerk-name">
            {{ clerk.nameLast }}, {{ clerk.nameFirst }}
          </span>
          <span class="authority-item">
            {{ clerk.authority.titleShort }} ({{
              getDepartmentNameById(clerk.authorityLocationNumber)
            }})
          </span>
        </div>
      </mat-list-item>
    </div>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button [mat-dialog-close]="false">
    {{ 'dialog.assignClerk.cancel' | translate }}
  </oaman-button>
</mat-dialog-actions>
