import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-input-type',
  template: `
    <input
      *ngIf="type === 'number' || type === 'zipcode'"
      [type]="type"
      oamanCleanNumber
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="to['focused'] = true"
      (blur)="to['focused'] = false"
    />
    <input
      *ngIf="type !== 'number' && type !== 'zipcode'"
      [type]="type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="to['focused'] = true"
      (blur)="to['focused'] = false"
    />
  `,
  styles: [
    `
      input {
        width: 100%;
        height: 2rem;
        box-sizing: border-box;
      }
    `
  ]
})
export class FormInputComponent extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type || 'text';
  }
}
