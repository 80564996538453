import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { isAuthorized } from '../authentication/state/auth.selectors';
import { SpinnerService } from 'oaman-components';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {
  excludedUrl = './assets/';
  constructor(
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private cookie: CookieService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // don't set the request header if a file is being uploaded as the content type multipart/form-data and boundary should be automatically set
    // 'File-Upload-Request' is a specific header set by the service acting as a hint for this interceptor
    // the header 'File-Upload-Request' is checked for and then removed should it be present
    if (req.headers.get('file-upload-request')) {
      req = req.clone({
        headers: req.headers.delete('file-upload-request')
      });
    } else {
      // default is that we explicitly set the content type to json
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
      req = req.clone({
        headers: req.headers.set('Accept', 'application/json')
      });
    }
    req = req.clone({
      headers: req.headers.set('X-XSRF-TOKEN', this.cookie.get('XSRF-TOKEN')), //needed for CSRF protection
      withCredentials: true //needed to automatically include the cookies on requests
    });

    // exclude the interceptor from translate service
    if (req.url.startsWith(this.excludedUrl)) return next.handle(req);
    // show the loading spinner
    this.spinner.show();
    this.store.select(isAuthorized).subscribe((authorized) => {
      if (!authorized) {
        this.spinner.hide();
      }
    });

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        // hide the spinner only on HTTP Response events
        if (event instanceof HttpResponse) {
          this.spinner.hide();
        }
        return event;
      })
      // Enable this if we need to have a retry option for the failed request
      // retryWhen((errors) =>
      //   errors.pipe(
      //     filter((result) => result),
      //     take(1),
      //     switchMap(() => this.retryRequest(next, req))
      //   )
      // )
    );
  }

  // /**
  //  * Used to retry the given request with updated header values.
  //  * @param next
  //  * @param req
  //  * @returns
  //  */
  // private retryRequest(
  //   next: HttpHandler,
  //   req: HttpRequest<any>
  // ): Observable<HttpEvent<any>> {
  //   req = req.clone({
  //     headers: req.headers.set('X-XSRF-TOKEN', this.cookie.get('XSRF-TOKEN')) //needed for CSRF protection
  //   });
  //   return next.handle(req);
  // }
}
