import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { FundingProject } from '../core/interfaces/funding-project';
import { GuidelineProjectIds } from '../core/interfaces/guideline-project-ids';
import { FundingProjectFilter, PageOption } from '../core/public-api';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';

@Component({
  selector: 'oaman-card-funding-grid',
  templateUrl: './card-funding-grid.component.html'
})
export class CardFundingGridComponent implements AfterViewInit {
  @ViewChild(InfiniteScrollDirective)
  infiniteScrollDirective!: InfiniteScrollDirective;

  @Input() fundingProjects!: FundingProject[];
  @Input() totalPages!: number;
  @Input() projectFilter!: FundingProjectFilter;
  @Output() openProjectDetails = new EventEmitter<GuidelineProjectIds>();
  @Output() setClerkAction = new EventEmitter<GuidelineProjectIds>();
  @Output() loadPage = new EventEmitter<PageOption>();

  private _reinitialiseInfiniteScroll$!: Observable<boolean>;
  page = 1;

  ngAfterViewInit(): void {
    this.reinitialiseInfiniteScroll$.subscribe((reinitialise) => {
      if (reinitialise) {
        this.resetInfiniteScroll();
      }
    });
  }

  loadNextPage(): void {
    this.page++;
    if (this.page <= this.totalPages) {
      // emits the page loading event if we are not at the last page already
      this.loadPage.emit({
        pageNumber: this.page,
        sortBy: this.projectFilter.sortBy,
        sortOrder: this.projectFilter.orderBy
      });
    }
  }

  hasClickedPassthrough(guidelineProjectIds: GuidelineProjectIds) {
    this.openProjectDetails.emit(guidelineProjectIds);
  }

  hasChangedClerkPassthrough(guidelineProjectIds: GuidelineProjectIds) {
    this.setClerkAction.emit(guidelineProjectIds);
  }

  resetInfiniteScroll() {
    this.page = 1;
    this.infiniteScrollDirective.destroyScroller();
    this.infiniteScrollDirective.setup();
  }

  @Input()
  set reinitialiseInfiniteScroll$(value: Observable<boolean>) {
    this._reinitialiseInfiniteScroll$ = value;
    this._reinitialiseInfiniteScroll$.subscribe((reinitialise) => {
      if (this.infiniteScrollDirective && reinitialise) {
        this.resetInfiniteScroll();
      }
    });
  }

  get reinitialiseInfiniteScroll$(): Observable<boolean> {
    return this._reinitialiseInfiniteScroll$;
  }
}
