<div class="wrapper">
  <h1>Impressum</h1>
  <div class="group section noline span3of4">
    <div><br /></div>
    <div><br /></div>
    <p class="c1">
      Anbieter gem. §5 Telemediengesetz und §55 Abs. 1 des Staatsvertrages über
      Rundfunk und Telemedien (Rundfunkstaatsvertrag - RStV):<br />
      <br />
      Verantwortlicher für Online Antragsmanagement Plattform:
    </p>
    <p class="c2">
      Niedersächsisches Ministerium für Bundes- und Europaangelegenheiten und
      Regionale<br />
      Entwicklung<br />
      Osterstraße 40<br />
      30159 Hannover<br />
      E-Mail:
      <a
        href="mailto:pressestelle@mb.niedersachsen.de"
        target="_blank"
        unselectable="on"
        >pressestelle&#64;mb.niedersachsen.de</a
      >
    </p>
    <p class="c2">
      <strong
        ><br />
        Vertretungsberechtigt<br
      /></strong>
      Staatssekretär Matthias Wunderling-Weilbier<br />
    </p>
    <p class="c2">
      <strong
        ><br />
        Verantwortlicher für die Richtlinien ZILE und LEADER:<br
      /></strong>
      Niedersächsisches Ministerium<br />
      für Ernährung, Landwirtschaft und<br />
      Verbraucherschutz
    </p>
    <p class="c2">
      Calenberger Straße 2<br />
      30169 Hannover<br />
      Telefon: (0511) 120 – 2095/2135/2136/2137<br />
      Telefax: (0511) 120 - 23 82<br />
      E-Mail:
      <a
        href="mailto:pressestelle@ml.niedersachsen.de"
        target="_blank"
        unselectable="on"
        >pressestelle&#64;ml.niedersachsen.de</a
      >
    </p>
    <p class="c2">
      <strong
        ><br />
        Vertretungsberechtigt<br /></strong
      >Staatssekretär Dr. Michael Marahrens<br />
    </p>
    <p class="c2">
      <strong>Technischer Betrieb:<br /></strong>
      <!-- $link_11(undefined) HTML{ --><a
        href="https://www.sla.niedersachsen.de/startseite/"
        class="download"
        target="target="
        >Servicezentrum Landentwicklung und Agrarförderung</a
      ><!-- }HTML --><br />
      Wiesenstraße 1<br />
      30169 Hannover
    </p>
    <p class="c2">
      <strong>Technische Voraussetzungen</strong><br />
      Diese Website ist optimiert für Chromium-basierte Browser. Für die
      Benutzung muss Javascript aktiviert sein.<br />
    </p>
    <p class="c2">
      <strong>Hinweis gemäß EU-Datenschutz-Grundverordnung<br /></strong>
      <strong
        ><br />Behördlicher Datenschutzbeauftragter für Online Antragsmanagement
        Plattform:<br
      /></strong>
      Niedersächsisches Ministerium für Bundes- und Europaangelegenheiten und
      Regionale<br />
      Entwicklung<br />
      Datenschutzbeauftragte/r<br />
      Osterstraße 40<br />
      30159 Hannover<br />
      E-Mail:
      <a
        href="mailto:datenschutzbeauftragte@mb.niedersachsen.de"
        target="_blank"
        unselectable="on"
        >datenschutzbeauftragte&#64;mb.niedersachsen.de</a
      >
    </p>
    <p class="c2">
      <strong
        ><br />
        Behördlicher Datenschutzbeauftragter für die Richtlinien ZILE und LEADER:<br
      /></strong>
      Calenberger Straße 2<br />
      30169 Hannover<br />
      Telefon: (0511) 120 - 2305<br />
      E-Mail:
      <a href="mailto:Datenschutz@ml.niedersachsen.de"
        >Datenschutz&#64;ml.niedersachsen.de</a
      >
    </p>
    <p class="c2"><br /></p>
    <p class="c1">Haftungsausschluss</p>
    <p class="c2">1. Inhalt des Onlineangebotes</p>
    <p class="c2">
      Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
      Vollständigkeit oder Qualität der bereitgestellten Informationen.
      Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
      oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der
      dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
      unvollständiger Informationen verursacht wurden, sind grundsätzlich
      ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches
      oder grob fahrlässiges Verschulden vorliegt.
    </p>
    <p class="c2">
      Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es
      sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
      gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
      Veröffentlichung zeitweise oder endgültig einzustellen.
    </p>
    <p class="c2">2. Verweise und Links</p>
    <p class="c2">
      Bei direkten oder indirekten Verweisen auf fremde Internetseiten
      ("Links"), die außerhalb des Verantwortungsbereiches des Autors liegen,
      würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft
      treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm
      technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger
      Inhalte zu verhindern.
    </p>
    <p class="c2">
      Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung
      keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren.
      Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die
      Urheberschaft der gelinkten/verknüpften Seiten hat der Autor keinerlei
      Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen
      Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung
      verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen
      Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in
      vom Autor eingerichteten Gästebüchern, Diskussionsforen und Mailinglisten.
      Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für
      Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
      Informationen entstehen, haftet allein der Anbieter der Seite, auf welche
      verwiesen wurde, nicht derjenige, der über Links auf die jeweilige
      Veröffentlichung lediglich verweist.
    </p>
    <p class="c2">3. Urheber- und Kennzeichenrecht</p>
    <p class="c2">
      Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
      verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten,
      von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte
      zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und
      Texte zurückzugreifen.
    </p>
    <p class="c2">
      Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte
      geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
      Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten
      der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen
      Nennung ist nicht der Schluß zu ziehen, dass Markenzeichen nicht durch
      Rechte Dritter geschützt sind! Für Vorschaubilder und
      Artikelillustrationen wird lizensiertes Bildmaterial von
      <!-- $link_10(undefined) HTML{ --><a
        href="https://de.fotolia.com/"
        class="download"
        target="target="
        >www.fotolia.com</a
      ><!-- }HTML -->
      sowie von
      <!-- $link_4(undefined) HTML{ --><a
        href="https://www.flickr.com/"
        class="download"
        target="target="
        >www.flickr.com</a
      ><!-- }HTML -->,
      <!-- $link_5(undefined) HTML{ --><a
        href="https://www.pixelio.de/"
        class="download"
        target="target="
        >www.pixelio.de</a
      ><!-- }HTML -->,
      <!-- $link_9(undefined) HTML{ --><a
        href="https://de.clipdealer.com/"
        class="download"
        target="target="
        >www.clipdealer.com</a
      ><!-- }HTML -->,
      <!-- $link_7(undefined) HTML{ --><a
        href="https://pixabay.com/de/"
        class="download"
        target="target="
        >www.pixabay.com</a
      ><!-- }HTML -->
      und
      <!-- $link_8(undefined) HTML{ --><a
        href="http://landpixel.eu/"
        class="download"
        target="target="
        >www.landpixel.eu</a
      ><!-- }HTML -->
      genutzt.
    </p>
    <p class="c2">
      Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte
      bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung
      solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen
      elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
      Zustimmung des Autors nicht gestattet.
    </p>
    <p class="c2">4. Rechtswirksamkeit dieses Haftungsausschlusses</p>
    <p class="c2">
      Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
      betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder
      einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,
      nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen
      Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
    </p>
  </div>
</div>
