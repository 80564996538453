<h2 mat-dialog-title>
  {{ 'fundingProject.details.project.dialog.stateChange.title' | translate }}
</h2>

<form
  [formGroup]="stateChangeForm"
  class="state-change-form"
  (ngSubmit)="onSubmit()"
  novalidate
>
  <mat-dialog-content class="mat-typography">
    <p class="message-1">
      {{
        'fundingProject.details.project.dialog.stateChange.message1' | translate
      }}
    </p>
    <p>
      {{
        'fundingProject.details.project.dialog.stateChange.message2' | translate
      }}
    </p>

    <div class="current-state-section">
      <span>
        {{
          'fundingProject.details.project.dialog.stateChange.currentState'
            | translate
        }}
      </span>
      <span class="current-state">
        {{ data.projectState.resourceKey | translate }}
      </span>
    </div>

    <mat-form-field appearance="fill" class="new-state-section">
      <mat-label>{{
        'fundingProject.details.project.dialog.stateChange.availableStates'
          | translate
      }}</mat-label>
      <mat-select
        formControlName="state"
        [(value)]="selectedState"
        [title]="
          'fundingProject.details.project.dialog.stateChange.chooseStateTooltip'
            | translate
        "
      >
        <mat-option
          *ngFor="let state of data.availableStates | orderBy: 'sortKey'"
          [value]="state"
        >
          {{ state.resourceKey | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <oaman-button
      [mat-dialog-close]="false"
      [style.margin-right]="'1rem'"
      type="reset"
    >
      {{
        'fundingProject.details.project.dialog.stateChange.cancelBtn'
          | translate
      }}
    </oaman-button>
    <oaman-button
      color="primary"
      [disabled]="!stateChangeForm.valid || this.activeProgressBar"
      type="submit"
    >
      {{
        'fundingProject.details.project.dialog.stateChange.submit' | translate
      }}
    </oaman-button>
  </mat-dialog-actions>

  <mat-progress-bar
    mode="determinate"
    *ngIf="activeProgressBar"
    value="20"
    class="full-width"
  ></mat-progress-bar>
</form>
