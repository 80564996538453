<div>
  <h1 class="profile-header">{{ 'profile.header' | translate }}</h1>
  <div *ngIf="dataSource$ | async as dataSource" class="profile-table">
    <table
      id="profile-table"
      mat-table
      class="mat-elevation-z8"
      [dataSource]="dataSource"
    >
      <caption>
        Profil
      </caption>
      <ng-container matColumnDef="key">
        <td mat-cell *matCellDef="let element">{{ element.key }}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <td mat-cell *matCellDef="let element">{{ element.value }}</td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div *ngIf="userState$ | async as userState" [style.margin-top]="'2rem'">
    <h2 class="profile-header">SLA Rollemitgliedschaften und Berechtigungen</h2>
    {{ userState | json }}
  </div>
</div>
