<div class="container">
  <div class="info-text">
    <p>{{ 'login.infoText.part1' | translate }}</p>
    <p>{{ 'login.infoText.part2' | translate }}</p>
    <p>{{ 'login.infoText.part3' | translate }}</p>
    <p>{{ 'login.infoText.part4' | translate }}</p>
    <p>{{ 'login.infoText.part5' | translate }}</p>
  </div>
  <div fxLayoutAlign="center">
    <oaman-button
      class="login-button"
      style="display: table-row; text-align: center; margin-bottom: 10px"
      *ngIf="isBasicAuthEnabled"
      id="login-button-nav"
      color="primary"
      aria-label="Login Button"
      (click)="login()"
    >
      {{ 'Test Login' | uppercase }}
    </oaman-button>
  </div>
  <h2>Anmeldung</h2>
  <div
    class="container login-box"
    fxLayout="row"
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
  >
    <mat-tab-group
      animationDuration="0ms"
      (selectedTabChange)="onTabSelect()"
      disableRipple
    >
      <mat-tab label="BundID">
        <div fxLayoutAlign="baseline" class="button-container">
          <oaman-button
            class="login-button"
            [style.margin]="'10px'"
            id="login-button-nav"
            color="primary"
            aria-label="Login Button"
            (click)="login('BundID')"
          >
            {{ 'Login mit BundID' }}
          </oaman-button>
        </div>
        <p *ngIf="isLoginFailed" class="login-error">
          <strong>{{ 'login.failedTitle' | translate }}</strong>
          <span class="login-error-description">{{
            getFailureMessageKey() | translate
          }}</span>
        </p>
        <h2>Fragen & Antworten zur BundID</h2>
        <app-info-bundidkonto></app-info-bundidkonto>
      </mat-tab>
      <mat-tab label="Unternehmenskonto">
        <div fxLayoutAlign="baseline" class="button-container">
          <oaman-button
            class="login-button"
            [style.margin]="'10px'"
            id="login-button-nav"
            color="primary"
            aria-label="Login Button"
            (click)="login('CorporateAccount')"
          >
            {{ 'Login mit Mein Unternehmenskonto' }}
          </oaman-button>
        </div>
        <p *ngIf="isLoginFailed" class="login-error">
          <strong>{{ 'login.failedTitle' | translate }}</strong>
          <span class="login-error-description">{{
            getFailureMessageKey() | translate
          }}</span>
        </p>
        <h2>Fragen & Antworten zum Unternehmenskonto</h2>
        <app-info-unternehmenskonto></app-info-unternehmenskonto>
      </mat-tab>
      <mat-tab label="" disabled></mat-tab>
    </mat-tab-group>
  </div>
</div>
