<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography" [innerHTML]="data.message">
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button mat-button [mat-dialog-close]="false">
    {{
      'fundingProject.details.documents.dialog.buttons.cancelBtn2' | translate
    }}
  </oaman-button>
  <oaman-button
    *ngIf="data.submitText"
    [mat-dialog-close]="true"
    [style.margin-left]="'1rem'"
  >
    {{ data.submitText }}
  </oaman-button>
</mat-dialog-actions>
