import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'oaman-form-date-type',
  template: `
    <input
      [type]="type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      oamanFormDate
    />
  `
})
export class FormDateTypeComponent extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type || 'text';
  }
}
