import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'oaman-form-sub-group-type',
  template: `
    <div class="sub-group-wrapper">
      <h3 *ngIf="props.label" [innerHTML]="props.label"></h3>
      <div class="description" *ngIf="props.description">
        {{ props.description }}
      </div>
      <ng-container *ngFor="let field of field.fieldGroup">
        <formly-field [field]="field"></formly-field>
      </ng-container>
    </div>
  `,
  styles: [
    `
      h3 {
        margin-left: -0.5rem;
      }
      .description {
        margin-left: -0.5rem;
        font-size: 16px;
      }
      .sub-group-wrapper {
        border: 1px solid #e2e2e2;
        padding: 1.5rem;
        margin-bottom: 3rem;
        border-radius: 5px;
      }
    `
  ]
})
export class FormSubGroupComponent extends FieldType<FieldTypeConfig> {}
