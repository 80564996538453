import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'oaman-answer-dialog',
  templateUrl: './answer-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerDialogComponent {
  createForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<AnswerDialogComponent>) {
    this.createForm = new UntypedFormGroup({
      message: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(4096)
      ])
    });
  }
  onSubmit() {
    this.dialogRef.close(this.createForm.value);
  }
}
