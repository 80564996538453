<oaman-detail-actions
  [actions]="actions"
  (clickTriggered)="navigateToRequestTable()"
></oaman-detail-actions>
<div class="mt-5">
  <h2>{{ 'fundingProject.details.request.requestTitle' | translate }}</h2>
</div>
<div>
  <h3>
    {{ 'fundingProject.' + requestType | translate }}
    {{
      'fundingProject.details.request.requestDate'
        | translate: { date: requestDate | date }
    }}
  </h3>
  <div *ngIf="isSubmitVisible()">
    <oaman-button
      [disabled]="isButtonDisabled('submit')"
      (click)="handleSubmit()"
      >{{
        'fundingProject.details.request.submitBtn' | translate
      }}</oaman-button
    >
    <oaman-button
      class="trust-level-button"
      *ngIf="isButtonDisabled('submit')"
      (click)="goToLogin()"
      >{{
        'fundingProject.details.request.goToLogin'
          | translate: { loginType: getLoginTypeParam() }
      }}</oaman-button
    >
    <div class="trust-level-info" *ngIf="isButtonDisabled('submit')">
      <oaman-alert fxFlex="100" fxFlex.lt-md="100" [context]="'missingContext'">
        <div>
          {{
            'fundingProject.details.request.alert.lowtrustlevel'
              | translate: { loginType: getLoginTypeParam() }
          }}
        </div>
      </oaman-alert>
    </div>
  </div>
  <div *ngIf="!isFormValid">
    <oaman-alert fxFlex="100" fxFlex.lt-md="100" [context]="'missingContext'">
      <div>{{ 'fundingProject.details.request.alert.text1' | translate }}</div>
    </oaman-alert>
  </div>
  <br />
  <div *ngIf="isDocumentEmpty">
    <oaman-alert fxFlex="100" fxFlex.lt-md="100" [context]="'missingContext'">
      <div>{{ 'fundingProject.details.request.alert.text3' | translate }}</div>
    </oaman-alert>
  </div>
</div>
<br />
<mat-list *ngIf="submittedForms.length > 0" [style.padding-top]="0">
  <h4>{{ 'fundingProject.details.request.formTitle' | translate }}</h4>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let form of submittedForms">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-list-item>
            <mat-icon
              [title]="'fundingProject.details.request.info' | translate"
              class="list-icon error-icon"
              *ngIf="!form.valid"
            >
              error
            </mat-icon>
            <span [ngClass]="form.valid ? 'valid-style' : ''"></span>
            {{ form.title }}
          </mat-list-item>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent *ngIf="getFormDocument(form)">
        <ng-container
          *ngTemplateOutlet="
            filesTableContent;
            context: { $implicit: getFormDocument(form) }
          "
        ></ng-container>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-list>
<br />
<mat-list
  *ngIf="filteredDocuments.length > 0"
  [style.padding-top]="0"
  class="mb-5"
>
  <h4>{{ 'fundingProject.details.request.documentTitle' | translate }}</h4>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let document of filteredDocuments">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-list-item>
            <mat-icon
              [title]="'fundingProject.details.request.info' | translate"
              class="error-icon"
              *ngIf="!getDocumentValidity(document)"
            >
              error
            </mat-icon>
            <span
              [ngClass]="getDocumentValidity(document) ? 'valid-style' : ''"
            ></span>
            {{ document.title }}
          </mat-list-item>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container
          *ngTemplateOutlet="
            filesTableContent;
            context: { $implicit: document }
          "
        ></ng-container
      ></ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-list>

<ng-template #filesTableContent let-document>
  <mat-table
    *ngIf="
      document.applicantDocumentsFiles &&
      document.applicantDocumentsFiles.length > 0
    "
    [dataSource]="document.applicantDocumentsFiles"
    class="mat-elevation-z8"
    style="box-shadow: none"
  >
    <!-- Column definitions -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>{{
        'fundingProject.details.documents.fileTable.title' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="author">
      <mat-header-cell *matHeaderCellDef>{{
        'fundingProject.details.documents.fileTable.author' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.author }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="uploaded">
      <mat-header-cell *matHeaderCellDef>{{
        'fundingProject.details.documents.fileTable.uploaded' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.uploaded | date
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>{{
        'fundingProject.details.documents.fileTable.fileType' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.fileType }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell class="last-column" *matHeaderCellDef> </mat-header-cell>
      <mat-cell class="last-column" *matCellDef="let element">
        <mat-icon
          style="cursor: pointer"
          (click)="openFile(element)"
          role="button"
          tabindex="0"
          matTooltip="{{
            'fundingProject.details.request.openFile' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.request.openFile' | translate
          }}"
          class="action-icon"
          >visibility
        </mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Table rows -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</ng-template>
