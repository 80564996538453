import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { distinctUntilChanged, share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SaveFormObserverService {
  private observable!: Observable<any>;
  private observer!: Observer<any>;

  constructor() {
    this.observable = new Observable((observer: Observer<any>) => {
      this.observer = observer;
    }).pipe(share());
  }

  subscribe(callback: any) {
    return this.observable.pipe(distinctUntilChanged()).subscribe(callback);
  }

  publish(event: any) {
    if (this.observer) {
      this.observer.next(event);
    }
  }

  destroy(subscriber: Subscription) {
    subscriber.unsubscribe();
  }
}
