import { AfterViewInit, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  // Corrects number values to avoid calculation errors
  correctDecimalNumber,
  // Corrects number value from x to x,xx (e.g. 1 to 1,00)
  correctDecimalNumberString,
  DEFAULT_MAX_FRACTIONAL_DIGITS,
  DEFAULT_TRUNCATE_FRACTIONAL_DIGITS
} from '../../../../../../core/util/field-utils';

@Component({
  selector: 'oaman-form-cost-calculation-table-type',
  template: `
    <div>
      <h3 *ngIf="props.label">{{ props.label }}</h3>
      <div class="description" *ngIf="props.description">
        {{ props.description }}
      </div>
      <div class="required-fields-info" *ngIf="props.requiredFieldsInfo">
        {{
          'fundingProject.details.forms.calculationTableRequiredFieldsInfo'
            | translate
        }}
      </div>
      <ng-container *ngFor="let field of field.fieldGroup">
        <div *ngIf="field.props.headline" class="headline">
          {{ field.props.headline }}
        </div>
        <formly-field [field]="field"></formly-field>
      </ng-container>
    </div>
  `,
  styles: [
    `
      h3 {
        margin-left: -0.5rem;
      }
      .description {
        margin-left: -0.5rem;
        margin-bottom: 1rem;
      }
      .headline {
        font-weight: 500;
        padding-bottom: 1rem;
      }
      .required-fields-info {
        font-weight: bold;
        font-style: italic;
        margin: 1rem 0;
      }
    `
  ]
})
export class FormCostCalculationTableComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit
{
  ngAfterViewInit(): void {
    const fieldKey: string = this.field.key as string;
    // Iterate through table controls to subscribe to input value changes
    const fieldKeyValue = this.form.controls[fieldKey].value;
    if (fieldKeyValue) {
      Object.keys(fieldKeyValue).forEach((key) => {
        if (key !== 'fundingYears') {
          const firstKeyValue = this.form.controls[fieldKey].get(key)?.value;
          if (firstKeyValue) {
            Object.keys(firstKeyValue).forEach((key2) => {
              // Add calculation events
              if (this.props.hasSumColumn) {
                this.form.controls[fieldKey]
                  ?.get(key)
                  ?.get(key2)
                  ?.valueChanges.subscribe(() => this.sumRow(fieldKey, key));
              } else if (this.props.hasTotalSumRow) {
                const value = this.form.controls[fieldKey]?.get(key)?.get(key2)
                  ?.value;
                if (value) {
                  Object.keys(
                    this.form.controls[fieldKey]?.get(key)?.get(key2)?.value
                  ).forEach((key3) => {
                    this.form.controls[fieldKey]
                      ?.get(key)
                      ?.get(key2)
                      ?.get(key3)
                      ?.valueChanges.subscribe(() => this.totalSum(fieldKey));
                  });
                }
              }
            });
          }
        }
      });
    }
  }

  private sumRow(fieldKey: string, childKey: string) {
    // Get property (key) of row sum cell
    const rowSumCellProperty = Object.keys(
      this.form.getRawValue()[fieldKey][childKey]
    ).pop() as string;

    // Sum rows, except last row
    const sum: any = Object.values(this.model[childKey])
      .slice(0, Object.values(this.model[childKey]).length - 1)
      .reduce(
        (a: any, b: any) => correctDecimalNumber(a) + correctDecimalNumber(b),
        0
      );

    this.form.controls[fieldKey]
      .get(childKey)
      ?.get(rowSumCellProperty)
      ?.setValue(
        // Corrects sum value from x to x,xx (e.g. 1 to 1,00)
        correctDecimalNumberString(
          sum,
          DEFAULT_MAX_FRACTIONAL_DIGITS,
          DEFAULT_TRUNCATE_FRACTIONAL_DIGITS,
          true
        )
      );

    // Only call totalSum() if table has/requires a total sum row
    if (this.props.hasTotalSumRow) {
      this.totalSum(fieldKey);
    }
  }

  private totalSum(fieldKey: string) {
    // Get total object property (key) of total sum row
    const totalSumObjectProperty = Object.keys(
      this.form.getRawValue()[fieldKey]
    ).pop() as string;

    // Iterate through the keys of total object, sum table columns and set values respectively
    Object.keys(
      this.form.controls[fieldKey].get(totalSumObjectProperty)?.value
    ).forEach((key, i) => {
      let sum: any = Object.keys(this.form.controls[fieldKey].value)
        .filter((key1) => key1 !== 'fundingYears')
        .map((key2) => {
          if (this.props.hasSumColumn) {
            return correctDecimalNumber(
              Object.values(this.model[key2])[i] as number
            );
          } else {
            let subTotal: number = 0;
            Object.keys(this.form.controls[fieldKey].get(key2)?.value).forEach(
              (key3) => {
                if (this.model && this.model[key2] && this.model[key2][key3]) {
                  subTotal =
                    correctDecimalNumber(
                      Object.values(this.model[key2][key3])[i] as number
                    ) + subTotal;
                }
              }
            );
            return subTotal;
          }
        })
        .reduce((a: any, b: any) => {
          return this.props.isSubstraction
            ? correctDecimalNumber(a) - correctDecimalNumber(b)
            : correctDecimalNumber(a) + correctDecimalNumber(b);
        });
      this.form.controls[fieldKey]
        .get(totalSumObjectProperty)
        ?.get(key)
        ?.setValue(
          // Corrects sum value from x to x,xx (e.g. 1 to 1,00)
          correctDecimalNumberString(
            sum,
            DEFAULT_MAX_FRACTIONAL_DIGITS,
            DEFAULT_TRUNCATE_FRACTIONAL_DIGITS,
            true
          )
        );
    });
  }
}
