import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogData } from '../interfaces/dialog-data';

@Component({
  selector: 'oaman-error-dialog',
  templateUrl: './form-valid-info-dialog.component.html',
  styleUrls: ['./form-valid-info-dialog.component.scss']
})
export class FormValidInfoDialogComponent implements OnInit {
  event!: string | undefined;
  title!: string;
  message1!: string;
  message2_1!: string;
  message2_2!: string;
  buttonKey!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.event = this.data.event;
    this.buttonKey = this.translateService.instant(
      'dialog.formValidInfo.buttonKey'
    );
    this.title = this.translateService.instant('dialog.formValidInfo.title');
    if (this.data.event === 'SaveAndClose') {
      this.message2_1 = this.translateService.instant(
        'dialog.formValidInfo.body2.part1'
      );
      this.message2_2 = this.translateService.instant(
        'dialog.formValidInfo.body2.part2'
      );
    } else if (this.data.event === 'FormChangedToValid') {
      this.message1 = this.translateService.instant(
        'dialog.formValidInfo.body1'
      );
    }
  }
}
