<h2 mat-dialog-title>
  {{
    'fundingProject.details.authorization.dialog.addDeputy.title' | translate
  }}
</h2>

<form
  [formGroup]="deputyForm"
  class="deputy-form"
  (ngSubmit)="onSubmit()"
  novalidate
>
  <mat-dialog-content class="mat-typography">
    <p class="description">
      {{
        'fundingProject.details.authorization.dialog.addDeputy.description'
          | translate
      }}
    </p>

    <div>
      <mat-form-field appearance="fill" class="full-width deputy-field">
        <mat-label>
          {{
            'fundingProject.details.authorization.dialog.addDeputy.participantRole'
              | translate
          }}
        </mat-label>
        <mat-select
          formControlName="participantRole"
          [(value)]="selectedRoleId"
          (selectionChange)="onParticipantRoleChange()"
        >
          <mat-option *ngFor="let role of availableRoles" [value]="role.id">
            {{
              'fundingProject.details.authorization.roles.' + role.title
                | translate
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container
        *ngTemplateOutlet="
          validationMessages;
          context: { $implicit: 'participantRole' }
        "
      ></ng-container>
    </div>

    <label for="deputy-email">
      {{
        'fundingProject.details.authorization.dialog.addDeputy.email'
          | translate
      }}
      <span [style.color]="'red'"> *</span>
    </label>
    <input
      id="deputy-email"
      formControlName="email"
      class="deputy-input-field"
      type="email"
    />
    <ng-container
      *ngTemplateOutlet="validationMessages; context: { $implicit: 'email' }"
    ></ng-container>

    <div class="deputy-field">
      <label for="deputy-nameFirst">
        {{
          'fundingProject.details.authorization.dialog.addDeputy.firstName'
            | translate
        }}
        <span [style.color]="'red'"> *</span>
      </label>
      <input
        id="deputy-nameFirst"
        formControlName="nameFirst"
        class="deputy-input-field"
        type="text"
      />
      <ng-container
        *ngTemplateOutlet="
          validationMessages;
          context: { $implicit: 'nameFirst' }
        "
      ></ng-container>
    </div>

    <div class="deputy-field">
      <label for="deputy-nameLast">
        {{
          'fundingProject.details.authorization.dialog.addDeputy.lastName'
            | translate
        }}
        <span [style.color]="'red'"> *</span>
      </label>
      <input
        id="deputy-nameLast"
        formControlName="nameLast"
        class="deputy-input-field"
        type="text"
      />
      <ng-container
        *ngTemplateOutlet="
          validationMessages;
          context: { $implicit: 'nameLast' }
        "
      ></ng-container>
    </div>

    <div [style.margin-top]="'2rem'">
      {{
        'fundingProject.details.authorization.dialog.addDeputy.accessToPlatform'
          | translate
      }}
    </div>

    <div class="deputy-field">
      <div
        fxLayout="row wrap"
        fxLayout.lt-sm="column wrap"
        fxLayoutGap="1.2rem grid"
        fxLayoutAlign.lt-lg="flex-start"
        fxLayoutAlign="space-between"
      >
        <div fxFlex="50">
          <label for="deputy-validFrom">
            {{
              'fundingProject.details.authorization.dialog.addDeputy.validFrom'
                | translate
            }}
            <span [style.color]="'red'"> *</span>
          </label>
          <input
            id="deputy-validFrom"
            class="deputy-input-field"
            formControlName="validFrom"
            type="date"
          />
          <ng-container
            *ngTemplateOutlet="
              validationMessages;
              context: { $implicit: 'validFrom' }
            "
          ></ng-container>
        </div>
        <div fxFlex="50">
          <label for="deputy-validTo">
            {{
              'fundingProject.details.authorization.dialog.addDeputy.validTo'
                | translate
            }}
          </label>
          <input
            id="deputy-validTo"
            class="deputy-input-field"
            formControlName="validTo"
            type="date"
          />
          <ng-container
            *ngTemplateOutlet="
              validationMessages;
              context: { $implicit: 'validTo' }
            "
          ></ng-container>
        </div>
      </div>
    </div>

    <div class="attorney-power-section" *ngIf="isRepresentativeDeputy()">
      <div>
        <mat-form-field appearance="fill" class="full-width deputy-field">
          <mat-label>
            {{
              'fundingProject.details.authorization.dialog.addDeputy.attorneyPowerType'
                | translate
            }}
          </mat-label>
          <mat-select
            formControlName="attorneyPowerType"
            (selectionChange)="onAttorneyPowerTypeChange()"
            [(value)]="selectedAttorneyPowerType"
          >
            <mat-option
              *ngFor="let attorney of attorneyPowerTypes"
              [value]="attorney.id"
            >
              {{ attorney.title | translate }}
            </mat-option>
          </mat-select>
          <ng-container
            *ngTemplateOutlet="
              validationMessages;
              context: { $implicit: 'attorneyPowerType' }
            "
          ></ng-container>
        </mat-form-field>
      </div>

      <div
        fxLayout="row wrap"
        fxLayout.lt-sm="column wrap"
        fxLayoutGap="1.2rem grid"
        fxLayoutAlign.lt-lg="flex-start"
        fxLayoutAlign="space-between"
      >
        <div fxFlex="50">
          <label for="deputy-attorney-power-start-date">
            {{
              'fundingProject.details.authorization.dialog.addDeputy.attorneyPowerStartDate'
                | translate
            }}
            <span *ngIf="isRepresentativeDeputy()" [style.color]="'red'">
              *</span
            >
          </label>
          <input
            id="deputy-attorney-power-start-date"
            class="deputy-input-field"
            formControlName="attorneyPowerStartDate"
            type="date"
          />
          <ng-container
            *ngTemplateOutlet="
              validationMessages;
              context: { $implicit: 'attorneyPowerStartDate' }
            "
          ></ng-container>
        </div>
        <div fxFlex="50" *ngIf="isAttorneyPowerEndVisible()">
          <label for="deputy-attorney-power-end-date">
            {{
              'fundingProject.details.authorization.dialog.addDeputy.attorneyPowerEndDate'
                | translate
            }}
            <span *ngIf="isAttorneyPowerEndRequired()" [style.color]="'red'">
              *</span
            >
          </label>
          <input
            id="deputy-attorney-power-end-date"
            class="deputy-input-field"
            formControlName="attorneyPowerEndDate"
            type="date"
          />
          <ng-container
            *ngTemplateOutlet="
              validationMessages;
              context: { $implicit: 'attorneyPowerEndDate' }
            "
          ></ng-container>
        </div>
      </div>

      <div>
        <mat-form-field appearance="fill" class="full-width deputy-field">
          <mat-label>
            {{
              'fundingProject.details.authorization.dialog.addDeputy.attorneyPowerAvailability'
                | translate
            }}
          </mat-label>
          <mat-select
            formControlName="attorneyPowerAvailability"
            [(value)]="selectedAttorneyPowerAvailability"
          >
            <mat-option
              *ngFor="let attorneyAvailability of attorneyPowerAvailabilities"
              [value]="attorneyAvailability.id"
            >
              {{ attorneyAvailability.title | translate }}
            </mat-option>
          </mat-select>
          <ng-container
            *ngTemplateOutlet="
              validationMessages;
              context: { $implicit: 'attorneyPowerAvailability' }
            "
          ></ng-container>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <oaman-button
      [mat-dialog-close]="false"
      [style.margin-right]="'1rem'"
      type="reset"
    >
      {{ 'fundingProject.details.authorization.dialog.cancelBtn' | translate }}
    </oaman-button>
    <oaman-button
      color="primary"
      [disabled]="!deputyForm.valid || this.activeProgressBar"
      type="submit"
    >
      {{
        'fundingProject.details.authorization.dialog.addDeputy.submit'
          | translate
      }}
    </oaman-button>
  </mat-dialog-actions>

  <mat-progress-bar
    mode="determinate"
    *ngIf="activeProgressBar"
    value="20"
    class="full-width"
  ></mat-progress-bar>
</form>

<ng-template #validationMessages let-name>
  <div
    class="validation-msg"
    *ngIf="
      deputyForm.controls[name].invalid &&
      (deputyForm.get(name)?.dirty || deputyForm.get(name)?.touched)
    "
  >
    <div *ngIf="deputyForm.get(name)?.errors?.required">
      {{ 'validationErrorMessages.required' | translate }}
    </div>
    <div *ngIf="deputyForm.get(name)?.errors?.minlength">
      {{ 'validationErrorMessages.minLength' | translate }}
    </div>
    <div
      *ngIf="
        name !== 'validFrom' &&
        name !== 'validTo' &&
        name !== 'attorneyPowerStartDate' &&
        name !== 'attorneyPowerEndDate'
      "
    >
      <div *ngIf="isMaxLengthError(name)">
        {{
          'validationErrorMessages.maxLength' | translate: { lengthVar: 255 }
        }}
      </div>
    </div>
    <div *ngIf="deputyForm.get(name)?.errors?.email">
      {{ 'validationErrorMessages.email' | translate }}
    </div>
    <div *ngIf="deputyForm.get(name)?.errors?.invalidDate">
      {{
        'fundingProject.details.authorization.dialog.validationErrorMessages.invalidDate'
          | translate
      }}
    </div>
    <div *ngIf="deputyForm.get(name)?.errors?.invalidDatePeriod">
      {{ getDatePeriodErrorMsg(name) | translate }}
    </div>
    <div
      *ngIf="
        name === 'validFrom' ||
        name === 'validTo' ||
        name === 'attorneyPowerStartDate' ||
        name === 'attorneyPowerEndDate'
      "
    >
      <div *ngIf="isMaxLengthError(name)">
        {{ 'validationErrorMessages.maxLength' | translate: { lengthVar: 10 } }}
      </div>
    </div>
  </div>
</ng-template>
