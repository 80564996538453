<oaman-detail-actions [fundingProject]="fundingProject"></oaman-detail-actions>
<ng-container *ngIf="groupedHistoryEvents.size > 0; else noData">
  <oaman-timeline
    [groupedHistoryEvents]="groupedHistoryEvents"
  ></oaman-timeline>
</ng-container>
<ng-template #noData>
  <div [style.margin-top]="'2rem'">
    <oaman-alert>
      <span>{{ 'common.noData' | translate }}</span>
    </oaman-alert>
  </div>
</ng-template>
