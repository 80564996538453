import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImpressumComponent } from './navigation/footer/components/impressum/impressum.component';
import { ContactComponent } from './navigation/footer/components/contact/contact.component';
import { PrivacyStatementComponent } from './navigation/footer/components/privacy-statement/privacy-statement.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './core/authentication/guards/auth-guard';
import { AboutComponent } from './navigation/navbar/components/menu/components/about/about.component';
import { ProfileComponent } from './navigation/navbar/components/menu/components/profile/profile.component';
import { NotFoundErrorComponent } from 'oaman-components';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'funding-projects',
    loadChildren: () =>
      import('./funding-project/funding-project.module').then(
        (m) => m.FundingProjectModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'funding-guidelines/:id/funding-projects',
    loadChildren: () =>
      import('./funding-project/funding-project.module').then(
        (m) => m.FundingProjectModule
      ),
    canActivate: [AuthGuard]
  },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'privacy', component: PrivacyStatementComponent },
  { path: 'login', component: LoginComponent },
  { path: 'about', component: AboutComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: '**',
    component: NotFoundErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
