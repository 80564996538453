import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormToPdfGenerator } from '../form-to-pdf-generator';
import { FieldGroupFormatter } from './field-group-formatter';

/**
 * Custom field group formatter for fields groups of custom type 'ExplanationAttachmentField'.
 */
export class FormExplanationAttachmentFieldGroupFormatter extends FieldGroupFormatter {
  private pdfGenerator: FormToPdfGenerator | undefined;
  private parentField: FormlyFieldConfig | undefined;
  private fieldGroup: FormlyFieldConfig[] | undefined;
  private breadcrumbKey: string | number | string[] | undefined;

  generateFormatedFieldGroupTag(
    pdfGenerator: FormToPdfGenerator,
    parentField: FormlyFieldConfig | undefined,
    fieldGroup: FormlyFieldConfig[] | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ): string {
    let html = '';
    if (pdfGenerator && fieldGroup) {
      this.pdfGenerator = pdfGenerator;
      this.parentField = parentField;
      this.fieldGroup = fieldGroup;
      this.breadcrumbKey = breadcrumbKey;

      html += '<div class="sub-group-wrapper">';
      html += '<h4>Der Antragsteller/Die Antragstellerin erklärt:</h4>';

      const field = this.pdfGenerator.findField(
        parentField ? parentField : fieldGroup,
        breadcrumbKey?.toString() + '.subsidyRequestedForVAT1'
      );
      if (field !== undefined) {
        this.pdfGenerator.getFieldValue(field, breadcrumbKey, undefined);
      }

      // Points
      html += '<table>';
      html += this.generatePointElement(1, this.descriptionPoint1());
      html += this.generatePointElement(2, this.descriptionPoint2());
      html += this.generatePointElement(3, this.descriptionPoint3());
      html += this.generatePointElement(4, this.descriptionPoint4());
      html += this.generatePointElement(5, this.descriptionPoint5());
      html += this.generatePointElement(6, this.descriptionPoint6());
      html += this.generatePointElement(7, this.descriptionPoint7());
      html += this.generatePointElement(8, this.descriptionPoint8());
      html += this.generatePointElement(9, this.descriptionPoint9());
      html += this.generatePointElement(10, this.descriptionPoint10());
      html += '</table>';

      html += '</div>';
    }
    return html;
  }

  /**
   * Get the value for the given/current field by breadcrump key chai.
   *
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   * @param defval the default value
   *
   * @returns the value of the field
   */
  private getFieldValue(
    breakcrumbKey: string,
    defval: string | undefined
  ): string {
    if (this.pdfGenerator) {
      const foundField = this.pdfGenerator.findField(
        this.parentField ? this.parentField : this.fieldGroup,
        this.breadcrumbKey?.toString() + '.' + breakcrumbKey
      );
      if (foundField !== undefined) {
        return this.pdfGenerator.getFieldValue(
          foundField,
          this.breadcrumbKey,
          defval
        );
      }
    }
    return '';
  }

  /**
   * Get a point value tag as string.
   *
   * @param point the number of the point
   * @param description the description of the point
   *
   * @returns the point value tag string
   */
  private generatePointElement(point: number, description: string): string {
    return (
      '<tr><td style="width: 5%; border: none;">' +
      point +
      '.</td><td style="width: 95%; border: none;">' +
      description +
      '</td></tr>'
    );
  }

  /**
   * Get a input element tag as string.
   *
   * @param label the label of the input element
   * @param text the text of the input element
   *
   * @returns the input element tag string
   */
  private generateInputElement(label: string, text: string) {
    return (
      '<div class="input">' +
      '<table style="width: 100%;">' +
      '<tr>' +
      '<td style="width: 100%; margin-bottom: 0px;">' +
      '<table style="width: 100%;">' +
      '<tr>' +
      '<td style="border: none; font-size: 10px; margin-bottom: 0px;">' +
      '<div class="label">' +
      label +
      '</div>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="width: 100%; border: none; margin-left: 15px; margin-bottom: 0px; font-weight: bold;">' +
      '<div class="value">' +
      text +
      '</div>' +
      '</td>' +
      '</tr>' +
      '</table>' +
      '</td>' +
      '</tr>' +
      '</table>' +
      '</div>'
    );
  }

  /**
   * Get the description of point 1.
   *
   * @returns the description point 1
   */
  private descriptionPoint1(): string {
    return (
      'Mit dem Vorhaben ist noch nicht begonnen worden und wird ' +
      'auch vor Bekanntgabe des Zuwendungsbescheides ' +
      'nicht begonnen (Als Vorhabenbeginn ist grundsätzlich der Abschluss eines der ' +
      'Ausführung zuzurechnenden Lieferungs- oder Leistungsvertrages zu werten.'
    );
  }

  /**
   * Get the description of point 2.
   *
   * @returns the description point 2
   */
  private descriptionPoint2(): string {
    return (
      'Sofern mit der Vorhabendurchführung die Schaffung von Arbeitsplätzen ' +
      'verbunden ist, wird eine geschlechterneutrale Verteilung ' +
      'sichergestellt. Sollte dies nicht möglich sein, so erfolgt hierzu ' +
      'die Vorlage einer begründenden Unterlage.'
    );
  }

  /**
   * Get the description of point 3.
   *
   * @returns the description point 3
   */
  private descriptionPoint3(): string {
    return (
      'Bei der Vorhabendurchführung werden die Belange der Barrierefreiheit ' +
      'berücksichtigt. Ausnahmen sind gesondert zu begründen.'
    );
  }

  /**
   * Get the description of point 4.
   *
   * @returns the description point 4
   */
  private descriptionPoint4(): string {
    const description =
      'Wie im Abschnitt Finanzierungsplan dargestellt, wird für die in Ansatz gebrachte Umsatzsteuer (MwSt.) ' +
      'eine Förderung beantragt. Da kein Anspruch auf die Vorsteuerabzugsberechtigung nach § 15 UStG besteht, ' +
      'wird hierzu die beigefügte Erklärung (Erklärung des/der Begünstigten) abgegeben.';
    const checked = this.getFieldValue('subsidyRequestedForVAT1', undefined);
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get the description of point 5.
   *
   * @returns the description point 5
   */
  private descriptionPoint5(): string {
    const description =
      'Wie im Abschnitt Finanzierungsplan dargestellt, wird für die in Ansatz gebrachte Umsatzsteuer (MwSt.) ' +
      'eine Förderung beantragt. Als Teilnehmergemeinschaft nach dem FlurbG wird hierzu die beigefügte ' +
      'Erklärung (Erklärung der/des Begünstigten) abgegeben und als Anlage beigefügt. Die Vorlage einer ' +
      'Bescheinigung eines unabhängigen Dritten ist daher nicht erforderlich.';
    const checked = this.getFieldValue('subsidyRequestedForVAT2', undefined);
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get the description of point 6.
   *
   * @returns the description point 6
   */
  private descriptionPoint6(): string {
    return (
      'Die Vorhabendurchführung erfolgt nicht zur Umsetzung einer ' +
      'gesetzlichen Verpflichtung.'
    );
  }

  /**
   * Get the description of point 7.
   *
   * @returns the description point 7
   */
  private descriptionPoint7(): string {
    const cb1Checked = this.getFieldValue('accordingToTasksDefined', undefined);
    const inputCb1 = this.getFieldValue('accordingToTasksDefinedInput', '-');
    const cb2Checked = this.getFieldValue('TransferOfTasks', undefined);
    const inputCb2 = this.getFieldValue('TransferOfTasksInput', '-');
    const cb3Checked = this.getFieldValue('noTransferOfTasks', undefined);
    const inputCb3 = this.getFieldValue('noTransferOfTasksInput', '-');
    return (
      '<p>Schriftliche Bestätigung über die Aufgabenzuordnung nach dem ' +
      'Niedersächsischen Kommunalverfassungsgesetz (NKomVG) (nur ' +
      'auszufüllen, wenn Antragsteller eine Samtgemeinde oder eine ' +
      'Mitgliedsgemeinde ist)</p>' +
      '<p>Das unter Nr. 3 dieses Antrages beschriebene Vorhaben liegt</p>' +
      this.descriptionPoint7Cb1(cb1Checked) +
      (cb1Checked ? this.generateInputElement('', inputCb1) : '') +
      this.descriptionPoint7Cb2(cb2Checked) +
      (cb2Checked ? this.generateInputElement('', inputCb2) : '') +
      this.descriptionPoint7Cb3(cb3Checked) +
      (cb3Checked ? this.generateInputElement('', inputCb3) : '')
    );
  }

  /**
   * Get checkbox tag 1 for the description 7.
   *
   * @returns the checkbox tag as string
   */
  private descriptionPoint7Cb1(checked: string): string {
    const description =
      'nach den in § 98 Abs. 1 Satz 1 bzw. § 98 Abs. 2 NKomVG ' +
      'definierten Aufgaben in der Zuständigkeit der Samtgemeinde:';
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get checkbox tag 2 for the description 7.
   *
   * @returns the checkbox tag as string
   */
  private descriptionPoint7Cb2(checked: string): string {
    const description =
      'aufgrund einer Aufgabenübertragung nach § 98 Abs. 1 Satz 2 ' +
      'NKomVG in der Zuständigkeit der Samtgemeinde:';
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get checkbox tag 3 for the description 7.
   *
   * @returns the checkbox tag as string
   */
  private descriptionPoint7Cb3(checked: string): string {
    const description =
      'weil keine Aufgabenübertragung stattgefunden hat, ' +
      'in der Zuständigkeit der Mitgliedsgemeinde:';
    if (this.pdfGenerator) {
      return this.pdfGenerator.getCheckboxTableTagByValue(checked, description);
    }
    return description;
  }

  /**
   * Get the description of point 8.
   *
   * @returns the description point 8
   */
  private descriptionPoint8(): string {
    return (
      'Ich/Wir bin/sind Eigentümer der zur Förderung beantragten Anlage/n. ' +
      'Soweit ich/wir nicht Eigentümer bin/sind, habe/n ich/wir diesem ' +
      'Antrag eine Einverständniserklärung des Eigentümers über die ' +
      'Durchführung des Vorhabens und die Duldung einer Zweckbindungsfrist ' +
      'beigefügt.'
    );
  }

  /**
   * Get the description of point 9.
   *
   * @returns the description point 9
   */
  private descriptionPoint9(): string {
    return (
      'Das als Anlage beigefügte Informationsblatt nach der ' +
      'Datenschutz-Grundverordnung habe ich/wir zur Kenntnis genommen.'
    );
  }

  /**
   * Get the description of point 10.
   *
   * @returns the description point 10
   */
  private descriptionPoint10(): string {
    return (
      'Das Vorhaben ist mit den Planungen für die Ver- und Entsorgung ' +
      'abgestimmt.'
    );
  }
}
