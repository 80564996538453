import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'oaman-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent {
  @Input() label = '';
  @Input() options: { key: string; value: string }[] = [];
  @Input() activeSelection: any;
  @Output() filterChanged = new EventEmitter<string>();

  changeFilter(value: string) {
    this.filterChanged.emit(value);
  }
}
