import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'oaman-action-sheet',
  templateUrl: './action-sheet.component.html',
  styleUrls: ['./action-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionSheetComponent {
  @Input() icon = '';
  @Input() text = '';
}
