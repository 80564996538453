<div class="page-wrapper">
  <h1 class="heading">{{ fundingProject.title }}</h1>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      mat-tab-link
      *ngFor="let link of links"
      (click)="activeLink = link"
      [routerLink]="link.link"
      [title]="link.title"
      routerLinkActive
      #rla="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: false }"
      [active]="rla.isActive"
      class="detail-tab"
    >
      {{ 'fundingProject.details.links.' + link.name | translate }}
    </a>
    <div class="spacer"></div>
  </nav>

  <mat-tab-nav-panel #tabPanel class="tabs-content">
    <router-outlet></router-outlet
  ></mat-tab-nav-panel>
</div>
