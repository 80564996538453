import { DocumentProvideDialogComponent } from './document-provide-dialog/document-provide-dialog.component';
import { ButtonModule } from '../button/button.module';
import { DialogComponent } from './dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MaterialModule } from '../material/material.module';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { AssignClerkDialogComponent } from './assign-clerk-dialog/assign-clerk-dialog.component';
import { AvatarModule } from '../avatar/avatar.module';
import { DocumentCreateDialogComponent } from './document-create-dialog/document-create-dialog.component';
import { DocumentSubmitDialogComponent } from './document-submit-dialog/document-submit-dialog.component';
import { DocumentRequestDialogComponent } from './document-request-dialog/document-request-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestCreateDialogComponent } from './request-create-dialog/request-create-dialog.component';
import { DocumentInfoDialogComponent } from './document-info-dialog/document-info-dialog.component';
import { InquiryCreateDialogComponent } from './inquiry-create-dialog/inquiry-create-dialog.component';
import { AnswerDialogComponent } from './answer-dialog/answer-dialog.component';
import { HttpErrorDialogComponent } from './http-error-dialog/http-error-dialog.component';
import { ProjectWithdrawalDialogComponent } from './project-withdrawal-dialog/project-withdrawal-dialog.component';
import { AddDeputyDialogComponent } from './deputy/add-deputy-dialog/add-deputy-dialog.component';
import { InactivateDeputyDialogComponent } from './deputy/inactivate-deputy-dialog/inactivate-deputy-dialog.component';
import { LowTrustLevelDialogComponent } from './low-trust-level-dialog/low-trust-level-dialog.component';
import { ProjectStateChangeDialogComponent } from './project-state-change-dialog/project-state-change-dialog.component';
import { OrderByPipe } from '../funding-project/funding-project-details/tabs/documents/pipes/order-by.pipe';
import { ProjectUpdateDialogComponent } from './project-update-dialog/project-update-dialog.component';
import { SearchModule } from '../funding-project/search/search.module';
import { DataProtectionConsentDialogComponent } from './data-protection-consent-dialog/data-protection-consent-dialog.component';
import { FormValidInfoDialogComponent } from './form-valid-info-dialog/form-valid-info-dialog.component';

@NgModule({
  declarations: [
    DialogComponent,
    UploadDialogComponent,
    ErrorDialogComponent,
    DeleteDialogComponent,
    AssignClerkDialogComponent,
    DocumentCreateDialogComponent,
    DocumentSubmitDialogComponent,
    DocumentRequestDialogComponent,
    DocumentInfoDialogComponent,
    DocumentProvideDialogComponent,
    RequestCreateDialogComponent,
    InquiryCreateDialogComponent,
    AnswerDialogComponent,
    HttpErrorDialogComponent,
    FormValidInfoDialogComponent,
    ProjectWithdrawalDialogComponent,
    AddDeputyDialogComponent,
    InactivateDeputyDialogComponent,
    LowTrustLevelDialogComponent,
    ProjectStateChangeDialogComponent,
    OrderByPipe,
    ProjectUpdateDialogComponent,
    DataProtectionConsentDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexModule,
    TranslateModule,
    ButtonModule,
    AvatarModule,
    ReactiveFormsModule,
    FormsModule,
    SearchModule
  ],
  exports: []
})
export class DialogModule {}
