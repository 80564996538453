import { AbstractControl } from '@angular/forms';

export class FormCustomValidators {
  static autocompleteInputValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const uuidRegexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (!uuidRegexExp.test(control.value)) {
      return { forbiddenString: { value: control.value } };
    }
    return null;
  }
}
