import { FieldWrapper } from '@ngx-formly/core';
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'oaman-form-field-wrapper',
  template: `
    <div class="field-wrapper">
      <div *ngIf="props.description" class="description">
        {{ props.description }}
      </div>

      <div *ngIf="props['focused'] && props['helpText']" class="help-text">
        <oaman-help-text [helpText]="props['helpText']"></oaman-help-text>
      </div>

      <div [style.margin-bottom]="'.2rem'">
        <label [attr.for]="id" *ngIf="props.label">
          <ng-container *ngIf="props['context'] !== 'checkbox'">
            {{ props.label }}
            <ng-container
              *ngIf="props.required && props.hideRequiredMarker !== true"
            >
              <span class="asterisk">*</span>
            </ng-container>
          </ng-container>
        </label>
      </div>
      <div fxLayout="row wrap" class="field">
        <div fxFlex="100" fxFlex.xs="100" [style.flex-direction]="'column'">
          <ng-template #fieldComponent></ng-template>
          <div *ngIf="showError" class="error-msg">
            <ng-container [ngSwitch]="props.type">
              <span *ngSwitchCase="'date'">
                {{
                  'fundingProject.details.forms.errors.date' | translate
                }}</span
              >
              <span *ngSwitchCase="'number'">
                <span
                  *ngIf="field.type === 'input' && !props.customValidationMsg"
                >
                  {{
                    'fundingProject.details.forms.errors.generalError'
                      | translate
                  }}
                </span>
                <span
                  *ngIf="field.type !== 'input' || props.customValidationMsg"
                >
                  <formly-validation-message [field]="field">
                  </formly-validation-message>
                </span>
              </span>
              <span *ngSwitchDefault>
                <formly-validation-message [field]="field">
                </formly-validation-message>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .field-wrapper {
        margin: 1rem 0;
        position: relative;
      }
      .description {
        margin-left: -0.5rem;
        font-size: 16px;
      }
      .error-msg {
        margin-top: 0.2rem;
        color: #ff002d;
      }
      .field {
        position: relative;
      }
      .help-text {
        position: absolute;
        display: block;
        z-index: 1;
        width: 42%;
        top: 10px;
        left: 100%;
        margin-top: 1px;
        margin-left: 4rem;
      }
    `
  ]
})
export class FormFieldWrapperComponent
  extends FieldWrapper
  implements AfterViewInit
{
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }
  ngAfterViewInit() {
    if (!this.formControl.valid && this.formControl.value) {
      this.formControl.markAllAsTouched();
      this.formControl.markAsDirty();
      this.changeDetectorRef.detectChanges();
    }
  }
}
