import {
  Component,
  EventEmitter,
  ViewEncapsulation,
  Input,
  Output
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'oaman-search',
  templateUrl: './search.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent {
  @Input() searchLabel = '';
  @Input() style = '';
  @Input()
  searchSelect$ = new Observable<string>();
  @Output()
  searchQuery = new EventEmitter<string>();
  private searchChanged: Subject<string> = new Subject();
  searchInput: string = '';

  onSearchInput(input: string) {
    if (this.searchChanged.observers.length === 0) {
      this.searchChanged
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((searchQuery) => this.triggerSearch(searchQuery));
    }
    this.searchChanged.next(input);
  }
  triggerSearch(searchQuery: string) {
    this.searchQuery.emit(searchQuery);
  }

  clearSearchField() {
    this.searchInput = '';
  }

  public sum(a: number, b: number) {
    return a + b;
  }
}
