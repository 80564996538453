import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDateValidator(startDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null) {
      return null;
    }
    const invalid =
      new Date(startDate).valueOf() > new Date(control.value).valueOf();
    return invalid ? { invalidDate: { value: control.value } } : null;
  };
}
