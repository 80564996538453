<div>
  <mat-accordion class="login-accordion">
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[0] = true"
      (closed)="panelOpenState[0] = false"
      hideToggle
    >
      <mat-expansion-panel-header
        [collapsedHeight]="panelHeaderHeight"
        [expandedHeight]="panelHeaderHeight"
      >
        <mat-panel-title class="number-box">1</mat-panel-title>
        <mat-panel-title class="headline">
          Was ist die BundID?
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[0]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[0]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Die BundID bietet Ihnen ein zentrales Konto zur Identifizierung für
          alle Ihre Online-Anträge (z. B. mit einem Online-Ausweis). Sie können
          das Formular Ihres Online-Antrags durch das Hinterlegen Ihrer
          persönlichen Daten vorausfüllen lassen. Das spart Zeit, ist sicher und
          bewahrt Sie vor Tippfehlern.
        </p>
        <p>
          Sie erhalten alle Bescheide und Nachrichten in Bezug auf den
          Online-Antrag bequem in Ihr elektronisches Postfach in Ihrem
          BundID-Konto.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[1] = true"
      (closed)="panelOpenState[1] = false"
      hideToggle
    >
      <mat-expansion-panel-header
        [collapsedHeight]="panelHeaderHeight"
        [expandedHeight]="panelHeaderHeight"
      >
        <mat-panel-title class="number-box">2</mat-panel-title>
        <mat-panel-title class="headline">
          Wie nutze ich die BundID?
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[1]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[1]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p class="info-list-title">
          Die BundID hat im Wesentlichen zwei wichtige Funktionen.
        </p>
        <ul class="info-list">
          <li>Die Identifikation bei Online-Anträgen</li>
          <li>
            Ähnlich wie im Bürgerbüro müssen Sie sich identifizieren, um einen
            Online-Antrag stellen zu können. Dafür müssen Sie Ihren
            Personalausweis vorzeigen. In der digitalen Verwaltung geschieht
            dies durch die BundID. Darin sind Ihre Daten hinterlegt, die Sie zur
            Stellung des Online-Antrags benötigen. Diese können bspw. durch den
            Online-Ausweis (elektronischer Personalausweis) übertragen werden.
          </li>
          <li>Ein Postfach für den Empfang von Bescheiden und Nachrichten</li>
          <li>
            Die BundID hat ein eigenes Postfach. In diesem Postfach können
            Bescheide und Schreiben aus gestellten Online-Anträgen empfangen
            werden. Sie werden über Eingänge in Ihrem BundID-Postfach über Ihre
            hinterlegte E-Mail-Adresse benachrichtigt.
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[2] = true"
      (closed)="panelOpenState[2] = false"
      hideToggle
    >
      <mat-expansion-panel-header
        [collapsedHeight]="panelHeaderHeight"
        [expandedHeight]="panelHeaderHeight"
      >
        <mat-panel-title class="number-box">3</mat-panel-title>
        <mat-panel-title class="headline">
          Warum sollte ich ein BundID-Konto erstellen?
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[2]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[2]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Um sich für die verschiedenen Online-Anträge digital identifizieren zu
          können, ist die Konto-Erstellung einer BundID notwendig. Mit Ihrer
          BundID können Sie Online-Anträge stellen und das elektronische
          Postfach verwenden. Ihre Daten werden in Ihrem Konto hinterlegt und
          bei erneutem Stellen eines Online-Antrags können die bereits
          hinterlegten Daten an den jeweiligen Onlinedienst weitergeleitet
          werden.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[3] = true"
      (closed)="panelOpenState[3] = false"
      hideToggle
    >
      <mat-expansion-panel-header
        [collapsedHeight]="panelHeaderHeight"
        [expandedHeight]="panelHeaderHeight"
      >
        <mat-panel-title class="number-box">4</mat-panel-title>
        <mat-panel-title class="headline">
          Was sind Vertrauensniveaus, Identifikationsmittel und Zugangsarten?
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[3]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[3]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Je nachdem welchen Online-Antrag Sie stellen möchten, erfordert es ein
          bestimmtes Vertrauensniveau. Ein Vertrauensniveau gibt an, welchen
          Grad der Vertrauenswürdigkeit ein Identifikationsmittel hat. Je höher
          das Vertrauensniveau, desto höher die Vertrauenswürdigkeit.
        </p>
        <p class="info-list-title">Ein Identifikationsmittel:</p>
        <ul class="info-list">
          <li>
            ist die Art mit der ihre personenbezogenen Daten ermittelt werden.
          </li>
          <li>hat ein bestimmtes Vertrauensniveau.</li>
          <li>stellt eine Zugangsart zur BundID dar.</li>
        </ul>
        <p>
          Als Grundlage dienen die Vorgaben der europäischen Verordnung über
          elektronische Identifizierung und Vertrauensdienste. Mehr
          Informationen finden Sie unter:
          <a
            href="https://www.bsi.bund.de/DE/Themen/Oeffentliche-Verwaltung/eIDAS-Verordnung/eidas-verordnung_node.html"
            rel="noopener noreferrer"
            target="_blank"
            >eIDAS-VO</a
          >
        </p>
        <p class="info-list-title">
          Es gibt drei verschiedene Vertrauensniveaus und die dazugehörigen
          Identifikationsmittel:
        </p>
        <ul class="info-list">
          <li>
            <strong>„Basisregistrierung“</strong> Dieses Vertrauensniveau
            erreichen Sie mit der Benutzung der Zugangsart „Benutzername &
            Passwort“. Diese Zugangsart ist verpflichtend für die Nutzung der
            BundID.
          </li>
          <li>
            <strong>Vertrauensniveau „substanziell“</strong> Dieses
            Vertrauensniveau erreichen Sie mit der Benutzung der Zugangsart
            „ELSTER-Zertifikat“ und einzelnen „EU Identitäten“.
          </li>
          <li>
            <strong>Vertrauensniveau „hoch“</strong> Dieses Vertrauensniveau
            erreichen Sie mit der Benutzung der Zugangsart „Online-Ausweis“
            sowie einzelnen „EU Identitäten“. Unter die Kategorie
            „Online-Ausweis“ fallen der Personalausweis mit Onlinefunktion, die
            Smart-eID, der elektronische Aufenthaltstitel und die
            Unionsbürgerkarte.
          </li>
        </ul>
        <p>
          Für die Anmeldung beim Online Antragsmanagement ist eine Anmeldung im
          Vertrauensniveau "hoch" erforderlich.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[4] = true"
      (closed)="panelOpenState[4] = false"
      hideToggle
    >
      <mat-expansion-panel-header
        [collapsedHeight]="panelHeaderHeight"
        [expandedHeight]="panelHeaderHeight"
      >
        <mat-panel-title class="number-box">5</mat-panel-title>
        <mat-panel-title class="headline">
          Warum wird die Option „Online-Ausweis“ empfohlen?
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[4]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[4]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Ein Online-Ausweis (z. B. Ihr Personalausweis) ist für jeden
          Online-Antrag nutzbar, weil er als Identifizierungsmittel das höchste
          Vertrauensniveau erfüllt.
        </p>
        <p>
          Bei einer Anmeldung mit einem Online-Ausweis können Sie immer alle
          Bescheide und Nachrichten in Ihrem BundID-Postfach öffnen und lesen
          (mit anderen Zugängen wie z. B. Benutzername & Passwort können Sie
          eventuell manche Bescheide aus Sicherheitsgründen nicht öffnen).
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[5] = true"
      (closed)="panelOpenState[5] = false"
      hideToggle
    >
      <mat-expansion-panel-header
        [collapsedHeight]="panelHeaderHeight"
        [expandedHeight]="panelHeaderHeight"
      >
        <mat-panel-title class="number-box">6</mat-panel-title>
        <mat-panel-title class="headline">
          Wie kann ich den Online-Ausweis beantragen?
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[5]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[5]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Personalausweis mit Onlinefunktion: alle deutschen Bürgerinnen und
          Bürger mit einem nach dem 1. November 2010 ausgestellten deutschen
          Personalausweis verfügen über einen Online-Ausweis. Allerdings kann
          diese Onlinefunktion deaktiviert sein. Bitte wenden Sie sich für die
          Aktivierung an das für Sie zuständige Bürgerbüro vor Ort.
        </p>
        <p>
          Elektronischer Aufenthaltstitel: diesen Ausweis müssen Sie nicht
          beantragen. Sie erhalten Ihn von der Ausländerbehörde, wenn Sie die
          Staatsbürgerschaft aus einem Land außerhalb der EU haben und in
          Deutschland leben.
        </p>
        <p>
          Unionsbürgerkarte: Bürgerinnen und Bürger der Europäischen Union (EU)
          und Angehörige des Europäischen Wirtschaftsraums (EWR) können diesen
          Ausweis beantragen, wenn sie mindestens 16 Jahre alt sind.
        </p>
        <p>
          Weitere Informationen zu den elektronischen Identitäten finden Sie
          <a
            href="https://www.bsi.bund.de/DE/Themen/Oeffentliche-Verwaltung/Elektronische-Identitaeten/elektronische-identitaeten_node.html"
            rel="noopener noreferrer"
            target="_blank"
            >hier</a
          >.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="login-expansion-panel"
      (opened)="panelOpenState[5] = true"
      (closed)="panelOpenState[5] = false"
      hideToggle
    >
      <mat-expansion-panel-header
        [collapsedHeight]="panelHeaderHeight"
        [expandedHeight]="panelHeaderHeight"
      >
        <mat-panel-title class="number-box">7</mat-panel-title>
        <mat-panel-title class="headline">
          Weitere Informationen zum BundID Konto
        </mat-panel-title>
        <mat-icon class="icon-open" *ngIf="!panelOpenState[6]">add</mat-icon>
        <mat-icon class="icon-close" *ngIf="panelOpenState[6]">remove</mat-icon>
      </mat-expansion-panel-header>
      <div class="info-text">
        <p>
          Weitere Informationen zum BundID Konto finden Sie
          <a
            href="https://id.bund.de/de/faq"
            rel="noopener noreferrer"
            target="_blank"
            >hier</a
          >.
        </p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
