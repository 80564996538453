import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-checkbox-type',
  template: `
    <input
      [indeterminate]="false"
      type="checkbox"
      [formControl]="formControl"
      [formlyAttributes]="field"
    />
  `,
  styles: [``]
})
export class FormCheckboxComponent extends FieldType<FieldTypeConfig> {}
