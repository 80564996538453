<h2 mat-dialog-title>
  {{ 'fundingProject.details.requests.dialog.createDialog.title' | translate }}
</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <mat-dialog-content class="mat-typography">
    <div>
      <div *ngIf="data.requestTypes.length === 0; else notEmpty">
        <p>
          {{
            'fundingProject.details.requests.dialog.createDialog.emptyRequests'
              | translate
          }}
        </p>
      </div>
      <ng-template #notEmpty>
        <p>
          {{
            'fundingProject.details.requests.dialog.createDialog.bodyText'
              | translate
          }}
        </p>
        <p>
          <label for="request-type">
            <strong>{{
              'fundingProject.details.requests.dialog.createDialog.requestTypeLabel'
                | translate
            }}</strong>
            <span [style.color]="'red'"> *</span>
          </label>
          <select
            id="request-type"
            formControlName="requestType"
            [style.width]="'100%'"
            [style.height]="'2rem'"
          >
            <option value="" selected hidden>
              {{
                'fundingProject.details.requests.dialog.createDialog.requestTypePlaceholder'
                  | translate
              }}
            </option>
            <option
              *ngFor="let requestType of data.requestTypes"
              [ngValue]="requestType.resourceKey"
            >
              {{ 'fundingProject.' + requestType.resourceKey | translate }}
            </option>
          </select>
        </p>
      </ng-template>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <oaman-button
      mat-button
      [mat-dialog-close]="false"
      type="reset"
      [style.margin-right]="'1rem'"
    >
      {{
        'fundingProject.details.requests.dialog.createDialog.cancelBtn'
          | translate
      }}
    </oaman-button>
    <oaman-button
      mat-button
      color="primary"
      [disabled]="data.createdStateDisbursementRequestAlreadyExists || !form.valid"
      [style.pointer-events]="!form.valid ? 'none' : 'auto'"
      type="submit"
      matTooltip="{{ 'fundingProject.details.requests.dialog.createDialog.sendExistsRequestBeforeCreateNew' | translate }}"
      [matTooltipDisabled]="!data.createdStateDisbursementRequestAlreadyExists && form.valid"
    >
      {{
        'fundingProject.details.requests.dialog.createDialog.createBtn'
          | translate
      }}
    </oaman-button>
  </mat-dialog-actions>
</form>
