<div class="contact">
  <h1 class="heading">Wir sind für Sie da</h1>
  <p class="opening-hours">
    Montag bis Donnerstag von 9.00 bis 15.30 Uhr, Freitag von 9.00 bis 12.00 Uhr
  </p>
  <div class="box">
    <div class="image-container">
      <img src="assets/wir-sind-fuer-sie-da.png" alt="contact" />
    </div>
    <div class="info">
      <div class="text-block">
        <p class="emphasized">Bei Fragen zu Richtlinien</p>
        <p class="emphasized">(ZILE, LEADER)</p>
      </div>

      <div class="text-block">
        <p class="emphasized">
          Amt für regionale Landesentwicklung Braunschweig
        </p>
      </div>

      <div class="text-block">
        <p>
          <a class="mail" href="mailto:ARL-BS-OAMan@arl-bs.niedersachsen.de"
            >ARL-BS-OAMAN&#64;arl-bs.niedersachsen.de</a
          >
        </p>
        <p>0531/484-1002</p>
      </div>

      <div class="text-block">
        <p class="emphasized">
          Amt für regionale Landesentwicklung Leine-Weser
        </p>
      </div>

      <div class="text-block">
        <p>
          <a class="mail" href="mailto:ARL-LW-OAMAN@arl-lw.niedersachsen.de"
            >ARL-LW-OAMan&#64;arl-lw.niedersachsen.de</a
          >
        </p>
        <p>05121/6970-0</p>
      </div>

      <div class="text-block">
        <p class="emphasized">Amt für regionale Landesentwicklung Lüneburg</p>
      </div>

      <div class="text-block">
        <p>
          <a class="mail" href="mailto:ARL-LG-OAMAN@arl-lg.niedersachsen.de"
            >ARL-LG-OAMAN&#64;arl-lg.niedersachsen.de</a
          >
        </p>
        <p>04131/6972-0</p>
      </div>

      <div class="text-block">
        <p class="emphasized">Amt für regionale Landesentwicklung Weser-Ems</p>
      </div>

      <div class="text-block">
        <p>
          <a class="mail" href="mailto:ARL-WE-OAMAN@arl-we.niedersachsen.de"
            >ARL-WE-OAMAN&#64;arl-we.niedersachsen.de</a
          >
        </p>
        <p>0441/9215-400</p>
      </div>

      <div class="text-block">
        <p class="emphasized">Bei technischen Fragen</p>
      </div>

      <div class="text-block">
        <p>
          <a class="mail" href="mailto:OAMAN-Support@sla.niedersachsen.de"
            >OAMAN-Support&#64;sla.niedersachsen.de</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
