import { MatDialogRef } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-data-protection-dialog',
  templateUrl: './data-protection-dialog.component.html',
  styleUrls: ['./data-protection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataProtectionDialogComponent {
  dataProtectionForm!: UntypedFormGroup;
  activeProgressBar = false; // used to display the progress bar during the backend call

  constructor(public dialogRef: MatDialogRef<DataProtectionDialogComponent>) {
    this.dataProtectionForm = new UntypedFormGroup({
      agreement: new UntypedFormControl(undefined, [Validators.required]),
      deliveryAgreement: new UntypedFormControl(undefined, [
        Validators.required
      ])
    });
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
