import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfiguration } from '../../../../core/interfaces/app-configuration';
import { APP_CONFIG } from '../../../../core/util/app-config.token';
import { DeputyInfo } from '../../../../core/interfaces/deputy-info';
import { DetailAction } from '../../interfaces/detail-actions';
import { FundingProject } from '../../../../core/interfaces/funding-project';
import { DialogService } from '../../../../dialog/services/dialog.service';
import { ProjectActionService } from '../../services/project-action.service';
import {
  DeputyProjectActionData,
  StateChangeProjectActionData
} from '../../public-api';
import { FundingProjectDataService } from '../../../public-api';
import { ApplicationContext } from '../../../../core/constants/application-context.constants';
import { isValidDeputy } from '../authorization/utils/deputy-utils';
import { BaseComponent } from '../../../../core/base.component';
import { UserObjectObservableService } from '../../../../core/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizationComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  dataSource = new MatTableDataSource<DeputyInfo>();
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('matMenuTrigger')
  matMenuTrigger!: MatMenuTrigger;
  fundingProject!: FundingProject;
  context = '';
  currentDate = new Date();
  authActions: DetailAction[] = [];
  displayedColumns: string[] = [
    'name',
    'participantRole',
    'validFrom',
    'validTo',
    'attorneyPowerStartDate'
  ];
  inactivateDeputyActionKey = 'endValidation';
  isChecked = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    private route: ActivatedRoute,
    public changeDetector: ChangeDetectorRef,
    public dialogService: DialogService,
    public projectActionService: ProjectActionService,
    private router: Router,
    private fundingProjectData: FundingProjectDataService,
    public userObjectObservableService: UserObjectObservableService,
    private translate: TranslateService
  ) {
    super(userObjectObservableService);
    // we must disable the reusability for this route
    // to be able to refresh the deputies table after inserting a new deputy
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.context = this.appConfig.context;

    if (this.context === ApplicationContext.ENROLL) {
      this.authActions.push({
        name: 'addDeputy',
        icon: 'person_add',
        event: 'addDeputyEvt',
        requiresProjectAuthority: true
      });
      this.displayedColumns.push('action');
    }

    this.isChecked = fundingProjectData.displayActiveDeputiesOnly;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Execute filter on init view!
    this.onFilter();
  }

  ngOnInit(): void {
    if (this.route.parent) {
      this.route.parent.data.subscribe((data) => {
        this.fundingProject = data['resolvedData'];
        this.initTable(this.fundingProject?.deputies || []);
      });
    }
  }

  initTable(data: DeputyInfo[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.user.nameLast + ', ' + item.user.nameFirst;
        case 'participantRole':
          return item.participantRole;
        case 'validFrom':
          return item.validFrom;
        case 'validTo':
          return item.validTo ? item.validTo : '';
        case 'attorneyPowerStartDate':
          return item.attorneyPowerStartDate;
        default: {
          console.error('error in sorting data accessor');
          return item.id;
        }
      }
    };
    // initializes the paginator and sort again after the content has changed
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.changeDetector.detectChanges();

    // Add filterPredicate for expired deputies
    this.dataSource.filterPredicate = function (record, isChecked) {
      if (isChecked === 'false') {
        return isValidDeputy(record);
      } else return true;
    };
  }

  isButtonDisabled(element: DeputyInfo, context: string): boolean {
    if (
      context === this.inactivateDeputyActionKey &&
      this.currentUser.id !== element.user.id
    ) {
      // as the disabled attribute disables the button if set to true
      // we have to invert the logic and return false if we want to enable it
      return !(
        this.fundingProjectData.hasProjectAuthority(context) &&
        isValidDeputy(element)
      );
    } else {
      return true;
    }
  }

  setEndValidationTooltip(element: DeputyInfo, context: string): string {
    return this.isButtonDisabled(element, context)
      ? this.translate.instant(
          'fundingProject.details.authorization.table.dropdown.endValidationTooltip'
        )
      : '';
  }

  onKeyupEnter() {
    this.matMenuTrigger.openMenu();
  }

  openDialog(event: string) {
    if (event === 'addDeputyEvt') {
      this.dialogService
        .openAddDeputyDialog(
          this.fundingProject as StateChangeProjectActionData
        )
        .subscribe((result) => {
          // if result is not false, it contains the data for the new deputy
          if (result?.data) {
            this.projectActionService.setAction('addDeputy', {
              deputies: this.fundingProject.deputies,
              newDeputy: result.data,
              title: this.fundingProject.title,
              fundingProjectId: this.fundingProject.id,
              fundingGuidelineId: this.fundingProject.fundingGuideline.id
            } as DeputyProjectActionData);
          }
        });
    } else if (event === 'onFilter') {
      this.onFilter();
    }
  }

  onFilter() {
    this.dataSource.filter = this.isChecked + '';
    this.fundingProjectData.displayActiveDeputiesOnly = this.isChecked;
  }

  endValidation(deputy: DeputyInfo): void {
    this.dialogService
      .openDeputyInactivationDialog(
        deputy.user.nameLast + ' ' + deputy.user.nameFirst
      )
      .subscribe((result) => {
        // send the submit request if the user confirms
        if (result) {
          this.projectActionService.setAction('inactivateDeputy', {
            deputies: this.fundingProject.deputies,
            deputyIdToInactivate: deputy.id,
            fundingProjectId: this.fundingProject.id,
            fundingGuidelineId: this.fundingProject.fundingGuideline.id
          } as DeputyProjectActionData);
        }
        return result;
      });
  }
}
