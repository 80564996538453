<div class="sort-wrapper">
  <mat-form-field appearance="outline"
    ><mat-label>{{ 'fundingProject.sorting.title' | translate }}</mat-label>
    <mat-select
      id="sort-select"
      #sortSelectionRef
      (selectionChange)="changeSort(sortSelectionRef.value)"
      [title]="'fundingProject.sorting.sortBy' | translate"
      [value]="sortValue$ | async"
      name="sort"
    >
      <mat-option id="sort-selection-title" value="title" >
        {{ 'fundingProject.fields.title' | translate }}
      </mat-option>
      <mat-option id="sort-selection-place" value="place">
        {{ 'fundingProject.fields.location' | translate }}
      </mat-option>
      <mat-option id="sort-selection-requested" value="requested">
        {{ 'fundingProject.fields.requestedFundingValue' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon
    id="sort-direction-icon"
    class="sort-direction-icon"
    *ngIf="(isAscending$ | async) !== null"
    (click)="changeDirection()"
    role="button"
    [title]="
      (isAscending$ | async)
        ? ('fundingProject.sorting.sortUpwards' | translate)
        : ('fundingProject.sorting.sortDownwards' | translate)
    "
    >{{ (isAscending$ | async) ? 'arrow_upward' : 'arrow_downward' }}</mat-icon
  >
</div>
