<h2 mat-dialog-title>
  {{
    isEditable
      ? ('fundingProject.details.documents.dialog.documentCreate.editTitle'
        | translate)
      : ('fundingProject.details.documents.dialog.documentCreate.title'
        | translate)
  }}
</h2>
<form [formGroup]="createForm" (ngSubmit)="onSubmit()" novalidate>
  <mat-dialog-content class="mat-typography">
    <p>
      {{
        'fundingProject.details.documents.dialog.documentCreate.body'
          | translate
      }}
    </p>
    <p>
      <label for="document-title">
        <strong>{{
          'fundingProject.details.documents.dialog.documentCreate.titleLabel'
            | translate
        }}</strong>
        <span [style.color]="'red'"> *</span>
      </label>
      <input
        id="document-title"
        formControlName="title"
        [style.width]="'100%'"
        [style.height]="'1.5rem'"
        type="text"
      />
      <ng-container *ngIf="createForm.controls['title'].invalid &&
    (createForm.get('title')?.dirty ||
      createForm.get('title')?.touched)">
        <div class="validation-msg">
          <div *ngIf="createForm.get('title')?.errors?.required">
            {{ 'validationErrorMessages.required' | translate }}
          </div>
          <div *ngIf="createForm.get('title')?.errors?.minlength">
            {{ 'validationErrorMessages.minLength' | translate }}
          </div>
          <div *ngIf="createForm.get('title')?.errors?.maxlength">
            {{ 'validationErrorMessages.maxLength' | translate: { lengthVar: maxTitleLength } }}
          </div>
          <div *ngIf="createForm.get('title')?.errors?.pattern">
            {{ 'validationErrorMessages.forbiddenCharacters' | translate: { forbiddenChars: forbiddenSpecialChars } }}
          </div>
        </div>
      </ng-container>
    </p>
    <p>
      <label for="document-description"
        ><strong>{{
          'fundingProject.details.documents.dialog.documentCreate.descriptionLabel'
            | translate
        }}</strong></label
      >
      <textarea
        id="document-description"
        formControlName="description"
        [style.width]="'100%'"
        [style.resize]="'none'"
        [style.font]="'inherit'"
        rows="6"
      ></textarea>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <oaman-button
      mat-button
      [mat-dialog-close]="false"
      type="reset"
      [style.margin-right]="'1rem'"
    >
      {{
        'fundingProject.details.documents.dialog.documentCreate.cancelBtn'
          | translate
      }}
    </oaman-button>
    <oaman-button
      mat-button
      color="primary"
      [disabled]="!createForm.valid"
      [style.pointer-events]="!createForm.valid ? 'none' : 'auto'"
      type="submit"
    >
      {{
        'fundingProject.details.documents.dialog.documentCreate.saveBtn'
          | translate
      }}
    </oaman-button>
  </mat-dialog-actions>
</form>
