import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FundingProjectHistory } from '../../../../core/interfaces/funding-project-history';
import { FundingProject } from '../../../../core/interfaces/funding-project';

@Component({
  templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit {
  history!: FundingProjectHistory[];
  groupedHistoryEvents = new Map<string, Array<FundingProjectHistory>>();
  fundingProject!: FundingProject;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.history = data['resolvedData'];
    });
    this.route.parent?.parent?.data.subscribe((data) => {
      const resolvedData: FundingProject = data['resolvedData'];
      if (resolvedData) {
        this.fundingProject = resolvedData;
      }
    });
    this.initTimeline();
  }

  private initTimeline() {
    // initialize the history events by grouping them together by date
    this.history.forEach((entry: FundingProjectHistory) => {
      // check if entry already exists, if so push the element to the resulting array
      const prevEntry = this.groupedHistoryEvents.get(entry.date);
      if (prevEntry) {
        prevEntry.push(entry);
      } else {
        // otherwise create a new entry
        this.groupedHistoryEvents.set(entry.date, [entry]);
      }
    });
  }
}
