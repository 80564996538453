import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-cost-calculation-type',
  template: `
    <div fxLayout="row wrap" class="wrapper">
      <div fxFlex="30">
        <span [innerHTML]="props.label"></span>
        <ng-container
          *ngIf="props.required && props.hideRequiredMarker !== true"
        >
          <span class="asterisk">*</span>
        </ng-container>
      </div>
      <div fxFlex="70">
        <div fxFlex="33.33" *ngFor="let field of field.fieldGroup">
          <formly-field [field]="field"></formly-field>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        margin-bottom: 2rem;
        align-items: center;
        word-break: break-word;
      }
    `
  ]
})
export class FormCostCalculationTypeComponent extends FieldType<FieldTypeConfig> {}
