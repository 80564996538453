import { DialogService } from '../services/dialog.service';
import { FundingProjectRequest } from '../../core/interfaces/funding-project-request';

export enum DocumentTypes {
  BESCHEID = 'Bescheid',
  ANTRAG = 'Antrag'
}

export enum TypeApplicantDocumentTypes {
  ANTRAGSFORMULAR = 'Antragsformular'
}

export interface DocumentType {
  id: string;
  title: string;
  description: string;
  submissionEnabled: boolean;
}

export interface DocumentSubType {
  id: string;
  title: string;
  description: string;
}

export interface TypeApplicantDocument {
  id: string;
  title: string;
  description: string;
}

export interface DocumentTypeData {
  documentTypes: DocumentType[];
  documentSubTypes: DocumentSubType[];
}

export interface ProvideDocumentDialogInputData {
  activeRequest: string;
  allRequests: FundingProjectRequest[];
  documentTypes: DocumentType[];
  documentSubTypes: DocumentSubType[];
  fundingProjectTitle: string;
  fundingProjectId: string;
  dialogService: DialogService;
}

export class ProvideDocumentDialogInputData
  implements ProvideDocumentDialogInputData
{
  constructor(
    public activeRequest: string,
    public allRequests: FundingProjectRequest[],
    public documentTypes: DocumentType[],
    public documentSubTypes: DocumentSubType[],
    public fundingProjectTitle: string,
    public fundingProjectId: string,
    public dialogService: DialogService
  ) {}
}
