<div *ngIf="hasFundingGuidelineFilter()">
  <mat-form-field
    id="funding-guideline-form"
    appearance="outline"
    class="filter"
  >
    <mat-label id="funding-guideline-label">{{
      'fundingProject.filter.label.fundingGuideline' | translate
    }}</mat-label>
    <mat-select
      id="funding-guideline-select"
      [disableOptionCentering]="true"
      [title]="'fundingProject.filter.label.fundingGuidelineTitle' | translate"
      matNativeControl
      [value]="fundingGuidelineSelect$ | async"
      (selectionChange)="onChangeFundingGuideline($event.value)"
    >
      <mat-option
        [id]="'funding-guideline-option-' + i"
        *ngFor="let fundingGuideline of fundingGuidelines; let i = index"
        [value]="fundingGuideline.id"
        >{{ fundingGuideline.title }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<div>
  <mat-form-field id="state-form" appearance="outline" class="filter">
    <mat-label id="state-label">{{
      'fundingProject.filter.label.state' | translate
    }}</mat-label>
    <mat-select
      id="state-select"
      [disableOptionCentering]="true"
      [title]="'fundingProject.filter.label.stateTitle' | translate"
      matNativeControl
      [value]="stateSelect$ | async"
      (selectionChange)="onChangeState($event.value)"
    >
      <mat-option id="state-option-all" label="All" value="">{{
        'fundingProject.filter.selectorAll' | translate
      }}</mat-option>
      <mat-option
        [id]="'state-option-' + i"
        *ngFor="let state of states$ | async; let i = index"
        [value]="state.id"
        >{{ state.resourceKey | translate }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="hasRoleFilter()">
  <mat-form-field id="role-form" appearance="outline" class="filter">
    <mat-label id="role-label">{{
      'fundingProject.filter.label.role' | translate
    }}</mat-label>
    <mat-select
      id="role-select"
      [disableOptionCentering]="true"
      [title]="'fundingProject.filter.label.roleTitle' | translate"
      matNativeControl
      [value]="roleSelect$ | async"
      (selectionChange)="onChangeRole($event.value)"
    >
      <mat-option id="role-option-both" label="Both" value="">{{
        'fundingProject.filter.selectorAll' | translate
      }}</mat-option>
      <mat-option
        id="role-option-applicant"
        label="Applicant"
        value="applicant"
        >{{ 'fundingProject.filter.selectorApplicant' | translate }}</mat-option
      >
      <mat-option id="role-option-deputy" label="Deputy" value="deputy">{{
        'fundingProject.filter.selectorDeputy' | translate
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
