import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../spinner.component';
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private overlayRef!: OverlayRef;
  private isLoading = false;

  constructor(private overlay: Overlay) {}

  public show() {
    // Hide the overlay if it aleady exists from an earlier request
    if (this.isLoading) {
      this.overlayRef.detach();
    }

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }
    this.isLoading = true;
    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(SpinnerComponent);
    this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
  }

  public hide() {
    this.isLoading = false;
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
