import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  FundingProject,
  StateFundingProject
} from '../core/interfaces/funding-project';
import {
  AppConfiguration,
  FundingGuideline,
  FundingProjectFilter,
  PageOption
} from '../core/public-api';
import { APP_CONFIG } from '../core/util/app-config.token';
import { GuidelineProjectIds } from '../core/interfaces/guideline-project-ids';

@Component({
  selector: 'oaman-funding-projects',
  templateUrl: './funding-project.component.html',
  styleUrls: ['./funding-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingProjectComponent {
  context;
  // Projects overview
  @Input()
  fundingProjects$: FundingProject[] = [];
  @Input()
  totalPages: number = 0;
  @Input()
  projectFilter!: FundingProjectFilter;
  @Input()
  totalElements: number = 0;
  @Input()
  isListView$ = new Observable<boolean>();
  @Output()
  toggleEvent = new EventEmitter<MouseEvent>();

  // Sort component
  @Input()
  sortValue$ = new Observable<string>();
  @Input()
  isAscending$ = new Observable<boolean>();
  @Output()
  sortCriterion = new EventEmitter<string>();
  @Output()
  sortDirection = new EventEmitter<MouseEvent>();
  @Output()
  loadPageOut = new EventEmitter<PageOption>();

  // filter component
  @Input()
  fundingGuidelines: FundingGuideline[] = [];
  @Input()
  fundingGuidelineSelect$ = new Observable<string>();
  @Input()
  states$ = new Observable<StateFundingProject[]>();
  @Input()
  searchSelect$ = new Observable<string>();
  @Input()
  stateSelect$ = new Observable<string>();
  @Input()
  roleSelect$ = new Observable<string>();
  @Output()
  changeState = new EventEmitter<string>();
  @Output()
  changeRole = new EventEmitter<string>();
  @Output()
  changeFundingGuideline = new EventEmitter<string>();
  @Output()
  openDetails = new EventEmitter<GuidelineProjectIds>();
  @Output()
  setClerkAction = new EventEmitter<GuidelineProjectIds>();

  // search component
  @Output()
  searchQuery = new EventEmitter<string>();

  // Tracks if the user has changed a search or filter option
  // if true the ui is no longer hidden from the user when no projects are found
  changedFilter = false;

  reinitialiseInfiniteScroll$: Observable<boolean> = new Observable<boolean>();

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfiguration) {
    this.context = this.appConfig.context;
  }

  toggleViewChange(event: MouseEvent): void {
    event.preventDefault();
    this.toggleEvent.emit(event);
    this.reinitialiseInfiniteScroll();
  }

  onSearch($event: string) {
    this.searchQuery.emit($event);
    this.changedFilter = true;
    this.reinitialiseInfiniteScroll();
  }

  onSortCriterion($event: string) {
    this.sortCriterion.emit($event);
    this.reinitialiseInfiniteScroll();
  }

  onToggleSortDirection($event: MouseEvent) {
    this.sortDirection.emit($event);
    this.reinitialiseInfiniteScroll();
  }

  onChangeState($event: string) {
    this.changeState.emit($event);
    this.changedFilter = true;
    this.reinitialiseInfiniteScroll();
  }

  onChangeRole($event: string) {
    this.changeRole.emit($event);
    this.changedFilter = true;
  }

  onChangeFundingGuideline($event: string) {
    this.changeFundingGuideline.emit($event);
    this.changedFilter = true;
    this.reinitialiseInfiniteScroll();
  }

  passRouteIds(guidelineProjectIds: GuidelineProjectIds) {
    this.openDetails.emit(guidelineProjectIds);
  }
  passChangeClerk(guidelineProjectIds: GuidelineProjectIds) {
    this.setClerkAction.emit(guidelineProjectIds);
  }

  loadPage(pageOption: PageOption): void {
    this.loadPageOut.emit(pageOption);
  }

  reinitialiseInfiniteScroll() {
    this.reinitialiseInfiniteScroll$ = of(true);
  }
}
