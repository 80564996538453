import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateRequestDialogData } from '../interfaces/create-request-dialog-data';
import * as FundingProjectStates from '../../funding-project/funding-project-details/utils/fundingProjectStates';

@Component({
  selector: 'oaman-request-create-dialog',
  templateUrl: './request-create-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestCreateDialogComponent {
  form: UntypedFormGroup;
  approvedState = FundingProjectStates.APPROVED;

  constructor(
    public dialogRef: MatDialogRef<RequestCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateRequestDialogData
  ) {
    this.form = new UntypedFormGroup({
      requestType: new UntypedFormControl('', [Validators.required])
    });
  }
  onSubmit() {
    this.dialogRef.close(this.form.value.requestType);
  }
}
