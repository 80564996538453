<div
  id="oaman-card-funding-grid"
  fxLayout="row wrap"
  fxLayoutGap="1.5rem grid"
  fxLayoutAlign.lt-lg="flex-start"
  fxLayoutAlign="space-between"
  [style.align-items]="'flex-start'"
  infinite-scroll
  (scrolled)="loadNextPage()"
  [infiniteScrollDisabled]="page - 1 >= totalPages"
>
  <oaman-card-funding
    *ngFor="let funding of fundingProjects"
    [fundingProject]="funding"
    (hasClicked)="hasClickedPassthrough($event)"
    (hasClickedAssignClerk)="hasChangedClerkPassthrough($event)"
  ></oaman-card-funding>
</div>
