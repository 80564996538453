import { MatDialogRef } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import {
  UserObjectObservableService,
  getLoginTypeLabel
} from '../../core/public-api';

@Component({
  selector: 'oaman-low-trust-level-dialog',
  templateUrl: './low-trust-level-dialog.component.html',
  styleUrls: ['./low-trust-level-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LowTrustLevelDialogComponent extends BaseComponent {
  constructor(
    public dialogRef: MatDialogRef<LowTrustLevelDialogComponent>,
    public userObjectObservableService: UserObjectObservableService
  ) {
    super(userObjectObservableService);
  }

  getLoginTypeParam(): string {
    return getLoginTypeLabel(this.currentUser);
  }
}
