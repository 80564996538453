import { Component, Input } from '@angular/core';

@Component({
  selector: 'oaman-help-text',
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.css']
})
export class HelpTextComponent {
  @Input() helpText!: string;
}
