export const DRAFT = 'fundingProject.state.draft';
export const SUBMITTED = 'fundingProject.state.submitted';
export const APPROVED = 'fundingProject.state.approved';
export const DISBURSEMENT_REQUEST_RECEIVED =
  'fundingProject.state.disbursementRequestReceived';
export const LEGAL_ACTION = 'fundingProject.state.legalAction';
export const REJECTED = 'fundingProject.state.rejected';
export const WITHDRAWN = 'fundingProject.state.withdrawn';
export const DISBURSEMENT_REQUEST_APPROVED =
  'fundingProject.state.disbursementRequestApproved';
export const INTERIM_PROOF_APPROVED =
  'fundingProject.state.interimProofApproved';
