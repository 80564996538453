import { DataSource } from '@angular/cdk/collections';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isPlainObject as _isPlainObject } from 'lodash';
import { correctDecimalFieldValue } from '../../../../../../lib/core/util/field-utils';
import { validateIBAN } from 'ngx-iban-validator';

export function IntegerValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /^(0|\-?[1-9][0-9]*)$/.test(control.value) ? null : { integer: true };
}

export function IBANValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }

  const ibanIsValid = !validateIBAN({
    value: control.value
  })?.ibanInvalid;

  return ibanIsValid ? null : { iban: true };
}

export function DateValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/.test(
    control.value
  )
    ? null
    : { date: true };
}

export function FromBeforeToDateValidator(control: AbstractControl) {
  const { startDate, endDate } = control.value;

  // avoid displaying the message error when values are empty
  if (!startDate || !endDate) {
    return null;
  }

  return Date.parse(startDate) < Date.parse(endDate)
    ? null
    : { dateCompare: false };
}

export function BirthDateValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const selectedDob = Date.parse(control.value);
  const today = new Date();
  const maxDob = Date.parse(
    new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    ).toDateString()
  );

  if (selectedDob > maxDob) {
    return { invalidBirthdate: true };
  } else {
    return null;
  }
}

export function FoundingDateValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const selectedDate = Date.parse(control.value);
  const yesterday = Date.parse(new Date(Date.now() - 86400000).toDateString());

  if (selectedDate <= yesterday) {
    return null;
  } else {
    return { invalidFoundingDate: true };
  }
}

export function FutureDateValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const selectedDate = Date.parse(control.value);
  const today = Date.parse(new Date(Date.now()).toDateString());

  if (today < selectedDate) {
    return null;
  } else {
    return { invalidFutureDate: true };
  }
}

export function EmailValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/.test(control.value)
    ? null
    : { email: true };
}

export function PLZValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}/.test(control.value)
    ? null
    : { plz: true };
}

export function PhoneNumberValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /^((\+[0-9]{1,4}) ?|(\([0-9]{2,5}\)) ?)[0-9 ]{1,15}$/.test(
    control.value
  )
    ? null
    : { invalidPhone: true };
}

export function TaxNumberValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /^[0-9]{2}\/[0-9]{3}\/[0-9]{5}$/.test(control.value)
    ? null
    : { invalidTaxNumber: true };
}

export function BICValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}) {0,1}/.test(
    control.value
  )
    ? null
    : { bic: true };
}

export function RegisterNrValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  return /([0-9]){15}/.test(control.value) ? null : { regnr: true };
}

export function MeasureNr1Validator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  // ensure a numeric digit between 0 and 9
  return /^[0-9]{1}$/.test(control.value) ? null : { measureNr1: true };
}

export function MeasureNr2Validator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  // ensure a number between 001 and 999
  return /^(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})$/.test(control.value)
    ? null
    : { measureNr2: true };
}

export function MultiCheckBoxValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value || !_isPlainObject(control.value)) {
    return null;
  }

  return Object.values(control.value).some((val) => val === true)
    ? null
    : ({ multiCheckRequired: true } as ValidationErrors);
}

export function CostValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  // Correct value if possible
  if (typeof control.setValue === 'function') {
    correctDecimalFieldValue(control);
  }
  // the maximum allowed decimal number is 2 for the cost
  return /^\d+(?:\.\d{1,2})?$/.test(control.value)
    ? null
    : ({ invalidDecimal2: true } as ValidationErrors);
}

export function Max4FloatingNumbersValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  // the maximum allowed decimal number is 4
  return /^\d+(?:\.\d{1,4})?$/.test(control.value)
    ? null
    : ({ invalidDecimal4: true } as ValidationErrors);
}

export function WholeNumberValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  // only whole numbers
  return /^\d+$/.test(control.value)
    ? null
    : ({ notWholeNumber: true } as ValidationErrors);
}

export function YearValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  // only within the range of 1900 and 2099
  return /^(?:19|20)\d{2}$/.test(control.value)
    ? null
    : ({ invalidYear: true } as ValidationErrors);
}

export function OnlyNumeric(value: string) {
  return isFinite(Number(value)) && value !== ' ';
}
