<mat-toolbar id="footer">
  <div class="content">
    <div class="links-and-logo">
      <div class="links">
        <button routerLink="/contact" mat-button>Kontakt</button>
        <a href="assets/files/OAMan ÄrL Anleitung für Antragsteller.pdf"
           download="OAMan ÄrL Anleitung für Antragsteller.pdf"
           mat-button
        >Anleitung für Antragsteller</a>
        <a href="/assets/files/FAQ_Antragsteller.pdf"
           download="FAQ_Antragsteller.pdf"
           mat-button
        >Häufig gestellte Fragen</a>
        <button routerLink="/privacy" mat-button>Datenschutz</button>
        <button routerLink="/impressum" mat-button>Impressum</button>
      </div>
      <div class="logo">
        <img src="assets/logo-niedersachsen.png" />
      </div>
    </div>
    <div class="copyright">
      <p>© {{ currentDate | date:'yyyy' }}. Alle Rechte geschützt</p>
    </div>
  </div>
</mat-toolbar>
