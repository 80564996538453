<div class="wrapper">
  <div fxLayout="row wrap" fxLayout.lt-sm="column wrap" fxLayoutGap="3rem">
    <oaman-action-sheet
      icon="toc"
      text="{{ 'home.myProjects' | translate }}"
      routerLink="/funding-projects"
    ></oaman-action-sheet>
    <oaman-action-sheet
      icon="add_circle"
      text="{{ 'home.createProject' | translate }}"
      routerLink="/funding-projects/create"
    ></oaman-action-sheet>
  </div>
</div>
