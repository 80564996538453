import { FundingProjectRequest } from '../../core/interfaces/funding-project-request';
import {
  DocumentType,
  DocumentSubType,
  ProvideDocumentDialogInputData
} from '../interfaces/document-dialog-data';
import { DocumentProvideDialogComponent } from '../document-provide-dialog/document-provide-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../spinner/services/spinner.service';
import { DialogComponent } from '../dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { DialogData } from '../interfaces/dialog-data';
import { ErrorDialogData } from '../interfaces/error-dialog-data';
import { UploadDialogData } from '../interfaces/upload-dialog-data';
import { DeleteDialogData } from '../interfaces/delete-dialog-date';
import { AssignClerkDialogComponent } from '../assign-clerk-dialog/assign-clerk-dialog.component';
import { User } from '../../core/interfaces/user';
import {
  FundingProject,
  FundingProjectAuthority,
  StateFundingProject
} from '../../core/public-api';
import { DocumentCreateDialogComponent } from '../document-create-dialog/document-create-dialog.component';
import { DocumentSubmitDialogComponent } from '../document-submit-dialog/document-submit-dialog.component';
import { DocumentRequestDialogComponent } from '../document-request-dialog/document-request-dialog.component';
import { DocumentInfoDialogComponent } from '../document-info-dialog/document-info-dialog.component';
import { Observable } from 'rxjs';
import {
  ProjectActionData,
  StateChangeProjectActionData
} from '../../funding-project/public-api';
import { SubmitDialogData } from '../interfaces/submit-dialog-data';
import { RequestCreateDialogComponent } from '../request-create-dialog/request-create-dialog.component';
import { CreateRequestDialogData } from '../interfaces/create-request-dialog-data';
import { InquiryCreateDialogComponent } from '../inquiry-create-dialog/inquiry-create-dialog.component';
import { AnswerDialogComponent } from '../answer-dialog/answer-dialog.component';
import { HttpErrorDialogComponent } from '../http-error-dialog/http-error-dialog.component';
import { ProjectWithdrawalDialogData } from '../interfaces/project-withdrawal-dialog-data';
import { ProjectWithdrawalDialogComponent } from '../project-withdrawal-dialog/project-withdrawal-dialog.component';
import { AddDeputyDialogComponent } from '../deputy/add-deputy-dialog/add-deputy-dialog.component';
import { InactivateDeputyDialogComponent } from '../deputy/inactivate-deputy-dialog/inactivate-deputy-dialog.component';
import { InactivateDeputyDialogData } from '../interfaces/deputy-dialog-data';
import { LowTrustLevelDialogComponent } from '../low-trust-level-dialog/low-trust-level-dialog.component';
import { ProjectStateChangeDialogComponent } from '../project-state-change-dialog/project-state-change-dialog.component';
import { ProjectStateChangeDialogData } from '../interfaces/project-state-dialog-data';
import { ProjectUpdateDialogComponent } from '../project-update-dialog/project-update-dialog.component';
import { DataProtectionConsentDialogComponent } from '../data-protection-consent-dialog/data-protection-consent-dialog.component';
import { FormValidInfoDialogComponent } from '../form-valid-info-dialog/form-valid-info-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  type = '';

  constructor(
    private matDialogService: MatDialog,
    private spinner: SpinnerService
  ) {}

  // generic dialog component
  // optional submitText attribute adds an additional button to agree, confirm, submit...
  openDialog(title: string, message: string, submitText?: string) {
    const dialogRef = this.matDialogService.open(DialogComponent, {
      width: '25rem',
      data: {
        message,
        title,
        submitText
      } as DialogData,
      hasBackdrop: true,
      disableClose: !!submitText // disable close when the dialog has a submit button
    });
    // returns the ref to handle the close event, returns true on confirm
    return dialogRef.afterClosed();
  }

  openAssignClerkDialog(
    projectAuthority: FundingProjectAuthority,
    currentUser: User,
    allUsers: User[],
    assignedToCurrentUser: boolean
  ) {
    const dialogRef = this.matDialogService.open(AssignClerkDialogComponent, {
      width: '25rem',
      data: {
        projectAuthority,
        currentUser,
        allUsers,
        assignedToCurrentUser
      },
      hasBackdrop: true,
      disableClose: true
    });
    // returns the ref to handle the close event, returns true on confirm
    return dialogRef.afterClosed();
  }

  openErrorDialog(message: string, title: string, status?: number) {
    this.spinner.hide();
    const dialogRef = this.matDialogService.open(ErrorDialogComponent, {
      data: {
        message,
        title,
        status
      } as ErrorDialogData,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openHttpErrorDialog(status: number, message: string, title?: string) {
    this.spinner.hide();
    const dialogRef = this.matDialogService.open(HttpErrorDialogComponent, {
      width: '35rem',
      data: {
        status,
        title,
        message
      } as ErrorDialogData,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openFormValidDialog(event: string) {
    this.spinner.hide();
    const dialogRef = this.matDialogService.open(FormValidInfoDialogComponent, {
      width: '40rem',
      data: {
        event
      } as DialogData,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openUploadDialog(
    title: string,
    fundingProjectId: string,
    documentId: string
  ) {
    const dialogRef = this.matDialogService.open(UploadDialogComponent, {
      width: '35rem',
      data: {
        title,
        fundingProjectId,
        documentId
      } as UploadDialogData,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openDocumentSubmitDialog(title: string) {
    const dialogRef = this.matDialogService.open(
      DocumentSubmitDialogComponent,
      {
        width: '35rem',
        data: {
          title
        } as SubmitDialogData,
        hasBackdrop: true,
        disableClose: true
      }
    );
    dialogRef.updateSize('35rem');
    return dialogRef.afterClosed();
  }

  openDeleteDialog(data: DeleteDialogData) {
    const dialogRef = this.matDialogService.open(DeleteDialogComponent, {
      width: '35rem',
      data,
      hasBackdrop: true,
      disableClose: true
    });
    dialogRef.updateSize('35rem');
    return dialogRef.afterClosed();
  }

  openCreateDocumentDialog(
    data?: ProjectActionData
  ): Observable<ProjectActionData | false> {
    const dialogRef = this.matDialogService.open(
      DocumentCreateDialogComponent,
      {
        width: '35rem',
        data,
        hasBackdrop: true,
        disableClose: true
      }
    );
    return dialogRef.afterClosed();
  }

  openRequestDocumentDialog(
    data?: ProjectActionData
  ): Observable<{ data: ProjectActionData; repeat: boolean } | false> {
    const dialogRef = this.matDialogService.open(
      DocumentRequestDialogComponent,
      {
        width: '45rem',
        data,
        hasBackdrop: true,
        disableClose: true
      }
    );
    return dialogRef.afterClosed();
  }

  openInfoDocumentDialog(
    data?: ProjectActionData
  ): Observable<{ data: ProjectActionData; repeat: boolean } | false> {
    const dialogRef = this.matDialogService.open(DocumentInfoDialogComponent, {
      width: '45rem',
      data,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openCreateRequestDialog(data: CreateRequestDialogData): Observable<string> {
    const dialogRef = this.matDialogService.open(RequestCreateDialogComponent, {
      width: '35rem',
      data,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openCreateInquiryDialog(
    data?: ProjectActionData
  ): Observable<ProjectActionData | false> {
    const dialogRef = this.matDialogService.open(InquiryCreateDialogComponent, {
      width: '35rem',
      data,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openAnswerDialog(): Observable<{ message: string } | false> {
    const dialogRef = this.matDialogService.open(AnswerDialogComponent, {
      width: '35rem',
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openProvideDocumentDialog(
    allRequests: FundingProjectRequest[],
    activeRequest: string,
    documentTypes: DocumentType[],
    documentSubTypes: DocumentSubType[],
    fundingProjectTitle: string,
    fundingProjectId: string
  ): Observable<any> {
    const dialogRef = this.matDialogService.open(
      DocumentProvideDialogComponent,
      {
        width: '35rem',
        data: new ProvideDocumentDialogInputData(
          activeRequest,
          allRequests,
          documentTypes,
          documentSubTypes,
          fundingProjectTitle,
          fundingProjectId,
          this
        ),
        hasBackdrop: true,
        disableClose: true
      }
    );
    return dialogRef.afterClosed();
  }

  openProjectWithdrawalDialog(projectTitle: string, projectState: string) {
    const dialogRef = this.matDialogService.open(
      ProjectWithdrawalDialogComponent,
      {
        width: '35rem',
        data: { projectTitle, projectState } as ProjectWithdrawalDialogData,
        hasBackdrop: true,
        disableClose: true
      }
    );
    dialogRef.updateSize('35rem');
    return dialogRef.afterClosed();
  }

  openAddDeputyDialog(data: StateChangeProjectActionData): Observable<any> {
    const dialogRef = this.matDialogService.open(AddDeputyDialogComponent, {
      width: '38rem',
      data: data.stateFundingProject,
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openDeputyInactivationDialog(userName: string) {
    const dialogRef = this.matDialogService.open(
      InactivateDeputyDialogComponent,
      {
        width: '35rem',
        data: { userName } as InactivateDeputyDialogData,
        hasBackdrop: true,
        disableClose: true
      }
    );
    return dialogRef.afterClosed();
  }

  openLowTrustLevelDialog() {
    const dialogRef = this.matDialogService.open(LowTrustLevelDialogComponent, {
      width: '35rem',
      hasBackdrop: true,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }

  openProjectStateChangeDialog(
    projectState: StateFundingProject,
    availableStates: StateFundingProject[]
  ) {
    const dialogRef = this.matDialogService.open(
      ProjectStateChangeDialogComponent,
      {
        width: '35rem',
        data: { projectState, availableStates } as ProjectStateChangeDialogData,
        hasBackdrop: true,
        disableClose: true
      }
    );
    dialogRef.updateSize('35rem');
    return dialogRef.afterClosed();
  }

  openProjectUpdateDialog(fundingProject: FundingProject) {
    const dialogRef = this.matDialogService.open(ProjectUpdateDialogComponent, {
      width: '35rem',
      data: fundingProject,
      hasBackdrop: true,
      disableClose: true
    });
    dialogRef.updateSize('35rem');
    return dialogRef.afterClosed();
  }

  openDataProtectionConsentDialog(
    data?: string
  ): Observable<ProjectActionData | false> {
    const dialogRef = this.matDialogService.open(
      DataProtectionConsentDialogComponent,
      {
        width: '35rem',
        data,
        hasBackdrop: true,
        disableClose: true
      }
    );
    return dialogRef.afterClosed();
  }
}
