import { Inject } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfiguration } from '../../core/public-api';
import { APP_CONFIG } from '../../core/util/app-config.token';
import {
  FundingProjectDocument,
  FundingProjectDocumentWithAppProperties
} from '../../funding-project/public-api';

@Component({
  selector: 'oaman-document-info-dialog',
  templateUrl: './document-info-dialog.component.html',
  styleUrls: ['./document-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentInfoDialogComponent {
  isEnroll;
  isRequested;
  document: FundingProjectDocument;
  connectorType!: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: FundingProjectDocumentWithAppProperties,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    public dialogRef: MatDialogRef<DocumentInfoDialogComponent>
  ) {
    this.document = data.fundingProjectDocument;
    this.connectorType = data?.connectorType;
    this.isEnroll = appConfig.context === 'enroll';
    this.isRequested = !!this.document.requestedDate;
  }
  public checkForTitle(title: string) {
    if (
      this.appConfig?.context === 'manage' &&
      this.connectorType === 'OAMAN'
    ) {
      let tempTitle = title.split('_');
      //removing the last elem, when it comes from Leader
      if (tempTitle.length > 1) {
        let index = tempTitle.length - 1;
        //check if elem is an uuid.
        let regex = /^[a-z,0-9,-]{36,36}$/;
        if (regex.test(tempTitle[index])) {
          tempTitle.splice(index, 1);
        }
      }
      //rebuilding the title from array.
      return tempTitle.join('_');
    }
    return title;
  }
}
