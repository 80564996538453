<oaman-detail-actions
  [actions]="documentActions"
  [fundingProject]="fundingProject"
  (clickTriggered)="openDialog($event)"
></oaman-detail-actions>
<div fxLayout="row wrap" fxLayout.lt-sm="column wrap">
  <div fxFlex="25">
    <oaman-filter-element
      [applicationFilterVisible]="applicationFilterVisible"
      class="filters"
      [requiredDocumentCount]="requiredDocumentCount"
      [projectTitle]="fundingProject.title"
    ></oaman-filter-element>
  </div>
  <div fxFlex>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <p class="tz-hint">
      {{ 'fundingProject.details.documents.table.timezone' | translate }}
      {{ currentDate | date: 'zzzz' }}
    </p>
  </div>
</div>
