import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexModule } from '@angular/flex-layout';
import { NotFoundErrorComponent } from './not-found-error/not-found-error.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NotFoundErrorComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FlexModule,
    TranslateModule
  ],
  exports: [NotFoundErrorComponent]
})
export class ErrorModule {}
