import { TranslateService } from '@ngx-translate/core';
import { HttpStatusCode } from './http-status';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { SpinnerService } from '../../spinner/services/spinner.service';
import { DialogService } from '../../dialog/services/dialog.service';
import { ErrorDialogActionService } from './errorDialogAction.service';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  isErrorActive = false;
  constructor(
    private dialogService: DialogService,
    private spinner: SpinnerService,
    private zone: NgZone,
    private errorDialogActionService: ErrorDialogActionService,
    private translateService: TranslateService
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    this.spinner.hide();
    console.error(error);
    if (!this.isErrorActive) {
      this.isErrorActive = true;
      if (this.isAuthFailure(error)) {
        let authErorrMsg =
          error instanceof HttpErrorResponse && error.error?.msgId
            ? this.translateService.instant(error.error?.msgId)
            : '';
        this.displayHttpError(HttpStatusCode.Unauthorized, authErorrMsg);
        return;
      }

      if (error instanceof HttpErrorResponse) {
        this.displayHttpError(
          error.status,
          this.getErrorMessage(error),
          this.getErrorTitle(error)
        );
        return;
      }

      this.zone.run(() => {
        this.dialogService
          .openErrorDialog(error.message, 'Fehler')
          .subscribe(() => {
            this.isErrorActive = false;
          });
      });
    }
  }

  private isAuthFailure(error: Error | HttpErrorResponse): boolean {
    if (
      error instanceof HttpErrorResponse &&
      error.status === HttpStatusCode.Unauthorized
    ) {
      return true;
    }

    let errorMsg = error.message;
    if (!errorMsg) {
      return false;
    }

    return errorMsg.includes(
      '"status":' + HttpStatusCode.Unauthorized.toString()
    );
  }

  private displayHttpError(
    status: number,
    message: string,
    title?: string
  ): void {
    this.zone.run(() => {
      this.dialogService
        .openHttpErrorDialog(status, message, title)
        .subscribe((value) => {
          if (value && status === HttpStatusCode.Unauthorized) {
            // for 401 (unaithorized) error code we have to logout the user and redirect to the login screen
            this.errorDialogActionService.logout();
          }
          this.isErrorActive = false;
        });
    });
  }

  private getErrorMessage(error: Error | HttpErrorResponse): string {
    if (error instanceof HttpErrorResponse && error.error?.data?.msgId) {
      var params = this.getErrorParams(error.error.data.params);
      if (params) {
        return this.translateService.instant(error.error.data.msgId, params);
      }

      return this.translateService.instant(error.error.data.msgId);
    }

    return '';
  }

  private getErrorTitle(error: Error | HttpErrorResponse): string | undefined {
    if (error instanceof HttpErrorResponse && error.error?.data?.titleId) {
      return this.translateService.instant(error.error.data.titleId);
    }

    return undefined;
  }

  private getErrorParams(params?: string[]): Object | null {
    if (!params || params.length === 0) {
      return null;
    }

    // transforms the given params array to Object of type: {0: 'paramValue1', 1: 'paramValue2', ...}
    return Object.assign({}, params);
  }
}
