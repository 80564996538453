import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexModule } from '@angular/flex-layout';
import { CardFundingGridComponent } from './card-funding-grid.component';
import { CardFundingComponent } from './card-funding/card-funding.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button/button.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [CardFundingGridComponent, CardFundingComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexModule,
    TranslateModule,
    ButtonModule,
    InfiniteScrollModule
  ],
  exports: [CardFundingGridComponent, CardFundingComponent]
})
export class CardFundingGridModule {}
