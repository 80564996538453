import { HostListener, Input, Directive } from '@angular/core';
import { OnlyNumeric } from '../utils/custom-validators';

@Directive({
  selector: '[oamanCleanNumber]'
})
export class CleanNumberDirective {
  @Input() cleanNumber: any;
  @HostListener('keypress', ['$event']) public onKeypress(
    event: KeyboardEvent
  ): void {
    if (!OnlyNumeric(event.key)) {
      event.preventDefault();
    }
  }
}
