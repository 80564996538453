import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'oaman-form-radio-type',
  template: `
    <div *ngFor="let btn of props.options">
      <input
        type="radio"
        [name]="btn.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
      <label>{{ btn.label }}</label>
    </div>
  `,
  styles: [``]
})
export class FormRadioComponent extends FieldType<FieldTypeConfig> {}
