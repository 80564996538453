export const environment = {
  mode: 'dev',
  production: false,
  authIssuer:
    'https://auth.oaman-dev.computacenter.io/auth/realms/oaman-ext-broker',
  authTokenEndpoint:
    'https://auth.oaman-dev.computacenter.io/auth/realms/oaman-ext-broker/protocol/openid-connect/token',
  authClientId: 'oaman-enroll',
  authResponseType: 'code',
  authScope: 'openid profile email offline_access',
  authDebug: true,
  authRedirectUrl: window.location.origin + '/login',
  backendApiUrl: 'https://enroll-api.oaman-dev.computacenter.io',
  enableBasicAuth: true
};
