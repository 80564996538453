import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { InactivateDeputyDialogData } from '../../interfaces/deputy-dialog-data';

@Component({
  selector: 'oaman-inactivate-deputy-dialog',
  templateUrl: './inactivate-deputy-dialog.component.html',
  styleUrls: ['./inactivate-deputy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InactivateDeputyDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InactivateDeputyDialogData
  ) {}
}
