import { Component } from '@angular/core';

@Component({
  selector: 'app-info-unternehmenskonto',
  templateUrl: './info-unternehmenskonto.component.html',
  styleUrls: ['./info-unternehmenskonto.component.scss']
})
export class InfoUnternehmenskontoComponent {
  panelOpenState: Array<boolean> = [];
  panelHeaderHeight = '100%';
}
