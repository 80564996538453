<div class="timeline-wrapper">
  <ul>
    <li *ngFor="let entry of timelineEntries">
      <div class="content">
        <h3>{{ entry.displayText }}</h3>
      </div>
      <ng-container
        *ngIf="
          entry.displayText === ('timeline.today' | translate);
          else clickableBtn
        "
      >
        <span class="dot grey"></span>
      </ng-container>
      <ng-template #clickableBtn>
        <span
          class="dot"
          role="button"
          [title]="'timeline.openDetails' | translate"
          tabindex="0"
          (click)="openDetails(entry)"
          (keyup.enter)="openDetails(entry)"
          [matBadge]="getBadgeCount(entry)"
        ></span>
      </ng-template>
      <div class="date">
        <h4>{{ entry.date | date }}</h4>
      </div>
    </li>
    <div style="clear: both"></div>
  </ul>
</div>
