import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FieldType,
  FormlyFieldConfig,
  FieldTypeConfig
} from '@ngx-formly/core';
import { SaveFormObserverService } from '../../services/save-form-observer.service';
import { SaveForm, SaveModel } from '../../interfaces/save-model';
import { DialogService } from '../../../../../../dialog/services/dialog.service';

@Component({
  selector: 'oaman-form-navigation-type',
  template: `
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      class="group-wrapper"
    >
      <mat-accordion class="form-navigation" fxFlex="25" fxFlex.lt-md="100">
        <mat-expansion-panel
          class="mat-elevation-z0 selection-group"
          *ngFor="let subField of allFieldGroups; let groupIndex = index"
          [expanded]="subField === activeGroup"
        >
          <mat-expansion-panel-header
            (click)="setActivePage(groupIndex, 0)"
            (keyup.enter)="setActivePage(groupIndex, 0)"
          >
            <mat-panel-title class="group-title">
              {{ subField.props.label }}
            </mat-panel-title>
            <mat-panel-description
              *ngIf="checkAnyInvalid(groupIndex)"
              class="nav-header"
            >
              <mat-icon
                [matTooltip]="
                  'fundingProject.details.forms.groupWarning' | translate
                "
                class="warn-icon"
                >info</mat-icon
              >
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list>
            <mat-list-item
              *ngFor="
                let listItem of subField.fieldGroup;
                let pageIndex = index
              "
              (click)="setActivePage(groupIndex, pageIndex)"
              (keyup.enter)="setActivePage(groupIndex, pageIndex)"
              role="button"
              tabindex="0"
              class="selection-item"
              [ngStyle]="{
                margin: isSpecialFieldGroup(groupIndex) ? '0 0 1rem' : '0 0 0',
                'min-height': '3rem',
                height: 'auto'
              }"
              [ngClass]="activePage === listItem ? 'active-selection' : ''"
            >
              <span class="selection-item-text">{{
                listItem.props.label
              }}</span>
              <mat-icon
                class="warn-icon"
                [ngClass]="setNavListIndex(groupIndex)"
                [matTooltip]="
                  'fundingProject.details.forms.pageWarning' | translate
                "
                *ngIf="anyInvalid(listItem.fieldGroup)"
                >info</mat-icon
              >
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
      <div fxFlex="50" fxFlex.lt-md="100" fxLayout="column wrap">
        <formly-field [field]="activePage"></formly-field>
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="center">
          <div
            class="navigation-button"
            (click)="setPreviousPage()"
            (keyup.enter)="setPreviousPage()"
            [ngClass]="
              selectedGroupIndex === 0 && selectedPageIndex === 0
                ? 'disabled'
                : ''
            "
            role="button"
            tabindex="0"
          >
            <mat-icon>fast_rewind</mat-icon>
            <u>
              {{ 'fundingProject.details.forms.previousPage' | translate }}
            </u>
          </div>
          <div
            class="navigation-button"
            role="button"
            tabindex="0"
            (click)="setNextPage()"
            (keyup.enter)="setNextPage()"
            [ngClass]="isLastElement()"
          >
            <u>{{ 'fundingProject.details.forms.nextPage' | translate }}</u>
            <mat-icon>fast_forward</mat-icon>
          </div>
        </div>
      </div>
      <div fxFlex="20"></div>
    </div>
  `,
  styleUrls: ['./form-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormNavigationComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnChanges
{
  // lots of typescript null checks here since all fieldGroups are optional
  allFieldGroups: FormlyFieldConfig[] = [];
  // currently selected form group
  selectedGroupIndex = 2;
  // currently selected page
  selectedPageIndex = 0;

  showedFormValidDialog = false;

  constructor(
    private saveFormObserverService: SaveFormObserverService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef,
    private zone: NgZone,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    if (this.field.fieldGroup) this.allFieldGroups = this.field.fieldGroup;

    // accepts queryparams, group and page, loads the first page by default
    this.route.queryParams.subscribe((params) => {
      if (params.group) {
        this.selectedGroupIndex = params.group;
      }
      if (params.page) {
        this.selectedPageIndex = params.page;
      }
    });

    if (this.form.valid) {
      this.showedFormValidDialog = true;
    }
    this.setupConditionalRendering();
  }

  isSpecialFieldGroup(groupIndex: number) {
    return (
      this.allFieldGroups.length > 1 &&
      groupIndex == this.allFieldGroups.length - 1
    );
  }
  isLastElement() {
    // check if the last element of the form is reached
    if (this.selectedGroupIndex >= this.allFieldGroups.length - 1) {
      const lastIndex = this.activeGroup?.fieldGroup?.length;
      if (lastIndex && this.selectedPageIndex >= lastIndex - 1) {
        return 'disabled';
      }
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges) {
    this.anyInvalid(this.allFieldGroups);
  }

  setActivePage(groupIndex: number, pageIndex: number) {
    // sets the active page on click
    this.selectedGroupIndex = groupIndex;
    this.selectedPageIndex = pageIndex;
    this.scrollToTop();
    this.saveModel();
  }

  setNextPage(skipModelSave?: boolean) {
    // if the amount of is smaller than the current page index, switch to the next group
    const currentGroupPageCount = this.activeGroup?.fieldGroup?.length;
    if (typeof currentGroupPageCount === 'number') {
      if (currentGroupPageCount - 1 <= this.selectedPageIndex) {
        this.selectedGroupIndex++;
        this.selectedPageIndex = 0;
      } else {
        this.selectedPageIndex++;
      }
      this.scrollToTop();
      if (!skipModelSave) {
        this.saveModel();
      }
    }
  }

  setPreviousPage() {
    // if the amount of is smaller than the current page index, switch to the next group
    if (this.selectedPageIndex === 0) {
      if (this.selectedGroupIndex > 0) {
        this.selectedGroupIndex--;
        const lastGroupLangth = this.activeGroup?.fieldGroup?.length;
        // check if the last group has any elements
        if (lastGroupLangth && lastGroupLangth > 0) {
          this.selectedPageIndex = lastGroupLangth - 1;
        }
      }
    } else {
      this.selectedPageIndex--;
    }
    this.scrollToTop();
    this.saveModel();
  }

  scrollToTop() {
    // select the app wrapper and scroll to top
    const appWrapperElem = document.querySelector('.app-wrapper');
    if (appWrapperElem) {
      appWrapperElem.scrollTop = 0;
    }
  }

  saveModel() {
    if (this.options?.formState?.disabled) {
      // nothing to save if the form is disabled
      return;
    }

    const saveForm: SaveForm = {
      context: 'page',
      saveModel: <SaveModel>{
        fundingGuidelineId:
          this.route.snapshot.parent?.parent?.parent?.params.id,
        fundingProjectId: this.route.snapshot.parent?.params.id,
        templateId: this.route.snapshot.params.formId,
        model: JSON.stringify(this.model),
        isValid: this.form.valid,
        requestId: this.route.snapshot.params.requestId
      }
    };
    if (this.form.dirty) {
      if (this.form.valid && !this.showedFormValidDialog) {
        this.zone.run(() => {
          this.dialogService
            .openFormValidDialog('FormChangedToValid')
            .subscribe(() => {
              //some logic if it´s needed.
              this.showedFormValidDialog = true;
              this.saveFormObserverService.publish(saveForm);
            });
        });
      } else {
        this.saveFormObserverService.publish(saveForm);
      }
    }
  }

  anyInvalid(fieldGroup: FormlyFieldConfig[]) {
    for (const field of fieldGroup) {
      if (field.key && field.formControl?.invalid) {
        return true;
      }
    }
    return false;
  }

  //used to marc all mat-icon-tag with the respective groupIndex
  setNavListIndex(index: number): string {
    return `navlist-${index}`;
  }

  checkAnyInvalid(groupIndex: number) {
    if (!this.form.valid) {
      const result = this.elementRef.nativeElement.querySelector(
        `.navlist-${groupIndex}`
      );
      this.showedFormValidDialog = false;
      return !!result;
    }
    return false;
  }

  get activeGroup() {
    const selectedGroup = this.allFieldGroups[this.selectedGroupIndex];
    if (selectedGroup) {
      return selectedGroup;
    }
    return null;
  }

  get activePage() {
    const selectedGroup = this.activeGroup?.fieldGroup;
    if (selectedGroup) {
      //console.log(`See- Key: ${selectedGroup[this.selectedPageIndex].key} and Id: ${selectedGroup[this.selectedPageIndex].id}`)
      return selectedGroup[this.selectedPageIndex];
    }
    return null;
  }

  /**
   * This is a support function for the createHook() method in form-helper.ts
   * that helps initialize hooks in filedGroups
   * @private
   */
  private setupConditionalRendering() {
    // navigate to next page after 10 nano secs to initialize field hooks (see forms.helper.ts)

    setTimeout(() => {
      // we don't need to trigger the model saving at this point,
      // because that will be triggered from the next timeout when the form is fully initialized
      this.setNextPage(true /*skipModelSave*/);
    }, 10);

    // after initialization of hooks, go back to first page

    setTimeout(() => {
      this.setActivePage(0, 0);
      this.ref.markForCheck();
    }, 400);
  }
}
