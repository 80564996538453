import { Pipe, PipeTransform } from '@angular/core';
import { FundingProjectFile } from '../interfaces/funding-project-file';

@Pipe({
  name: 'fileSearch'
})
export class FileSearchPipe implements PipeTransform {
  transform(value: FundingProjectFile[], args?: string): FundingProjectFile[] {
    if (!value) return [];
    if (args) {
      args = args.toLowerCase();
      return value.filter((data) => {
        if (args)
          return (
            data.author.toLowerCase().includes(args) ||
            data.title.toLowerCase().includes(args) ||
            data.fileType.toLowerCase().includes(args)
          );
        return data;
      });
    }
    return value;
  }
}
