<h2 [style.white-space]="'nowrap'" mat-dialog-title>
  {{ 'fundingProject.details.consents.dialog.title' | translate }}
</h2>
<mat-dialog-content class="mat-typography">
  <p>
    {{ 'fundingProject.details.consents.dialog.' + data | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button
    mat-button
    type="reset"
    [style.margin-right]="'1rem'"
    (click)="onClick(false)"
  >
    {{ 'fundingProject.details.consents.dialog.cancelBtn' | translate }}
  </oaman-button>
  <oaman-button mat-button color="primary" (click)="onClick(true)">
    {{ 'fundingProject.details.consents.dialog.withdrawBtn' | translate }}
  </oaman-button>
</mat-dialog-actions>
