import { User } from 'oaman-components';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../config/app-config.service';
import { isCookieWithValue } from '../constants/cookie-constants';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    private env: AppConfigService
  ) {}

  fetchCurrentUser(invitationKey?: string): Observable<User> {
    let userUrl = `${this.env.getConfig().backendApiUrl}/users/current`;
    if (isCookieWithValue(invitationKey)) {
      userUrl += `?key=${invitationKey}`;
    }

    return this.http.get<User>(userUrl);
  }

  validateInvitationKey(invitationKey: string): Observable<string> {
    let validationUrl = `${
      this.env.getConfig().backendApiUrl
    }/users/invitationcheck?key=${invitationKey}`;

    return this.http.get<string>(validationUrl);
  }
}
