import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from '../public-api';

@Injectable({ providedIn: 'root' })
export class UserObjectObservableService {
  private user: Subject<User> = new ReplaySubject<User>(1);

  get userObject$() {
    return this.user.asObservable().pipe(filter((node) => !!node));
  }

  publish(data: User | null) {
    if (data) this.user.next(data);
  }
}
